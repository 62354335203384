// global imports
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { StyleRules } from '@material-ui/core/styles';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Button from '../../components/CustomButtons/Button.jsx';
import { disconnectRequest } from '../../store/actions/common_actions';

// project types imports
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const DisconnectWidget: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, appName, loading } = props;

  const disconnect = () => {
    props.disconnectRequest({ system: appName });
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          {loading[appName] && <LinearProgress className={classes.contentLarge} />}
          {!loading[appName] && (
            <>
              <a className={classes.disconnect} {...bindTrigger(popupState)}>
                {translate('disconnect')}
              </a>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className={classes.confirmationContainer}>
                  <h5 className={classes.confirmationHeading}>
                    {translate('disconnect_confirmation', { appName: translate(`${appName}_app_name`) })}
                  </h5>
                  <div className={classes.buttonsContainer}>
                    <Button color="danger" size="sm" onClick={disconnect}>
                      {translate('yes')}
                    </Button>
                    <Button color="info" size="sm" onClick={popupState.close}>
                      {translate('no')}
                    </Button>
                  </div>
                </div>
              </Popover>
            </>
          )}
        </div>
      )}
    </PopupState>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  loading: {
    prestashop: state.prestashop.isFetchingDisconnect,
    debitoor: state.debitoor.isFetchingDisconnect,
    'zoho-books': state['zoho-books'].isFetchingDisconnect,
    'zoho-crm': state['zoho-crm'].isFetchingDisconnect,
    vend: state.vend.isFetchingDisconnect,
    sumup: state.sumup.isFetchingDisconnect,
    wix: state.wix.isFetchingDisconnect,
    bitrix: state.bitrix.isFetchingDisconnect,
    shopify: state.shopify.isFetchingDisconnect,
    bexio: state.bexio.isFetchingDisconnect,
    mailchimp: state.mailchimp.isFetchingDisconnect,
    gastrofix: state.gastrofix.isFetchingDisconnect,
    lightspeed: false,
    'lightspeed-r': false,
    pingen: state.pingen.isFetchingDisconnect,
  },
});

const mapDispatchToProps = {
  disconnectRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(DisconnectWidget));
