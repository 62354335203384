import commonStyles from "../../assets/jss/commonStyles";

const styles = {
  ...commonStyles,
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
  buttonsColumn: {},
};

export default styles;
