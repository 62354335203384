import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import AuthService from '../../services/auth.service';
import * as jwtJsDecode from 'jwt-js-decode';

const AdminBanner = () => {
  if (!AuthService.isImpersonated || !AuthService.token) {
      return null;
  }
  const { payload: { email }} = jwtJsDecode.jwtDecode(AuthService.token) as any;
  const clickHandler = () => {
    AuthService.stopImpersonate();
    window.location.href = '/admin/apps';
  };
  const btn = (
    <Button color="inherit" size="small" onClick={clickHandler}>
      Go back
    </Button>
  );
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: '50%',
        width: '500px',
        marginLeft: '-250px',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 10000,
      }}
      onClick={clickHandler}
    >
      <Alert variant="filled" severity="info" action={btn}>
        {`Logged in as ${email}`}
      </Alert>
    </div>
  )
}

export default AdminBanner;
