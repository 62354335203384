import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, StyleRules, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';
import styles from './styles';

const useStyles = makeStyles<Theme, IStyleProps>(styles as StyleRules);

type OwnProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  children: React.ReactNode;
}

function PopupDialog({children, title, open, handleClose}: OwnProps) {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle disableTypography={true} className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default PopupDialog;
