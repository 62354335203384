import React from 'react';

import { Subscription, StateProps, DispatchProps} from '../../../../components/Subscription';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import * as wixActions from '../../../../store/actions/wix_actions';

const PrestashopSubscription = () => {
  const dispatch = useAppDispatch();
  const state: StateProps = useAppSelector(state => ({
    hasSubscription: state.wix.hasSubscription,
    checkoutUrl: state.wix.checkoutUrl,
    isFetching: state.wix.isFetching,
  }));
  const dispatchProps: DispatchProps = {
    hasSubscriptionRequest: () => dispatch(wixActions.hasSubscriptionRequest()),
    postCheckoutRequest: () => dispatch(wixActions.postCheckoutRequest()),
  };
  return <Subscription baseName="wix" {...state} {...dispatchProps} />
};

export default PrestashopSubscription;
