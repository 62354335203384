// global imports
import { Grid, makeStyles, Theme, TextField, InputLabel } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';
import GridItem from '../../../../components/Grid/GridItem';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import style from '../../../../assets/jss/commonStyles';
import { WooCommerceSettings } from '../../../../types/WooCommerceSettings';
import BaseStoreOrderAs from '../../PrestashopWidgets/StoreOrderAs/BaseStoreOrderAs';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

function TextControl({
  type,
  value,
  onChange,
  title,
}: {
  type: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  title: string
}) {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  return (
    <Grid container={true} className={classes.w100}>
      <GridItem xs={4} className={classes.gridItem} style={{ display: 'flex', alignItems: 'center' }}>
        <InputLabel>{title}</InputLabel>
      </GridItem>
      <GridItem xs={8} className={classes.gridItem}>
        <TextField
          type={type}
          placeholder={title}
          className={classes.w100}
          value={value}
          onChange={onChange}
          disabled={false}
        />
      </GridItem>
    </Grid>
  );
}

type WooCommerceAppProps = LocalizeContextProps;

const WooCommerceApp = (props: WooCommerceAppProps) => {
  const dispatch = useDispatch();
  const woocommerceSettings = useSelector<IState, WooCommerceSettings>(state => state.woocommerce.settings);
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [settings, setSettings] = React.useState<WooCommerceSettings | null>(woocommerceSettings);

  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'woocommerce' }));
  }, []);

  React.useEffect(() => {
    setSettings(woocommerceSettings);
  }, [woocommerceSettings]);

  const delayedInput = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'woocommerce', data: { [field]: value } }));
    }, 1000),
  ).current;

  const handleValueChange = (field: 'apiUser' | 'apiSecret' | 'webhookSecret' | 'shopUrl' | 'targetEntity', newValue: any) => {
    if (!settings) {
      return;
    }

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  return (
    <SettingsContainer appName={translate('woocommerce.title') as string}>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('woocommerce.options') as string}
            desc={translate('woocommerce.options-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <div className={classes.w100}>
              <TextControl
                type="url"
                value={settings ? settings.shopUrl : ''}
                onChange={e => handleValueChange('shopUrl', e.target.value)}
                title={translate('woocommerce.shop_url') as string}
              />
              <TextControl
                type="text"
                title={translate('woocommerce.api_user') as string}
                value={settings ? settings.apiUser : ''}
                onChange={e => handleValueChange('apiUser', e.target.value)}
              />
              <TextControl
                type="text"
                title={translate('woocommerce.api_secret') as string}
                value={settings ? settings.apiSecret : ''}
                onChange={e => handleValueChange('apiSecret', e.target.value)}
              />
              <TextControl
                type="text"
                title={translate('woocommerce.webhook_secret') as string}
                value={settings ? settings.webhookSecret : ''}
                onChange={e => handleValueChange('webhookSecret', e.target.value)}
              />
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <BaseStoreOrderAs
        system="WooCommerce"
        targetEntity={settings?.targetEntity}
        onChange={v => handleValueChange('targetEntity', v)}
      />
      <BexioConfig system="WooCommerce" />
    </SettingsContainer>
  );
};

export default withLocalize(WooCommerceApp);
