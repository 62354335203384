// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// local imports
import { CircularProgress, Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import FormCard from '../../../../components/FormCard/FormCard';
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import GridRow from '../../../../components/NewUI/GridRow';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import { useStyles } from '../../../ReminderSettings/styles';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import { Props, StateProps } from './types';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { IOptionType } from '../../../../types/ReactSelect';

const LightspeedRApp = (props: Props) => {
  const { translate, OAuthURL, connectionStatus, isFetching, fetchOAuthUrlRequest, testConnectionRequest, settings, getSettingsRequest, putSettingsRequest } = props;
  const classes: PropsClasses = useStyles({} as IStyleProps);

  React.useEffect(() => {
    testConnectionRequest({ system: 'lightspeed-r' });
  }, []);
  React.useEffect(() => {
    if (!OAuthURL) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'lightspeed-r' });
      }
    }
  }, [connectionStatus]);

  useEffect(() => {
    getSettingsRequest({ system: 'lightspeed-r' });
  }, []);

  const optionList = [
    {
      label: '' + translate('bexio_app_name'),
      value: 'bexio',
    },
  ];
  const [selectedOption, setSelectedOption] = useState<IOptionType | null>(null);

  const getDestinationSystemSettings = () => {
    if (settings) {
      switch (settings.destinationSystem) {
        case "bexio":
          return <BexioConfig system="lightspeed-r" />;
      }
    }
  };

  const destinationSystemChanged = (value: IOptionType) => {
    if (settings) {
      setSelectedOption(value);
      putSettingsRequest({
        system: 'lightspeed-r',
        data: { destinationSystem: value.value, }
      });
    }
  };

  React.useEffect(() => {
    if (settings) {
      const option = optionList.find(x => x.value === settings.destinationSystem);
      setSelectedOption(option ? option : null);
    }
  }, [settings]);

  const [productSync, setProductSync] = useState<boolean>(false);
  const productSyncChanged = (newValue: boolean) => {
    if (settings) {
      setProductSync(newValue);
      putSettingsRequest({
        system: 'lightspeed-r',
        data: {
          productSync: {
            enabled: newValue,
          },
        },
      });
    }
  }
  React.useEffect(() => {
    if (settings) {
      setProductSync(settings.productSync.enabled);
    }
  }, [settings]);

  const [invoiceSync, setInvoiceSync] = useState<boolean>(false);
  const invoiceSyncChanged = (newValue: boolean) => {
    if (settings) {
      setInvoiceSync(newValue);
      putSettingsRequest({
        system: 'lightspeed-r',
        data: {
          invoiceSync: {
            enabled: newValue,
          },
        },
      });
    }
  }
  React.useEffect(() => {
    if (settings) {
      setInvoiceSync(settings.invoiceSync.enabled);
    }
  }, [settings]);

  return (
    <SettingsContainer appName="Lightspeed-R">
      <GridRow container={true}>
        <Grid item={true} xs={12} sm={4}>
          <Label title={translate('lightspeed-r.connect') as string} desc="" />
        </Grid>
        <Grid item={true} xs={12} sm={8}>
          <FormCard>
            <div style={{ width: '100%' }}>
              {isFetching ? (
                <CircularProgress />
              ) : connectionStatus === 'ok' ? (
                <DisconnectWidget appName="lightspeed-r" />
              ) : (
                <ConnectWidget appName="lightspeed-r" />
              )}
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} xs={12} sm={4}>
          <Label title={translate('lightspeed-r.sync-options') as string} desc={translate('lightspeed-r.sync-options-desc') as string} />
        </Grid>
        <Grid item={true} xs={12} sm={8}>
          <FormCard>
            <div style={{ width: '100%' }}>
              <Grid container={true} style={{ alignItems: 'center' }}>
                <Grid item={true} sm={4}>
                  <InputLabel>{translate('lightspeed-r.sync-products')}</InputLabel>
                </Grid>
                <Grid item={true} sm={8}>
                  <StyledSwitch
                    checked={productSync}
                    onChange={() => productSyncChanged(!productSync)}
                    disabled={isFetching}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ alignItems: 'center' }}>
                <Grid item={true} sm={4}>
                  <InputLabel>{translate('lightspeed-r.sync-invoices')}</InputLabel>
                </Grid>
                <Grid item={true} sm={8}>
                  <StyledSwitch
                    checked={invoiceSync}
                    onChange={() => invoiceSyncChanged(!invoiceSync)}
                    disabled={isFetching}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} xs={12} sm={4}>
          <Label title={translate('lightspeed-r.destination-system') as string} desc={translate('lightspeed-r.destination-system-desc') as string} />
        </Grid>
        <Grid item={true} xs={12} sm={8}>
          <FormCard>
            <div style={{ width: '100%' }}>
              <Select
                isSearchable={false}
                className={classes.w100}
                options={optionList}
                value={selectedOption}
                onChange={(value: ValueType<IOptionType, false>) => destinationSystemChanged(value as IOptionType)}
                styles={colourStyles}
              />
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      {getDestinationSystemSettings()}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  OAuthURL: state['lightspeed-r'].OAuthURL,
  settings: state['lightspeed-r'].settings,
  connectionStatus: state['lightspeed-r'].connectionStatus,
  isFetching: state['lightspeed-r'].isFetching,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LightspeedRApp),
);
