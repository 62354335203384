import React from 'react';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { SmsSettings } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';

type SmsSectionProps = {
  enabled: boolean;
  settings: SmsSettings;
  setSettings: (item: SmsSettings) => void;
} & LocalizeContextProps;

function SmsSection(props: SmsSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  return (
    <Grid container={true}>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center'}}>
        {translate('reminders.sms.test-phone-number') as string}
      </Grid>
      <Grid item={true} xs={9}>
        <TextFieldDelayed
          placeholder={translate('reminders.sms.test-phone-number') as string}
          className={classes.w100}
          value={settings.testPhoneNumber}
          onChange={value => setSettings({...settings, testPhoneNumber: value})}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(SmsSection);
