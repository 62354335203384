import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { config } from '../../config';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  bexioLoginRequest: (bexioLoginRequest: any) => any;
  authenticated: boolean;
  token: string;
  location: {
    search: string;
    pathname: string;
  };
}

const ZohoOAuthHandler: React.FC<IProps> = props => {
  const { classes, authenticated, location } = props;

  const [doRedirect, setDoRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (authenticated) {
      setDoRedirect(true);
    }
  }, [authenticated]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      console.log('Params search', location.search);
      fetch(config.backendUrl + '/ui/user/oauth/zoho/callback' + location.search, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => setDoRedirect(true));
    }
  }, []);

  return (
    <div className={classes.container}>
      {doRedirect && <Redirect to="/admin/zoho-app" />}
      <CircularProgress size={100} />
      {/*<button onClick={() => setDoRedirect(true)}>Redirect</button>*/}
    </div>
  );
};

export default withStyles(style)(ZohoOAuthHandler);
