import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store/reducers';
import { DpdSettings } from '../../types/DpdSettings';
import * as commonActions from '../../store/actions/common_actions';
import * as dpdActions from '../../store/actions/dpd_actions';

export function useDpdSettings(): [boolean, boolean, () => any] {
  const dispatch = useDispatch();

  const dpdSettings = useSelector<IState, DpdSettings>(state => state.dpd.settings);
  const hasSubscription = useSelector<IState, boolean>(state => !!state.dpd.hasSubscription);
  const checkoutUrl = useSelector<IState, string>(state => state.dpd.checkoutUrl);

  const [dpdEnabled, setDpdEnabled] = React.useState<boolean>(false);
  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'dpd' }));
    dispatch(dpdActions.hasSubscriptionRequest());
  }, []);
  React.useEffect(() => {
    setDpdEnabled(
      !!(
        dpdSettings &&
        dpdSettings.login &&
        dpdSettings.login.delisID &&
        dpdSettings.login.password &&
        dpdSettings.login.delisID.length &&
        dpdSettings.login.password.length
      ),
    );
  }, [dpdSettings]);

  React.useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  const checkout = () => dispatch(dpdActions.postCheckoutRequest());
  return [dpdEnabled, hasSubscription, checkout];
}
