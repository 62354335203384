import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import SettingsContainer from '../../components/NewUI/SettingsContainer';
import DpdWidget from './Widgets/DpdWidget';
import PingenWidget from './Widgets/PingenWidget';
import PlanzerWidget from './Widgets/PlanzerWidget';
import GlsWidget from './Widgets/GlsWidget';
import Section from './Widgets/Section';

type ServiceAccountsPageProps = LocalizeContextProps;

function ServiceAccountsPage(props: ServiceAccountsPageProps) {
  const { translate } = props;
  return (
    <SettingsContainer appName={translate('serviceAccounts.title') as string}>
      <Section title={translate('serviceAccounts.pingen.title') as string} desc="">
        <PingenWidget />
      </Section>
      <Section title={translate('serviceAccounts.dpd.title') as string} desc="">
        <DpdWidget />
      </Section>
      <Section title={translate('serviceAccounts.planzer.title') as string} desc="">
        <PlanzerWidget />
      </Section>
      <Section title={translate('serviceAccounts.gls.title') as string} desc="">
        <GlsWidget />
      </Section>
    </SettingsContainer>
  );
}

export default withLocalize(ServiceAccountsPage);
