// global imports
import { Dialog, DialogContent, DialogTitle, makeStyles, Theme, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Button from '../../components/CustomButtons/Button.jsx';
import { config } from '../../config';
import { logoutRequest, putUserInfoRequest } from '../../store/actions/auth_actions';
import Checkbox from '../Checkbox/Checkbox';
import LanguageToggle from '../LanguageToggle';

// project types imports
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const GDPRGuard: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, putUserInfoRequest } = props;

  const [policyAccepted, setPolicyAccepted] = useState(true);
  const [accepted, setAccepted] = useState(false);

  // test connection status
  useEffect(() => {
    if (props.userInfo && props.userInfo.privacyPolicies) {
      if (!props.userInfo.privacyPolicies.find((policy: any) => policy.version === config.policyVersion)) {
        putUserInfoRequest({
          privacyPolicies: [
            ...props.userInfo.privacyPolicies,
            {
              confirmed: null,
              version: config.policyVersion,
            },
          ],
        });
      }
      if (props.userInfo.privacyPolicies.find((policy: any) => !policy.confirmed)) {
        setPolicyAccepted(false);
      }
    }
  }, [props.userInfo]);

  const handleClose = () => {
    setPolicyAccepted(true);
  };

  const handleAccept = () => {
    putUserInfoRequest({
      privacyPolicies: props.userInfo.privacyPolicies.map((policy: any) =>
        policy.confirmed
          ? policy
          : {
              confirmed: Date.now(),
              version: policy.version,
            },
      ),
    });
    setPolicyAccepted(true);
  };

  const handleCancel = () => {
    props.logoutRequest({});
    setPolicyAccepted(true);
  };

  return props.userInfo &&
    props.userInfo.privacyPolicies &&
    props.userInfo.privacyPolicies.length ? (
    <Dialog
      disableBackdropClick={true}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={!policyAccepted}
    >
      <DialogTitle disableTypography={true} className={classes.root}>
        <Typography variant="h6">{translate('privacy_policy')}</Typography>
        <LanguageToggle big={true} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ul>
          {props.userInfo.privacyPolicies
            .filter((policy: any) => !policy.confirmed)
            .map((policy: any) => (
              <li key={policy.version}>{policy.version}</li>
            ))}
        </ul>
        <Typography gutterBottom={true}>
          {translate('privacy_policy_details', undefined, { renderInnerHtml: true })}
        </Typography>
        <Typography gutterBottom={true}>
          <Checkbox
            name="issueDeliveryAfterGenerating"
            onChange={() => setAccepted(!accepted)}
            checked={accepted}
            label={translate('privacy_policy_accept') as string}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAccept} disabled={!accepted} color="primary">
          Next
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  logoutRequest,
  putUserInfoRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(GDPRGuard));
