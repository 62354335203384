// global imports
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Theme, Typography, TextField, InputAdornment } from '@material-ui/core';
import ReloadIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Dvr from '@material-ui/icons/Dvr';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import _ from 'lodash';

// project imports
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import { loginFinished } from '../../store/actions/auth_actions';
import {
  getProtocolDataRequest,
  getProtocolExitStatesRequest,
  getProtocolTopicsRequest,
  reRunProtocolRequest,
} from '../../store/actions/protocol_actions';
import { getProtocolPayload, getProtocolCanonical, getProtocolLogs } from '../../store/api/protocol';

// project types imports
import { StyleRules } from '@material-ui/core/styles';
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import { PlayArrowRounded, FilterListOutlined, AttachFileOutlined, SendOutlined } from '@material-ui/icons';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import AuthService from '../../services/auth.service';
import AclService from '../../services/acl.service';
import style from './styles';
import { IErrorMessage, ITableState, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

function useProtocolPayload(token: string | null) {
    const [state, setState] = React.useState<string>('initial');
    const [result, setResult] = React.useState<any>(null);
    const trigger = React.useCallback((protocolId: string) => {
        if (token) {
            setState('loading');
            setResult(null);
            getProtocolPayload(protocolId, token).then((resp) => {
                setResult(resp.data);
                setState('ready');
            }).catch(() => {
                setState('error');
            });
        }
    }, []);
    const reset = React.useCallback(() => {
        setState('initial');
        setResult(null);
    }, []);
    return { trigger, reset, state, result };
}

function useProtocolCanonical(token: string | null) {
    const [state, setState] = React.useState<string>('initial');
    const [result, setResult] = React.useState<any>(null);
    const trigger = React.useCallback((protocolId: string) => {
        if (token) {
            setState('loading');
            setResult(null);
            getProtocolCanonical(protocolId, token).then((resp) => {
                setResult(resp.data);
                setState('ready');
            }).catch(() => {
                setState('error');
            });
        }
    }, []);
    const reset = React.useCallback(() => {
        setState('initial');
        setResult(null);
    }, []);
    return { trigger, reset, state, result };
}

function useProtocolLogs(token: string | null) {
    const [state, setState] = React.useState<string>('initial');
    const [result, setResult] = React.useState<any>(null);
    const trigger = React.useCallback((protocolId: string) => {
        if (token) {
            setState('loading');
            setResult(null);
            getProtocolLogs(protocolId, token, { page: 0, resultsPerPage: 50 }).then((resp) => {
                setResult({
                    ...resp.data,
                    result: resp.data.result.map((x: any) => ({
                        url: x.url,
                        details: x,
                    })),
                });
                setState('ready');
            }).catch(() => {
                setState('error');
            });
        }
    }, []);
    const reset = React.useCallback(() => {
        setState('initial');
        setResult(null);
    }, []);
    return { trigger, reset, state, result };
}

function escapeRegExp(txt: string): string {
    return encodeURIComponent(txt.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
}

const ProtocolPage = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    protocolData,
    exitStates,
    topics,
    redirectPath,
    loginFinishedFunc,
    getProtocolDataRequestFunc,
    getProtocolTopicsRequestFunc,
    getProtocolExitStatesRequestFunc,
    reRunProtocolFunc,
    reRunning,
  } = props;

  const [open, setOpen] = useState(false);
  const [showRerunConfirmation, setShowRerunConfirmation] = useState(false);
  const [selectedError, setSelectedError] = useState<any>(null);
  const [tableData, setTableData] = useState([]);
  const [tableState, setTableState] = useState<ITableState | null>(null);

  const handleOpen = (row: any) => {
    setSelectedError(row);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedError(null);
    setOpen(false);
  };

  useEffect(() => {
    if (!reRunning) {
      refreshTable();
    }
  }, [reRunning]);

  useEffect(() => {
    if (redirectPath) {
      loginFinishedFunc();
    }
  }, [redirectPath]);

  const showRerunConfirm = () => {
    setOpen(false);
    setShowRerunConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowRerunConfirmation(false);
  };

  const reRun = () => {
    if (!selectedError) {
      return;
    }
    reRunProtocolFunc(selectedError._id);
    toast.success(translate('protocols.started'));
    handleConfirmationClose();
  };

  useEffect(() => {
    if (protocolData.result) {
      const dataRows = protocolData.result.map((row: any) => ({
        id: row._id,
        started: moment(row.started).format('DD.MM.YYYY HH:mm:ss'),
        source: row.source.system,
        shop: row.source.shop,
        transferObjectName: row.transferObjectName,
        topic: row.topic,
        exitState: row.exitState,
        actions: (
          <div className={classes.buttonsColumn} key={row._id}>
            <Button
              justIcon={true}
              round={true}
              simple={true}
              color="warning"
              onClick={() => handleOpen(row)}
            >
              <Dvr />
            </Button>
          </div>
        ),
      }));
      setTableData(dataRows);
    }
  }, [protocolData]);

  useEffect(() => {
    refreshTable();
  }, [tableState]);

  const refreshTable = () => {
    if (tableState) {
      const exitStateFilter = tableState.filtered.find(it => it.id === 'exitState');
      const topicFilter = tableState.filtered.find(it => it.id === 'topic');
      const objectNameFilter = tableState.filtered.find(it => it.id === 'transferObjectName');
      getProtocolDataRequestFunc({
        sortField: tableState.sorted.length ? tableState.sorted[0].id : 'started',
        sortOrder: tableState.sorted.length ? (tableState.sorted[0].desc ? 'desc' : 'asc') : 'desc',
        page: tableState.page + 1,
        resultsPerPage: tableState.pageSize,
        exitStateFilter: exitStateFilter ? exitStateFilter.value : null,
        topicFilter: topicFilter ? topicFilter.value : null,
        objectNameFilter: objectNameFilter ? escapeRegExp(objectNameFilter.value) : null,
      });
    }
  };

  const fetchData = ({ sorted, page, pageSize, filtered }: any) => {
    setTableState({ sorted, page, pageSize, filtered });
  };
  const fetchDataDebounced = _.debounce(fetchData, 500);

  const errorsList = (list: any) => {
    if (list) {
      let message: IErrorMessage[] = list.message;
      if (!AuthService.isImpersonated) {
        message = message.filter((x: IErrorMessage) => x.level !== 'debug');
      }
      return (
        <ul style={{ listStyleType: 'disc' }}>
          {
              message.map((error: IErrorMessage, index: number) => <li key={index}>{parseError(error.message)}</li>)
          }
        </ul>
      );
    }
  };

  const parseError = (error: string) => {
    try {
      const object = JSON.parse(
        `${error
          .split(' - ')[1]
          .replace(/\\"/g, '"')
          .replace(`"{`, '{')
          .replace(`}"`, `}`)}`,
      );
      return <ReactJson name={false} src={object} />;
    } catch (err) {
      return error;
    }
  };

  const loadFilterOptions = () => {
    getProtocolTopicsRequestFunc({});
    getProtocolExitStatesRequestFunc({});
  };

  const onRefresh = () => {
    if (tableState) {
      setTableState({ ...tableState });
    }
    loadFilterOptions();
  };

  useEffect(loadFilterOptions, []);

  const hasAdminPerm = AclService.hasPermission(props.auth, 'bedaya-admin');
  const adminToken = AuthService.isImpersonated ? AuthService.adminToken : (hasAdminPerm ? AuthService.token : null);

  const { trigger: triggerPayload, reset: resetPayload, state: payloadState, result: payloadResult } = useProtocolPayload(adminToken);
  const [openPayload, setOpenPayload] = React.useState<boolean>(false);

  const showPayloadDialog = () => {
    if (selectedError) {
      triggerPayload(selectedError._id);
    }
    setOpenPayload(true);
  }
  const hidePayloadDialog = () => {
    resetPayload();
    setOpenPayload(false);
  }

  const { trigger: triggerCanonical, reset: resetCanonical, state: canonicalState, result: canonicalResult } = useProtocolCanonical(adminToken);
  const [openCanonical, setOpenCanonical] = React.useState<boolean>(false);

  const showCanonicalDialog = () => {
    if (selectedError) {
      triggerCanonical(selectedError._id);
    }
    setOpenCanonical(true);
  }
  const hideCanonicalDialog = () => {
    resetCanonical();
    setOpenCanonical(false);
  }

  const { trigger: triggerLogs, reset: resetLogs, state: logsState, result: logsResult } = useProtocolLogs(adminToken);
  const [openLogs, setOpenLogs] = React.useState<boolean>(false);

  const showLogsDialog = () => {
    if (selectedError) {
      triggerLogs(selectedError._id);
    }
    setOpenLogs(true);
  }
  const hideLogsDialog = () => {
    resetLogs();
    setOpenLogs(false);
  }

  return (
    <Card>
      <CardHeader color="primary" className={cx(classes.connectRow, classes.flexCenter)}>
        <h4 className={classes.cardTitleWhite}>{translate('protocol')}</h4>
        <IconButton onClick={onRefresh}>
          <ReloadIcon className={classes.whiteColor} />
        </IconButton>
      </CardHeader>
      <CardBody>
        <ReactTable
          data={tableData}
          pages={Math.ceil(protocolData.count / protocolData.resultsPerPage) || -1}
          defaultPageSize={parseInt(protocolData.resultsPerPage, 10) || 10}
          loading={props.loading}
          // filterable
          manual={true}
          onFetchData={fetchDataDebounced}
          getTrProps={(state: any, rowInfo: any, column: any) => {
            const styles: any = {};
            if (rowInfo && rowInfo.row.exitState === 'error') {
              styles.color = 'red';
            }
            return { style: styles };
          }}
          columns={[
            {
              Header: translate('date'),
              accessor: 'started',
            },
            {
              Header: translate('source'),
              accessor: 'source',
            },
            {
              Header: translate('shop'),
              accessor: 'shop',
            },
            {
              Header: translate('transferObjectName'),
              accessor: 'transferObjectName',
              Filter: ({ filter, onChange }) => (
                <TextField
                  style={{ width: '100%', height: '2rem' }}
                  value={filter ? filter.value : ''}
                  onChange={(ev: any) => onChange(ev.target.value)}
                  multiline={false}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                          {<CloseOutlined height={20} width={20} style={{ cursor: 'pointer', width: 'auto', height: 'auto', fill: 'gray' }} onClick={() => onChange('')} />}
                      </InputAdornment>
                    ),
                  }}
                />
              ),
              headerStyle: { overflow: 'visible' },
              filterable: true,
            },
            {
              Header: translate('topic'),
              Filter: ({ filter, onChange }) => (
                <Select
                  isClearable={true}
                  isSearchable={false}
                  options={topics}
                  onChange={opt => onChange(opt ? opt.value : null)}
                  value={filter ? topics.find(it => it.value === filter.value) : null}
                  styles={{
                    container: base => ({ ...base, width: '100%' }),
                    valueContainer: base => ({ ...base, height: '2rem' }),
                  }}
                />
              ),
              headerStyle: { overflow: 'visible' },
              filterable: true,
              accessor: 'topic',
            },
            {
              Header: translate('status'),
              Filter: ({ filter, onChange }) => (
                <Select
                  isClearable={true}
                  isSearchable={false}
                  options={exitStates}
                  onChange={opt => onChange(opt ? opt.value : null)}
                  value={filter ? exitStates.find(it => it.value === filter.value) : null}
                  styles={{
                    container: base => ({ ...base, width: '100%' }),
                    valueContainer: base => ({ ...base, height: '2rem' }),
                  }}
                />
              ),
              headerStyle: { overflow: 'visible' },
              filterable: true,
              accessor: 'exitState',
            },
            {
              Header: translate('actions'),
              accessor: 'actions',
              sortable: false,
              filterable: false,
            },
          ]}
          showPaginationTop={true}
          showPaginationBottom={false}
          className="-striped -highlight"
          previousText={translate('react-table.previous')}
          nextText={translate('react-table.next')}
          loadingText={translate('react-table.next')}
          noDataText={translate('react-table.noData')}
          pageText={translate('react-table.page')}
          ofText={translate('react-table.of')}
          rowsText={'' + translate('react-table.rows')}
        />
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle disableTypography={true} className={classes.root}>
            <Typography variant="h6">{translate('errors.errors')}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Typography gutterBottom={true}>{errorsList(selectedError)}</Typography>
            {selectedError && selectedError.exitState === 'error' && (
              <Button type="button" color="primary" size="sm" onClick={showRerunConfirm}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PlayArrowRounded fontSize="large" /> {translate('protocols.replay')}
                </div>
              </Button>
            )}
            {adminToken && (
              <React.Fragment>
                <Button type="button" color="primary" size="sm" onClick={showPayloadDialog}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SendOutlined fontSize="large" /> {translate('protocols.payload')}
                  </div>
                </Button>
                <Button type="button" color="primary" size="sm" onClick={showCanonicalDialog}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AttachFileOutlined fontSize="large" /> {translate('protocols.canonical')}
                  </div>
                </Button>
                <Button type="button" color="primary" size="sm" onClick={showLogsDialog}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FilterListOutlined fontSize="large" /> {translate('protocols.logs')}
                  </div>
                </Button>
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
        <ConfirmDialog
          text={translate('protocols.confirmation-text') as string}
          onClose={() => setShowRerunConfirmation(false)}
          onConfirm={reRun}
          visible={showRerunConfirmation}
        />
        <Dialog onClose={hidePayloadDialog} aria-labelledby="customized-dialog-title" open={openPayload} fullWidth maxWidth="sm">
          <DialogTitle disableTypography={true} className={classes.root}>
            <Typography variant="h6">{translate('protocols.payload')}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={hidePayloadDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            {payloadState === 'loading' && (
                <span>Loading..</span>
            )}
            {payloadState === 'error' && (
                <span>Error..</span>
            )}
            {payloadState === 'ready' && (
                <ReactJson src={payloadResult} collapsed={1} />
            )}
          </DialogContent>
        </Dialog>
        <Dialog onClose={hideCanonicalDialog} aria-labelledby="customized-dialog-title" open={openCanonical} fullWidth maxWidth="sm">
          <DialogTitle disableTypography={true} className={classes.root}>
            <Typography variant="h6">{translate('protocols.canonical')}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={hideCanonicalDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            {canonicalState === 'loading' && (
                <span>Loading..</span>
            )}
            {canonicalState === 'error' && (
                <span>Error..</span>
            )}
            {canonicalState === 'ready' && (
                <ReactJson src={canonicalResult} collapsed={1} />
            )}
          </DialogContent>
        </Dialog>
        <Dialog onClose={hideLogsDialog} aria-labelledby="customized-dialog-title" open={openLogs} fullWidth maxWidth="sm">
          <DialogTitle disableTypography={true} className={classes.root}>
            <Typography variant="h6">{translate('protocols.logs')}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={hideLogsDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={true}>
            {logsState === 'loading' && (
                <span>Loading..</span>
            )}
            {logsState === 'error' && (
                <span>Error..</span>
            )}
            {logsState === 'ready' && (
                <ReactJson src={logsResult?.result} collapsed={2} />
            )}
          </DialogContent>
        </Dialog>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  protocolData: state.protocol.protocolData,
  exitStates: state.protocol.exitStates.map(it => ({ value: it, label: it })),
  topics: state.protocol.topics.map(it => ({ value: it, label: it })),
  loading: state.protocol.isFetching,
  reRunning: state.protocol.reRunning,
  redirectPath: state.auth.redirectPath,
});

const mapDispatchToProps = {
  getProtocolDataRequestFunc: getProtocolDataRequest,
  getProtocolExitStatesRequestFunc: getProtocolExitStatesRequest,
  getProtocolTopicsRequestFunc: getProtocolTopicsRequest,
  reRunProtocolFunc: reRunProtocolRequest,
  loginFinishedFunc: loginFinished,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProtocolPage),
);
