/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

// core components
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';

import sidebarStyle from '../../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.js';

import avatar from '../../../assets/img/faces/avatar.jpg';
import BetaOverlay from '../../../components/BetaOverlay/BetaOverlay';
import { CardActions } from '@material-ui/core';

var ps;

const renderIcon = props =>
  props.icon !== undefined ? (
    typeof props.icon === 'string' && props.iconType !== 'img' ? (
      <Icon className={props.itemIcon}>{props.icon}</Icon>
    ) : props.iconType === 'img' ? (
      <img className={props.itemIcon} src={props.icon} />
    ) : (
      <props.icon className={props.itemIcon} />
    )
  ) : (
    <span className={props.collapseItemMini}>{props.rtlActive ? props.rtlMini : props.mini}</span>
  );

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {/*{user}*/}
        {headerLinks}
        {links}
      </div>
    );
  }
}

const stylesBedayaSidebarLogo = {
  float: 'left',
  textAlign: 'center',
  display: 'inline-block',
  marginLeft: '22px',
  marginRight: '18px',
  marginTop: '7px',
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }

  mainPanel = React.createRef();

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  };

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color, rtlActive, auth, apps, translate } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect || prop.doNotShowInSidebar) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop.state] = !this.state[prop.state];

        const navLinkClasses = cx({
          [classes.itemLink]: true,
          [' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views),
        });
        const itemText = cx({
          [classes.itemText]: true,
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
        const collapseItemText = cx({
          [classes.collapseItemText]: true,
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
        const itemIcon = cx({
          [classes.itemIcon]: true,
          [classes.itemIconRTL]: rtlActive,
        });
        const caret = cx({
          [classes.caret]: true,
          [classes.caretRTL]: rtlActive,
        });
        const collapseItemMini = cx({
          [classes.collapseItemMini]: true,
          [classes.collapseItemMiniRTL]: rtlActive,
        });

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {renderIcon({
                icon: prop.icon,
                itemIcon,
                collapseItemMini,
                rtlActive,
                rtlMini: prop.rtlMini,
                mini: prop.mini,
                iconType: prop.iconType,
              })}
              <ListItemText
                primary={rtlActive ? prop.rtlName : typeof prop.name === 'string' ? prop.name : prop.name(translate)}
                secondary={<b className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')} />}
                disableTypography={true}
                className={cx({ [itemText]: prop.icon !== undefined }, { [collapseItemText]: prop.icon === undefined })}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>{this.createLinks(prop.views)}</List>
            </Collapse>
          </ListItem>
        );
      }

      const innerNavLinkClasses = cx({
        [classes.collapseItemLink]: true,
        [' ' + classes[color]]: this.activeRoute(prop.path),
      });
      const collapseItemMini = cx({
        [classes.collapseItemMini]: true,
        [classes.collapseItemMiniRTL]: rtlActive,
      });
      const navLinkClasses = cx({
        [classes.itemLink]: true,
        [' ' + classes[color]]: this.activeRoute(prop.path),
      });
      const itemText = cx({
        [classes.itemText]: true,
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
      const collapseItemText = cx({
        [classes.collapseItemText]: true,
        [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
      const itemIcon = cx({
        [classes.itemIcon]: true,
        [classes.itemIconRTL]: rtlActive,
      });

      const availableApp =
        auth.userInfo &&
        auth.userInfo.availableApps &&
        prop.appName &&
        auth.userInfo.availableApps.find(app => app.name === prop.appName);

      const version = availableApp && availableApp.version;

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          {((prop.enabled && prop.enabled(auth, apps)) || !prop.enabled) && (
            <NavLink
              to={prop.layout + prop.path}
              className={cx(
                { [navLinkClasses]: prop.icon !== undefined },
                { [innerNavLinkClasses]: prop.icon === undefined },
              )}
            >
              {renderIcon({
                icon: prop.icon,
                itemIcon,
                collapseItemMini,
                rtlActive,
                rtlMini: prop.rtlMini,
                mini: prop.mini,
                iconType: prop.iconType,
              })}
              <ListItemText
                primary={rtlActive ? prop.rtlName : typeof prop.name === 'string' ? prop.name : prop.name(translate)}
                disableTypography={true}
                className={cx({ [itemText]: prop.icon !== undefined }, { [collapseItemText]: prop.icon === undefined })}
              />
              {availableApp && version > 0 && version < 1 && <BetaOverlay small={true} isBeta={true} />}
              {availableApp && version === 0 && <BetaOverlay small={true} />}
            </NavLink>
          )}
        </ListItem>
      );
    });
  };

  render() {
    const { classes, logo, logoLink, image, logoText, routes, bgColor, rtlActive, translate } = this.props;
    const itemText = cx({
      [classes.itemText]: true,
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    });
    const collapseItemText = cx({
      [classes.collapseItemText]: true,
      [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    });
    const userWrapperClass = cx({
      [classes.user]: true,
      [classes.whiteAfter]: bgColor === 'white',
    });
    const caret = cx({
      [classes.caret]: true,
      [classes.caretRTL]: rtlActive,
    });
    const collapseItemMini = cx({
      [classes.collapseItemMini]: true,
      [classes.collapseItemMiniRTL]: rtlActive,
    });
    const photo = cx({
      [classes.photo]: true,
      [classes.photoRTL]: rtlActive,
    });

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={translate('menu_title')}
                secondary={
                  <b
                    className={
                      caret + ' ' + classes.userCaret + ' ' + (this.state.openAvatar ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink to="#" className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                    <span className={collapseItemMini}>{rtlActive ? 'مع' : 'MP'}</span>
                    <ListItemText
                      primary={rtlActive ? 'ملفي' : 'My Profile'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink to="#" className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                    <span className={collapseItemMini}>{rtlActive ? 'هوع' : 'EP'}</span>
                    <ListItemText
                      primary={rtlActive ? 'تعديل الملف الشخصي' : 'Edit Profile'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink to="#" className={classes.itemLink + ' ' + classes.userCollapseLinks}>
                    <span className={collapseItemMini}>{rtlActive ? 'و' : 'S'}</span>
                    <ListItemText
                      primary={rtlActive ? 'إعدادات' : 'Settings'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    const links = <List className={classes.list}>{this.createLinks(routes)}</List>;

    const logoNormal = cx({
      [classes.logoNormal]: true,
      [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
      [classes.logoNormalSidebarMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.logoNormalRTL]: rtlActive,
    });
    const logoMini = cx({
      [classes.logoMini]: true,
      [classes.logoMiniRTL]: rtlActive,
    });
    const logoClasses = cx({
      [classes.logo]: true,
      [classes.whiteAfter]: bgColor === 'white',
    });

    let brand = (
      <div className={logoClasses}>
        <div style={stylesBedayaSidebarLogo}>
          <img src={logo} alt="logo" />
        </div>
      </div>
    );

    const drawerPaper = cx({
      [classes.drawerPaper]: true,
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    });
    const sidebarWrapper = cx({
      [classes.sidebarWrapper]: true,
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    });

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoLink: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

const mapStateToProps = state => ({
  auth: state.auth,
  apps: state.apps.apps,
  translate: getTranslate(state.localize),
});

export default withLocalize(withStyles(sidebarStyle)(connect(mapStateToProps)(Sidebar)));
