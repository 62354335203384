// global imports
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withLocalize } from 'react-localize-redux';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';

// project imports
import Card from '../../../../../components/Card/Card.jsx';
import CardBody from '../../../../../components/Card/CardBody';
import CardHeader from '../../../../../components/Card/CardHeader.jsx';
import ConnectWidget from '../../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../../components/DisconnectWidget/DisconnectWidget';
import { fetchOAuthUrlRequest } from '../../../../../store/actions/auth_actions';
import { testConnectionRequest } from '../../../../../store/actions/common_actions';
import commonStyles from '../../../../../assets/jss/commonStyles';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';

// local imports
import { IState } from '../../../../../store/reducers';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const ZohoBooksApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  const { translate } = props;

  const OAuthURL = useSelector<IState, StateProps['OAuthURL']>(({ 'zoho-books': { OAuthURL } }) => OAuthURL);
  const connectionStatus = useSelector<IState, StateProps['connectionStatus']>(
    ({ 'zoho-books': { connectionStatus } }) => connectionStatus,
  );

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL) {
      dispatch(fetchOAuthUrlRequest({ system: 'zoho-books' }));
    }

    dispatch(testConnectionRequest({ system: 'zoho-books' }));
  }, []);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        dispatch(fetchOAuthUrlRequest({ system: 'zoho-books' }));
      }
    }
  }, [connectionStatus]);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Sumup</h4>
      </CardHeader>
      <CardBody>
        <h3>{translate('status')}</h3>
        <div className={classes.connectRow}>
          {OAuthURL && connectionStatus !== 'loading' ? (
            connectionStatus === 'ok' ? (
              <>
                <div className={classes.connectedContainer}>
                  <span className={classes.connectedStatus}>{translate('connected')}</span>
                  <DisconnectWidget appName="zoho-books" />
                </div>
              </>
            ) : (
              <ConnectWidget appName="zoho-books" redirect="/admin/zoho-books" />
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default withLocalize(ZohoBooksApp);
