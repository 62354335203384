import { BedayaAction } from '../../types/BedayaAction';
import { WooCommerceSettings } from '../../types/WooCommerceSettings';
import { GET_SETTINGS, PUT_SETTINGS } from '../actions/common_actions';

export interface IWooCommerceState {
  settings: WooCommerceSettings;
  isFetching: boolean;
  error: any;
}

const initialState: IWooCommerceState = {
  settings: {
  },
  isFetching: false,
  error: '',
};

export default function reducer(state: IWooCommerceState = initialState, action: BedayaAction): IWooCommerceState {
  switch (action.type) {
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['woocommerce'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
      if (['woocommerce'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.SUCCESS:
      if (['woocommerce'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    default:
      return state;
  }
}
