// global imports
import { makeStyles, Theme, TextField } from '@material-ui/core';
import ReloadIcon from '@material-ui/icons/Cached';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

// project imports
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import { getUsers, getUserToken } from '../../store/api/user';

// project types imports
import { StyleRules } from '@material-ui/core/styles';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import style from './styles';
import { ITableState, Props, IUser, IResponse } from './types';
import AuthService from '../../services/auth.service';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

type Loading = 'initial' | 'pending' | 'ready' | 'error';

function useUserSearch(tableState: ITableState | null): [Loading, IResponse | null] {
  const [loading, setLoading] = useState<Loading>('initial');
  const [result, setResult] = useState<IResponse | null>(null);
  useEffect(() => {
    if (!tableState) {
      setLoading('initial');
    } else {
      setLoading('pending');
      getUsers({
        sortField: tableState.sorted.length ? tableState.sorted[0].id : 'id',
        sortOrder: tableState.sorted.length ? (tableState.sorted[0].desc ? 'desc' : 'asc') : 'desc',
        page: tableState.page + 1,
        resultsPerPage: tableState.pageSize,
        textFilter: tableState.filtered,
      }).then(response => {
        setResult(response.data as IResponse);
        setLoading('ready');
      }).catch(() => {
        setLoading('error');
      });
    }
  }, [tableState, setLoading, setResult]);
  return [loading, result];
}

function useUserToken(userPropertiesId: string | null): [Loading, string | null] {
  const [loading, setLoading] = useState<Loading>('initial');
  const [result, setResult] = useState<string | null>(null);
  useEffect(() => {
    if (!userPropertiesId) {
      setLoading('initial');
    } else {
      setLoading('pending');
      getUserToken(userPropertiesId).then(response => {
        setResult(response.data as string);
        setLoading('ready');
      }).catch(() => {
        setLoading('error');
      });
    }
  }, [userPropertiesId, setLoading, setResult]);
  return [loading, result];
}

const UsersPage = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;

  const [showConfirmation, setShowConfirmation] = useState<string | null>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableState, setTableState] = useState<ITableState | null>(null);
  const [userPropertiesId, setUserPropertiesId] = useState<string | null>(null);

  const [loading, data] = useUserSearch(tableState);
  const [,newToken] = useUserToken(userPropertiesId);

  useEffect(() => {
    if (data && data.result) {
      const dataRows = data.result.map((row: IUser) => ({
        id: row._id,
        notificationsEmail: row.notificationsEmail,
        companyName: row.companyName,
        userEmail: row.userEmail,
        name: row.name,
        actions: (
          <div className={classes.buttonsColumn} key={row._id}>
            <Button
              justIcon={true}
              round={true}
              simple={true}
              color="warning"
              onClick={() => setShowConfirmation(row._id)}
            >
              <ReloadIcon />
            </Button>
          </div>
        ),
      }));
      setTableData(dataRows);
    }
  }, [data]);

  const fetchData = ({ sorted, page, pageSize }: any) => {
    if (tableState) {
      setTableState({ ...tableState, sorted, page, pageSize });
    } else {
      setTableState({ sorted, page, pageSize, filtered: '' });
    }
  };

  const onConfirm = () => {
    setUserPropertiesId(showConfirmation);
    setShowConfirmation(null);
  }

  useEffect(() => {
    if (newToken) {
      AuthService.impersonate({ token: newToken });
      window.location.href = '/admin/apps';
    }
  }, [newToken]);

  const [currentValue, setCurrentValue] = useState('');
  const onSearch = () => {
    if (tableState) {
      setTableState({ ...tableState, filtered: currentValue });
    }
  };

  return (
    <Card>
      <CardHeader color="primary" className={cx(classes.connectRow, classes.flexCenter)}>
        <h4 className={classes.cardTitleWhite}>Users Admin</h4>
      </CardHeader>
      <CardBody>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }} style={{width: '100%', display: 'flex', justifyContent: 'right'}}>
          <div style={{width: '50%', display: 'flex', alignItems: 'center'}}>
            <TextField name="search" value={currentValue} style={{flexGrow: 1}} onChange={ev => setCurrentValue(ev.target.value)} />
            <Button type="submit" color="warning">Search</Button>
          </div>
        </form>
        <ReactTable
          data={tableData}
          pages={data ? Math.ceil(data.count / data.resultsPerPage) : -1}
          defaultPageSize={data ? data.resultsPerPage : 10}
          loading={loading === 'pending'}
          onFetchData={fetchData}
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'Bexio Email',
              accessor: 'notificationsEmail',
            },
            {
              Header: 'Bedaya Email',
              accessor: 'userEmail',
            },
            {
              Header: 'Company',
              accessor: 'companyName',
            },
            {
              Header: 'Name',
              accessor: 'name',
            },
            {
              Header: translate('actions'),
              accessor: 'actions',
              sortable: false,
            },
          ]}
          showPaginationTop={true}
          showPaginationBottom={false}
          className="-striped -highlight"
          previousText={translate('react-table.previous')}
          nextText={translate('react-table.next')}
          loadingText={translate('react-table.next')}
          noDataText="Not found"
          pageText={translate('react-table.page')}
          ofText={translate('react-table.of')}
          rowsText={'' + translate('react-table.rows')}
        />
        <ConfirmDialog
          text="Impersonate account - you will be authenticated as the user and will see exactly his dashboard. Are you sure you want to continue?"
          onClose={() => setShowConfirmation(null)}
          onConfirm={onConfirm}
          visible={showConfirmation !== null}
        />
      </CardBody>
    </Card>
  );
};

export default withLocalize(UsersPage);
