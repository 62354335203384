// global imports
import { Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const WebhookHash = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  // tslint:disable-next-line:no-shadowed-variable
  const settings = useSelector<IState, StateProps['prestashopSettings']>(({ prestashop: { settings } }) => settings);

  const [currentWebhookSign, setCurrentWebhookSign] = useState(settings.webhookSign);
  const delayedInput = useRef(
    _.debounce(
      (currentSettings, value) =>
        dispatch(putSettingsRequest({ system: 'prestashop', data: { ...currentSettings, webhookSign: value } })),
      300,
    ),
  ).current;

  useEffect(() => {
    setCurrentWebhookSign(settings.webhookSign);
  }, [settings.webhookSign]);

  const handleValueChange = (event: any) => {
    const newValue = event.target.value;

    setCurrentWebhookSign(newValue);
    delayedInput(settings, newValue);
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={4}>
        <InputLabel className={classes.pt10}>HASHALT</InputLabel>
      </Grid>
      <Grid item={true} xs={8}>
        <TextField className={classes.w100} value={currentWebhookSign} onChange={handleValueChange} />
      </Grid>
    </Grid>
  );
};

export default withLocalize(WebhookHash);
