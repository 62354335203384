import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
// @ts-ignore
import TagManager from 'react-gtm-module';
import { LocalizeContextProps, LocalizeProvider, withLocalize } from 'react-localize-redux';
import { connect, Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/material-dashboard-react.css';
import rootSaga from './store/sagas';
import { clientApp } from './store/api';
import { history, persistor, runSaga, store } from './store/storeConfig';

import { CircularProgress, createTheme, ThemeProvider } from '@material-ui/core';

import Admin from './layouts/Admin.jsx';
import Auth from './layouts/Auth';
import PrivateRoute from './layouts/components/PrivateRoute/PrivateRoute';

import CookieConsentBanner from './components/CookieConsentBanner';
import LegalGuards from './components/LegalGuards';
import AdminBanner from './components/AdminBanner';

import ShopifyChargeHandler from './components/OAuthHandler/ShopifyChargeHandler';
import { config } from './config';
import AuthService from './services/auth.service';
import { getAppsRequest } from './store/actions/apps_actions';
import { getDebugModeRequest, getUserInfoRequest, putUserInfoRequest } from './store/actions/auth_actions';
import { IState } from './store/reducers';
import { deTranslation, enTranslation, frTranslation, itTranslation } from './translations';
import { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as jwtJsDecode from 'jwt-js-decode';

if (config.sentryUrl) {
  Sentry.init({
    dsn: config.sentryUrl,
    environment: config.activeDomain,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
  clientApp.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error?.response?.status !== 401) {
        const state = store.getState();
        const { location } = state.router;
        Sentry.addBreadcrumb({
          category: 'location',
          level: 'info',
          message: location.pathname,
        });
        Sentry.addBreadcrumb({
          category: 'status',
          level: 'info',
          message: error?.response?.status + '',
        });
        Sentry.addBreadcrumb({
          category: 'request',
          level: 'info',
          message: JSON.stringify(error?.response?.config),
        });
        Sentry.addBreadcrumb({
          category: 'response',
          level: 'info',
          message: JSON.stringify(error?.response?.data),
        });
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    },
  );
}

const tagManagerArgs = {
  gtmId: config.activeDomain === 'bexio' ? 'GTM-P7W52R2' : 'GTM-PVZHWMV',
};

TagManager.initialize(tagManagerArgs);

runSaga(rootSaga);

interface IAppProps extends LocalizeContextProps {
  tokenInfo: any;
  userInfo: any;
  apps: any;
  getUserInfoRequest: any;
  putUserInfoRequest: any;
  getAppsRequest: any;
  getDebugModeRequest: any;
}

const mapStateToProps = (state: IState) => ({
  tokenInfo: state.auth.tokenInfo,
  userInfo: state.auth.userInfo,
  apps: state.apps.apps,
});

const mapDispatchToProps = {
  getUserInfoRequest,
  putUserInfoRequest,
  getAppsRequest,
  getDebugModeRequest,
};

const App = withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props: IAppProps) => {
    const theme = createTheme({
      palette: {
        primary: {
          light: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50' : '#fa6100',
          main: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50' : '#fa6100',
          dark: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50' : '#fa6100',
          contrastText: '#fff',
        },
      },
    });

    useEffect(() => {
      props.initialize({
        languages: [
          { name: 'Deutsch', code: 'de' },
          { name: 'English', code: 'en' },
          { name: 'Français', code: 'fr' },
          // { name: 'Español', code: 'es' },
        ],
        options: { renderToStaticMarkup },
      });

      props.addTranslationForLanguage(enTranslation, 'en');
      props.addTranslationForLanguage(deTranslation, 'de');
      props.addTranslationForLanguage(frTranslation, 'fr');
      props.addTranslationForLanguage(itTranslation, 'it');
    }, []);

    useEffect(() => {
      if (AuthService.isAuthenticated) {
        try {
          if (AuthService.token) {
            const { payload: { company }} = jwtJsDecode.jwtDecode(AuthService.token) as any;
            Sentry.setTag('company', company);
          }
        } catch (e) {
          console.warn(e);
        }
        props.getUserInfoRequest();
        props.getDebugModeRequest();
      }

      if (AuthService.isAuthenticated && !props.apps) {
        props.getAppsRequest();
      }
    }, [AuthService.isAuthenticated]);

    // handle user's language loading
    const [tokenLangSet, setTokenLang] = React.useState(false);
    const [userLangSet, setUserLang] = React.useState(false);
    useEffect(() => {
      const {
        tokenInfo: {
          payload: { language: tokenLanguageCode },
        },
        userInfo: { language: userLanguageCode },
        activeLanguage,
        setActiveLanguage,
      } = props;

      if (tokenLanguageCode || userLanguageCode) {
        const [language] = (userLanguageCode || tokenLanguageCode).split(/_|-/);
        if (activeLanguage && (!tokenLangSet || !userLangSet) && language !== activeLanguage.code.toUpperCase()) {
          if (userLanguageCode) {
            setUserLang(true);
          }
          if (tokenLanguageCode) {
            setTokenLang(true);
          }
          setActiveLanguage(AuthService.isAuthenticated ? language.toLowerCase() : activeLanguage.code);
        }
      }
    }, [props.tokenInfo.payload.language, props.userInfo.language, props.activeLanguage]);

    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/(auth|oauth)" component={Auth} />
          <Route path="/app/shopify/callback" component={ShopifyChargeHandler} />
          <PrivateRoute path="/admin" component={Admin} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </ThemeProvider>
    );
  }),
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<CircularProgress />} persistor={persistor}>
      <LocalizeProvider store={store}>
        <ConnectedRouter history={history}>
          <App />
          <ToastContainer />
          <LegalGuards />
          <CookieConsentBanner />
          <AdminBanner />
        </ConnectedRouter>
      </LocalizeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
