import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'WECLAPP';
export const GET_TAXES = createRequestTypes(`${filePrefix}_GET_TAXES`);
export const GET_CUSTOMER = createRequestTypes(`${filePrefix}_GET_CUSTOMER`);
export const SEARCH_CUSTOMER = createRequestTypes(`${filePrefix}_SEARCH_CUSTOMER`);
export const SEARCH_PRODUCT = createRequestTypes(`${filePrefix}_SEARCH_PRODUCT`);
export const GET_PRODUCT = createRequestTypes(`${filePrefix}_GET_PRODUCT`);

export const getTaxesRequest = (data: any) => ({
  type: GET_TAXES.REQUEST,
  data,
});
export const getTaxesSuccess = (data: any) => ({
  type: GET_TAXES.SUCCESS,
  data,
});
export const getTaxesFailure = (error: any) => ({
  type: GET_TAXES.FAILURE,
  error,
});

export const getCustomerRequest = (data: any) => ({
  type: GET_CUSTOMER.REQUEST,
  data,
});
export const getCustomerSuccess = (data: any) => ({
  type: GET_CUSTOMER.SUCCESS,
  data,
});
export const getCustomerFailure = (error: any) => ({
  type: GET_CUSTOMER.FAILURE,
  error,
});

export const searchCustomerRequest = (data: any) => ({
  type: SEARCH_CUSTOMER.REQUEST,
  data,
});
export const searchCustomerSuccess = (data: any) => ({
  type: SEARCH_CUSTOMER.SUCCESS,
  data,
});
export const searchCustomerFailure = (error: any) => ({
  type: SEARCH_CUSTOMER.FAILURE,
  error,
});
export const getProductRequest = (data: any) => ({
  type: GET_PRODUCT.REQUEST,
  data,
});
export const getProductSuccess = (data: any) => ({
  type: GET_PRODUCT.SUCCESS,
  data,
});
export const getProductFailure = (error: any) => ({
  type: GET_PRODUCT.FAILURE,
  error,
});

export const searchProductRequest = (data: any) => ({
  type: SEARCH_PRODUCT.REQUEST,
  data,
});
export const searchProductSuccess = (data: any) => ({
  type: SEARCH_PRODUCT.SUCCESS,
  data,
});
export const searchProductFailure = (error: any) => ({
  type: SEARCH_PRODUCT.FAILURE,
  error,
});
