import React from 'react';
import { debounce } from 'lodash';
import { TextField } from '@material-ui/core';

type TextFieldProps = {
  placeholder?: string;
  className?: string;
  type?: string;
  inputProps?: any;
  style?: any;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
}

type TextFieldDelayedProps = {
  value: string | undefined;
  onChange: (value: string) => void;
} & TextFieldProps;

function TextFieldDelayed(props: TextFieldDelayedProps) {
  const {
    value: initialValue,
    onChange,
    placeholder = "",
    className = "",
    style = {},
    disabled = false,
    multiline = false,
    rows = 1,
    type = 'text',
    inputProps = {}
  } = props;
  const [value, setValue] = React.useState<string | undefined>(initialValue);
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const setValueDelayed = React.useCallback(
    debounce(onChange, 1000),
    [onChange]
  );
  const onValueChange = React.useCallback(
    (event: any) => {
      const value = event.target.value;
      setValue(value);
      setValueDelayed(value);
    },
    [setValue, setValueDelayed]
  );
  return (
    <TextField
      value={value}
      onChange={onValueChange}
      type={type}
      inputProps={inputProps}
      placeholder={placeholder}
      className={className}
      style={style}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
    />
  );
}

export default TextFieldDelayed;
