import { ActionPattern, all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_SHOP,
  addShopFailure,
  addShopSuccess,
  CHANGE_OPTION,
  HAS_SUBSCRIPTION,
  hasSubscriptionFailure,
  hasSubscriptionSuccess,
  POST_CHECKOUT,
  postCheckoutFailure,
  postCheckoutSuccess,
  IMPORT_ORDER,
  IMPORT_ORDERS,
  importOrderFailure,
  importOrdersFailure,
  importOrdersSuccess,
  importOrderSuccess,
  REMOVE_SHOP,
  removeShopFailure,
  removeShopSuccess,
} from './../actions/prestashop_actions';
import { checkSubscription, postCheckout, importOrder, importOrders } from './../api/prestashop';

import { BedayaAction } from '../../types/BedayaAction';

import { isUrl } from '../../views/Apps/PrestashopWidgets/ShopUrl/ShopUrl';

import { TShop } from '../../types/PrestashopSettings';
import { putSettings } from '../api/common';
import { IState } from '../reducers';

import moment from 'moment';

function* addShopSaga(action: BedayaAction) {
  try {
    const {
      prestashop: { settings },
    }: IState = yield select((state: IState) => state);

    yield call(putSettings, {
      system: 'prestashop',
      data: { ...settings, shops: [...settings.shops, action.data] },
    });

    yield put(addShopSuccess());
  } catch (error) {
    yield put(addShopFailure(error));
  }
}
function* removeShopSaga(action: BedayaAction) {
  try {
    const {
      prestashop: { settings },
    }: IState = yield select((state: IState) => state);

    const newShopsList = settings.shops.filter(shop => shop.id !== action.data);

    yield call(putSettings, { system: 'prestashop', data: { ...settings, shops: newShopsList } });

    yield put(removeShopSuccess());
  } catch (error) {
    yield put(removeShopFailure(error));
  }
}
function* changeShopOptionSaga(action: BedayaAction) {
  try {
    const {
      prestashop: { settings },
    }: IState = yield select((state: IState) => state);
    const newShops = [...settings.shops];
    const updatedShopIndex = newShops.findIndex(shop => shop.id === action.data.id);

    newShops[updatedShopIndex][action.data.optionName as keyof TShop] = action.data.value as never;

    if (isUrl(newShops[updatedShopIndex].url)) {
      yield call(putSettings, { system: 'prestashop', data: { ...settings, shops: newShops } });
    }

    yield put(removeShopSuccess());
  } catch (error) {
    yield put(removeShopFailure(error));
  }
}

function* addShopWatcher() {
  yield takeLatest(ADD_SHOP.REQUEST as ActionPattern, addShopSaga);
}

function* removeShopWatcher() {
  yield takeLatest(REMOVE_SHOP.REQUEST as ActionPattern, removeShopSaga);
}

function* changeShopOptionWatcher() {
  yield takeLatest(CHANGE_OPTION.REQUEST as ActionPattern, changeShopOptionSaga);
}

function* importOrdersSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(
      importOrders,
      moment(action.data.from).format('YYYY-MM-DD'),
      moment(action.data.to).format('YYYY-MM-DD'),
      action.data.shop,
    );
    yield put(importOrdersSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importOrdersFailure(processedError || error));
  }
}

function* importOrderSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(importOrder, action.data.id, action.data.shop);
    yield put(importOrderSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importOrderFailure(processedError || error));
  }
}
function* importOrdersWatcher() {
  yield takeLatest(IMPORT_ORDERS.REQUEST as ActionPattern, importOrdersSaga);
}
function* importOrderWatcher() {
  yield takeLatest(IMPORT_ORDER.REQUEST as ActionPattern, importOrderSaga);
}

function* hasSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(checkSubscription);
    yield put(hasSubscriptionSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(hasSubscriptionFailure(processedError || error));
  }
}
function* hasSubscriptionWatcher() {
  yield takeLatest(HAS_SUBSCRIPTION.REQUEST as ActionPattern, hasSubscriptionSaga);
}

function* postCheckoutSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postCheckout);
    yield put(postCheckoutSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postCheckoutFailure(processedError || error));
  }
}
function* postCheckoutWatcher() {
  yield takeLatest(POST_CHECKOUT.REQUEST as ActionPattern, postCheckoutSaga);
}

export default function* prestashopSaga() {
  yield all([
    fork(addShopWatcher),
    fork(removeShopWatcher),
    fork(changeShopOptionWatcher),
    fork(importOrdersWatcher),
    fork(importOrderWatcher),
    fork(hasSubscriptionWatcher),
    fork(postCheckoutWatcher),
  ]);
}
