import { Radio } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

const style = {
  radioBase: {
    '&$checked': {
      color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#fa6100 !important',
    },
  },
};

export const StyledRadio = withStyles({
  root: {
    ...style.radioBase,
  },
  checked: {},
})((props: any) => <Radio color="default" {...props} />);
