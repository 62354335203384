import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import Select from 'react-select';

import commonStyles from '../../../../../assets/jss/commonStyles';
import colourStyles from '../../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import Button from '../../../../../components/CustomButtons/Button.jsx';
import { IOptionType } from '../../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';
import { findOption } from './MappingItem';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

export interface IRowProps2 {
  aOptions: IOptionType[];
  bOptions: IOptionType[];
  cOptions: IOptionType[];
  a?: string;
  b?: string;
  c?: string;
  isDefault: boolean;
  onToggleDefault: () => void;
  onChange: (a: string, b: string, c: string | null, bt: string | null) => void;
  onDelete?: () => void;
  isDisabled: boolean;
  translate: (a: string) => any;
}

export function MappingItem2(props: IRowProps2): JSX.Element {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    aOptions,
    bOptions,
    cOptions,
    a: initA,
    b: initB,
    c: initC,
    onChange,
    onDelete,
    isDisabled,
    isDefault,
    onToggleDefault,
    translate,
  } = props;
  const [a, setA] = React.useState<IOptionType | null>(null);
  const [b, setB] = React.useState<IOptionType | null>(null);
  const [c, setC] = React.useState<IOptionType | null>(null);
  React.useEffect(() => {
    setA(findOption(aOptions, initA));
  }, [initA, aOptions]);
  React.useEffect(() => {
    setB(findOption(bOptions, initB));
  }, [initB, bOptions]);
  React.useEffect(() => {
    setC(findOption(cOptions, initC));
  }, [initC, cOptions]);
  return (
    <Grid container={true}>
      <Grid item={true} md={3} style={{ padding: 10 }}>
        <Select
          className={classes.w100}
          isSearchable={false}
          options={bOptions}
          value={b}
          onChange={opt => {
            setB(opt ? opt : null);
            if (opt && a) {
              onChange(a.value, opt.value, c ? c.value : null, opt.type ?? null);
            }
          }}
          isDisabled={isDisabled}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} md={3} style={{ padding: 10 }}>
        <Select
          className={classes.w100}
          isSearchable={false}
          options={aOptions}
          value={a}
          onChange={opt => {
            setA(opt ? opt : null);
            if (opt && b) {
              onChange(opt.value, b.value, c ? c.value : null, b.type ?? null);
            }
          }}
          isDisabled={isDisabled}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} md={3} style={{ padding: 10 }}>
        <Select
          className={classes.w100}
          isSearchable={false}
          isClearable={true}
          options={cOptions}
          value={c}
          onChange={opt => {
            setC(opt ? opt : null);
            if (a && b) {
              onChange(a.value, b.value, opt ? opt.value : null, b.type ?? null);
            }
          }}
          isDisabled={isDisabled}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} md={2} style={{ padding: 10 }}>
        <Checkbox onChange={() => onToggleDefault()} checked={isDefault} label={translate('default') as string} />
      </Grid>
      <Grid item={true} md={1} style={{ padding: 10 }}>
        {a && b && onDelete && (
          <Button justIcon={true} round={true} color="transparent" onClick={() => onDelete()}>
            <DeleteIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default MappingItem2;
