// global imports
import { Divider, LinearProgress, Link, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import cx from 'classnames';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import GridContainer from '../../../components/Grid/GridContainer';
import * as appsActions from '../../../store/actions/apps_actions';
import * as commonActions from '../../../store/actions/common_actions';
import * as prestashopActions from '../../../store/actions/prestashop_actions';
import * as shopifyActions from '../../../store/actions/shopify_actions';
import isAllowed from '../../Apps/isAllowed';
import ImportOrderWidget from '../../Apps/ShopifyWidgets/ImportOrder/ImportOrder';
import ImportOrdersWidget from '../../Apps/ShopifyWidgets/ImportOrders/ImportOrders';
import ImportProductsWidget from '../../Apps/ShopifyWidgets/ImportProducts/ImportProducts';
import SumupRunWidget from '../../Apps/SumupWidgets/Run/Run';
import XeniaPosImport from '../../Apps/XeniaPosWidgets/ImportOrders/ImportOrders';
import SystemContainer from '../SystemContainer';

// project types imports
import { IState } from '../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../types/StyleProps';

// local imports
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import { IOptionType } from '../../../types/ReactSelect';
import UploadCSV from '../../Apps/Apps/IbelsaApp/components/UploadCSV/UploadCSV';
import ImportByDate from '../../Apps/GastrofixWidgets/ImportByDate/ImportByDate';
import ImportOrder from './../../Apps/PrestashopWidgets/ImportOrder/ImportOrder';
import ImportOrders from './../../Apps/PrestashopWidgets/ImportOrders/ImportOrders';
import style from './styles';
import { Props, StateProps } from './types';

enum IAppsList {
  'sumup',
  'shopify',
  'prestashop',
  'bexio',
  'debitoor',
  'zoho',
  'gastrofix',
}

type ActionsSettings = {
  [x in IAppsList]: boolean;
};

type IVersionedApp = {
  name: string;
  version: number;
};

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const ActionsPage = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    sumupSettings,
    getAppsRequest,
    getSettingsRequest,
    checkConfirmationRequest,
    shopifyConfirmation,
    shopifyIsLoading,
    setActiveShopRequest,
    shopifyShops,
    getShopsRequest,
    testConnectionForShopRequest,
    getPrestashopSubscription,
  } = props;

  const [testDelayActive, setTestDelayActive] = useState<any>(true);

  const [settings, setSettings] = useState<ActionsSettings | null>(null);

  const [expanded, setExpanded] = React.useState('');
  const [shopsList, setShopsList] = useState<IOptionType[]>([]);
  const [selectedShop, setSelectedShop] = useState<IOptionType | null>(null);

  setTimeout(() => {
    setTestDelayActive(false);
  }, 4000);

  const isExpanded = (list: string[] | string) =>
    !expanded || (typeof list === 'string' ? expanded === list : list.some((appName: string) => expanded === appName));

  const isSumupManualEntry = sumupSettings && sumupSettings.transaction && sumupSettings.transaction.enabled;

  // didMount fetch data
  useEffect(() => {
    if (shopifyShops.length === 0) {
      getShopsRequest();
    }
    getSettingsRequest({ system: 'sumup' });
    getSettingsRequest({ system: 'prestashop' });
    getSettingsRequest({ system: 'gastrofix' });
    getAppsRequest({});
    getPrestashopSubscription();
  }, []);

  // update expanded configurator from url
  useEffect(() => {
    const match = props.location.pathname.match(/\/admin\/apps\/(\w+)\/?(\d)?/);
    if (match && match[1]) {
      setExpanded(match[1]);
    }
  }, [props.location.pathname]);

  // set settings locally
  useEffect(() => {
    if (props.apps && props.userInfo && props.userInfo.availableApps) {
      const newSettings = props.userInfo.availableApps.reduce((acc: any, cur: IVersionedApp, i: any) => {
        acc[cur.name] = props.apps.includes(cur.name);
        return acc;
      }, {});
      setSettings(newSettings);
    }
  }, [props.apps, props.userInfo]);

  // save settings to backend
  useEffect(() => {
    if (props.apps && props.userInfo && props.userInfo.availableApps && settings) {
      const updatedSettings = [
        ...Object.keys(settings).filter((app: string) => settings[(app as unknown) as IAppsList]),
      ];
      if (!isEqual(props.apps, updatedSettings)) {
        props.putAppsRequest(updatedSettings);
      }
    }
  }, [settings]);

  useEffect(() => {
    if (shopifyShops && shopifyShops.length > 0) {
      const shopOptions = shopifyShops.map((shop: any) => ({
        value: shop.name,
        label: shop.name,
      }));
      setShopsList(shopOptions);
      if (!selectedShop) {
        setSelectedShop(shopOptions[0]);
        setActiveShopRequest({ shop: shopOptions[0].value });
        testConnectionForShopRequest({ shop: shopOptions[0].value });
        checkConfirmationRequest({ shop: shopOptions[0].value });
      }
    }
  }, [shopifyShops]);

  const shopChanged = (value: IOptionType) => {
    setSelectedShop(value);
    if (selectedShop) {
      setActiveShopRequest({ shop: value.value });
      testConnectionForShopRequest({ shop: value.value });
      checkConfirmationRequest({ shop: value.value });
    }
  };

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>{translate('actions')}</h4>
      </CardHeader>
      <CardBody>
        {props.userInfo.availableApps ? (
          <GridContainer>
            {!(isAllowed(['sumup'], props.apps) && isExpanded(['sumup']) && isSumupManualEntry) &&
              !(isAllowed(['shopify'], props.apps) && isExpanded(['shopify'])) && (
                <div className={classes.blockAlignCenter}>
                  <h2>{translate('no-actions')}</h2>
                </div>
              )}
            {isAllowed(['sumup'], props.apps) && isExpanded(['sumup']) && isSumupManualEntry && (
              <SystemContainer appName="sumup" title={translate('sumup_app_name')} {...props}>
                {sumupSettings && !sumupSettings.transaction.feeAccount && (
                  <Alert variant="outlined" severity="warning">
                    {`${translate('sumup.transactions_processing_logic_update_desc')}`}
                  </Alert>
                )}
                {props.OAuthURL.sumup &&
                  props.sumupConnectionStatus !== 'loading' &&
                  props.sumupConnectionStatus !== 'ok' && (
                    <a className={classes.linkInline} href="/admin/sumup-app">
                      Go to Sumup Settings to Connect
                    </a>
                  )}
                {props.sumupConnectionStatus !== 'loading' && props.sumupConnectionStatus === 'ok' && (
                  <p className={classes.bodyLg}>Import Transaction</p>
                )}
                <div className={cx(classes.buttonsContainer, classes.inverted)}>
                  <SumupRunWidget isRow={true} testConnection={true} testConnectionTimeout={0} />
                </div>
              </SystemContainer>
            )}
            {isAllowed(['xeniapos'], props.apps) && isExpanded(['xeniapos']) && (
              <SystemContainer appName="xeniapos" title={translate('xeniapos_app_name')} {...props}>
                <p className={classes.bodyLg}>Import</p>
                <div className={cx(classes.buttonsContainer, classes.inverted)}>
                  <XeniaPosImport />
                </div>
              </SystemContainer>
            )}
            {isAllowed(['shopify'], props.apps) && isExpanded(['shopify']) && (
              <SystemContainer appName="shopify" title={translate('shopify_app_name')} {...props}>
                {(testDelayActive || shopifyIsLoading) && <LinearProgress className={classes.contentLarge} />}
                <p className={classes.bodyLg}>{translate('do-import-action-for-shopify-shop')}</p>
                <Select
                  isSearchable={false}
                  className={classes.w100}
                  options={shopsList}
                  value={selectedShop}
                  styles={colourStyles}
                  onChange={(value: ValueType<IOptionType, false>) => shopChanged(value as IOptionType)}
                />

                {!testDelayActive &&
                  props.OAuthURL.shopify &&
                  props.shopifyConnectionStatus !== 'loading' &&
                  props.shopifyConnectionStatus !== 'ok' && (
                    <Link className={classes.linkInline} href="/admin/shopify-app">
                      {translate('shopify_go_settings')}
                    </Link>
                  )}

                {props.shopifyConnectionStatus === 'ok' && !shopifyIsLoading && !shopifyConfirmation ? (
                  <Alert variant="outlined" severity="warning">
                    Before you can import your orders and products, you need to confirm your payment by{' '}
                    <Link href={`/auth/plans/${selectedShop ? selectedShop.value : '#'}`}>clicking here.</Link>
                  </Alert>
                ) : (
                  <div className={cx(classes.buttonsContainer, classes.flexColumn)}>
                    {props.errors.shopify && <p className={classes.iconRed}>{props.errors.shopify}</p>}
                    <ImportProductsWidget />
                    <Divider className={classes.divider} />
                    <ImportOrdersWidget />
                    <Divider className={classes.divider} />
                    <ImportOrderWidget />
                  </div>
                )}
              </SystemContainer>
            )}
            {isAllowed(['prestashop'], props.apps) && isExpanded(['prestashop']) && props.prestashop.hasSubscription && (
              <SystemContainer appName="prestashop" title={translate('prestashop_app_name')} {...props}>
                {testDelayActive && <LinearProgress className={classes.contentLarge} />}
                {!testDelayActive && !props.prestashop.webhookSign && props.prestashop.shops.length === 0 && (
                  <a className={classes.linkInline} href="/admin/prestashop-app">
                    {translate('go_to_ps_settings')}
                  </a>
                )}
                <div className={cx(classes.buttonsContainer, classes.flexColumn)}>
                  {props.errors.shopify && <p className={classes.iconRed}>{props.errors.shopify}</p>}
                  <ImportOrder />
                  <ImportOrders />
                </div>
              </SystemContainer>
            )}
            {isAllowed(['gastrofix'], props.apps) && isExpanded(['gastrofix']) && (
              <SystemContainer appName="gastrofix" title={translate('gastrofix_app_name')} {...props}>
                {testDelayActive ? (
                  <LinearProgress className={classes.contentLarge} />
                ) : !testDelayActive && !props.gastrofix.businessUnitId && !props.gastrofix.apiToken ? (
                  <a className={classes.linkInline} href="/admin/gastrofix-app">
                    {translate('go_to_gastrofix_settings')}
                  </a>
                ) : (
                  <div className={cx(classes.buttonsContainer, classes.flexColumn)}>
                    <ImportByDate />
                  </div>
                )}
              </SystemContainer>
            )}
            {isAllowed(['ibelsa'], props.apps) && isExpanded(['ibelsa']) && (
              <SystemContainer appName="ibelsa" title={translate('ibelsa_app_name')} {...props}>
                {testDelayActive ? (
                  <LinearProgress className={classes.contentLarge} />
                ) : (
                  <div className={cx(classes.buttonsContainer, classes.flexColumn)}>
                    <UploadCSV />
                  </div>
                )}
              </SystemContainer>
            )}
          </GridContainer>
        ) : (
          <LinearProgress className={classes.contentLarge} />
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: {
    sumup: state.sumup.OAuthURL,
    shopify: state.shopify.OAuthURL,
  },
  errors: {
    sumup: state.sumup.error,
    shopify: state.shopify.error,
  },
  apps: state.apps.apps,
  userInfo: state.auth.userInfo,
  isFetching: state.apps.isFetching,
  sumupConnectionStatus: state.sumup.connectionStatus,
  shopifyConnectionStatus: state.shopify.connectionStatus,
  shopifyConfirmation: state.shopify.confirmed,
  shopifyIsLoading: state.shopify.isLoading,
  shopifyConfirmationUrl: state.shopify.confirmationUrl,
  shopifyShops: state.shopify.shops,
  sumupSettings: state.sumup.settings,
  wixSettings: state.wix.settings,
  prestashop: {
    webhookSign: state.prestashop.settings.webhookSign,
    shops: state.prestashop.settings.shops,
    hasSubscription: state.prestashop.hasSubscription,
  },
  gastrofix: {
    apiToken: state.gastrofix.settings.apiToken,
    businessUnitId: state.gastrofix.settings.businessUnitId,
  },
});

const mapDispatchToProps = {
  getAppsRequest: appsActions.getAppsRequest,
  putAppsRequest: appsActions.putAppsRequest,
  disconnectRequest: commonActions.disconnectRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  checkConfirmationRequest: shopifyActions.checkConfirmationRequest,
  setActiveShopRequest: shopifyActions.setActiveShopRequest,
  getShopsRequest: shopifyActions.shopsRequest,
  testConnectionForShopRequest: shopifyActions.testConnectionForShopRequest,
  getPrestashopSubscription: prestashopActions.hasSubscriptionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ActionsPage),
);
