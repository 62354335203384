// global imports
import { Grid, makeStyles, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import GridItem from '../../../../../../components/Grid/GridItem';
import { StyledSwitch } from '../../../../../../components/StyledSwitch';
import assign from '../../../../../../services/assign';
import * as commonActions from '../../../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../../../store/reducers';
import { LightspeedSettings } from '../../../../../../types/LightspeedSettings';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';

import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const TransferOptions = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, lightspeedSettings, putSettingsRequest } = props;

  const [settings, setSettings] = useState<LightspeedSettings | null>(null);

  useEffect(() => {
    if (lightspeedSettings) {
      setSettings({
        ...cloneDeep(lightspeedSettings),
      });
    }
  }, [lightspeedSettings]);

  // save settings to backend
  useEffect(() => {
    if (settings && lightspeedSettings && !isEqual(lightspeedSettings, settings)) {
      putSettingsRequest({
        system: 'lightspeed',
        data: {
          ...settings,
        },
      });
    }
  }, [settings]);

  const handleSwitchChange = (name: any) => (event: any) => {
    if (settings) {
      setSettings(assign({ ...settings }, name, event.target.checked));
    }
  };

  const isDisabled = !settings;

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('lightspeed.transfer-options') as string}
          desc={translate('lightspeed.transfer-options-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true}>
              <GridItem xs={4} className={classes.gridItem}>
                <InputLabel>{translate('lightspeed.product_transfer')}</InputLabel>
              </GridItem>
              <GridItem xs={8} className={classes.gridItem}>
                <StyledSwitch
                  disabled={isDisabled}
                  checked={settings ? settings.productSync.enabled : false}
                  onChange={handleSwitchChange('productSync.enabled')}
                  value="productSync"
                />
              </GridItem>
            </Grid>

            <Grid container={true}>
              <GridItem xs={4} className={classes.gridItem}>
                <InputLabel>{translate('lightspeed.invoice_transfer')}</InputLabel>
              </GridItem>
              <GridItem xs={8} className={classes.gridItem}>
                <StyledSwitch
                  disabled={isDisabled}
                  checked={settings ? settings.invoiceSync.enabled : false}
                  onChange={handleSwitchChange('invoiceSync.enabled')}
                  value="invoiceSync"
                />
              </GridItem>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  lightspeedSettings: state.lightspeed.settings,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TransferOptions),
);
