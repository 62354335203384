// global imports
import { FormControlLabel, Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import * as commonActions from '../../../../store/actions/common_actions';
import * as gastrofixActions from '../../../../store/actions/gastrofix_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Button from '../../../../components/CustomButtons/Button';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { IOptionType } from '../../../../types/ReactSelect';
import { DispatchProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const CreditAccounts = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    gastrofixSettings,
    getArticleFamilies,
    creditAccounts,
    getAccountsRequest,
    articleFamilies,
    putSettingsRequest,
    isFetching,
  } = props;

  const [creditCards, setCreditAccounts] = useState(gastrofixSettings.creditCards);

  useEffect(() => {
    getAccountsRequest({});
    if (gastrofixSettings.id) {
      getArticleFamilies({ id: gastrofixSettings.id });
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (_.isEqual(creditCards, gastrofixSettings.creditCards)) {
      return;
    }

    for (const account of creditCards) {
      if (account.articleFamilyId === 0 || account.creditAccountNumber.length === 0) {
        return;
      }
    }

    const newSettings = _.cloneDeep(gastrofixSettings);
    newSettings.creditCards = creditCards;

    putSettingsRequest({ system: 'gastrofix', data: newSettings });
  }, [creditCards]);

  useEffect(() => {
    setCreditAccounts(gastrofixSettings.creditCards);
  }, [gastrofixSettings.creditCards]);

  const creditAccountChange = (key: number, newAccount: IOptionType) => {
    setCreditAccounts([
      ...creditCards.slice(0, key),
      {
        ...creditCards[key],
        creditAccountNumber: newAccount.value,
      },
      ...creditCards.slice(key + 1),
    ]);
  };

  const articleFamilyChange = (key: number, newFamily: IOptionType) => {
    setCreditAccounts([
      ...creditCards.slice(0, key),
      {
        ...creditCards[key],
        articleFamilyId: parseInt(newFamily.value, 10),
      },
      ...creditCards.slice(key + 1),
    ]);
  };

  const toggleCardDefault = (key: number) => {
    const checked = creditCards[key].isDefault;

    if (checked) {
      return;
    }

    setCreditAccounts(
      creditCards.map((card, index) => ({
        ...card,
        isDefault: key === index ? !card.isDefault : false,
      })),
    );
  };

  const addCard = () => {
    setCreditAccounts([
      ...creditCards,
      {
        creditAccountNumber: '',
        articleFamilyId: 0,
        isDefault: !hasDefault(),
      },
    ]);
  };

  const removeCreditCard = (key: number) => {
    const newCreditCards = [...creditCards.slice(0, key), ...creditCards.slice(key + 1)];
    if (creditCards[key].isDefault && newCreditCards[0]) {
      newCreditCards[0].isDefault = true;
    }

    setCreditAccounts(newCreditCards);
  };

  const hasDefault = () => {
    for (const card of creditCards) {
      if (card.isDefault) {
        return true;
      }
    }
    return false;
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('gastrofix.credit_accounts') as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard style={{ display: 'block' }}>
          {creditCards.map((card, key) => (
            <div
              key={key}
              className={cx(
                classes.contentContainer,
                classes.mt10,
                card.articleFamilyId === 0 || card.creditAccountNumber.length === 0 ? classes.errorBLeft : '',
              )}
            >
              <Grid md={3} style={{ padding: 10 }}>
                <InputLabel className={classes.mb10}>{translate('gastrofix.article_family')}</InputLabel>
                <Select
                  isDisabled={isFetching}
                  options={articleFamilies.map(fam => ({ label: fam.name, value: fam.id + '' }))}
                  value={articleFamilies
                    .map(fam => ({ label: fam.name, value: fam.id + '' }))
                    .find(fam => (fam ? parseInt(fam.value, 10) === card.articleFamilyId : false))}
                  onChange={(value: ValueType<IOptionType, false>) => articleFamilyChange(key, value as IOptionType)}
                />
              </Grid>
              <Grid md={3} style={{ padding: 10 }}>
                <InputLabel className={classes.mb10}>{translate('gastrofix.credit_account')}</InputLabel>
                <Select
                  isDisabled={isFetching}
                  options={creditAccounts.map(acc => ({ label: acc.name, value: acc.number }))}
                  value={creditAccounts
                    .map(acc => ({ label: acc.name, value: acc.number }))
                    .find(acc => (acc ? acc.value === card.creditAccountNumber : false))}
                  onChange={(value: ValueType<IOptionType, false>) => creditAccountChange(key, value as IOptionType)}
                />
              </Grid>
              <Grid>
                <FormControlLabel
                  control={<Checkbox onChange={() => toggleCardDefault(key)} checked={card.isDefault} />}
                  style={{
                    marginTop: 35,
                    marginLeft: 10,
                  }}
                  label={translate('default')}
                />
              </Grid>
              <Grid>
                <Button
                  style={{ marginTop: 35 }}
                  justIcon={true}
                  round={true}
                  color="transparent"
                  onClick={() => removeCreditCard(key)}
                >
                  <DeleteIcon fontSize="large" />
                </Button>
              </Grid>
            </div>
          ))}
          <Button className={classes.addButton} justIcon={true} round={true} onClick={addCard}>
            <AddIcon />
          </Button>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  creditAccounts: state.gastrofix.accounts.credit,
  articleFamilies: state.gastrofix.articleFamilies,
  gastrofixSettings: state.gastrofix.settings,
  isFetching: state.gastrofix.isFetching,
});

const mapDispatchToProps = {
  getAccountsRequest: gastrofixActions.getAccountsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  getArticleFamilies: gastrofixActions.getArticleFamiliesRequest,
};

export default connect<StateProps, DispatchProps, {}, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(CreditAccounts));
