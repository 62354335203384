import commonStyles from '../../../assets/jss/commonStyles';
import { blackColor, grayColor, hexToRgb, whiteColor } from '../../../assets/jss/material-dashboard-pro-react';
import buttonStyle from '../../../assets/jss/material-dashboard-pro-react/components/buttonStyle';

const styles = {
  ...commonStyles,
  ...buttonStyle,
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  smallCard: {
    flexDirection: 'column',
    marginBottom: '30px',
    marginTop: '0',
    // minWidth: '200px',
    minHeight: '107px',
  },
  smallCardExpanded: {
    width: '100%',
    maxWidth: '100%',
    flexBasis: 'auto',
    boxShadow: 'none',
  },
  smallTitle: {
    marginBottom: '0',
    paddingBottom: '0',
    marginTop: '0',
    paddingTop: '0',
  },
  selectSmall: {
    width: '150px',
    display: 'inline-block',
    padding: '0 5px',
  },
  typo: {
    paddingLeft: '25%',
    marginBottom: '40px',
  },
  cardTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: '500',
  },
  overflow: {
    overflow: 'hidden',
    width: 'calc(100% - 15px)',
    justifyContent: 'space-between',
    paddingTop: '0',
    paddingBottom: '0',
  },
  overflowActions: {
    overflow: 'hidden',
    width: 'calc(100% - 15px)',
    justifyContent: 'space-around',
    paddingTop: '0',
    paddingBottom: '0',
  },
  linkButton: {
    padding: '0 5px',
  },
  smallButton: {
    width: '70px',
    whiteSpace: 'normal',
  },
  verticalDivider: {
    height: '25px',
    width: '1px',
  },
  customButtonStyles: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.12)',
    border: 'none',
    position: 'relative',
    margin: '.3125rem 1px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginTop: '-1em',
      marginBottom: '-1em',
      fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      width: '18px',
      height: '18px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        marginTop: '0px',
        position: 'absolute',
        width: '100%',
        transform: 'none',
        left: '0px',
        top: '0px',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
      },
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        marginTop: '1px',
      },
    },
    padding: '0.40625rem 1.25rem',
    fontSize: '0.6875rem',
    lineHeight: '1.5',
    borderRadius: '0.2rem',
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: grayColor[0],
      boxShadow: 'none',
    },
  },
  collapsedPanel: {},
  expandedPanel: {
    opacity: '1',
  },
  summaryCollapsed: {
    padding: '0',
  },
  summaryExpanded: {},
  summaryCollapsedContent: {
    margin: '0',
  },
  summaryExpandedContent: {},
};

export default styles;
