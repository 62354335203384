import CSS from 'csstype';
import React from 'react';

export default function FormCard(props: {
  children: any;
  marginBottom?: boolean;
  className?: string;
  style?: CSS.Properties;
}) {
  return (
    <div
      className={props.className}
      style={{
        background: '#FCFCFC',
        border: '1px solid #FFFFFF',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14)',
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        padding: '20px 23px 15px 23px',
        marginBottom: props.marginBottom ? 20 : 0,
        // ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}
