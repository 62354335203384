import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import * as authActions from '../../store/actions/auth_actions';
import * as shopifyActions from '../../store/actions/shopify_actions';
import { IState } from '../../store/reducers';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postChargeIDRequest: (data: any) => any;
  shopifyConfirmation: boolean;
  shopifyConfirmationLoading: boolean;
  redirectPath: string;
  wixAuthenticated: boolean;
  token: string;
  location: {
    search: string;
    pathname: string;
  };
}

const ShopifyChargeHandler: React.FC<IProps> = props => {
  const { classes, location, postChargeIDRequest, shopifyConfirmation, shopifyConfirmationLoading } = props;

  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [doErrorRedirect, setDoErrorRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (shopifyConfirmation) {
      setDoRedirect(true);
    }
  }, [shopifyConfirmation]);

  useEffect(() => {
    if (!shopifyConfirmationLoading && !shopifyConfirmation) {
      setDoErrorRedirect(true);
    }
  }, [shopifyConfirmationLoading]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      if (shopifyConfirmation) {
        setDoRedirect(true);
      }

      const params = new URLSearchParams(location.search);
      if (params.get('charge_id')) {
        postChargeIDRequest({ id: params.get('charge_id') });
      } else {
        setDoErrorRedirect(true);
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      {(doRedirect || doErrorRedirect) && <Redirect to="/admin/shopify-app" />}
      <CircularProgress size={100} />
      {/*<button onClick={() => setDoRedirect(true)}>Redirect</button>*/}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  shopifyConfirmation: state.shopify.confirmed,
  shopifyConfirmationLoading: state.shopify.isLoading,
  redirectPath: state.auth.redirectPath,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
  postChargeIDRequest: shopifyActions.postChargeIDRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(ShopifyChargeHandler));
