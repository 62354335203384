import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../store/reducers';
import GDPRGuard from '../GDPRGuard/GDPRGuard';
import TermsGuard from '../TermsGuard';
import { config } from '../../config';
import AuthService from '../../services/auth.service';

export type StateProps = {
  userInfo: any;
}

const LegalGuards: React.FC<StateProps> = ({ userInfo }) => {
  if (AuthService.isImpersonated) {
      return null;
  }

  if (config.policyEnabled && userInfo && userInfo.privacyPolicies) {
    const hasUnconfirmed = userInfo.privacyPolicies.find((policy: any) => !policy.confirmed);
    const hasNoCurrent = !userInfo.privacyPolicies.find((policy: any) => policy.version === config.policyVersion);
    if (hasUnconfirmed || hasNoCurrent) {
      return <GDPRGuard />;
    }
  }
  if (config.termsEnabled && userInfo && userInfo.termsAndConditions) {
    const hasUnconfirmed = userInfo.termsAndConditions.find((terms: any) => !terms.confirmed);
    const hasNoCurrent = !userInfo.termsAndConditions.find((terms: any) => terms.version === config.termsVersion);
    if (hasUnconfirmed || hasNoCurrent) {
      return <TermsGuard />;
    }
  }
  return null;
}

const mapStateToProps = (state: IState): StateProps => ({
  userInfo: state.auth.userInfo,
});

export default connect(
  mapStateToProps,
)(LegalGuards);
