import commonStyles from '../../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  smallTitle: {
    marginBottom: '0',
    paddingBottom: '0',
    marginTop: '0',
    paddingTop: '0',
    marginLeft: '35px',
  },
  divider: {
    marginTop: '15px',
  },
  container: {
    flexDirection: 'row',
  },
  inverted: {
    flexDirection: 'column-reverse !important',
  },
  cardTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  collapsedPanel: {},
  expandedPanel: {
    opacity: '1',
  },
  summaryCollapsed: {
    padding: '0',
  },
  summaryExpanded: {},
  summaryCollapsedContent: {
    margin: '0',
  },
  summaryExpandedContent: {},
};

export default styles;
