// global imports
import { CircularProgress, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

// project imports
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import * as commonActions from '../../../../store/actions/common_actions';
import * as shopifyActions from '../../../../store/actions/shopify_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { ShopifySettings } from '../../../../types/ShopifySettings';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// widgets imports
import ProductField from '../../ShopifyWidgets/ProductField/ProductField';
import ShopCurrency from '../../ShopifyWidgets/ShopCurrency/ShopCurrency';
import TransferOptions from '../../ShopifyWidgets/TransferOptions/TransferOptions';

// local imports
import { Alert } from '@material-ui/lab';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { getPlatformName } from '../../../../services/utils';
import { IOptionType } from '../../../../types/ReactSelect';
import TransferDesc from '../../BexioWidgets/TransferDesc/TransferDesc';
import TransferSalesPrice from '../../BexioWidgets/TransferSalesPrice/TransferSalesPrice';
import TransferVendor from '../../BexioWidgets/TransferVendor/TransferVendor';
import WriteBackStockAmount from '../../BexioWidgets/WriteBackStockAmount/WriteBackStockAmount';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const clamp = (x: number, a: number, b: number): number => x < a ? a : (x > b ? b : x);

const ShopifyApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    shopifySettings,
    connectionStatus,
    connectionStatusLoading,
    testConnectionForShopRequest,
    checkConfirmationRequest,
    shopifyConfirmation,
    getShopsRequest,
    shopifyShops,
    getSettingsForShopRequest,
    setActiveShopRequest,
    isLoading,
    getShopStatisticsAllRequest,
    getShopStatisticsDetailedRequest,
    statistics,
    statisticsDetailed,
  } = props;

  const [settings, setSettings] = useState<ShopifySettings | null>(null);
  const [shopsList, setShopsList] = useState<IOptionType[]>([]);
  const [selectedShop, setSelectedShop] = useState<IOptionType | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  // didMount fetch data
  useEffect(() => {
    if (shopifyShops.length === 0) {
      getShopsRequest();
    }
    getShopStatisticsAllRequest({});
  }, []);

  useEffect(() => {
    if (shopifyShops && shopifyShops.length > 0) {
      const shopOptions = shopifyShops.map((shop: any) => ({
        value: shop.name,
        label: shop.name,
      }));
      setShopsList(shopOptions);
      if (!selectedShop || !selectedShop.value) {
        setSelectedShop(shopOptions[0]);
        setActiveShopRequest({ shop: shopOptions[0].value });
        getSettingsForShopRequest({ shop: shopOptions[0].value });
        testConnectionForShopRequest({ shop: shopOptions[0].value, system: 'shopify' });
        checkConfirmationRequest({ shop: shopOptions[0].value });
        getShopStatisticsAllRequest({});
      }
    }
  }, [shopifyShops]);

  useEffect(() => {
    if (shopifySettings && shopifySettings.shop) {
      const match = shopifySettings.shop[0].match(/(.*).myshopify.com/);
      const shopName = match ? match[1] : shopifySettings.shop[0];
      setSettings({
        ...cloneDeep(shopifySettings),
        shop: shopName,
      });
    }
  }, [shopifySettings]);

  const getDestinationSystemSettings = () => {
    return <BexioConfig system="Shopify" />;
  };

  const shopChanged = (value: IOptionType) => {
    setSelectedShop(value);
    if (selectedShop) {
      setActiveShopRequest({ shop: value.value });
      getSettingsForShopRequest({ shop: value.value });
      testConnectionForShopRequest({ shop: value.value, system: 'shopify' });
      checkConfirmationRequest({ shop: value.value });
      getShopStatisticsAllRequest({});
    }
  };

  useEffect(() => {
    if (showDetails) {
      getShopStatisticsDetailedRequest({});
    }
  }, [showDetails]);

  const toggleDetails = () => setShowDetails(!showDetails);

  const hasShopConnection = connectionStatus === 'ok' && shopifySettings && shopifySettings.shop;
  const hasWarning = statistics.maxOrders > 0 && statistics.numOrders >= statistics.maxOrders;

  if (hasShopConnection && !shopifyConfirmation && !isLoading) {
      return <Redirect to={`/auth/plans/${shopifySettings ? shopifySettings.shop : ''}`} />
  }
  const getPercent = () => {
    if (statistics.maxOrders > 0) {
      return clamp(statistics.numOrders / statistics.maxOrders * 100, 0, 100).toFixed(2);
    }
    return 0;
  }
  return (
    <SettingsContainer appName={translate('shopify_app_name') as string}>
      {hasShopConnection && shopifyConfirmation && !isLoading && hasWarning && (
        <Alert variant="outlined" severity="warning">
          {translate('shopify.plan-alert') + ' '}
          <Link href={`/auth/plans/${shopifySettings ? shopifySettings.shop : ''}`}>{translate('shopify.update-plan')}</Link>
        </Alert>
      )}
      {hasShopConnection && shopifyConfirmation && statistics.lastPayment && (
        <GridRow container={true}>
          <Grid item={true} style={{width: '100%'}}>
            <FormCard>
            {isLoading ? <CircularProgress /> : (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{fontWeight: 'bold'}}>{translate(`shopify_plans.names.${statistics.planName}`) + ''}</div>
                <div style={{flexGrow: 1}}></div>
                {statistics.maxOrders > 0 ? (
                  <div>{translate('shopify.order-statistics', statistics) + ''}</div>
                ) : (
                  <div>{translate('shopify.order-statistics-unlimited', statistics) + ''}</div>
                )}
              </div>
              <div style={{backgroundColor: '#e5e1e6', width: '100%', height: '0.25rem', position: 'relative', margin: '0.5rem 0'}}>
                <div style={{backgroundColor: hasWarning ? '#D6001C' : '#31D618', width: `${getPercent()}%`, position: 'absolute', left: 0, top: 0, height: '0.25rem'}}>
                </div>
              </div>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div>{translate('shopify.order-statistics-since', { date: moment(statistics.lastPayment).format('DD.MM.YYYY')}) + ''}</div>
                {shopifyShops.length > 1 ? (
                  <div style={{ marginLeft: '1rem' }}>
                    <Link onClick={toggleDetails}>
                      {!showDetails ? translate('shopify.show-statistics-details') : translate('shopify.hide-statistics-details')}
                    </Link>
                  </div>
                ) : null}
                <div style={{flexGrow: 1}}></div>
                <Link href={`/auth/plans/${shopifyShops.length ? shopifyShops[0].name : ''}`}>
                  {translate('shopify.update-plan')}
                </Link>
              </div>
              {shopifyShops.length > 1 && statisticsDetailed.length > 0 && showDetails ? (
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: '1rem'}}>
                {statisticsDetailed.map((it, id) => (
                  <div key={id} style={{}}>
                    {it.shop}: {translate('shopify.order-statistics-unlimited', it) + ''}
                  </div>
                ))}
              </div>
              ) : null}
            </div>
           )}
           </FormCard>
          </Grid>
        </GridRow>
      )}
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('shopify.shop_name') as string}
            desc={translate('shopify.shop_name-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <Select
              isSearchable={false}
              className={classes.w100}
              options={shopsList}
              value={selectedShop}
              styles={colourStyles}
              onChange={(value: ValueType<IOptionType, false>) => shopChanged(value as IOptionType)}
            />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('shopify.status', { platform: getPlatformName() }) as string}
            desc={translate('shopify.status-desc', { platform: getPlatformName() }) as string}
            help={{
              text: translate('help') as string,
              link: translate('shopify.status-link') as string,
              inNewLine: true,
            }}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            {!connectionStatusLoading ? (
              connectionStatus === 'ok' ? (
                <div className={classes.connectedContainer}>
                  <span className={classes.connectedStatus}>{translate('connected')}</span>
                  <DisconnectWidget appName="shopify" />
                </div>
              ) : (
                <ConnectWidget
                  appName="shopify"
                  error={settings && !settings.shop ? translate('shop_required') : undefined}
                  disabled={false}
                />
              )
            ) : (
              <CircularProgress />
            )}
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('shopify.options') as string}
            desc={translate('shopify.options-desc') as string}
            help={{
              text: translate('help') as string,
              link: translate('shopify.options-link') as string,
              inNewLine: true,
            }}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <div className={classes.w100}>
              <ShopCurrency />
              <ProductField />
              <TransferDesc />
              <TransferSalesPrice />
              <WriteBackStockAmount system="shopify" />
              <TransferVendor />
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('shopify.document-settings') as string}
            desc={translate('shopify.document-settings-desc') as string}
            help={{
              text: translate('help') as string,
              link: translate('shopify.document-settings-link') as string,
              inNewLine: true,
            }}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <div className={classes.w100}>
              <TransferOptions />
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      {getDestinationSystemSettings()}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shopifySettings: state.shopify.settings,
  connectionStatus: state.shopify.connectionStatus,
  connectionStatusLoading: state.shopify.connectionStatusLoading,
  shopifyConfirmation: state.shopify.confirmed,
  shopifyShops: state.shopify.shops,
  isLoading: state.shopify.settingsLoading || state.shopify.connectionStatusLoading || state.shopify.confirmationLoading || state.shopify.statisticsLoading,
  statistics: state.shopify.statisticsAll,
  statisticsDetailed: state.shopify.statisticsDetailed,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  testConnectionForShopRequest: shopifyActions.testConnectionForShopRequest,
  checkConfirmationRequest: shopifyActions.checkConfirmationRequest,
  getShopsRequest: shopifyActions.shopsRequest,
  getSettingsForShopRequest: shopifyActions.getSettingsForShopRequest,
  putSettingsForShopRequest: shopifyActions.putSettingsForShopRequest,
  setActiveShopRequest: shopifyActions.setActiveShopRequest,
  getShopStatisticsAllRequest: shopifyActions.getShopStatisticsAllRequest,
  getShopStatisticsDetailedRequest: shopifyActions.getShopStatisticsDetailedRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ShopifyApp),
);
