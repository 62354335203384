import { BedayaAction } from '../../types/BedayaAction';
import { EnforePaymentMethod, EnforeSalesCategory, EnforeSettings } from '../../types/EnforeSettings';
import { GET_SETTINGS, PUT_SETTINGS, TEST_CONNECTION } from '../actions/common_actions';
import { GET_PAYMENT_METHODS, GET_SALES_CATEGORIES } from '../actions/enfore_actions';

export interface IEnforeState {
  settings: EnforeSettings;
  isFetching: boolean;
  isFetchingConnection: boolean;
  error: any;
  connectionStatus: string;
  salesCategories: EnforeSalesCategory[];
  paymentMethods: EnforePaymentMethod[];
}

const initialState: IEnforeState = {
  settings: {
    user: null,
    password: null,
    orgId: null,
  },

  isFetching: false,
  error: '',
  connectionStatus: 'nook',
  isFetchingConnection: false,

  salesCategories: [],
  paymentMethods: [],
};

export default function reducer(state: IEnforeState = initialState, action: BedayaAction): IEnforeState {
  switch (action.type) {
    case GET_PAYMENT_METHODS.REQUEST:
    case GET_SALES_CATEGORIES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PAYMENT_METHODS.FAILURE:
    case GET_SALES_CATEGORIES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error.toString(),
      };
    case GET_PAYMENT_METHODS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentMethods: action.data.data,
      };
    case GET_SALES_CATEGORIES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        salesCategories: action.data.data,
      };
    // protocol actions
    case TEST_CONNECTION.REQUEST:
      if (['enfore'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingConnection: true,
          isFetching: true,
        };
      }
      return state;
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['enfore'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
      if (['enfore'].includes(action.data.system)) {
        const authChanged =
          state.settings.user !== action.data.data.user ||
          state.settings.password !== action.data.data.password ||
          state.settings.orgId !== action.data.data.orgId;
        return {
          ...state,
          settings: action.data.data,
          connectionStatus: authChanged ? 'nook' : state.connectionStatus,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.SUCCESS:
      if (['enfore'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case TEST_CONNECTION.SUCCESS:
      if (['enfore'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          isFetchingConnection: false,
          connectionStatus: action.data.status || 'nook',
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['enfore'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error.toString(),
        };
      }
      return state;
    default:
      return state;
  }
}
