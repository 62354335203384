import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import * as authActions from '../../store/actions/auth_actions';
import { IState } from '../../store/reducers';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postOAuthTokenRequest: (loginRequest: any) => any;
  authenticated: boolean;
  location: {
    search: string;
    pathname: string;
  };
}

const LightspeedROAuthHandler: React.FC<IProps> = props => {
  const { classes, location, authenticated, postOAuthTokenRequest } = props;
  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (authenticated) {
      setDoRedirect(true);
    }
  }, [authenticated]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      if (authenticated) {
        setDoRedirect(true);
      }

      const params = new URLSearchParams(location.search);
      if (params.get('code')) {
        postOAuthTokenRequest({
          system: 'lightspeed-r',
          code: params.get('code'),
        });
      } else {
        setError(true);
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      {doRedirect && <Redirect to={'/admin/lightspeed-r-app'} />}
      {error ? 'Error' : null}
      <CircularProgress size={100} />
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  authenticated: state['lightspeed-r'].authenticated,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(LightspeedROAuthHandler));
