import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';

import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import * as authActions from '../../store/actions/auth_actions';
import { IState } from '../../store/reducers';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postOAuthTokenRequest: (loginRequest: any) => any;
  redirectPath: string;
  authenticated: boolean;
  location: {
    search: string;
    pathname: string;
  };
}

const PingenOAuthHandler: React.FC<IProps> = props => {
  const { classes, location, authenticated, postOAuthTokenRequest } = props;
  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const cookies = new Cookies();

  useEffect(() => {
    if (authenticated) {
      cookies.remove('pingen_state', { path: '/' });
      cookies.remove('pingen_code_verifier', { path: '/' });
      setDoRedirect(true);
    }
  }, [authenticated]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      if (authenticated) {
        setDoRedirect(true);
      }

      const params = new URLSearchParams(location.search);
      if (params.get('code') && params.get('state') && cookies.get('pingen_state') === params.get('state')) {
        postOAuthTokenRequest({
          system: 'pingen',
          code: params.get('code'),
          codeVerifier: cookies.get('pingen_code_verifier'),
        });
      } else {
        setError(true);
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      {doRedirect && <Redirect to={props.redirectPath ? props.redirectPath : '/admin/service-accounts'} />}
      {error ? 'Error' : null}
      <CircularProgress size={100} />
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  authenticated: state.pingen.authenticated,
  redirectPath: state.auth.redirectPath,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(PingenOAuthHandler));
