import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../../../../components/CustomButtons/Button.jsx';
import FormCard from '../../../../components/FormCard/FormCard';
import CustomButton from '../../../../components/NewUI/CustomButton';
import { CustomerLocation, BexioSettings } from '../../../../types/BexioSettings';
import * as commonActions from '../../../../store/actions/common_actions';
import * as shopifyActions from '../../../../store/actions/shopify_actions';
import { IState } from '../../../../store/reducers';
import CustomerSearch from './CustomerSearch';
import LocationSelect from './LocationSelect';

type ItemProps = {
  item: CustomerLocation;
  setItem: (item: CustomerLocation) => void;
  locations: string[];
  enabled: boolean;
  onDelete: () => void;
};

function Item(props: ItemProps) {
  const { item, setItem, locations, enabled, onDelete } = props;
  const { location, defaultCustomerId } = item;
  const setCustomerId = (customerId: number) => setItem({...item, defaultCustomerId: customerId});
  const setLocation = (location: string) => setItem({...item, location });
  return (
    <FormCard>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <CustomerSearch
            enabled={enabled}
            customerId={defaultCustomerId}
            setCustomerId={setCustomerId}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <LocationSelect
            enabled={enabled}
            locations={locations}
            location={location}
            setLocation={setLocation}
          />
        </Grid>
        <Grid item={true} xs={11} />
        <Grid item={true} xs={1}>
          <Button justIcon={true} round={true} color="transparent" onClick={() => onDelete()}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </FormCard>
  );
}

function newCustomerLocation(): CustomerLocation {
  return {
    location: '',
    defaultCustomerId: 0,
  }
}

type StateProps = {
  shopifyConnected: boolean;
  shopifyLocations: string[];
  shopifyLoading: boolean;
  bexioConnected: boolean;
  bexioSettings: BexioSettings | null;
};

type DispatchProps = {
  putSettingsRequest: (data: any) => void;
  shopifyShopLocationsRequest: (data: any) => void;
};

type OwnProps = {
  system: string;
};

type CustomerByLocationProps = DispatchProps & StateProps & OwnProps & LocalizeContextProps;

function CustomerByLocation(props: CustomerByLocationProps) {
  const { shopifyConnected, shopifyLocations, shopifyShopLocationsRequest } = props;
  const { bexioSettings, bexioConnected, putSettingsRequest } = props;
  const { system, translate } = props;
  const [settings, setSettings] = useState<CustomerLocation[]>([]);

  const setSettingsWrap = (items: CustomerLocation[]) => {
    setSettings(items);
    const isValid = items.every(x => x.location.length !== 0 && x.defaultCustomerId !== 0);
    if (isValid) {
      putSettingsRequest({
        system: 'bexio',
        data: {
          ...bexioSettings,
          defaultCustomerByLocation: items,
        },
      });
    }
  };

  useEffect(() => {
    if (bexioSettings && bexioSettings.defaultCustomerByLocation) {
      setSettings(bexioSettings.defaultCustomerByLocation);
    }
  }, [bexioSettings, setSettings]);

  useEffect(() => {
    if (shopifyConnected) {
      shopifyShopLocationsRequest({});
    }
  }, [shopifyConnected, shopifyShopLocationsRequest]);

  if (system.toLowerCase() !== 'shopify') {
    return null;
  }

  if (!shopifyConnected || !shopifyLocations || shopifyLocations.length < 2) {
    return null;
  }

  if (!bexioConnected || !bexioSettings) {
    return null;
  }

  return (
    <>
      <Grid container={true} style={{ marginTop: 10 }}>
        <Grid item={true} xs={12}>
          {translate('location_based') as string}
        </Grid>
      </Grid>
      {settings.map((item, idx) => (
        <Item
          key={idx}
          item={item}
          setItem={(it: CustomerLocation) => setSettingsWrap(settings.map((x, i) => i !== idx ? x : it))}
          enabled={true}
          onDelete={() => setSettingsWrap(settings.filter((x, i) => i !== idx))}
          locations={shopifyLocations}
        />
      ))}
      <CustomButton
        onClick={() => setSettingsWrap([...settings, newCustomerLocation()])}
      >
        <Add />
      </CustomButton>
    </>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  shopifyConnected: state.shopify.connectionStatus === 'ok',
  shopifyLocations: state.shopify.locations,
  shopifyLoading: state.shopify.isFetching,
  bexioSettings: state.bexio.settings,
  bexioConnected: state.bexio.connectionStatus === 'ok',
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  shopifyShopLocationsRequest: shopifyActions.shopLocationsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(CustomerByLocation));
