// global imports
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';

// local imports
import StoreOrderAs from '../../PrestashopWidgets/StoreOrderAs/StoreOrderAs';

import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { config } from '../../../../config';
import { IState } from '../../../../store/reducers';
import DestinationSystem from '../../CommonWidgets/DestinationSystem/DestinationSystem';
import ProductSettings from '../../CommonWidgets/ProductSettings/ProductSettings';
import BexioConfig from './components/BexioConfig/BexioConfig';
import DownloadPlugin from './components/DownloadPlugin/DownloadPlugin';
import ShopsList from './components/ShopsList/ShopsList';
import Subscription from './components/Subscription/Subscription';
import WeclappConfig from './components/WeclappConfig/WeclappConfig';
import { Props, StateProps } from './types';

const PrestashopApp = (props: Props) => {
  const dispatch = useDispatch();
  const { prestashopSettings, getSettingsRequest } = props;

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'prestashop' }));
    dispatch(getSettingsRequest({ system: 'bexio' }));
  }, []);

  const getDestinationSystemSettings = () => {
    if (config.activeDomain === 'bexio' || prestashopSettings.destinationSystem === 'bexio') {
      return <BexioConfig system="PrestaShop" />;
    }

    switch (prestashopSettings.destinationSystem) {
      case 'zoho-books':
        return <Label title="Zoho Books" desc="Coming soon" />;
      case 'weclapp':
        return <WeclappConfig />;
      default:
        return <BexioConfig system="PrestaShop" />;
    }
  };

  return (
    <SettingsContainer appName="PrestaShop">
      <Subscription />
      <DownloadPlugin />
      <StoreOrderAs />
      <ProductSettings system="prestashop" />
      <ShopsList />
      {config.activeDomain !== 'bexio' && <DestinationSystem system="prestashop" />}
      {getDestinationSystemSettings()}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shops: state.prestashop.settings.shops,
  prestashopSettings: state.prestashop.settings,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrestashopApp),
);
