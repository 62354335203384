// global imports
import { makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const TransferOptions: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;

  const { sumupSettings, putSettingsRequest } = props;

  const [selectedOption, setSelectedOption] = useState<IOptionType | null>(null);
  const optionList = [
    {
      label: '' + translate('configurator.no-sync'),
      value: 'no-sync',
    },
    {
      label: '' + translate('configurator.manual-entry'),
      value: 'transaction',
    },
    {
      label: '' + translate('configurator.invoice-order'),
      value: 'advanced',
    },
  ];

  if (sumupSettings && (sumupSettings.destinationSystem !== 'bexio' || !sumupSettings.destinationSystem)) {
    delete optionList[1];
  }

  const destinationSystemChanged = (value: IOptionType) => {
    if (sumupSettings) {
      const updatedSettings = {
        ...sumupSettings,
        transaction: {
          ...sumupSettings.transaction,
          enabled: value.value === 'transaction',
        },
        advanced: {
          ...sumupSettings.advanced,
          enabled: value.value === 'advanced',
        },
      };
      // setSettings(newSettings);
      setSelectedOption({
        label: value.label,
        value: value.value,
      });
      putSettingsRequest({ system: 'sumup', data: updatedSettings });
    }
  };

  useEffect(() => {
    if (sumupSettings) {
      if (sumupSettings.transaction && sumupSettings.transaction.enabled) {
        setSelectedOption({
          label: '' + translate('configurator.manual-entry'),
          value: 'transaction',
        });
      }
      if (sumupSettings.advanced && sumupSettings.advanced.enabled) {
        setSelectedOption({
          label: '' + translate('configurator.invoice-order'),
          value: 'advanced',
        });
      }
    }
  }, [sumupSettings]);

  return (
    <>
      <Select
        isSearchable={false}
        className={classes.selectSmall}
        options={optionList}
        value={selectedOption}
        onChange={(value: ValueType<IOptionType, false>) => destinationSystemChanged(value as IOptionType)}
        styles={colourStyles}
      />
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  sumupSettings: state.sumup.settings,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TransferOptions),
);
