console.log('evn....', process.env);
export const config = {
  backendUrl: process.env.REACT_APP_BE_URL || 'http://localhost:3001',
  frontendUlr: process.env.REACT_APP_FE_URL || 'http://localhost:3000',
  activeDomain: process.env.REACT_APP_ACTIVE_DOMAIN || 'bexio',
  policyVersion: process.env.REACT_APP_POLICY_VER || 'bexio_1',
  policyEnabled: process.env.REACT_APP_POLICY_ENABLED || false,
  termsVersion: process.env.REACT_APP_TERMS_VER || 'v1',
  termsEnabled: process.env.REACT_APP_TERMS_ENABLED || false,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  sentryUrl: process.env.REACT_APP_SENTRY_URL || '',
};
