import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
  EXPORT_PRODUCTS,
  exportProductsFailure,
  exportProductsSuccess,
  GET_ACCOUNTING,
  GET_BANK_ACCOUNTS,
  GET_COUNTRIES,
  GET_CUSTOMER,
  GET_PAYMENT_TYPES,
  GET_REMINDER_SETTINGS,
  GET_SHIPPING_PRODUCT,
  GET_TAXES,
  GET_USERS,
  GET_WRAPPING_PRODUCT,
  getAccountingFailure,
  getAccountingSuccess,
  getBankAccountsFailure,
  getBankAccountsSuccess,
  getCountriesFailure,
  getCountriesSuccess,
  getCustomerFailure,
  getCustomerSuccess,
  getPaymentTypesFailure,
  getPaymentTypesSuccess,
  getReminderSettingsFailure,
  getReminderSettingsSuccess,
  getShippingProductFailure,
  getShippingProductSuccess,
  getTaxesFailure,
  getTaxesSuccess,
  getUsersFailure,
  getUsersSuccess,
  getWrappingProductFailure,
  getWrappingProductSuccess,
  PUT_REMINDER_SETTINGS,
  putReminderSettingsFailure,
  putReminderSettingsSuccess,
  SEARCH_CUSTOMER,
  SEARCH_SHIPPING_PRODUCT,
  SEARCH_WRAPPING_PRODUCT,
  searchCustomerFailure,
  searchCustomerSuccess,
  searchShippingProductFailure,
  searchShippingProductSuccess,
  searchWrappingProductFailure,
  searchWrappingProductSuccess,
  TEST_CONNECTION,
  testConnectionFailure,
  testConnectionSuccess,
} from '../actions/bexio_actions';
import {
  exportProducts,
  getBexioAccounting,
  getBexioBankAccounts,
  getBexioCustomer,
  getBexioPaymentTypes,
  getBexioProduct,
  getBexioReminderSettings,
  getBexioTaxes,
  getBexioUsers,
  getCountries,
  putBexioReminderSettings,
  searchBexioCustomer,
  searchBexioProduct,
} from '../api/bexio';
import { testConnection } from '../api/common';

function* getBexioTaxesSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getBexioTaxes);
    yield put(getTaxesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getTaxesFailure(processedError || error));
  }
}
function* getBexioBankAccountsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getBexioBankAccounts);
    yield put(getBankAccountsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getBankAccountsFailure(processedError || error));
  }
}
function* getBexioCustomerSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getBexioCustomer, { customerId: action.data.customerId });
    yield put(getCustomerSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getCustomerFailure(processedError || error));
  }
}
function* searchBexioCustomerSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(searchBexioCustomer, { searchTerm: action.data.searchTerm });
    yield put(searchCustomerSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(searchCustomerFailure(processedError || error));
  }
}
function* getBexioShippingProductSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getBexioProduct, { productId: action.data.productId });
    yield put(getShippingProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getShippingProductFailure(processedError || error));
  }
}
function* getBexioWrappingProductSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getBexioProduct, { productId: action.data.productId });
    yield put(getWrappingProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getWrappingProductFailure(processedError || error));
  }
}
function* searchBexioShippingProductSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(searchBexioProduct, { searchTerm: action.data.searchTerm });
    yield put(searchShippingProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(searchShippingProductFailure(processedError || error));
  }
}
function* searchBexioWrappingProductSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(searchBexioProduct, { searchTerm: action.data.searchTerm });
    yield put(searchWrappingProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(searchWrappingProductFailure(processedError || error));
  }
}
function* getBexioUsersSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getBexioUsers);
    yield put(getUsersSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getUsersFailure(processedError || error));
  }
}
function* testConnectionSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(testConnection, { system: 'bexio' });
    yield put(testConnectionSuccess({ ...data, system: 'bexio' }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(testConnectionFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* getReminderSettingsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getBexioReminderSettings);
    yield put(getReminderSettingsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getReminderSettingsFailure(processedError || error));
  }
}
function* putReminderSettingsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(putBexioReminderSettings, action.data);
    yield put(putReminderSettingsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(putReminderSettingsFailure(processedError || error));
  }
}

function* exportProductsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(exportProducts);
    yield put(exportProductsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(exportProductsFailure(processedError || error));
  }
}

function* getPaymentTypesSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getBexioPaymentTypes);
    yield put(getPaymentTypesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getPaymentTypesFailure(processedError || error));
  }
}

function* getCountriesSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getCountries);
    yield put(getCountriesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getCountriesFailure(processedError || error));
  }
}

function* getBexioAccountingSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getBexioAccounting);
    yield put(getAccountingSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getAccountingFailure(processedError || error));
  }
}

function* getBexioAccountingWatcher() {
  yield takeLatest(GET_ACCOUNTING.REQUEST as ActionPattern, getBexioAccountingSaga);
}
function* getBexioTaxesWatcher() {
  yield takeLatest(GET_TAXES.REQUEST as ActionPattern, getBexioTaxesSaga);
}
function* getBexioBankAccountsWatcher() {
  yield takeLatest(GET_BANK_ACCOUNTS.REQUEST as ActionPattern, getBexioBankAccountsSaga);
}
function* getBexioCustomerWatcher() {
  yield takeLatest(GET_CUSTOMER.REQUEST as ActionPattern, getBexioCustomerSaga);
}
function* searchBexioCustomerWatcher() {
  yield takeLatest(SEARCH_CUSTOMER.REQUEST as ActionPattern, searchBexioCustomerSaga);
}
function* getBexioShippingProductWatcher() {
  yield takeLatest(GET_SHIPPING_PRODUCT.REQUEST as ActionPattern, getBexioShippingProductSaga);
}
function* getBexioWrappingProductWatcher() {
  yield takeLatest(GET_WRAPPING_PRODUCT.REQUEST as ActionPattern, getBexioWrappingProductSaga);
}
function* searchBexioShippingProductWatcher() {
  yield takeLatest(SEARCH_SHIPPING_PRODUCT.REQUEST as ActionPattern, searchBexioShippingProductSaga);
}
function* searchBexioWrappingProductWatcher() {
  yield takeLatest(SEARCH_WRAPPING_PRODUCT.REQUEST as ActionPattern, searchBexioWrappingProductSaga);
}
function* getBexioUsersWatcher() {
  yield takeLatest(GET_USERS.REQUEST as ActionPattern, getBexioUsersSaga);
}
function* testConnectionWatcher() {
  yield takeLatest(TEST_CONNECTION.REQUEST as ActionPattern, testConnectionSaga);
}
function* getReminderSettingsWatcher() {
  yield takeLatest(GET_REMINDER_SETTINGS.REQUEST as ActionPattern, getReminderSettingsSaga);
}
function* putReminderSettingsWatcher() {
  yield takeLatest(PUT_REMINDER_SETTINGS.REQUEST as ActionPattern, putReminderSettingsSaga);
}
function* exportProductsWatcher() {
  yield takeLatest(EXPORT_PRODUCTS.REQUEST as ActionPattern, exportProductsSaga);
}
function* getPaymentTypesWatcher() {
  yield takeLatest(GET_PAYMENT_TYPES.REQUEST as ActionPattern, getPaymentTypesSaga);
}
function* getCountriesWatcher() {
  yield takeLatest(GET_COUNTRIES.REQUEST as ActionPattern, getCountriesSaga);
}

export default function* authSaga() {
  yield all([
    fork(getBexioAccountingWatcher),
    fork(getBexioTaxesWatcher),
    fork(getBexioBankAccountsWatcher),
    fork(getBexioCustomerWatcher),
    fork(searchBexioCustomerWatcher),
    fork(getBexioShippingProductWatcher),
    fork(getBexioWrappingProductWatcher),
    fork(searchBexioShippingProductWatcher),
    fork(searchBexioWrappingProductWatcher),
    fork(getBexioUsersWatcher),
    fork(testConnectionWatcher),
    fork(getReminderSettingsWatcher),
    fork(putReminderSettingsWatcher),
    fork(exportProductsWatcher),
    fork(getPaymentTypesWatcher),
    fork(getCountriesWatcher),
  ]);
}
