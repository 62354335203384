import React from 'react';
import Select from 'react-select';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { IOptionType } from '../../../../types/ReactSelect';
import { ValueType } from 'react-select/src/types';
import colourStyles from '../../../../assets/jss/reactSelectStyle';

type LocationSelectProps = {
  enabled: boolean;
  locations: string[];
  location: string;
  setLocation: (data: string) => void;
} & LocalizeContextProps;

function LocationSelect(props: LocationSelectProps) {
  const { enabled, locations, location, setLocation, translate } = props;
  const locationOptions: IOptionType[] = locations.map(loc => ({
    label: loc,
    value: loc,
  }));
  const onChange = (value: IOptionType | null) => setLocation(value ? value.value : '');
  return (
    <Grid container={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item={true} xs={12}>
        <Select
          placeholder={translate('location') as string}
          isDisabled={!enabled}
          isSearchable={false}
          options={locationOptions}
          defaultValue={null}
          value={locationOptions.find(option => option.value === location) || null}
          onChange={(value: ValueType<IOptionType, false>) => onChange(value ? value : null)}
          styles={colourStyles}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(LocationSelect);
