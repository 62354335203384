import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import { GET_ACCOUNTS, getAccountsFailure, getAccountsSuccess, HAS_SUBSCRIPTION, hasSubscriptionFailure, hasSubscriptionSuccess, POST_CHECKOUT, postCheckoutFailure, postCheckoutSuccess } from '../actions/wix_actions';

import { getAccounts, checkSubscription, postCheckout } from '../api/wix';

function* getAccountsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getAccounts, action.data);
    // console.log('data fetched', action.accountType, data)
    yield put(getAccountsSuccess({ type: action.data, data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getAccountsFailure(processedError || error));
  }
}

function* getAccountsWatcher() {
  yield takeLatest(GET_ACCOUNTS.REQUEST as ActionPattern, getAccountsSaga);
}

function* hasSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(checkSubscription);
    yield put(hasSubscriptionSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(hasSubscriptionFailure(processedError || error));
  }
}

function* hasSubscriptionWatcher() {
  yield takeLatest(HAS_SUBSCRIPTION.REQUEST as ActionPattern, hasSubscriptionSaga);
}

function* postCheckoutSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postCheckout);
    yield put(postCheckoutSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postCheckoutFailure(processedError || error));
  }
}

function* postCheckoutWatcher() {
  yield takeLatest(POST_CHECKOUT.REQUEST as ActionPattern, postCheckoutSaga);
}

export default function* authSaga() {
  yield all([
    fork(getAccountsWatcher),
    fork(hasSubscriptionWatcher),
    fork(postCheckoutWatcher),
  ]);
}
