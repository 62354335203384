// global imports
import React, { useEffect, useState, useCallback } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

import { useDispatch, useSelector } from 'react-redux';

// project imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Grid, InputLabel, makeStyles, StyleRules, Theme, Typography } from '@material-ui/core';
import GridItem from '../../../../components/Grid/GridItem';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import PopupDialog from '../../../../components/PopupDialog/PopupDialog';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const WriteBackPaidInvoices = (props: Props) => {
  const { system, translate } = props;
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);
  const hasPrestashopKey = useSelector<IState, boolean>(({ prestashop: { settings: { shops }} }) => shops.map(x => x.webServiceKey).some(key => key !== ''));

  const [writeBackPaidInvoices, setValue] = useState(
    bexioSettings ? bexioSettings.enableWriteBackPaidInvoices : false,
  );
  const [disabledShown, setDisabledShown] = useState<boolean>(false);

  const setWriteBackPaidInvoices = useCallback((v: boolean) => {
    setValue(v);
    if (bexioSettings) {
      dispatch(
        putSettingsRequest({
          system: 'bexio',
          sourceSystem: system,
          data: { ...bexioSettings, enableWriteBackPaidInvoices: v },
        }),
      );
    }
  }, [writeBackPaidInvoices, bexioSettings, system, dispatch, putSettingsRequest]);

  useEffect(() => {
    if (bexioSettings) {
      setValue(bexioSettings.enableWriteBackPaidInvoices);
    }
  }, [bexioSettings, setValue]);

  const onChange = useCallback((ev: React.ChangeEvent) => {
    if (system === 'prestashop' && !hasPrestashopKey) {
      setDisabledShown(true);
      return;
    }
    setWriteBackPaidInvoices(!writeBackPaidInvoices);
  }, [system, hasPrestashopKey, writeBackPaidInvoices, setWriteBackPaidInvoices, setDisabledShown]);

  return (
    <Grid container={true}>
      <GridItem md={4} className={classes.gridItem}>
        <InputLabel>
          <Translate id={system === 'prestashop' ? `prestashop.paid-invoice` : 'shopify.paid-invoice'} />
        </InputLabel>
      </GridItem>
      <GridItem md={8} className={classes.gridItem}>
        <StyledSwitch checked={writeBackPaidInvoices} onChange={onChange} disabled={!bexioSettings} />
      </GridItem>
      <PopupDialog
        title={translate('errors.errors') as string}
        open={disabledShown}
        handleClose={() => setDisabledShown(false)}
      >
        <Typography gutterBottom={true} style={{ marginBottom: 20 }}>
          {(system === 'prestashop' && !hasPrestashopKey) ? (translate('prestashop.paid-invoices-disabled') as string) : null}
        </Typography>
      </PopupDialog>
    </Grid>
  );
};

export default withLocalize<Props>(WriteBackPaidInvoices);
