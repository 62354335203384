import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'COMMONS';
export const GET_FIELDS = createRequestTypes(`${filePrefix}_GET_FIELDS`);

export const getFieldsRequest = (fieldType: string) => ({
  type: GET_FIELDS.REQUEST,
  data: { fieldType },
});
export const getFieldsSuccess = ({ data, fieldType }: any) => ({
  type: GET_FIELDS.SUCCESS,
  data,
  fieldType,
});
export const getFieldsFailure = (error: any) => ({
  type: GET_FIELDS.FAILURE,
  error,
});
