import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'COMMONS';
export const GET_CANONICAL_FIELDS = createRequestTypes(`${filePrefix}_GET_CANONICAL_FIELDS`);
export const POST_STRIPE_CUSTOMER = createRequestTypes(`${filePrefix}_POST_STRIPE_CUSTOMER`);
export const POST_STRIPE_SUBSCRIPTION = createRequestTypes(`${filePrefix}_POST_STRIPE_SUBSCRIPTION`);
export const GET_STRIPE_PORTAL = createRequestTypes(`${filePrefix}_GET_STRIPE_PORTAL`);
export const USER_HAS_SUBSCRIPTION = createRequestTypes(`${filePrefix}_USER_HAS_SUBSCRIPTION`);
export const USER_SEARCH_SUBSCRIPTION_PRODUCT = createRequestTypes(`${filePrefix}_USER_SEARCH_SUBSCRIPTION_PRODUCT`);
export const POST_STRIPE_CHECKOUT = createRequestTypes(`${filePrefix}_POST_STRIPE_CHECKOUT`);
export const GET_NAMED_KEY_VALUES = createRequestTypes(`${filePrefix}_GET_NAMED_KEY_VALUES`);
export const POST_NAMED_KEY_VALUE = createRequestTypes(`${filePrefix}_POST_NAMED_KEY_VALUE`);
export const DELETE_NAMED_KEY_VALUE = createRequestTypes(`${filePrefix}_DELETE_NAMED_KEY_VALUE`);

export const getCanonicalFieldsRequest = () => ({
  type: GET_CANONICAL_FIELDS.REQUEST,
});
export const getCanonicalFieldsSuccess = (data: any) => ({
  type: GET_CANONICAL_FIELDS.SUCCESS,
  data,
});
export const getCanonicalFieldsFailure = (error: any) => ({
  type: GET_CANONICAL_FIELDS.FAILURE,
  error,
});
export const postStripeCustomerRequest = (data: any) => ({
  type: POST_STRIPE_CUSTOMER.REQUEST,
  data,
});
export const postStripeCustomerSuccess = (data: any) => ({
  type: POST_STRIPE_CUSTOMER.SUCCESS,
  data,
});
export const postStripeCustomerFailure = (error: any) => ({
  type: POST_STRIPE_CUSTOMER.FAILURE,
  error,
});
export const postStripeSubscriptionRequest = (data: any) => ({
  type: POST_STRIPE_SUBSCRIPTION.REQUEST,
  data,
});
export const postStripeSubscriptionSuccess = (data: any) => ({
  type: POST_STRIPE_SUBSCRIPTION.SUCCESS,
  data,
});
export const postStripeSubscriptionFailure = (error: any) => ({
  type: POST_STRIPE_SUBSCRIPTION.FAILURE,
  error,
});
export const getStripePortalRequest = () => ({
  type: GET_STRIPE_PORTAL.REQUEST,
});
export const getStripePortalSuccess = (data: any) => ({
  type: GET_STRIPE_PORTAL.SUCCESS,
  data,
});
export const getStripePortalFailure = (error: any) => ({
  type: GET_STRIPE_PORTAL.FAILURE,
  error,
});
export const userHasSubscriptionRequest = () => ({
  type: USER_HAS_SUBSCRIPTION.REQUEST,
});
export const userHasSubscriptionSuccess = (data: any) => ({
  type: USER_HAS_SUBSCRIPTION.SUCCESS,
  data,
});
export const userHasSubscriptionFailure = (error: any) => ({
  type: USER_HAS_SUBSCRIPTION.FAILURE,
  error,
});
export const userSearchSubscriptionProductRequest = () => ({
  type: USER_SEARCH_SUBSCRIPTION_PRODUCT.REQUEST,
});
export const userSearchSubscriptionProductSuccess = (data: any) => ({
  type: USER_SEARCH_SUBSCRIPTION_PRODUCT.SUCCESS,
  data,
});
export const userSearchSubscriptionProductFailure = (error: any) => ({
  type: USER_SEARCH_SUBSCRIPTION_PRODUCT.FAILURE,
  error,
});
export const postStripeCheckoutRequest = (data: any) => ({
  type: POST_STRIPE_CHECKOUT.REQUEST,
  data,
});
export const postStripeCheckoutSuccess = (data: any) => ({
  type: POST_STRIPE_CHECKOUT.SUCCESS,
  data,
});
export const postStripeCheckoutFailure = (error: any) => ({
  type: POST_STRIPE_CHECKOUT.FAILURE,
  error,
});

export const getNamedKeyValuesRequest = (name: string) => ({
  type: GET_NAMED_KEY_VALUES.REQUEST,
  data: { name },
});
export const getNamedKeyValuesSuccess = (name: string, data: any) => ({
  type: GET_NAMED_KEY_VALUES.SUCCESS,
  data: { name, data },
});
export const getNamedKeyValuesFailure = (name: string, error: any) => ({
  type: GET_NAMED_KEY_VALUES.FAILURE,
  data: { name },
  error,
});
export const postNamedKeyValueRequest = (name: string, data: any) => ({
  type: POST_NAMED_KEY_VALUE.REQUEST,
  data: { name, data },
});
export const postNamedKeyValueSuccess = (name: string, data: any) => ({
  type: POST_NAMED_KEY_VALUE.SUCCESS,
  data: { name, data },
});
export const postNamedKeyValueFailure = (name: string, error: any) => ({
  type: POST_NAMED_KEY_VALUE.FAILURE,
  data: { name },
  error,
});
export const deleteNamedKeyValueRequest = (name: string, data: any) => ({
  type: DELETE_NAMED_KEY_VALUE.REQUEST,
  data: { name, data },
});
export const deleteNamedKeyValueSuccess = (name: string, data: any) => ({
  type: DELETE_NAMED_KEY_VALUE.SUCCESS,
  data: { name, data },
});
export const deleteNamedKeyValueFailure = (name: string, error: any) => ({
  type: DELETE_NAMED_KEY_VALUE.FAILURE,
  data: { name },
  error,
});
