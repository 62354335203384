import { CircularProgress, Grid, Link } from '@material-ui/core';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import CustomButton from '../../components/NewUI/CustomButton';
import * as dpdActions from '../../store/actions/dpd_actions';
import * as planzerActions from '../../store/actions/planzer_actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReactComponent as IconChrome } from '../../assets/img/chrome.svg';
import { ReactComponent as IconDpd } from '../../assets/img/dpd.svg';
import { ReactComponent as IconPlanzer } from '../../assets/img/planzer.svg';

function Label({
  title,
  desc,
  help,
  img,
}: {
  title: string;
  img?: JSX.Element;
  desc?: string;
  help?: { text: string; link: string; inNewLine?: boolean };
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {img ? img : null}
      <span style={{ fontSize: 15, fontWeight: 'bold', marginLeft: img ? '0.5em' : '0' }}>{title}</span>
      <p style={{ margin: 0, fontSize: 14, fontWeight: 'normal' }}>
        {desc ? desc : null}
        {help && help.link && (
          <Link
            color="primary"
            href={help.link}
            style={{ display: help.inNewLine ? 'flex' : 'initial' }}
            target="_blank"
          >
            {help.text}
          </Link>
        )}
      </p>
    </div>
  );
}

const DpdSubscription = ({ translate }: LocalizeContextProps) => {
  const dispatch = useAppDispatch();
  const hasSubscription = useAppSelector(state => state.dpd.hasSubscription);
  const checkoutUrl = useAppSelector(state => state.dpd.checkoutUrl);
  const isFetching = useAppSelector(state => state.dpd.isFetching);
  const postCheckoutRequest = () => dispatch(dpdActions.postCheckoutRequest());

  React.useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  return (
    <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Label
        title={translate('delivery-manager.dpd-subscription') as string}
        img={<IconDpd height="2em" width="2em" />}
      />
      {isFetching ? (
        <CircularProgress />
      ) : hasSubscription ? (
        <span style={{ margin: '1em 0' }}>{translate('delivery-manager.subscription-status')}</span>
      ) : (
        <CustomButton onClick={() => postCheckoutRequest()} disabled={isFetching}>
          {translate('delivery-manager.subscription-checkout-button')}
        </CustomButton>
      )}
    </Grid>
  );
};

const PlanzerSubscription = ({ translate }: LocalizeContextProps) => {
  const dispatch = useAppDispatch();
  const hasSubscription = useAppSelector(state => state.planzer.hasSubscription);
  const checkoutUrl = useAppSelector(state => state.planzer.checkoutUrl);
  const isFetching = useAppSelector(state => state.planzer.isFetching);
  const postCheckoutRequest = () => dispatch(planzerActions.postCheckoutRequest());

  React.useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  return (
    <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Label
        title={translate('delivery-manager.planzer-subscription') as string}
        img={<IconPlanzer height="2em" width="2em" />}
      />
      {isFetching ? (
        <CircularProgress />
      ) : hasSubscription ? (
        <span style={{ margin: '1em 0' }}>{translate('delivery-manager.subscription-status')}</span>
      ) : (
        <CustomButton onClick={() => postCheckoutRequest()} disabled={isFetching}>
          {translate('delivery-manager.subscription-checkout-button')}
        </CustomButton>
      )}
    </Grid>
  );
};

const Extension = ({ translate }: LocalizeContextProps) => (
  <Grid item style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>
    <Link
      color="primary"
      href="https://chrome.google.com/webstore/detail/bedayaconnect/eibeaplchmkmphipoicdhndkhhjkkbdo"
      target="_blank"
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <span style={{ marginRight: '0.25em', color: 'black' }}>Google Chrome Extension</span>
      <IconChrome style={{ width: '1.5em', height: '1.5em', filter: 'grayscale(1)' }} />
    </Link>
  </Grid>
);

const Subscription = (props: LocalizeContextProps) => {
  return (
    <Grid
      container={true}
      style={{
        boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.23913), 0px 1px 1px rgba(0, 0, 0, 0.0869565)',
        borderRadius: 2,
        backgroundColor: 'white',
        padding: '8px 30px',
        marginTop: 5,
      }}
    >
      <Grid item md={6}>
        <DpdSubscription {...props} />
        <PlanzerSubscription {...props} />
      </Grid>
      <Grid item md={6}>
        <Extension {...props} />
      </Grid>
    </Grid>
  );
};

export default withLocalize(Subscription);
