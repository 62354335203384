// global imports
import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import { StyledSwitch } from '../../../../components/StyledSwitch';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { Props, StateProps } from './types';

const SwitchWidget = (props: Props) => {
  const { translate, bitrixSettings, connectionStatus, isFetching, putSettingsRequest, fieldName, labelName } = props;

  const [value, setValue] = useState<boolean | null>(null);

  useEffect(() => {
    if (bitrixSettings) {
        setValue(bitrixSettings[fieldName]);
    }
  }, [bitrixSettings, fieldName]);

  const handleSwitchChange = useCallback((event: any) => {
    if (bitrixSettings) {
      setValue(event.target.checked);
      putSettingsRequest({
        system: 'bitrix',
        data: {
            [fieldName]: event.target.checked,
        },
      });
    }
  }, [bitrixSettings, fieldName]);

  const isDisabled = value === null || connectionStatus !== 'ok' || isFetching;

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate(labelName) as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <StyledSwitch
            disabled={isDisabled}
            checked={value ?? false}
            onChange={handleSwitchChange}
            value={fieldName}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};
const mapStateToProps = (state: IState): StateProps => ({
  bitrixSettings: state.bitrix.settings,
  connectionStatus: state.bitrix.connectionStatus,
  isFetching: state.bitrix.isFetching,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SwitchWidget),
);
