import React from 'react';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { LevelBehavior } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


type BehaviorLevelSectionProps = {
  enabled: boolean;
  settings: LevelBehavior;
  setSettings: (item: LevelBehavior) => void;
} & LocalizeContextProps;

function BehaviorLevelSection(props: BehaviorLevelSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  return (
    <Grid container={true}>
      <Grid item={true} xs={12} style={{marginBottom: 10, fontWeight: 'bold'}}>
        {translate('reminders.behavior.title') as string}
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.behavior.reminderOffsetDays') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          type="number"
          placeholder={translate('reminders.behavior.reminderOffsetDays') as string}
          value={settings.reminderOffsetDays + ''}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, reminderOffsetDays: +value})}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(BehaviorLevelSection);
