import { BedayaAction } from '../../types/BedayaAction';
import { PrestashopSettings, TShop } from '../../types/PrestashopSettings';

import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { GET_SETTINGS, PUT_SETTINGS } from '../actions/common_actions';
import { ADD_SHOP, CHANGE_OPTION, HAS_SUBSCRIPTION, REMOVE_SHOP, POST_CHECKOUT } from '../actions/prestashop_actions';

export interface IPrestashopState {
  settings: PrestashopSettings;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: string;
  hasSubscription: boolean | undefined;
  checkoutUrl: string;

  error: any;
  isFetching: boolean;
  isFetchingDisconnect: boolean;
}

const initialState: IPrestashopState = {
  settings: {
    destinationSystem: 'bexio',
    webhookSign: '',
    targetEntity: 'invoice',
    shops: [],
    includeSpecialPrices: false,
  },

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'nook',
  hasSubscription: undefined,
  checkoutUrl: '',

  error: '',
  isFetching: false,
  isFetchingDisconnect: false,
};

export default function reducer(state: IPrestashopState = initialState, action: BedayaAction): any {
  switch (action.type) {
    case ADD_SHOP.REQUEST:
      const newSettings = { ...state.settings, shops: [...state.settings.shops, action.data] };

      return {
        ...state,
        settings: newSettings,
      };
    case REMOVE_SHOP.REQUEST:
      const newShopsList = state.settings.shops.filter(shop => shop.id !== action.data);

      return {
        ...state,
        settings: { ...state.settings, shops: newShopsList },
      };
    case CHANGE_OPTION.REQUEST:
      const newShops = [...state.settings.shops];
      const updatedShopIndex = newShops.findIndex(shop => shop.id === action.data.id);

      newShops[updatedShopIndex][action.data.optionName as keyof TShop] = action.data.value as never;

      return {
        ...state,
        settings: { ...state.settings, shops: newShops },
      };
    case FETCH_OAUTH_URL.REQUEST:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;
    case HAS_SUBSCRIPTION.REQUEST:
    case POST_CHECKOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: action.data.subscription,
          isFetching: false,
        };
      }
      return state;
    case HAS_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        hasSubscription: action.data.subscription,
        isFetching: false,
      };
    case POST_CHECKOUT.SUCCESS:
      return {
        ...state,
        checkoutUrl: action.data.url,
        isFetching: false,
      };
    case HAS_SUBSCRIPTION.FAILURE:
    case POST_CHECKOUT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error.toString(),
      };
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case FETCH_OAUTH_URL.FAILURE:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['prestashop'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    default:
      return state;
  }
}
