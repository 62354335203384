import React from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import CustomButton from '../../../components/NewUI/CustomButton';
import { Level } from '../types';
import ReminderLevelSection from './ReminderLevelSection';

type ReminderLevelsSectionProps = {
  enabled: boolean;
  settings: Level[];
  setSettings: (data: Level[]) => void;
} & LocalizeContextProps;

function ReminderLevelsSection(props: ReminderLevelsSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  return (
    <>
      {settings.map((it, idx) => (
        <ReminderLevelSection
          key={idx}
          enabled={enabled}
          settings={it}
          setSettings={(data: Level) => setSettings(settings.map((x, i) => i !== idx ? x : data))}
          onDelete={() => setSettings(settings.filter((x, i) => i !== idx))}
        />
      ))}
      <CustomButton disabled={!enabled} onClick={() => setSettings([...settings, newLevel()])}>
        {translate('reminders.levels.itemAdd') as string}
      </CustomButton>
    </>
  );
}

function newLevel(): Level {
  return {
    level: 0,
    lastLevel: false,
    reminderCosts: {
      enabled: false,
      standardPosition: {
        text: [],
        price: '',
        vat: '',
      },
      productPosition: {
        productId: 0,
        price: '',
      }
    },
    emailSettings: {
      mailers: [],
      subjects: [],
      emailTemplates: [],
      filename: [],
      bccEMail: '',
    },
    behavior: {
      reminderOffsetDays: 0,
    },
    smsSettings: {
      notifications: {
        enabled: false,
        daysBeforeDueDate: 0,
        templates: [],
      },
    },
    debtCollection: {
      enabled: false,
      notifications: {
        creditorNotificationEnabled: false,
        creditorNotificationEMail: '',
        creditorNotificationOffsetDays: 0,
      }
    },
  };
}

export default withLocalize(ReminderLevelsSection);
