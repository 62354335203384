import { defaultFont, containerFluid, whiteColor, grayColor } from '../../material-dashboard-pro-react.js';

const footerStyle = theme => ({
  footer: {
    bottom: '0',
    borderTop: '1px solid ' + grayColor[15],
    padding: '15px 0',
    ...defaultFont,
    zIndex: 4,
    color: '#95989b',
  },
  container: {
    zIndex: 3,
    //...container,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100% !important',
    marginBottom: '0',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  right: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: '0',
      right: '0',
    },
  },
  a: {
    color: '#95989b',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      color: '#95989b',
    },
  },
  footerLink: {
    color: '#95989b',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
      color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
    },
  },
  footerCopy: {
    fontSize: '14px',
    padding: '15px',
    fontWeight: '300',
    display: 'inline-block',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: whiteColor,
    },
  },
});
export default footerStyle;
