import { ActionPattern, all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_PAYMENT_METHODS,
  GET_SALES_CATEGORIES,
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  getSalesCategoriesFailure,
  getSalesCategoriesSuccess,
} from './../actions/enfore_actions';

import { BedayaAction } from '../../types/BedayaAction';
import { getPaymentMethods, getSalesCategories } from './../api/enfore';

function* getPaymentMethodsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getPaymentMethods);
    yield put(getPaymentMethodsSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getPaymentMethodsFailure(processedError || error));
  }
}

function* getSalesCategoriesSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getSalesCategories);
    yield put(getSalesCategoriesSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getSalesCategoriesFailure(processedError || error));
  }
}

function* getPaymentMethodsWatcher() {
  yield takeEvery(GET_PAYMENT_METHODS.REQUEST as ActionPattern, getPaymentMethodsSaga);
}

function* getSalesCategoriesWatcher() {
  yield takeEvery(GET_SALES_CATEGORIES.REQUEST as ActionPattern, getSalesCategoriesSaga);
}

export default function* enforeSaga() {
  yield all([fork(getPaymentMethodsWatcher), fork(getSalesCategoriesWatcher)]);
}
