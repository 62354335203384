// global imports
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  RadioGroup,
  StyleRules,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { StyledRadio } from '../../../../components/StyledRadio';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const PriceIncludeVat = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, vatInc, onChange } = props;

  const [currentValue, setCurrentValue] = useState(vatInc ? 'yes' : 'no');

  const handleValueChange = (ev: React.ChangeEvent<{}>, value: string) => {
    setCurrentValue(value);
    onChange('vatInc', value === 'yes');
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={4}>
        <InputLabel className={classes.pt10}>{translate('prestashop.price-vat')}</InputLabel>
      </Grid>
      <Grid item={true} xs={8}>
        <FormControl component="fieldset">
          <RadioGroup value={currentValue} onChange={handleValueChange} className={classes.radio}>
            <FormControlLabel value="yes" control={<StyledRadio />} label={translate('yes')} />
            <FormControlLabel value="no" control={<StyledRadio />} label={translate('no')} />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default withLocalize(PriceIncludeVat);
