import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Email from '@material-ui/icons/Email';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';

import { loginRequest, bedayaLoginRequest } from '../../store/actions/auth_actions';

import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import BexioIcon from '../../assets/img/bexio-icon.png';
import { withLocalize } from 'react-localize-redux';

// const themeColor = process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? 'primary' : 'rose';

const useStyles = makeStyles(styles);

function LoginPage(props) {
  const classes = useStyles();
  const { translate } = props;
  const [password, setPassword] = useState('');
  const [email, setEMail] = useState('');
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');

  setTimeout(function() {
    setCardAnimation('');
  }, 700);

  const handleEMailInput = event => {
    setEMail(event.target.value);
  };

  const handlePasswordInput = event => {
    setPassword(event.target.value);
  };

  const loginBedaya = () => {
    props.bedayaLoginRequest({
      email,
      password,
    });
  };

  const loginBexio = () => {
    props.loginRequest();
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card className={classes[cardAnimaton]}>
              <CardHeader className={classes.cardHeaderBlank}>
                <h3 className={classes.cardName}>{translate('login.title')}</h3>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: handleEMailInput,
                  }}
                  error={!!props.error}
                />
                <CustomInput
                  labelText={translate('login.password')}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                    onChange: handlePasswordInput,
                  }}
                  error={!!props.error}
                />
              </CardBody>
              <CardFooter className={classes.noFlex}>
                <div className={classes.fullWidth}>
                  <Button type="button" color="primary" size="lg" block onClick={() => loginBedaya()}>
                    {translate('login.login')}
                  </Button>
                </div>
                {process.env.REACT_APP_ACTIVE_DOMAIN !== 'bexio' ? (
                  <div className={classes.text}>
                    {translate('login.no-account')}{' '}
                    <a className={classes.link} href="/auth/register">
                      {translate('login.sign-up')}
                    </a>
                  </div>
                ) : null}
                <div style={{ marginTop: 15, textAlign: 'center' }}></div>
                <div className={classes.fullWidth} style={{ marginTop: 10 }}>
                  <Button
                    type="button"
                    size="lg"
                    color="white"
                    block
                    onClick={() => loginBexio()}
                    style={{ backgroundColor: '#66b245', color: 'white' }}
                  >
                    <img src={BexioIcon} alt="logo" className={classes.img} height="20" style={{ marginRight: 10 }} />{' '}
                    {translate('login.login-bexio')}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  error: state.auth.errors.login,
});

const mapDispatchToProps = {
  loginRequest,
  bedayaLoginRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginPage),
);
