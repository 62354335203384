// global imports
import { Grid, makeStyles, Theme, TextField, InputLabel } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';
import GridItem from '../../../../components/Grid/GridItem';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { IOptionType } from '../../../../types/ReactSelect';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import style from '../../../../assets/jss/commonStyles';
import { EcwidSettings } from '../../../../types/EcwidSettings';
import BaseStoreOrderAs from '../../PrestashopWidgets/StoreOrderAs/BaseStoreOrderAs';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

function TextControl({
  type,
  value,
  onChange,
  title,
}: {
  type: string;
  value?: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  title: string
}) {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  return (
    <Grid container={true} className={classes.w100}>
      <GridItem xs={4} className={classes.gridItem} style={{ display: 'flex', alignItems: 'center' }}>
        <InputLabel>{title}</InputLabel>
      </GridItem>
      <GridItem xs={8} className={classes.gridItem}>
        <TextField
          type={type}
          placeholder={title}
          className={classes.w100}
          value={value}
          onChange={onChange}
          disabled={false}
        />
      </GridItem>
    </Grid>
  );
}

type EcwidAppProps = LocalizeContextProps;

const currencies: IOptionType[] = [{ label: 'CHF', value: 'chf' }, { label: 'EUR', value: 'eur' }];

const EcwidApp = (props: EcwidAppProps) => {
  const dispatch = useDispatch();
  const ecwidSettings = useSelector<IState, EcwidSettings>(state => state.ecwid.settings);
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [settings, setSettings] = React.useState<EcwidSettings | null>(ecwidSettings);

  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'ecwid' }));
  }, []);

  React.useEffect(() => {
    setSettings(ecwidSettings);
  }, [ecwidSettings]);

  const delayedInput = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'ecwid', data: { [field]: value } }));
    }, 1000),
  ).current;

  const handleValueChange = (field: 'shopId' | 'secretToken' | 'clientSecret' | 'targetEntity', newValue: any) => {
    if (!settings) {
      return;
    }

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  const delayedInputOrder = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'ecwid', data: { order: { [field]: value } } }));
    }, 1000),
  ).current;

  const handleValueChangeOrder = (field: 'titlePrefix' | 'isoCurrency', newValue: any) => {
    if (!settings) {
      return;
    }

    setSettings({ ...settings, order: { ...settings.order, [field]: newValue } });
    delayedInputOrder(field, newValue);
  };

  return (
    <SettingsContainer appName={translate('ecwid.title') as string}>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('ecwid.options') as string}
            desc={translate('ecwid.options-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <div className={classes.w100}>
              <TextControl
                type="number"
                title={translate('ecwid.shop_id') as string}
                value={settings ? settings.shopId : ''}
                onChange={e => handleValueChange('shopId', e.target.value)}
              />
              <TextControl
                type="text"
                title={translate('ecwid.secret_token') as string}
                value={settings ? settings.secretToken : ''}
                onChange={e => handleValueChange('secretToken', e.target.value)}
              />
              <TextControl
                type="text"
                title={translate('ecwid.client_secret') as string}
                value={settings ? settings.clientSecret : ''}
                onChange={e => handleValueChange('clientSecret', e.target.value)}
              />
              <TextControl
                type="text"
                title={translate('ecwid.order_title_prefix') as string}
                value={settings && settings.order ? settings.order.titlePrefix : ''}
                onChange={e => handleValueChangeOrder('titlePrefix', e.target.value)}
              />
              <Grid container={true} className={classes.w100}>
                <GridItem xs={4} className={classes.gridItem} style={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel>{translate('ecwid.order_iso_currency')}</InputLabel>
                </GridItem>
                <GridItem xs={8} className={classes.gridItem}>
                  <Select
                    isSearchable={false}
                    className={classes.w100}
                    disabled={!settings}
                    options={currencies}
                    value={settings && settings.order && currencies.find(curr => curr.value === settings.order.isoCurrency)}
                    onChange={(value: ValueType<IOptionType, false>) =>
                      handleValueChangeOrder('isoCurrency', (value as IOptionType).value)
                    }
                    styles={colourStyles}
                  />
                </GridItem>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <BaseStoreOrderAs
        system="ecwid"
        targetEntity={settings?.targetEntity}
        onChange={v => handleValueChange('targetEntity', v)}
      />
      <BexioConfig system="Ecwid" />
    </SettingsContainer>
  );
};

export default withLocalize(EcwidApp);
