import commonStyles from '../../../../../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  selectSmall: {
    width: '150px',
    display: 'inline-block',
    padding: '0 5px',
  },
};

export default styles;
