import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'AUTH';
export const SET_REDIRECT = createRequestTypes(`${filePrefix}_SET_REDIRECT`);
export const LOGIN = createRequestTypes(`${filePrefix}_LOGIN`);
export const BEXIO_LOGIN = createRequestTypes(`${filePrefix}_BEXIO_LOGIN`);
export const BEDAYA_LOGIN = createRequestTypes(`${filePrefix}_BEDAYA_LOGIN`);
export const BEDAYA_REGISTER = createRequestTypes(`${filePrefix}_BEDAYA_REGISTER`);
export const LOGIN_FINISHED = createRequestTypes(`${filePrefix}_LOGIN_FINISHED`);
export const LOGOUT = createRequestTypes(`${filePrefix}_LOGOUT`);
export const GET_USER_INFO = createRequestTypes(`${filePrefix}_GET_USER_INFO`);
export const PUT_USER_INFO = createRequestTypes(`${filePrefix}_PUT_USER_INFO`);
export const FETCH_OAUTH_URL = createRequestTypes(`${filePrefix}_FETCH_OAUTH_URL`);
export const POST_OAUTH_TOKEN = createRequestTypes(`${filePrefix}_POST_OAUTH_TOKEN`);
export const GET_DEBUG_MODE = createRequestTypes(`${filePrefix}_GET_DEBUG_MODE`);
export const PUT_DEBUG_MODE = createRequestTypes(`${filePrefix}_PUT_DEBUG_MODE`);

export const setRedirect = (data: any) => ({
  type: SET_REDIRECT.REQUEST,
  data,
});

export const loginRequest = () => ({
  type: LOGIN.REQUEST,
});
export const bexioLoginRequest = (data: any) => ({
  type: BEXIO_LOGIN.REQUEST,
  data,
});
export const bedayaLoginRequest = (data: any) => ({
  type: BEDAYA_LOGIN.REQUEST,
  data,
});
export const loginSuccess = (data: any) => ({
  type: LOGIN.SUCCESS,
  data,
});
export const loginFailure = (error: any) => ({
  type: LOGIN.FAILURE,
  error,
});

export const loginFinished = () => ({
  type: LOGIN_FINISHED.REQUEST,
});

export const bedayaRegisterRequest = (data: any) => ({
  type: BEDAYA_REGISTER.REQUEST,
  data,
});

export const logoutRequest = (data: any) => ({
  type: LOGOUT.REQUEST,
  data,
});
export const logoutSuccess = () => ({
  type: LOGOUT.SUCCESS,
});
export const logoutFailure = (error: any) => ({
  type: LOGOUT.FAILURE,
  error,
});

export const getUserInfoRequest = () => ({
  type: GET_USER_INFO.REQUEST,
});
export const getUserInfoSuccess = (data: any) => ({
  type: GET_USER_INFO.SUCCESS,
  data,
});
export const getUserInfoFailure = (error: any) => ({
  type: GET_USER_INFO.FAILURE,
  error,
});

export const putUserInfoRequest = (data: any) => ({
  type: PUT_USER_INFO.REQUEST,
  data,
});
export const putUserInfoSuccess = (data: any) => ({
  type: PUT_USER_INFO.SUCCESS,
  data,
});
export const putUserInfoFailure = (error: any) => ({
  type: PUT_USER_INFO.FAILURE,
  error,
});

export const fetchOAuthUrlRequest = (data: any) => ({
  type: FETCH_OAUTH_URL.REQUEST,
  data,
});
export const fetchOAuthUrlSuccess = (data: any) => ({
  type: FETCH_OAUTH_URL.SUCCESS,
  data,
});
export const fetchOAuthUrlFailure = (data: any) => ({
  type: FETCH_OAUTH_URL.FAILURE,
  data,
});

export const postOAuthTokenRequest = (data: any) => ({
  type: POST_OAUTH_TOKEN.REQUEST,
  data,
});
export const postOAuthTokenSuccess = (data: any) => ({
  type: POST_OAUTH_TOKEN.SUCCESS,
  data,
});
export const postOAuthTokenFailure = (data: any) => ({
  type: POST_OAUTH_TOKEN.FAILURE,
  data,
});

export const getDebugModeRequest = () => ({
  type: GET_DEBUG_MODE.REQUEST,
});
export const getDebugModeSuccess = (data: any) => ({
  type: GET_DEBUG_MODE.SUCCESS,
  data,
});
export const getDebugModeFailure = (error: any) => ({
  type: GET_DEBUG_MODE.FAILURE,
  error,
});

export const putDebugModeRequest = (data: any) => ({
  type: PUT_DEBUG_MODE.REQUEST,
  data,
});
export const putDebugModeSuccess = (data: any) => ({
  type: PUT_DEBUG_MODE.SUCCESS,
  data,
});
export const putDebugModeFailure = (error: any) => ({
  type: PUT_DEBUG_MODE.FAILURE,
  error,
});
