import { clientApp } from './index';

export const getBexioTaxes = () => clientApp.get(`/ui/bexio/settings/tax/sale`);

export const getBexioBankAccounts = () => clientApp.get(`/ui/bexio/settings/bankaccounts`);

export const getBexioAccounting = () => clientApp.get(`/ui/bexio/accounts`);

export const getBexioCustomer = ({ customerId = '' }) => clientApp.get(`/ui/bexio/settings/customer/${customerId}`);

export const searchBexioCustomer = ({ searchTerm = '' }) =>
  clientApp.get(`/ui/bexio/settings/customer/search/${searchTerm}`);

export const getBexioProduct = ({ productId = '' }) => clientApp.get(`/ui/bexio/settings/product/${productId}`);

export const searchBexioProduct = ({ searchTerm = '' }) =>
  clientApp.get(`/ui/bexio/settings/product/search/${searchTerm}`);

export const getBexioUsers = () => clientApp.get(`/ui/bexio/settings/user`);

export const getBexioReminderSettings = () => clientApp.get(`/ui/bexio/settings/reminders`);
export const putBexioReminderSettings = (data: any) => clientApp.put(`/ui/bexio/settings/reminders`, data);

export const getBexioReminderSubscription = () => clientApp.get(`/ui/bexio/subscriptions/reminders`);
export const getBexioReminderStatistics = () => clientApp.get(`/ui/bexio/subscriptions/reminders/statistics`);
export const postBexioReminderSubscription = (plan: string, returnUrl: string) => clientApp.post(`/ui/bexio/subscriptions/reminders/checkout/${plan}`, { returnUrl });

export const exportProducts = () => clientApp.get(`/ui/bexio/export/product`);

export const getBexioPaymentTypes = () => clientApp.get(`/ui/bexio/invoice/paymenttype`);

export const getCountries = () => clientApp.get(`/ui/bexio/countries`);

export const getDpdDelivery = (ids: string, quantity: number, product: string) =>
  clientApp.get(`/ui/bexio/delivery/dpd/${ids}?quantity=${quantity}&product=${product}`, {
    responseType: 'blob',
  });

export const getPlanzerDelivery = (ids: string, quantity: number) =>
  clientApp.get(`/ui/bexio/delivery/planzer/${ids}?quantity=${quantity}`);

export const getPlanzerDeliveryDoc = (ref: string) =>
  clientApp.get(`/ui/bexio/delivery/planzer/download/${ref}`, {
    responseType: 'blob',
  });

export const setPlanzerTemplate = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return clientApp.post('/ui/planzer/template', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const getPlanzerTemplate = () => clientApp.get('/ui/planzer/template', {
  responseType: 'blob',
});

export const getPlanzerDefaultTemplate = () => clientApp.get('/ui/planzer/template?default=true', {
  responseType: 'blob',
});
