import { clientApp } from './index';

export const importOrders = (from: string, to: string, shop: string = '') =>
  clientApp.get(`/ui/prestashop/import/orders?from=${from}&to=${to}&shop=${shop}`);

export const importOrder = (id: number, shop: string = '') =>
  clientApp.get(`/ui/prestashop/import/order/${id}?shop=${shop}`);

export const checkSubscription = () => clientApp.get(`/ui/prestashop/subscription`);

export const postCheckout = () => clientApp.post(`/ui/prestashop/subscription/checkout`, { url: process.env.REACT_APP_FE_URL });

export const testConnection = (shop: string) => clientApp.get(`/ui/prestashop/connection/test/${shop}`);
