import { Checkbox as MuCheckbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import React from 'react';

import { IStyleProps, PropsClasses } from '../../types/StyleProps';
import style from './styles';
import { Props } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const Checkbox = (props: Props) => {
  const { label, name, onChange, checked, disabled } = props;
  const classes: PropsClasses = useStyles({} as IStyleProps);

  return (
    <FormControlLabel
      control={
        <MuCheckbox
          name={name}
          onChange={onChange}
          checked={checked}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      }
      classes={{
        label: classes.label,
        root: classes.checkbox,
        ...(props.classes ? props.classes : {})
      }}
      label={label}
      disabled={disabled}
    />
  );
};

export default Checkbox;
