// global imports
import { CircularProgress, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import ConnectWidget from '../../../../../../components/ConnectWidget/ConnectWidget';
import * as authActions from '../../../../../../store/actions/auth_actions';
import * as bexioActions from '../../../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../../../store/actions/common_actions';
import BankAccounts from '../../../../BexioWidgets/BankAccounts/BankAccounts';
import CustomerByLocation from '../../../../BexioWidgets/CustomerByLocation/CustomerByLocation';
import CustomerSearch from '../../../../BexioWidgets/CustomerSearch/CustomerSearch';
import ShippingProductSearch from '../../../../BexioWidgets/ShippingProductSearch/ShippingProductSearch';
import VatAccounts from '../../../../BexioWidgets/VatAccounts/VatAccounts';
import WrappingProductSearch from '../../../../BexioWidgets/WrappingProductSearch/WrappingProductSearch';

// project types imports
import { IState } from '../../../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';

// local imports
import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import WebhookSettings from '../../../../../../components/WebhookSettings/WebhookSettings';
import { getPlatformName } from '../../../../../../services/utils';
import InvoiceOrderOwnerSelect from '../../../../BexioWidgets/InvoiceOrderOwnerSelect/InvoiceOrderOwnerSelect';
import SendDelivery from '../../../../BexioWidgets/SendDelivery/SendDelivery';
import SendInvoice from '../../../../BexioWidgets/SendInvoice/SendInvoice';
import SendInvoiceByBexio from '../../../../BexioWidgets/SendInvoiceByBexio/SendInvoiceByBexio';
import StandardPosition from '../../../../BexioWidgets/StandardPosition/StandardPosition';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const BexioConfig = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    simple,
    OAuthURL,
    bexioSettings,
    connectionStatus,
    isFetching,
    fetchOAuthUrlRequest,
    getSettingsRequest,
    getBankAccountsRequest,
    testConnectionRequest,
    system,
  } = props;

  // didMount fetch data
  useEffect(() => {
    testConnectionRequest({ system: 'bexio' });
    getSettingsRequest({ system: 'bexio' });
  }, []);

  useEffect(() => {
    if (bexioSettings) {
      getSettingsRequest({ system: 'sumup' });
    }
  }, [bexioSettings]);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'bexio' });
      }
      if (connectionStatus === 'ok') {
        getBankAccountsRequest({});
      }
    }
  }, [connectionStatus]);

  return (
    <>
      <GridRow container={true}>
        <Grid item={true} xs={12} md={4}>
          <Label
            title={translate('bexio.status') as string}
            desc={translate('bexio.status-desc', { platform: getPlatformName() }) as string}
          />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <FormCard>
            {connectionStatus !== 'loading' && !isFetching ? (
              connectionStatus === 'ok' ? (
                <div className={classes.connectedContainer}>
                  <span className={classes.connectedStatus}>{translate('connected')}</span>
                </div>
              ) : OAuthURL ? (
                <ConnectWidget appName="bexio" redirect="/admin/bexio-app" noSync={false} />
              ) : null
            ) : (
              <CircularProgress />
            )}
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} xs={12} md={4}>
          <Label
            title={translate('bexio.vat') as string}
            desc={translate('bexio.vat-desc', { system }) as string}
            help={{
              text: translate('help') as string,
              link: translate('bexio.vat-link') as string,
              inNewLine: true,
            }}
          />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <VatAccounts />
        </Grid>
      </GridRow>
      {system === 'Sumup' ? (
        <GridRow container={true}>
          <Grid item={true} xs={12} md={4}>
            <Label
              title={translate('bexio.standard-position-title') as string}
            />
          </Grid>
          <Grid item={true} xs={12} md={8}>
            <FormCard>
              <div className={classes.w100}>
                <StandardPosition />
              </div>
            </FormCard>
          </Grid>
        </GridRow>
      ) : null}
      {!simple ? (
        <>
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.bank-accounts') as string}
                desc={translate('bexio.bank-accounts-desc') as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.bank-accounts-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <BankAccounts system={system} />
              </FormCard>
            </Grid>
          </GridRow>
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.shipping_products') as string}
                desc={translate('bexio.shipping_products-desc', { system }) as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.shipping_products-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <div className={classes.w100}>
                  <ShippingProductSearch />
                </div>
              </FormCard>
            </Grid>
          </GridRow>
          {system === 'PrestaShop' ? (
            <GridRow container={true}>
              <Grid item={true} xs={12} md={4}>
                <Label
                  title={translate('bexio.wrapping_products') as string}
                  desc={translate('bexio.wrapping_products-desc', { system }) as string}
                  help={{
                    text: translate('help') as string,
                    link: translate('bexio.wrapping_products-link') as string,
                    inNewLine: true,
                  }}
                />
              </Grid>
              <Grid item={true} xs={12} md={8}>
                <FormCard>
                  <div className={classes.w100}>
                    <WrappingProductSearch />
                  </div>
                </FormCard>
              </Grid>
            </GridRow>
          ) : null}
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.default-customer') as string}
                desc={translate('bexio.default-customer-desc') as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.default-customer-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <div className={classes.w100}>
                  <CustomerSearch />
                  <CustomerByLocation system={system} />
                </div>
              </FormCard>
            </Grid>
          </GridRow>
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.order_invoice_owner') as string}
                desc={translate('bexio.order_invoice_owner-desc') as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.order_invoice_owner-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <div className={classes.w100}>
                  <InvoiceOrderOwnerSelect />
                </div>
              </FormCard>
            </Grid>
          </GridRow>
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.send_delivery') as string}
                desc={translate('bexio.send_delivery-desc') as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.send_delivery-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <div className={classes.w100}>
                  <SendDelivery />
                  <SendInvoice />
                  <SendInvoiceByBexio />
                  <Link href="/admin/email">{translate('email.title')}</Link>
                </div>
              </FormCard>
            </Grid>
          </GridRow>
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label
                title={translate('bexio.webhook') as string}
                desc={translate('bexio.webhook-desc') as string}
                help={{
                  text: translate('help') as string,
                  link: translate('bexio.webhook-link') as string,
                  inNewLine: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard style={{ display: 'block' }}>
                <WebhookSettings />
              </FormCard>
            </Grid>
          </GridRow>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: state.bexio.OAuthURL,
  apps: state.apps.apps,
  bexioSettings: state.bexio.settings,
  sumupSettings: state.sumup.settings,
  prestashopSettings: state.prestashop.settings,
  bexioTaxes: state.bexio.taxes,
  bexioAccounts: state.bexio.bankAccounts,
  connectionStatus: state.bexio.connectionStatus,
  isFetching: state.bexio.isFetching,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  testConnectionRequest: bexioActions.testConnectionRequest,
  getTaxesRequest: bexioActions.getTaxesRequest,
  getBankAccountsRequest: bexioActions.getBankAccountsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BexioConfig),
);
