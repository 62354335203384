// global imports
import { Grid, TextField } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Props, StateProps } from './types';

const BusinessUnit = (props: Props) => {
  const dispatch = useDispatch();

  const gastrofixSettings = useSelector<IState, StateProps['gastrofixSettings']>(
    ({ gastrofix: { settings } }) => settings,
  );

  const [currentBusinessUnitId, setCurrentBusinessUnitId] = useState(gastrofixSettings.businessUnitId);
  const delayedInput = useRef(
    _.debounce(
      (currentSettings, value) =>
        dispatch(
          putSettingsRequest({
            system: 'gastrofix',
            data: { ...currentSettings, businessUnitId: parseInt(value, 10) },
          }),
        ),
      1000,
    ),
  ).current;

  useEffect(() => {
    setCurrentBusinessUnitId(gastrofixSettings.businessUnitId);
  }, [gastrofixSettings.businessUnitId]);

  const handleValueChange = (event: any) => {
    const newValue = event.target.value;

    setCurrentBusinessUnitId(newValue);
    delayedInput(gastrofixSettings, newValue);
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title="Business Unit ID" desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <TextField
            placeholder="Business Unit ID"
            value={currentBusinessUnitId}
            onChange={handleValueChange}
            style={{ width: '100%' }}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(BusinessUnit);
