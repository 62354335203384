// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader.jsx';
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import * as authActions from '../../../../store/actions/auth_actions';
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import AfterOrderCreation from '../../BexioWidgets/AfterOrderCreation/AfterOrderCreation';
import BankAccounts from '../../BexioWidgets/BankAccounts/BankAccounts';
import CustomerByLocation from '../../BexioWidgets/CustomerByLocation/CustomerByLocation';
import CustomerSearch from '../../BexioWidgets/CustomerSearch/CustomerSearch';
import InvoiceOrderOwnerSelect from '../../BexioWidgets/InvoiceOrderOwnerSelect/InvoiceOrderOwnerSelect';
import ShippingProductSearch from '../../BexioWidgets/ShippingProductSearch/ShippingProductSearch';
import TransferSalesPrice from '../../BexioWidgets/TransferSalesPrice/TransferSalesPrice';
import VatAccounts from '../../BexioWidgets/VatAccounts/VatAccounts';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioSettings } from '../../../../types/BexioSettings';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { config } from '../../../../config';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const BexioApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    OAuthURL,
    bexioSettings,
    connectionStatus,
    isFetching,
    fetchOAuthUrlRequest,
    getSettingsRequest,
    getBankAccountsRequest,
    testConnectionRequest,
  } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL) {
      fetchOAuthUrlRequest({ system: 'bexio' });
    }
    testConnectionRequest({ system: 'bexio' });

    getSettingsRequest({ system: 'bexio' });
  }, []);

  useEffect(() => {
    if (bexioSettings) {
      getSettingsRequest({ system: 'sumup' });
    }
  }, [bexioSettings]);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'bexio' });
      }
      if (connectionStatus === 'ok') {
        getBankAccountsRequest({});
      }
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connectionStatus === 'ok' && OAuthURL === '') {
      fetchOAuthUrlRequest({ system: 'bexio' });
    }
  }, [connectionStatus, OAuthURL]);

  // set settings locally
  useEffect(() => {
    if (bexioSettings) {
      const newSettings = { ...cloneDeep(bexioSettings) };
      if (!newSettings.taxes.length) {
        newSettings.taxes = [
          {
            title: ``,
            value: 0,
            isDefault: false,
            type: 'sales',
            countryIso: null,
          },
        ];
      }
      if (!newSettings.bankAccounts.length) {
        newSettings.bankAccounts = [
          {
            paymentMethod: ``,
            id: 0,
            isDefault: false,
            dependCurrency: false,
          },
        ];
      }
      setSettings(newSettings);
    }
  }, [bexioSettings]);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Bexio</h4>
        <p className={classes.cardCategoryWhite}>{translate('connect_bexio')}</p>
      </CardHeader>
      <CardBody>
        <h3>{translate('status')}</h3>
        <div className={classes.connectRow}>
          {OAuthURL && connectionStatus !== 'loading' && !isFetching ? (
            connectionStatus === 'ok' ? (
              <div className={classes.connectedContainer}>
                <span className={classes.connectedStatus}>{translate('connected')}</span>
                <DisconnectWidget appName="bexio" />
              </div>
            ) : config.activeDomain !== 'bexio' ? (
              <ConnectWidget appName="bexio" redirect="/admin/bexio-app" noSync={true} />
            ) : null
          ) : (
            <CircularProgress />
          )}
        </div>
      </CardBody>
      <CardBody>
        <h3>{translate('settings')}</h3>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.contentContainer}>
                <InputLabel className={cx(classes.selectSmall, classes.pt2)}>{translate('vat')}</InputLabel>
                <div className={cx(classes.contentLarge, classes.bexioVat)}>
                  <VatAccounts />
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.contentContainer}>
                <InputLabel className={cx(classes.selectSmall, classes.pt10)}>
                  {translate('default_customer')}
                </InputLabel>
                <div className={cx(classes.contentLarge, classes.w45)}>
                  <CustomerSearch />
                  <CustomerByLocation system="bexio" />
                </div>
              </div>
            </GridItem>
            {settings &&
              props.apps &&
              (props.apps.includes('shopify') ||
                props.apps.includes('prestashop') ||
                props.apps.includes('wix') ||
                (props.sumupSettings && props.sumupSettings.advanced.enabled)) && (
                <>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.contentContainer}>
                      <InputLabel className={cx(classes.selectSmall, classes.pt2)}>
                        {translate('bank_accounts')}
                      </InputLabel>
                      <div className={classes.bankAccounts}>
                        <div>
                          <BankAccounts system="bexio" />
                        </div>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.contentContainer}>
                      <InputLabel className={cx(classes.selectSmall, classes.pt10)}>
                        {translate('shipping_products')}
                      </InputLabel>
                      <div className={cx(classes.contentLarge, classes.w45)}>
                        <ShippingProductSearch />
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.contentContainer}>
                      <InputLabel className={cx(classes.selectSmall, classes.pt10)}>
                        {translate('order_invoice_owner')}
                      </InputLabel>
                      <div className={cx(classes.contentLarge, classes.w45)}>
                        <InvoiceOrderOwnerSelect />
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.contentContainer}>
                      <InputLabel className={cx(classes.selectSmall, classes.pt10)}>
                        {translate('after_order_creation')}
                      </InputLabel>
                      <div className={classes.contentLarge}>
                        <AfterOrderCreation />
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.contentContainer}>
                      <InputLabel className={cx(classes.selectSmall, classes.pt10)}>
                        {translate('transfer_sales_price')}
                      </InputLabel>
                      <div className={classes.contentLarge}>
                        <TransferSalesPrice />
                      </div>
                    </div>
                  </GridItem>
                </>
              )}
          </GridContainer>
        </CardBody>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: state.bexio.OAuthURL,
  apps: state.apps.apps,
  bexioSettings: state.bexio.settings,
  sumupSettings: state.sumup.settings,
  prestashopSettings: state.prestashop.settings,
  bexioTaxes: state.bexio.taxes,
  bexioAccounts: state.bexio.bankAccounts,
  connectionStatus: state.bexio.connectionStatus,
  isFetching: state.bexio.isFetching,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  testConnectionRequest: bexioActions.testConnectionRequest,
  getTaxesRequest: bexioActions.getTaxesRequest,
  getBankAccountsRequest: bexioActions.getBankAccountsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BexioApp),
);
