// global imports
import { Grid } from '@material-ui/core';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import FormCard from '../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../components/NewUI/GridRow';
import Label from '../../../../../components/NewUI/Label/Label';
import { IState } from '../../../../../store/reducers';
import { XeniaPosSettings } from '../../../../../types/XeniaPosSettings';
import { putSettingsRequest } from '../../../../../store/actions/common_actions';
import { StyledSwitch } from '../../../../../components/StyledSwitch';

const Options = (props: LocalizeContextProps & StateProps) => {
  const { settings: xeniaPosSettings, translate } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = React.useState<XeniaPosSettings>(xeniaPosSettings);

  React.useEffect(() => {
    setSettings(xeniaPosSettings);
  }, [xeniaPosSettings]);

  const onChangeDailyImport = (checked: boolean) => {
    setSettings(x => ({...x, dailyImport: checked }));
    dispatch(putSettingsRequest({ system: 'xeniapos', data: { dailyImport: checked } }))
  }

  const onChangeCreateInvoiceForDebtor = (checked: boolean) => {
    setSettings(x => ({...x, createInvoiceForDebtor: checked }));
    dispatch(putSettingsRequest({ system: 'xeniapos', data: { createInvoiceForDebtor: checked } }))
  }

  return (
    <React.Fragment>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('xenia-pos.daily-import') as string} desc={translate('xenia-pos.daily-import-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <StyledSwitch
              checked={!!settings.dailyImport}
              onChange={event => onChangeDailyImport(event.target.checked)}
            />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('xenia-pos.create-invoice-for-debtor') as string} desc={translate('xenia-pos.create-invoice-for-debtor-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <StyledSwitch
              checked={!!settings.createInvoiceForDebtor}
              onChange={event => onChangeCreateInvoiceForDebtor(event.target.checked)}
            />
          </FormCard>
        </Grid>
      </GridRow>
    </React.Fragment>
  );
};

type StateProps = {
  settings: XeniaPosSettings;
};

const mapStateToProps = (state: IState): StateProps => ({
  settings: state.xeniaPos.settings,
});

export default withLocalize(connect(mapStateToProps)(Options));
