// global imports
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { useDispatch, useSelector } from 'react-redux';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { IState } from '../../../../store/reducers';
import { BitrixSettings } from '../../../../types/BitrixSettings';

export const isUrl = (url: string | null) => {
  if (!url) {
    return false;
  }
  return url.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  );
};

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const ShopUrl = (props: {}) => {
  const dispatch = useDispatch();
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const bitrixSettings = useSelector<IState, BitrixSettings>(({ bitrix: { settings } }) => settings);

  const [currentValue, setCurrentValue] = useState(bitrixSettings.bitrixUrl);
  const delayedInput = useRef(_.debounce(value => onChange(value), 1000)).current;

  const handleValueChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const value = ev.target.value;

    setCurrentValue(value);
    delayedInput(value);
  };

  const onChange = (bitrixUrl: string) => {
    dispatch(putSettingsRequest({ system: 'bitrix', data: { bitrixUrl } }));
  };

  useEffect(() => {
    setCurrentValue(bitrixSettings.bitrixUrl);
  }, [bitrixSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title="Bitrix URL" desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <TextField
            className={classes.w100}
            placeholder="Bitrix URL"
            error={!isUrl(currentValue)}
            value={currentValue}
            onChange={handleValueChange}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(ShopUrl);
