// global imports
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import CardBody from '../../../../components/Card/CardBody';
import * as commonActions from '../../../../store/actions/common_actions';
import * as gastrofixActions from '../../../../store/actions/gastrofix_actions';

// local imports
import GastrofixLogo from '../../../../assets/img/gastrofix-icon.png';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { IState } from '../../../../store/reducers';
import { GastrofixSettings } from '../../../../types/GastrofixSettings';
import DestinationSystem from '../../CommonWidgets/DestinationSystem/DestinationSystem';
import ApiToken from '../../GastrofixWidgets/ApiToken/ApiToken';
import BusinessUnit from '../../GastrofixWidgets/BusinessUnit/BusinessUnit';
import CreditAccounts from '../../GastrofixWidgets/CreditAccounts/CreditAccounts';
import DebitAccounts from '../../GastrofixWidgets/DebitAccounts/DebitAccounts';
import { Props, StateProps } from './types';

const GastrofixApp = (props: Props) => {
  const {
    putSettingsRequest,
    getSettingsRequest,
    gastrofixSettings,
    allSettings,
    setCurrentSettings,
    deleteConfigRequest,
  } = props;
  // didMount fetch data
  useEffect(() => {
    getSettingsRequest({ system: 'gastrofix' });
  }, []);

  const showConfig = (id: string) => {
    setCurrentSettings({ id });
  };

  const addConfig = () => {
    putSettingsRequest({ system: 'gastrofix', data: { id: '' } });
  };

  const deleteConfig = (id: string) => {
    deleteConfigRequest({ id });
    setCurrentSettings({ id: 'reset' });
  };

  const renderConfigs = () => {
    return (
      <Grid container={true}>
        {allSettings.map((config: GastrofixSettings, index: number) => (
          <Grid md={4} lg={3} key={config.id} style={{ padding: 10 }}>
            <CardBody
              style={{ backgroundColor: 'white', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => showConfig(config.id)}
            >
              <img src={GastrofixLogo} style={{ maxWidth: '100%', height: 130 }} alt="gastro-logo" />
              <h3 style={{ margin: 0, fontSize: 20, fontWeight: 'bold' }}>Gastrofix #{index + 1}</h3>
              <h6 style={{ margin: 0, fontSize: 16, textTransform: 'none' }} color={'#c4c4c4'}>
                Business Unit ID: {config.businessUnitId}
              </h6>
            </CardBody>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderContent = () => {
    if (!gastrofixSettings || !gastrofixSettings.id || gastrofixSettings.id === '') {
      return renderConfigs();
    }
    return (
      <>
        <DestinationSystem system="gastrofix" />
        <ApiToken />
        <BusinessUnit />
        <CreditAccounts />
        <DebitAccounts />
      </>
    );
  };

  return (
    <SettingsContainer
      appName="Gastrofix"
      showBack={gastrofixSettings && gastrofixSettings.id !== ''}
      onBack={() => setCurrentSettings({ id: 'reset' })}
      showAdd={!gastrofixSettings || gastrofixSettings.id === ''}
      onAdd={addConfig}
      addText="Add new gastrofix configuration"
      showDelete={gastrofixSettings && gastrofixSettings.id !== ''}
      onDelete={() => deleteConfig(gastrofixSettings.id)}
    >
      {renderContent()}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  gastrofixSettings: state.gastrofix.settings,
  allSettings: state.gastrofix.allSettings,
  connectionStatus: state.gastrofix.connectionStatus,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  setCurrentSettings: gastrofixActions.setCurrentSettings,
  deleteConfigRequest: gastrofixActions.deleteConfigRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GastrofixApp),
);
