// global imports
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';

// local imports
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import Accounting from './components/Accounting';
import Credentials from './components/Credentials';
import Status from './components/Status';
import Options from './components/Options';

const XeniaPosApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'xeniapos' }));
  }, []);

  return (
    <SettingsContainer appName="FLM">
      <Credentials />
      <Status />
      <Accounting />
      <Options />
      <BexioConfig system="xeniapos" simple={true} />;
    </SettingsContainer>
  );
};

export default XeniaPosApp;
