import { BedayaAction } from '../../types/BedayaAction';
import { ShopifySettings } from '../../types/ShopifySettings';

import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';
import { IMPORT_INVOICES, IMPORT_PRODUCTS } from '../actions/shopify_actions';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';

export interface IMailchimpState {
  settings: ShopifySettings | null;

  lastRun: any;
  lastJob: any;
  importProductsStatus: any;
  importOrdersStatus: any;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;
}

const initialState: IMailchimpState = {
  settings: null,

  lastRun: null,
  lastJob: null,
  importProductsStatus: '',
  importOrdersStatus: '',

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'nook',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',
};

try {
  const lastRun = localStorage.getItem('mailchimpLastRun');
  const lastJob = localStorage.getItem('mailchimpLastJob');
  initialState.lastRun = lastRun ? JSON.parse(lastRun) : null;
  initialState.lastJob = lastJob ? JSON.parse(lastJob) : null;
} catch (e) {
  console.warn(e);
}

export default function reducer(state: IMailchimpState = initialState, action: BedayaAction): IMailchimpState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['mailchimp'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          lastRun: null,
          lastJob: null,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case RUN_JOB.SUCCESS:
    case GET_JOB.SUCCESS:
      if (['mailchimp'].includes(action.data.system)) {
        localStorage.setItem(`${action.data.system}LastRun`, JSON.stringify(action.data.data));
        localStorage.setItem(`${action.data.system}LastJob`, JSON.stringify(action.data.jobNumber));
        return {
          ...state,
          lastRun: action.data.data,
          lastJob: action.data.jobNumber,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case RUN_JOB.FAILURE:
    case GET_JOB.FAILURE:
      if (['mailchimp'].includes(action.data.system)) {
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          lastRun: null,
          lastJob: null,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['mailchimp'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    // own actions
    case IMPORT_PRODUCTS.REQUEST:
      return {
        ...state,
        importProductsStatus: 'loading',
      };
    case IMPORT_PRODUCTS.SUCCESS:
      return {
        ...state,
        importProductsStatus: 'ok',
      };
    case IMPORT_PRODUCTS.FAILURE:
      return {
        ...state,
        importProductsStatus: 'failed',
      };
    case IMPORT_INVOICES.REQUEST:
      return {
        ...state,
        importOrdersStatus: 'loading',
      };
    case IMPORT_INVOICES.SUCCESS:
      return {
        ...state,
        importOrdersStatus: 'ok',
      };
    case IMPORT_INVOICES.FAILURE:
      return {
        ...state,
        importOrdersStatus: 'failed',
      };
    default:
      return state;
  }
}
