// global imports
import { makeStyles, Theme } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Translate from '@material-ui/icons/Translate';
import cx from 'classnames';
import React, { SyntheticEvent } from 'react';
import { Language, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Button from '../../components/CustomButtons/Button.jsx';
import AuthService from '../../services/auth.service';
import { putUserInfoRequest } from '../../store/actions/auth_actions';

// project types imports
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import baseStyle from '../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

// scss fixes
import './styles.scss';

const useStyles = makeStyles<Theme, IStyleProps>(baseStyle as any);

const LanguageToggle: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { languages, activeLanguage, setActiveLanguage } = props;

  const managerClasses = cx({
    [classes.managerClasses]: true,
  });

  const [open, setOpen] = React.useState<Element | null>(null);
  const handleClick = (event: SyntheticEvent) => {
    if (open && open.contains(event.target as Element)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };
  const handleClose = () => {
    setOpen(null);
  };

  const dropdownItem = cx(classes.dropdownItem, classes.primaryHover);

  const updateLanguage = (code: string) => {
    setActiveLanguage(code);
    handleClose();

    if (AuthService.isAuthenticated) {
      props.putUserInfoRequest({
        language: `${code}-CH`,
      });
    }
  };

  return (
    <div className={managerClasses}>
      <Button
        color="transparent"
        aria-label="Language"
        // justIcon
        aria-owns={open ? 'language-menu-list' : null}
        aria-haspopup="true"
        onClick={handleClick}
        className={cx(classes.justIconFix, classes.buttonLink)}
      >
        <Translate className={classes.headerLinksSvg + ' ' + classes.links} />
        {activeLanguage.name}
      </Button>
      <Popper
        open={Boolean(open)}
        anchorEl={open}
        transition={true}
        disablePortal={true}
        placement="bottom"
        className={cx({
          [classes.popperClose]: !open,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
            <Paper className={cx(classes.dropdown, { [classes.dropdownDark]: props.big })}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  {languages.map((lang: Language) => (
                    <MenuItem
                      key={lang.code}
                      onClick={() => updateLanguage(lang.code)}
                      className={dropdownItem}
                      selected={lang.code === activeLanguage.code}
                    >
                      {lang.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const defaultProps = {
  activeLanguage: {
    name: '',
    code: '',
    active: true,
  },
};
LanguageToggle.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  tokenInfo: state.auth.tokenInfo,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  putUserInfoRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(LanguageToggle));
