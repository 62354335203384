import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import AuthService from '../../services/auth.service';
import * as appsActions from '../../store/actions/apps_actions';
import * as authActions from '../../store/actions/auth_actions';
import { IState } from '../../store/reducers';
import style from './styles';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postOAuthTokenRequest: (bexioLoginRequest: any) => any;
  redirectPath: string;
  wixAuthenticated: boolean;
  apps: any;
  putAppsRequest: (data: any) => any;
  token: string;
  location: {
    search: string;
    pathname: string;
  };
}

const WixOAuthHandler: React.FC<IProps> = props => {
  const { classes, wixAuthenticated, apps, putAppsRequest, location, postOAuthTokenRequest } = props;
  const dispatch = useDispatch();

  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('/admin/wix-app');

  useEffect(() => {
    if (wixAuthenticated) {
      putAppsRequest([...apps, 'wix']);
      setDoRedirect(true);
    }
  }, [wixAuthenticated]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      //  Check if user is logged
      const params = new URLSearchParams(location.search);

      if (AuthService.isAuthenticated) {
        postOAuthTokenRequest({ system: 'wix', code: params.get('code'), instanceId: params.get('instanceId') });
      } else {
        dispatch(
          authActions.setRedirect(
            `/oauth/wix/callback?code=${params.get('code')}&instanceId=${params.get('instanceId')}`,
          ),
        );
        setRedirectUrl('/admin/login');
        setDoRedirect(true);
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      {doRedirect && <Redirect to={redirectUrl} />}
      <CircularProgress size={100} />
      {/*<button onClick={() => setDoRedirect(true)}>Redirect</button>*/}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  wixAuthenticated: state.wix.authenticated,
  redirectPath: state.auth.redirectPath,
  apps: state.apps.apps,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
  putAppsRequest: appsActions.putAppsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(WixOAuthHandler));
