import commonStyles from '../../assets/jss/commonStyles';
import { IStyleProps } from '../../types/StyleProps';

const styles: IStyleProps = {
  ...commonStyles,
  blackColor: {
    color: '#000',
  },
};

export default styles;
