// global imports
import React, { useCallback, useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { exportProductsRequest } from '../../../../store/actions/bexio_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Grid, InputLabel, makeStyles, StyleRules, Theme, Typography, CircularProgress } from '@material-ui/core';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';
import GridItem from '../../../../components/Grid/GridItem';
import PopupDialog from '../../../../components/PopupDialog/PopupDialog';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import CustomButton from '../../../../components/NewUI/CustomButton';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const WriteBackStockAmount = (props: Props) => {
  const { system, translate } = props;
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();
  const { settings: bexioSettings, connectionStatus, exportProductsProgress } = useSelector<IState, StateProps>(
    ({ bexio, shopify, wix, prestashop }) => ({
      settings: bexio.settings,
      connectionStatus: {
        shopify: shopify.connectionStatus,
        lightspeed: 'ok',
        wix: wix.connectionStatus,
        prestashop: 'ok',
        bexio: bexio.connectionStatus,
      },
      exportProductsProgress: bexio.exportProductsProgress,
    }),
  );
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showConfirmExport, setShowConfirmExport] = useState<boolean>(false);

  const [value, setValue] = useState(bexioSettings ? bexioSettings.enableWriteBackStockAmount[system] : false);
  const [disabledShown, setDisabledShown] = useState<boolean>(false);

  useEffect(() => {
    if (exportProductsProgress) {
      setShowConfirmExport(false);
      toast.success(translate('bexio.export-products-started') as string);
    }
  }, [exportProductsProgress]);

  useEffect(() => {
    if (bexioSettings) {
      setValue(bexioSettings.enableWriteBackStockAmount[system]);
    }
  }, [bexioSettings, system, setValue])

  const setValueWrapped = useCallback(
    (v: boolean) => {
      setValue(v);
      if (bexioSettings) {
        dispatch(
          putSettingsRequest({
            system: 'bexio',
            sourceSystem: system,
            data: {
              ...bexioSettings,
              enableWriteBackStockAmount: { ...bexioSettings.enableWriteBackStockAmount, [system]: v },
            },
          }),
        );
      }
    },
    [bexioSettings, system, dispatch, putSettingsRequest],
  );

  const onChange = useCallback(
    (ev: React.ChangeEvent) => {
      if (connectionStatus[system] !== 'ok' || connectionStatus.bexio !== 'ok') {
        setDisabledShown(true);
      } else if (!value) {
        setShowConfirm(true);
      } else {
        setValueWrapped(false);
      }
    },
    [bexioSettings, value, setValueWrapped, setShowConfirm, connectionStatus, system, setDisabledShown],
  );

  const enable = useCallback(() => {
    setValueWrapped(true);
    setShowConfirm(false);
  }, [setValueWrapped, setShowConfirm]);

  const callExportProducts = useCallback(() => dispatch(exportProductsRequest({})), [dispatch, exportProductsRequest]);

  const disabled = !bexioSettings || connectionStatus[system] === 'loading' || connectionStatus.bexio === 'loading';
  return (
    <>
      <Grid container={true}>
        <GridItem xs={4} className={classes.gridItem}>
          <InputLabel>
            <Translate id={`${system}.stock-amount`} />
          </InputLabel>
        </GridItem>
        <GridItem xs={4} className={classes.gridItem}>
          <StyledSwitch checked={value} onChange={onChange} disabled={disabled} />
        </GridItem>
        <GridItem xs={4} className={classes.gridItem}>
          {!disabled && bexioSettings && bexioSettings.enableWriteBackStockAmount[system] ? (
            exportProductsProgress ? (
              <CircularProgress className={classes.spacedIcon} />
            ) : (
              <CustomButton onClick={() => setShowConfirmExport(true)}>{translate('bexio.export-products')}</CustomButton>
            )
          ) : null}
        </GridItem>
      </Grid>
      <ConfirmDialog
        visible={showConfirm}
        text={translate(`${system}.stock-amount-confirm`) as string}
        onClose={() => setShowConfirm(false)}
        onConfirm={enable}
      />
      <PopupDialog
        title={translate('errors.errors') as string}
        open={disabledShown}
        handleClose={() => setDisabledShown(false)}
      >
        <Typography gutterBottom={true} style={{ marginBottom: 20 }}>
          {connectionStatus[system] !== 'ok' ? (translate(`${system}.stock-amount-disabled`) as string) : null}
          {connectionStatus.bexio !== 'ok' ? (translate('bexio.stock-amount-disabled') as string) : null}
        </Typography>
      </PopupDialog>
      <ConfirmDialog
        visible={showConfirmExport}
        text={translate('bexio.export-products-confirm', {system}) as string}
        onClose={() => setShowConfirmExport(false)}
        onConfirm={callExportProducts}
      />
    </>
  );
};

export default withLocalize<Props>(WriteBackStockAmount);
