import { BedayaAction } from '../../types/BedayaAction';
import { WeclappSettings } from '../../types/WeclappSettings';

import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { GET_SETTINGS, PUT_SETTINGS } from '../actions/common_actions';
import { GET_CUSTOMER, GET_PRODUCT, GET_TAXES, SEARCH_CUSTOMER, SEARCH_PRODUCT } from '../actions/weclapp_actions';

export interface IWeclappState {
  settings: WeclappSettings;

  taxes: Array<{ id: number; name: string }>;
  customerFetchResult: any;
  customersSearchResult: any;

  productFetchResult: any;
  productSearchResult: any;

  OAuthURL: string;
  authenticated: boolean;

  error: any;
  isFetching: boolean;
}

const initialState: IWeclappState = {
  settings: {
    tenant: '',
    apiToken: '',
    defaultCustomerId: null,
    sales: {
      taxes: [],
    },
    shipping: {
      productId: '',
    },
  },

  productFetchResult: null,
  productSearchResult: [],

  taxes: [],
  customerFetchResult: null,
  customersSearchResult: [],

  OAuthURL: '',
  authenticated: false,

  error: '',
  isFetching: false,
};

export default function reducer(state: IWeclappState = initialState, action: BedayaAction): IWeclappState {
  switch (action.type) {
    case FETCH_OAUTH_URL.REQUEST:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
        };
      }
      return state;
    case GET_TAXES.SUCCESS:
      return { ...state, taxes: action.data };
    case SEARCH_CUSTOMER.SUCCESS:
      return { ...state, customersSearchResult: action.data };
    case GET_CUSTOMER.SUCCESS:
      return { ...state, customerFetchResult: action.data };
    case FETCH_OAUTH_URL.SUCCESS:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case FETCH_OAUTH_URL.FAILURE:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['weclapp'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    case GET_PRODUCT.SUCCESS:
      return {
        ...state,
        productFetchResult: action.data,
        isFetching: false,
      };
    case SEARCH_PRODUCT.SUCCESS:
      return {
        ...state,
        productSearchResult: action.data.result,
        isFetching: false,
      };

    default:
      return state;
  }
}
