import commonStyles from '../../../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  subLabel: {
    fontSize: 13,
    display: 'block',
  },
};

export default styles;
