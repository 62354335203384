import { Grid, IconButton, Input, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import commonStyles from '../../../assets/jss/commonStyles';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import FormCard from '../../../components/FormCard/FormCard';
import Label from '../../../components/NewUI/Label/Label';
import * as commonActions from '../../../store/actions/common_actions';
import { IState } from '../../../store/reducers';
import { DpdSettings } from '../../../types/DpdSettings';
import { IOptionType } from '../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../types/StyleProps';

type DpdWidgetProps = LocalizeContextProps;

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

function DpdWidget(props: DpdWidgetProps) {
  const dispatch = useDispatch();
  const dpdSettings = useSelector<IState, DpdSettings>(state => state.dpd.settings);
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [settings, setSettings] = React.useState<DpdSettings | null>(dpdSettings);

  const countryOptions: IOptionType[] = [
    { label: translate('serviceAccounts.dpd.switzerland') as string, value: 'ch' },
  ];

  const productOptions: IOptionType[] = [
    { value: 'B2B', label: 'DPD CLASSIC' },
    { value: 'B2BP', label: 'DPD CLASSIC Predict' },
    { value: 'PM2', label: 'DPD CLASSIC Guarantee' },
    { value: 'B2C', label: 'DPD COMFORT' },
    { value: 'AM1', label: 'DPD EXPRESS 10:00' },
    { value: 'AM2', label: 'DPD EXPRESS 12:00' },
    { value: 'IE2', label: 'DPD EXPRESS' },
    { value: 'HOME', label: 'DPD HOME' },
    { value: 'PSD', label: 'DPD SHOP' },
    { value: 'PL', label: 'DPD PARCELLETTER' },
    { value: 'PBOX', label: 'DPD PARCELBOX' },
  ];

  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'dpd' }));
  }, []);

  React.useEffect(() => {
    setSettings(dpdSettings);
  }, [dpdSettings]);

  const delayedInput = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'dpd', data: { product: value } }));
    }, 1000),
  ).current;

  const delayedInputAuth = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'dpd', data: { login: { [field]: value } } }));
    }, 1000),
  ).current;

  const handleValueChangeAuth = (field: 'delisID' | 'password') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, login: { ...settings.login, [field]: newValue } });
    delayedInputAuth(field, newValue);
  };

  const delayedInputSender = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'dpd', data: { sender: { [field]: value } } }));
    }, 1000),
  ).current;

  const handleValueChangeSender = (field: 'name' | 'street' | 'zip' | 'city' | 'country') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, sender: { ...settings.sender, [field]: newValue } });
    delayedInputSender(field, newValue);
  };

  const handleValueChangeCountry = (newValue: string) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, sender: { ...settings.sender, country: newValue } });
    delayedInputSender('country', newValue);
  };

  const handleValueChangeProduct = (newValue: string) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, product: newValue });
    delayedInput('product', newValue);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /*React.useEffect(() => {
    setSettings(enforeSettings);
  }, [enforeSettings]);*/
  return (
    <Grid container={true}>
      <Grid container={true}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.dpd.credentials') as string}
            desc={translate('serviceAccounts.dpd.credentials-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.username')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.dpd.username') as string}
                    className={classes.w100}
                    value={settings ? settings.login.delisID : ''}
                    onChange={handleValueChangeAuth('delisID')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.password')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <Input
                    placeholder={translate('serviceAccounts.dpd.password') as string}
                    className={classes.w100}
                    value={settings ? settings.login.password : ''}
                    onChange={handleValueChangeAuth('password')}
                    disabled={false /*!enforeSettings*/}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
      <Grid container={true} style={{ marginTop: '2em' }}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.dpd.sender') as string}
            desc={translate('serviceAccounts.dpd.sender-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.name')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.dpd.name') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.name : ''}
                    onChange={handleValueChangeSender('name')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.street')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.dpd.street') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.street : ''}
                    onChange={handleValueChangeSender('street')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.zip')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.dpd.zip') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.zip : ''}
                    onChange={handleValueChangeSender('zip')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.city')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.dpd.city') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.city : ''}
                    onChange={handleValueChangeSender('city')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.dpd.country')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <Select
                    className={classes.w100}
                    isSearchable={false}
                    options={countryOptions}
                    value={settings ? countryOptions.find(x => x.value === settings.sender.country) : null}
                    onChange={opt => {
                      handleValueChangeCountry(opt ? opt.value : '');
                    }}
                    isDisabled={false /*isDisabled*/}
                    styles={colourStyles}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
      <Grid container={true} style={{ marginTop: '2em' }}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.dpd.product') as string}
            desc={translate('serviceAccounts.dpd.product-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={12}>
                  <Select
                    className={classes.w100}
                    isSearchable={false}
                    options={productOptions}
                    value={settings ? productOptions.find(x => x.value === settings.product) : null}
                    onChange={opt => {
                      handleValueChangeProduct(opt ? opt.value : '');
                    }}
                    isDisabled={false /*isDisabled*/}
                    styles={colourStyles}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withLocalize(DpdWidget);
