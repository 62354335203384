import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import commonStyles from '../../../../../assets/jss/commonStyles';
import colourStyles from '../../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import FormCard from '../../../../../components/FormCard/FormCard';
import Label from '../../../../../components/NewUI/Label/Label';
import * as enforeActions from '../../../../../store/actions/enfore_actions';
import { IState } from '../../../../../store/reducers';
import { EnforeCategoryMapping, EnforeSalesCategory } from '../../../../../types/EnforeSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';
import { findOption, mapDisabled, MappingItem } from './MappingItem';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const SalesCategories = (props: LocalizeContextProps & OwnProps) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { accountsList, translate, settings, setSettings } = props;
  const enforeConnectionStatus = useSelector<IState, string>(state => state.enfore.connectionStatus);
  const isFetching = useSelector<IState, boolean>(state => state.enfore.isFetching);
  const enforeSalesCategories = useSelector<IState, EnforeSalesCategory[]>(state => state.enfore.salesCategories);
  const dispatch = useDispatch();

  const [salesCategories, setSalesCategories] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (enforeConnectionStatus === 'ok') {
      dispatch(enforeActions.getSalesCategoriesRequest({}));
    }
  }, [enforeConnectionStatus]);
  React.useEffect(() => {
    if (enforeSalesCategories) {
      let arr = enforeSalesCategories.map((category: EnforeSalesCategory) => ({
        value: category.id,
        label: category.name,
        isDisabled: false,
      }));
      if (settings) {
        arr = mapDisabled(arr, settings.mapping.map(x => x.categoryId));
      }
      setSalesCategories(arr);
    }
  }, [enforeSalesCategories, settings]);

  const [newIsDefault, setNewIsDefault] = React.useState<boolean>(false);
  const [allCategoriesOption, setAllCategoriesOption] = React.useState<IOptionType | null>(null);
  React.useEffect(() => {
    setAllCategoriesOption(
      settings.allCategoriesAccountNo !== null ? findOption(accountsList, settings.allCategoriesAccountNo + '') : null,
    );
  }, [settings.allCategoriesAccountNo, accountsList]);

  return (
    <Grid container={true} style={{ marginTop: 40 }}>
      <Grid item={true} md={4} sm={12}>
        <Label title={translate('enfore.categories') as string} desc={translate('enfore.categories-desc') as string} />
      </Grid>
      <Grid item={true} md={8} sm={12}>
        <FormCard>
          <Grid container={true}>
            <Grid item={true} md={12} style={{ padding: 10 }}>
              <Checkbox
                onChange={() => {
                  setSettings({ ...settings, isSingle: !settings.isSingle });
                }}
                checked={settings.isSingle}
                label={translate('enfore.categories-single-mapping') as string}
              />
            </Grid>
            {settings.isSingle ? (
              <Grid item={true} md={12} style={{ padding: 10 }}>
                <Select
                  className={classes.w100}
                  isSearchable={false}
                  options={accountsList}
                  value={allCategoriesOption}
                  onChange={opt => {
                    setSettings({ ...settings, allCategoriesAccountNo: opt ? +opt.value : null });
                  }}
                  isDisabled={isFetching}
                  styles={colourStyles}
                />
              </Grid>
            ) : (
              <React.Fragment>
                {settings.mapping.map((m, idx) => (
                  <MappingItem
                    key={idx}
                    aOptions={accountsList}
                    bOptions={salesCategories}
                    a={m.accountNo + ''}
                    b={m.categoryId}
                    isDefault={m.isDefault}
                    onChange={(a, b) => {
                      setSettings({
                        ...settings,
                        mapping: settings.mapping.map((it, i) =>
                          idx === i ? { ...it, accountNo: +a, categoryId: b } : it,
                        ),
                      });
                    }}
                    isDisabled={isFetching}
                    translate={translate}
                    onToggleDefault={() => {
                      setSettings({
                        ...settings,
                        mapping: settings.mapping.map((it, i) =>
                          idx === i ? { ...it, isDefault: !it.isDefault } : { ...it, isDefault: false },
                        ),
                      });
                      setNewIsDefault(false);
                    }}
                    onDelete={() => {
                      setSettings({ ...settings, mapping: settings.mapping.filter((it, i) => i !== idx) });
                    }}
                  />
                ))}
                <MappingItem
                  key={settings.mapping.length}
                  aOptions={accountsList}
                  bOptions={salesCategories}
                  isDefault={newIsDefault}
                  onChange={(a, b) => {
                    const newItem = { accountNo: +a, categoryId: b, isDefault: newIsDefault };
                    setSettings({ ...settings, mapping: [...settings.mapping, newItem] });
                    setNewIsDefault(false);
                  }}
                  isDisabled={isFetching}
                  translate={translate}
                  onToggleDefault={() => {
                    setSettings({ ...settings, mapping: settings.mapping.map(it => ({ ...it, isDefault: false })) });
                    setNewIsDefault(!newIsDefault);
                  }}
                />
              </React.Fragment>
            )}
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

type OwnProps = {
  accountsList: IOptionType[];
  settings: EnforeCategoryMapping;
  setSettings: (settings: EnforeCategoryMapping) => void;
};

export default withLocalize(SalesCategories);
