const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export type IRequestTypeOptions = typeof REQUEST | typeof SUCCESS | typeof FAILURE;

export type IRequestType = Record<IRequestTypeOptions, object[]>;

export default function createRequestTypes(base: string) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => ({ ...acc, [type]: `${base}_${type}` }), {} as IRequestType);
}
