// global imports
import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import commonStyles from '../../../../../assets/jss/commonStyles';
import Button from '../../../../../components/CustomButtons/Button.jsx';
import FormCard from '../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../components/NewUI/GridRow';
import Label from '../../../../../components/NewUI/Label/Label';
import * as commonActions from '../../../../../store/actions/common_actions';
import { IState } from '../../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const Status = (props: LocalizeContextProps & StateProps) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { connectionStatus, isFetching, isFetchingConnection, error, translate } = props;
  const dispatch = useDispatch();
  const connectCall = () => dispatch(commonActions.testConnectionRequest({ system: 'enfore' }));
  React.useEffect(() => {
    connectCall();
  }, []);
  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('enfore.status') as string} desc={translate('enfore.status-desc') as string} />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          {!isFetchingConnection ? (
            connectionStatus === 'ok' ? (
              <div className={classes.connectedContainer}>
                <span className={classes.connectedStatus}>{translate('connected')}</span>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <Button type="button" color="primary" onClick={connectCall} disabled={isFetching}>
                  {translate('connect')}
                </Button>
                <div>{error && <p className={classes.connectError}>{error}</p>}</div>
              </div>
            )
          ) : (
            <CircularProgress />
          )}
        </FormCard>
      </Grid>
    </GridRow>
  );
};

type StateProps = {
  connectionStatus: string;
  isFetching: boolean;
  isFetchingConnection: boolean;
  error: any;
};

const mapStateToProps = (state: IState): StateProps => ({
  connectionStatus: state.enfore.connectionStatus,
  isFetching: state.enfore.isFetching,
  isFetchingConnection: state.enfore.isFetchingConnection,
  error: state.enfore.error,
});

export default withLocalize(connect(mapStateToProps)(Status));
