// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';

import { useDispatch, useSelector } from 'react-redux';

// project imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import { Props, StateProps } from './types';

const SendInvoiceByBexio = (props: Props) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);

  const [sendInvoiceByBexio, setSendInvoiceByBexio] = useState(bexioSettings ? bexioSettings.sendInvoiceByBexio : true);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    if (sendInvoiceByBexio === bexioSettings.sendInvoiceByBexio) {
      return;
    }
    dispatch(putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, sendInvoiceByBexio } }));
  }, [sendInvoiceByBexio]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    setSendInvoiceByBexio(bexioSettings.sendInvoiceByBexio);
  }, [bexioSettings]);

  const onChange = (ev: React.ChangeEvent) => {
    setSendInvoiceByBexio(!sendInvoiceByBexio);
  };

  return (
    bexioSettings && (
      <Grid container={true} style={{ alignItems: 'center' }}>
        <Grid item={true} md={4}>
          <InputLabel>{translate('bexio.invoice_by_bexio')}</InputLabel>
        </Grid>
        <Grid item={true} md={8}>
          <StyledSwitch checked={sendInvoiceByBexio} onChange={onChange} disabled={!bexioSettings} />
        </Grid>
      </Grid>
    )
  );
};

export default withLocalize(SendInvoiceByBexio);
