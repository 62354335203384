import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import commonStyles from '../../../../../assets/jss/commonStyles';
import colourStyles from '../../../../../assets/jss/reactSelectStyle';
import FormCard from '../../../../../components/FormCard/FormCard';
import Label from '../../../../../components/NewUI/Label/Label';
import { IState } from '../../../../../store/reducers';
import { IOptionType } from '../../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';
import { findOption } from './MappingItem';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const Voucher = (props: LocalizeContextProps & OwnProps) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { accountsList, translate, settings, setSettings } = props;
  const isFetching = useSelector<IState, boolean>(state => state.enfore.isFetching);

  const [voucher, setVoucher] = React.useState<IOptionType | null>(null);
  React.useEffect(() => {
    setVoucher(settings !== null ? findOption(accountsList, settings + '') : null);
  }, [settings, accountsList]);

  return (
    <Grid container={true} style={{ marginTop: 40 }}>
      <Grid item={true} md={4} sm={12}>
        <Label title={translate('enfore.voucher') as string} desc={translate('enfore.voucher-desc') as string} />
      </Grid>
      <Grid item={true} md={8} sm={12}>
        <FormCard>
          <Grid container={true}>
            <Grid item={true} md={12} style={{ padding: 10 }}>
              <Select
                className={classes.w100}
                isSearchable={false}
                options={accountsList}
                value={voucher}
                onChange={opt => {
                  setSettings(opt ? +opt.value : null);
                }}
                isDisabled={isFetching}
                styles={colourStyles}
              />
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

type OwnProps = {
  accountsList: IOptionType[];
  settings: number | null;
  setSettings: (settings: number | null) => void;
};

export default withLocalize(Voucher);
