import { BedayaAction } from '../../types/BedayaAction';
import { XeniaPosSettings, XeniaPosGroup, XeniaPosGroupApi, XeniaPosPaymentMethod } from '../../types/XeniaPosSettings';
import { GET_SETTINGS, PUT_SETTINGS, TEST_CONNECTION } from '../actions/common_actions';
import { GET_PAYMENT_METHODS, GET_GROUPS, IMPORT_ENTRIES } from '../actions/xeniapos_actions';

export interface IXeniaPosState {
  settings: XeniaPosSettings;
  isFetching: boolean;
  isFetchingConnection: boolean;
  error: any;
  connectionStatus: string;
  groups: XeniaPosGroup[];
  paymentMethods: XeniaPosPaymentMethod[];
}

const initialState: IXeniaPosState = {
  settings: {
    user: null,
    password: null,
    dbName: null,
  },

  isFetching: false,
  error: '',
  connectionStatus: 'nook',
  isFetchingConnection: false,

  groups: [],
  paymentMethods: [],
};

export default function reducer(state: IXeniaPosState = initialState, action: BedayaAction): IXeniaPosState {
  switch (action.type) {
    case GET_PAYMENT_METHODS.REQUEST:
    case GET_GROUPS.REQUEST:
    case IMPORT_ENTRIES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PAYMENT_METHODS.FAILURE:
    case GET_GROUPS.FAILURE:
    case IMPORT_ENTRIES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error.toString(),
      };
    case IMPORT_ENTRIES.SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case GET_PAYMENT_METHODS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentMethods: action.data.data,
      };
    case GET_GROUPS.SUCCESS:
      return {
        ...state,
        isFetching: !action.data.done,
        groups: [
            ...state.groups.filter((x: XeniaPosGroup) => x.type !== action.data.groupType),
            ...action.data.data.map((x: XeniaPosGroupApi) => ({...x, type: action.data.groupType})),
        ],
      };
    // protocol actions
    case TEST_CONNECTION.REQUEST:
      if (['xeniapos'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingConnection: true,
          isFetching: true,
        };
      }
      return state;
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['xeniapos'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
      if (['xeniapos'].includes(action.data.system)) {
        const authChanged =
          state.settings.user !== action.data.data.user ||
          state.settings.password !== action.data.data.password ||
          state.settings.dbName !== action.data.data.dbName;
        return {
          ...state,
          settings: action.data.data,
          connectionStatus: authChanged ? 'nook' : state.connectionStatus,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.SUCCESS:
      if (['xeniapos'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case TEST_CONNECTION.SUCCESS:
      if (['xeniapos'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          isFetchingConnection: false,
          connectionStatus: action.data.status || 'nook',
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['xeniapos'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error.toString(),
        };
      }
      return state;
    default:
      return state;
  }
}
