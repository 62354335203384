import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'SUMUP';
export const GET_ACCOUNTS = createRequestTypes(`${filePrefix}_GET_ACCOUNTS`);
export const GET_STATISTICS = createRequestTypes(`${filePrefix}_GET_STATISTICS`);
export const GET_SUBSCRIPTION = createRequestTypes(`${filePrefix}_GET_SUBSCRIPTION`);

export const getAccountsRequest = (data: any) => ({
  type: GET_ACCOUNTS.REQUEST,
  data,
});
export const getAccountsSuccess = (data: any) => ({
  type: GET_ACCOUNTS.SUCCESS,
  data,
});
export const getAccountsFailure = (error: any) => ({
  type: GET_ACCOUNTS.FAILURE,
  error,
});

export const getStatisticsRequest = (data: any) => ({
  type: GET_STATISTICS.REQUEST,
  data,
});
export const getStatisticsSuccess = (data: any) => ({
  type: GET_STATISTICS.SUCCESS,
  data,
});
export const getStatisticsFailure = (error: any) => ({
  type: GET_STATISTICS.FAILURE,
  error,
});

export const getSubscriptionRequest = (data: any) => ({
  type: GET_SUBSCRIPTION.REQUEST,
  data,
});
export const getSubscriptionSuccess = (data: any) => ({
  type: GET_SUBSCRIPTION.SUCCESS,
  data,
});
export const getSubscriptionFailure = (error: any) => ({
  type: GET_SUBSCRIPTION.FAILURE,
  error,
});
