import { BedayaAction } from '../../types/BedayaAction';
import { LightspeedRSettings } from '../../types/LightspeedRSettings';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';

export interface ILightspeedRState {
  settings: LightspeedRSettings;

  OAuthURL: string;
  authenticated: boolean;
  connectionStatus: any;

  isFetching: boolean;
  error: any;
}

const initialState: ILightspeedRState = {
  settings: {
    destinationSystem: null,

    productSync: {
      enabled: false,
    },
    invoiceSync: {
      enabled: false,
    },
  },

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'loading',

  isFetching: false,
  error: '',
};

export default function reducer(state: ILightspeedRState = initialState, action: BedayaAction): ILightspeedRState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case FETCH_OAUTH_URL:
    case POST_OAUTH_TOKEN:
    case TEST_CONNECTION.FAILURE:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case TEST_CONNECTION.SUCCESS:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          connectionStatus: action.data.status || 'nook',
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['lightspeed-r'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;
    default:
      return state;
  }
}
