import commonStyles from '../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  checkbox: {
    '&.Mui-disabled': {
      opacity: '0.5',
      cursor: 'not-allowed',
    },
  },
};

export default styles;
