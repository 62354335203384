const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'white',
    '&:hover': { borderColor: 'gray' },
    border: '1px solid lightgray',
    boxShadow: 'none',
  }),
  option: (styles: { [x: string]: any; }, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor:
        isDisabled
          ? '#ffffff'
          : isSelected
          ? '#ECEDEB'
          : isFocused
            ? '#FAFAFA'
            : '#ffffff',
      color:
        isDisabled
          ? '#0000008a'
          : isSelected
          ? '#0000008a'
          : '#0000008a',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        backgroundColor: !isDisabled && (isSelected ? '#0000008a' : '#0000008a'),
      },
    };
  },
};

export default colourStyles;