import commonStyles from '../../../../assets/jss/commonStyles';
import { IStyleProps } from '../../../../types/StyleProps';

const styles: IStyleProps = {
  ...commonStyles,
  selectSmall: {
    width: '150px',
    display: 'inline-block',
    padding: '0 5px',
  },
};

export default styles;
