// global imports
import { FormControlLabel, Grid, makeStyles, RadioGroup, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import GridItem from '../../../../components/Grid/GridItem';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import assign from '../../../../services/assign';

// project types imports
import { IState } from '../../../../store/reducers';
import { ShopifySettings } from '../../../../types/ShopifySettings';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { StyledRadio } from '../../../../components/StyledRadio';
import * as shopifyActions from '../../../../store/actions/shopify_actions';
import AfterOrderCreation from '../../BexioWidgets/AfterOrderCreation/AfterOrderCreation';
import PayInvoice from '../../BexioWidgets/PayInvoice/PayInvoice';
import WriteBackPaidInvoices from '../../BexioWidgets/WriteBackPaidInvoices/WriteBackPaidInvoices';
import TransferOrderUpdate from '../TransferOrderUpdate/TransferOrderUpdate';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const TransferOptions = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, shopifySettings, connectionStatus, isFetching, putSettingsForShopRequest, shopifyShops } = props;

  const [settings, setSettings] = useState<ShopifySettings | null>(null);

  useEffect(() => {
    if (shopifySettings) {
      setSettings(shopifySettings);
    }
  }, [shopifySettings]);

  const setValue = (name: any, value: any) => {
    if (settings) {
      const newSettings = assign({ ...settings }, name, value);
      setSettings(newSettings);
      const shop = shopifyShops.find(s => s.active);
      if (shop) {
        putSettingsForShopRequest({
          data: newSettings,
          shop: shop.name,
        });
      }
    }
  }

  const handleSwitchChange = (name: any) => (event: any) => setValue(name, event.target.checked);

  const handleTargetEntityChange = (event: any) => setValue('invoiceSync.targetEntity', event.target.value);

  const isDisabled = !settings || connectionStatus !== 'ok' || isFetching;

  return (
    <>
      <Grid container={true}>
        <GridItem xs={4} className={classes.gridItem}>
          <InputLabel>{translate('shopify.product_transfer')}</InputLabel>
        </GridItem>
        <GridItem xs={8} className={classes.gridItem}>
          <StyledSwitch
            disabled={isDisabled}
            checked={settings ? settings.productSync.enabled : false}
            onChange={handleSwitchChange('productSync.enabled')}
            value="productSync"
          />
        </GridItem>
        <GridItem xs={4} className={classes.gridItem}>
          <InputLabel>{translate('shopify.customer_transfer')}</InputLabel>
        </GridItem>
        <GridItem xs={8} className={classes.gridItem}>
          <StyledSwitch
            disabled={isDisabled}
            checked={settings ? settings.contactSync.enabled : false}
            onChange={handleSwitchChange('contactSync.enabled')}
            value="contactSync"
          />
        </GridItem>
        <GridItem xs={4} className={classes.gridItem}>
          <InputLabel>{translate('shopify.order_transfer')}</InputLabel>
        </GridItem>
        <GridItem xs={8} className={classes.gridItem}>
          <StyledSwitch
            disabled={isDisabled}
            checked={settings ? settings.invoiceSync.enabled : false}
            onChange={handleSwitchChange('invoiceSync.enabled')}
            value="invoiceSync"
          />
          {settings && settings.invoiceSync.enabled && (
            <TransferOrderUpdate />
          )}
        </GridItem>

        <WriteBackPaidInvoices system="shopify" />
      </Grid>
      {settings && settings.invoiceSync.enabled && (
        <>
          <Grid container={true}>
            <Grid md={4} />
            <Grid md={8}>
              <RadioGroup
                value={settings.invoiceSync.targetEntity ? settings.invoiceSync.targetEntity : 'Invoice'}
                onChange={handleTargetEntityChange}
                className={classes.radio}
                style={{ display: 'flex', flexDirection: 'column', paddingLeft: 35 }}
              >
                <FormControlLabel value="Order" control={<StyledRadio />} label={translate('shopify.order')} />
                {settings.invoiceSync.targetEntity === 'Order' ? (
                  <>
                    <AfterOrderCreation />
                    <PayInvoice />
                  </>
                ) : null}
                <FormControlLabel value="Invoice" control={<StyledRadio />} label={translate('shopify.invoice')} />
                {settings.invoiceSync.targetEntity === 'Invoice' ? (
                  <PayInvoice />
                ) : null}
                <FormControlLabel value="Offer" control={<StyledRadio />} label={translate('shopify.offer')} />
              </RadioGroup>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shopifySettings: state.shopify.settings,
  shopifyShops: state.shopify.shops,
  connectionStatus: state.shopify.connectionStatus,
  isFetching: state.shopify.isFetching,
});

const mapDispatchToProps = {
  putSettingsForShopRequest: shopifyActions.putSettingsForShopRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TransferOptions),
);
