import { connect } from 'react-redux';
import { Component } from 'react';

class AclService extends Component {
  static hasPermission = (store, permission) => {
    return store.tokenInfo.payload.roles ? store.tokenInfo.payload.roles.includes(permission) : false;
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AclService);
