import React from 'react';

import { Grid } from '@material-ui/core';
import { withLocalize } from 'react-localize-redux';
import FormCard from '../../../../../../components/FormCard/FormCard';
import CustomButton from '../../../../../../components/NewUI/CustomButton';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import { config } from '../../../../../../config';
import { getPlatformName } from '../../../../../../services/utils';

const DownloadPlugin = (props: any) => {
  const { translate } = props;
  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('prestashop.connect', { platform: getPlatformName() }) as string}
          desc={translate('prestashop.connect-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <span style={{ marginRight: '7.5%', fontWeight: 'normal' }}>{translate('prestashop.download-plugin')}</span>
          <form method="get" action={config.activeDomain === 'bexio' ? '/zip/bexio.zip' : '/zip/bedayaintegration.zip'}>
            <CustomButton type="submit">{translate('prestashop.download-zip')}</CustomButton>
          </form>
          <span style={{ marginLeft: '7.5%', fontWeight: 'normal' }}>
            {config.activeDomain === 'bexio' ? 'bexio.zip (25 KB)' : 'bedayaintegration.zip (139 KB)'}
          </span>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(DownloadPlugin);
