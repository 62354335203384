import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import commonStyles from '../../../../../assets/jss/commonStyles';
import colourStyles from '../../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import FormCard from '../../../../../components/FormCard/FormCard';
import Label from '../../../../../components/NewUI/Label/Label';
import * as xeniaPosActions from '../../../../../store/actions/xeniapos_actions';
import { IState } from '../../../../../store/reducers';
import { XeniaPosGroupMapping, XeniaPosGroup } from '../../../../../types/XeniaPosSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';
import { findOption, mapDisabled } from './MappingItem';
import { MappingItem2 } from './MappingItem2';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const Groups = (props: LocalizeContextProps & OwnProps) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { accountsList, translate, settings, setSettings } = props;
  const xeniaPosConnectionStatus = useSelector<IState, string>(state => state.xeniaPos.connectionStatus);
  const isFetching = useSelector<IState, boolean>(state => state.xeniaPos.isFetching);
  const xeniaPosGroups = useSelector<IState, XeniaPosGroup[]>(state => state.xeniaPos.groups);
  const dispatch = useDispatch();

  const [groups, setGroups] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (xeniaPosConnectionStatus === 'ok') {
      dispatch(xeniaPosActions.getGroupsRequest({}));
    }
  }, [xeniaPosConnectionStatus]);
  React.useEffect(() => {
    if (xeniaPosGroups) {
      let arr = xeniaPosGroups.map((group: XeniaPosGroup) => ({
        value: group.id + '',
        label: group.name,
        isDisabled: false,
        type: group.type ?? '',
      }) as IOptionType);
      if (settings) {
        arr = mapDisabled(arr, settings.mapping.map(x => x.groupId + ''));
      }
      setGroups(arr);
    }
  }, [xeniaPosGroups, settings]);

  const [newIsDefault, setNewIsDefault] = React.useState<boolean>(false);
  const [allGroupOption, setAllGroupOption] = React.useState<IOptionType | null>(null);
  React.useEffect(() => {
    setAllGroupOption(
      settings.allGroupAccountNo !== null ? findOption(accountsList, settings.allGroupAccountNo + '') : null,
    );
  }, [settings.allGroupAccountNo, accountsList]);

  return (
    <Grid container={true} style={{ marginTop: 40 }}>
      <Grid item={true} md={4} sm={12}>
        <Label title={translate('xenia-pos.groups') as string} desc={translate('xenia-pos.groups-desc') as string} />
      </Grid>
      <Grid item={true} md={8} sm={12}>
        <FormCard>
          <Grid container={true}>
            <Grid item={true} md={12} style={{ padding: 10 }}>
              <Checkbox
                onChange={() => {
                  setSettings({ ...settings, isSingle: !settings.isSingle });
                }}
                checked={settings.isSingle}
                label={translate('xenia-pos.groups-single-mapping') as string}
              />
            </Grid>
            {settings.isSingle ? (
              <Grid item={true} md={12} style={{ padding: 10 }}>
                <Select
                  className={classes.w100}
                  isSearchable={false}
                  options={accountsList}
                  value={allGroupOption}
                  onChange={opt => {
                    setSettings({ ...settings, allGroupAccountNo: opt ? +opt.value : null });
                  }}
                  isDisabled={isFetching}
                  styles={colourStyles}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid container={true}>
                  <Grid item={true} md={3} style={{textAlign: 'center'}}>
                    {translate('xenia-pos.groups-group-column') as string}
                  </Grid>
                  <Grid item={true} md={3} style={{textAlign: 'center'}}>
                    {translate('xenia-pos.groups-account-column') as string}
                  </Grid>
                  <Grid item={true} md={3} style={{textAlign: 'center'}}>
                    {translate('xenia-pos.groups-account-invoice-column') as string}
                  </Grid>
                  <Grid item={true} md={3}>
                  </Grid>
                </Grid>
                {settings.mapping.map((m, idx) => (
                  <MappingItem2
                    key={idx}
                    aOptions={accountsList}
                    bOptions={groups}
                    cOptions={accountsList}
                    a={m.accountNo}
                    b={m.groupId + ''}
                    c={m.accountNoInvoice ?? undefined}
                    isDefault={m.isDefault}
                    onChange={(a, b, c, bt) => {
                      setSettings({
                        ...settings,
                        mapping: settings.mapping.map((it, i) =>
                          idx === i ? { ...it, accountNo: a, accountNoInvoice: c, groupId: +b, type: bt ?? '' } : it,
                        ),
                      });
                    }}
                    isDisabled={isFetching}
                    translate={translate}
                    onToggleDefault={() => {
                      setSettings({
                        ...settings,
                        mapping: settings.mapping.map((it, i) =>
                          idx === i ? { ...it, isDefault: !it.isDefault } : { ...it, isDefault: false },
                        ),
                      });
                      setNewIsDefault(false);
                    }}
                    onDelete={() => {
                      setSettings({ ...settings, mapping: settings.mapping.filter((it, i) => i !== idx) });
                    }}
                  />
                ))}
                <MappingItem2
                  key={settings.mapping.length}
                  aOptions={accountsList}
                  bOptions={groups}
                  cOptions={accountsList}
                  isDefault={newIsDefault}
                  onChange={(a, b, c, bt) => {
                    const newItem = { accountNo: a, groupId: +b, accountNoInvoice: c, isDefault: newIsDefault, type: bt ?? '' };
                    setSettings({ ...settings, mapping: [...settings.mapping, newItem] });
                    setNewIsDefault(false);
                  }}
                  isDisabled={isFetching}
                  translate={translate}
                  onToggleDefault={() => {
                    setSettings({ ...settings, mapping: settings.mapping.map(it => ({ ...it, isDefault: false })) });
                    setNewIsDefault(!newIsDefault);
                  }}
                />
              </React.Fragment>
            )}
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

type OwnProps = {
  accountsList: IOptionType[];
  settings: XeniaPosGroupMapping;
  setSettings: (settings: XeniaPosGroupMapping) => void;
};

export default withLocalize(Groups);
