// global imports
import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project types imports
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import { StyleRules } from '@material-ui/styles/withStyles/withStyles';
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const BetaOverlay: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, isBeta, small, type } = props;

  return (
    <div
      className={cx(type === 'corner' ? classes.corner : classes.button, {
        [classes.small]: small,
        [classes.blue]: !isBeta,
      })}
    >
      {translate(isBeta ? 'beta' : 'coming_soon', undefined, { renderInnerHtml: true })}
    </div>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  tokenInfo: state.auth.tokenInfo,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(BetaOverlay));
