import { BedayaAction } from '../../types/BedayaAction';
import { SumupSettings } from '../../types/SumupSettings';

import { DISCONNECT, GET_SETTINGS, PUT_SETTINGS, TEST_CONNECTION, RUN_JOB, GET_JOB } from '../actions/common_actions';
import { GET_ACCOUNTS, GET_SUBSCRIPTION, GET_STATISTICS } from '../actions/sumup_actions';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';

export interface ISumupState {
  settings: SumupSettings | null;

  accounts: any;

  lastRun: any;
  lastJob: any;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;

  subscription: any;
  subscriptionFetching: boolean;

  statistics: any;
  statisticsFetching: boolean;
}

const initialState: ISumupState = {
  settings: null,

  accounts: {
    debit: [],
    credit: [],
  },

  lastRun: null,
  lastJob: null,

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'loading',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',

  subscription: undefined,
  subscriptionFetching: false,

  statistics: undefined,
  statisticsFetching: false,
};

try {
  const lastRun = localStorage.getItem('sumupLastRun');
  const lastJob = localStorage.getItem('sumupLastJob');
  initialState.lastRun = lastRun ? JSON.parse(lastRun) : null;
  initialState.lastJob = lastJob ? JSON.parse(lastJob) : null;
} catch (e) {
  console.warn(e);
}

export default function reducer(state: ISumupState = initialState, action: BedayaAction): ISumupState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['sumup'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          lastRun: null,
          lastJob: null,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case RUN_JOB.SUCCESS:
    case GET_JOB.SUCCESS:
      if (['sumup'].includes(action.data.system)) {
        localStorage.setItem(`${action.data.system}LastRun`, JSON.stringify(action.data.data));
        localStorage.setItem(`${action.data.system}LastJob`, JSON.stringify(action.data.jobNumber));
        return {
          ...state,
          lastRun: action.data.data,
          lastJob: action.data.jobNumber,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case RUN_JOB.FAILURE:
    case GET_JOB.FAILURE:
      if (['sumup'].includes(action.data.system)) {
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          lastRun: null,
          lastJob: null,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['sumup'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    // own actions
    case GET_ACCOUNTS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ACCOUNTS.SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.data.type]: action.data.data,
        },
        isFetching: false,
      };
    case GET_ACCOUNTS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case GET_STATISTICS.REQUEST:
      return {
        ...state,
        statisticsFetching: true,
      };
    case GET_STATISTICS.SUCCESS:
      return {
        ...state,
        statistics: action.data.data,
        statisticsFetching: false,
      };
    case GET_STATISTICS.FAILURE:
      return {
        ...state,
        error: action.error,
        statisticsFetching: false,
      };
    case GET_SUBSCRIPTION.REQUEST:
      return {
        ...state,
        subscriptionFetching: true,
      };
    case GET_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        subscription: action.data.data,
        subscriptionFetching: false,
      };
    case GET_SUBSCRIPTION.FAILURE:
      return {
        ...state,
        error: action.error,
        subscriptionFetching: false,
      };
    default:
      return state;
  }
}
