import { createStyles, Grid, Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';

import commonStyles from '../../../../assets/jss/commonStyles';

import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { getSettingsRequest } from '../../../../store/actions/common_actions';
import BexioEmailTranslations from './../BexioEmailTranslations/BexioEmailTranslations';
import SwitchField from './../SwitchField/SwitchField';

const styles = ({ palette, spacing }: Theme) => createStyles(commonStyles);

function BexioSection(props: any) {
  const { translate } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'email', type: 'invoice_by_bexio' }));
  }, []);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate(`email.invoice_by_bexio`) as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div style={{ width: '100%' }}>
            <Grid container={true} style={{ marginBottom: 15 }}>
              <Grid item={true} md={3}>
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {translate(`email.attach_pdf`)}
                </span>
              </Grid>
              <Grid item={true} md={9}>
                <SwitchField type="invoice_by_bexio" fieldName="attachPdf" />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 15 }}>
              <Grid item={true} md={3}>
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {translate(`email.translations`)}
                </span>
              </Grid>
              <Grid item={true} md={9}>
                <BexioEmailTranslations />
              </Grid>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
}

export default withStyles(styles)(withLocalize(BexioSection));
