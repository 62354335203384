import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
  DELETE_CONFIG,
  deleteConfigFailure,
  deleteConfigSuccess,
  GET_ACCOUNTS,
  GET_ARTICLE_FAMILIES,
  getAccountsFailure,
  getAccountsSuccess,
  getArticleFamiliesFailure,
  getArticleFamiliesSuccess,
  IMPORT_TRANSACTIONS,
} from './../actions/gastrofix_actions';
import { deleteConfig, getAccounts, getArticleFamilies, importTransactions } from './../api/gastrofix';

import moment from 'moment';
import { BedayaAction } from '../../types/BedayaAction';
import { importOrdersFailure, importOrdersSuccess } from '../actions/prestashop_actions';

export default function* gastrofixSagas() {
  yield all([
    fork(importTransactionsWatcher),
    fork(getArticleFamiliesWatcher),
    fork(getAccountsWatcher),
    fork(deleteConfigWatcher),
  ]);
}

function* importTransactionsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(importTransactions, moment(action.data.date).format('YYYY-MM-DD'));
    yield put(importOrdersSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importOrdersFailure(processedError || error));
  }
}

function* getArticleFamiliesSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getArticleFamilies, action.data.id);
    yield put(getArticleFamiliesSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getArticleFamiliesFailure(processedError || error));
  }
}

function* getAccountsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getAccounts);

    yield put(getAccountsSuccess({ type: action.data, data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getAccountsFailure(processedError || error));
  }
}

function* deleteConfigSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(deleteConfig, action.data.id);

    yield put(deleteConfigSuccess({ type: action.data, data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(deleteConfigFailure(processedError || error));
  }
}

function* getAccountsWatcher() {
  yield takeLatest(GET_ACCOUNTS.REQUEST as ActionPattern, getAccountsSaga);
}

function* importTransactionsWatcher() {
  yield takeLatest(IMPORT_TRANSACTIONS.REQUEST as ActionPattern, importTransactionsSaga);
}

function* getArticleFamiliesWatcher() {
  yield takeLatest(GET_ARTICLE_FAMILIES.REQUEST as ActionPattern, getArticleFamiliesSaga);
}

function* deleteConfigWatcher() {
  yield takeLatest(DELETE_CONFIG.REQUEST as ActionPattern, deleteConfigSaga);
}
