import React, { useState } from 'react';
import Cookies from 'universal-cookie/es6';
import { makeStyles } from '@material-ui/core/styles';

import { config } from '../../config';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';

import styles from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import { withLocalize } from 'react-localize-redux';

const themeColor = process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? 'primary' : 'rose';

const useStyles = makeStyles(styles);

function BexioLoginPage(props) {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const { translate } = props;

  setTimeout(function() {
    setCardAnimation('');
  }, 700);

  const loginBexio = () => {
    const backendUrl = config.backendUrl;
    fetch(backendUrl + '/auth/login/bexio', {
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => {
        return response.json();
      })
      .then(oAuthInfos => {
        const cookies = new Cookies();
        cookies.set('bexioAuthValidation', oAuthInfos.codeVerifier, { path: '/' });
        cookies.set('flow', 'login', { path: '/' });
        return oAuthInfos.url;
      })
      .then(url => {
        window.open(url, '_self');
      });
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card className={classes[cardAnimaton]}>
              <CardHeader className={classes.cardHeaderBlank}>
                <h4 className={classes.cardName}>bexioSync</h4>
              </CardHeader>
              <CardFooter className={classes.noFlex}>
                <div className={classes.fullWidth}>
                  <Button color={themeColor} size="lg" block onClick={() => loginBexio()}>
                    {translate('login.login-bexio')}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withLocalize(BexioLoginPage);
