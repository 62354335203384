import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'ENFORE';
export const GET_PAYMENT_METHODS = createRequestTypes(`${filePrefix}_GET_PAYMENT_METHODS`);
export const GET_SALES_CATEGORIES = createRequestTypes(`${filePrefix}_GET_SALES_CATEGORIES`);

export const getPaymentMethodsRequest = (data: any) => ({
  type: GET_PAYMENT_METHODS.REQUEST,
  data,
});
export const getPaymentMethodsSuccess = (data: any) => ({
  type: GET_PAYMENT_METHODS.SUCCESS,
  data,
});
export const getPaymentMethodsFailure = (error: any) => ({
  type: GET_PAYMENT_METHODS.FAILURE,
  error,
});

export const getSalesCategoriesRequest = (data: any) => ({
  type: GET_SALES_CATEGORIES.REQUEST,
  data,
});
export const getSalesCategoriesSuccess = (data: any) => ({
  type: GET_SALES_CATEGORIES.SUCCESS,
  data,
});
export const getSalesCategoriesFailure = (error: any) => ({
  type: GET_SALES_CATEGORIES.FAILURE,
  error,
});
