// global imports
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import _ from 'lodash';
import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import {
  getSettingsRequest,
  putSettingsRequest as putSettingsRequestAction,
} from '../../../../../../store/actions/common_actions';
import { IState } from '../../../../../../store/reducers';
import { ForceManagerSettings } from '../../../../../../types/ForceManagerSettings';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const AuthCreds = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { forcemanagerSettings, putSettingsRequest, translate } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<ForceManagerSettings | null>(forcemanagerSettings);

  const delayedInput = useRef(
    _.debounce(
      (field, value) => dispatch(putSettingsRequest({ system: 'forcemanager', data: { ...settings, [field]: value } })),
      1000,
    ),
  ).current;

  const handleValueChange = (field: 'apiKey' | 'privateKey') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  useEffect(() => {
    setSettings(forcemanagerSettings);
  }, [forcemanagerSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('forcemanager.creds') as string}
          desc={translate('forcemanager.creds-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('forcemanager.api-key')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('forcemanager.api-key') as string}
                  className={classes.w100}
                  value={settings ? settings.apiKey : ''}
                  onChange={handleValueChange('apiKey')}
                  disabled={!forcemanagerSettings}
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('forcemanager.private-key')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('forcemanager.private-key') as string}
                  className={classes.w100}
                  value={settings ? settings.privateKey : ''}
                  onChange={handleValueChange('privateKey')}
                  disabled={!forcemanagerSettings}
                />
              </Grid>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  forcemanagerSettings: state.forcemanager.settings,
});

const mapDispatchToProps = {
  getSettingsRequest,
  putSettingsRequest: putSettingsRequestAction,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthCreds),
);
