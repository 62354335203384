// global imports
import { Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as gastrofixActions from '../../../../store/actions/gastrofix_actions';
// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { toast } from 'react-toastify';
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const ImportByDate: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const [selectedDate, setSelectedDate] = useState(
    moment()
      .subtract(1, 'day')
      .toDate(),
  );
  const { translate, importTransactionsRequest } = props;

  const DateSelectBlock = () => (
    <div
      className={cx({
        [classes.selectSmall]: true,
        [classes.contentContainer]: true,
        [classes.flexCenter]: true,
        [classes.widthAuto]: true,
      })}
    >
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={selectedDate}
        onChange={(date: any) => setSelectedDate(date)}
      />
    </div>
  );

  const importTransactions = () => {
    importTransactionsRequest({ date: selectedDate });
    toast.success(translate('import-order-success') as string);
  };

  return (
    <>
      <h3 className={cx(classes.bodyLg, classes.boldTitle)}>{translate('import_transactions_by_date')}</h3>
      <Grid container={true}>
        <Grid item={true} style={{ padding: 10 }}>
          <InputLabel style={{ marginBottom: 10 }}>{translate('date')}</InputLabel>
          <DateSelectBlock />
        </Grid>
        <Grid item={true} style={{ padding: 10, paddingTop: 30 }}>
          <Button className={classes.formButton} type="button" color="primary" onClick={importTransactions}>
            {translate('import')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const defaultProps: OwnProps = {
  testConnection: false,
  testConnectionTimeout: 3000,
  showSpinner: false,
};
ImportByDate.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  gastrofixSettings: state.gastrofix.settings,
});

const mapDispatchToProps = {
  importTransactionsRequest: gastrofixActions.importTransactionsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ImportByDate));
