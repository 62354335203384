import React from 'react';
import { CircularProgress  } from '@material-ui/core';
import { searchBexioProduct, getBexioProduct } from '../../../store/api/bexio';
import { BexioProduct } from '../../../types/BexioSettings';
import { IOptionType } from '../../../types/ReactSelect';
import Search from '../../Search/Search';

type Loading = 'initial' | 'pending' | 'ready' | 'error';

function useProductSearch(searchTerm: string): [Loading, BexioProduct[]] {
  const [loading, setLoading] = React.useState<Loading>('initial');
  const [results, setResults] = React.useState<BexioProduct[]>([]);
  React.useEffect(() => {
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      setLoading('pending');
      searchBexioProduct({ searchTerm }).then(response => {
        setResults(response.data as BexioProduct[]);
        setLoading('ready');
      }).catch(() => {
        setLoading('error');
      });
    } else {
      setLoading('ready');
    }
  }, [searchTerm, setLoading, setResults]);
  return [loading, results];
}

function useFetchProduct(productId: number | undefined): [Loading, BexioProduct | null] {
  const [loading, setLoading] = React.useState<Loading>('initial');
  const [results, setResults] = React.useState<BexioProduct | null>(null);
  React.useEffect(() => {
    if (productId) {
      setLoading('pending');
      getBexioProduct({ productId: '' + productId }).then(response => {
        setResults(response.data as BexioProduct);
        setLoading('ready');
      }).catch(() => {
        setLoading('error');
      });
    } else {
      setResults(null);
      setLoading('ready');
    }
  }, [productId, setLoading, setResults]);
  return [loading, results];
}

export function ProductSearch({
  productId,
  onChange,
  title,
}: {
  productId?: number;
  onChange: (value: IOptionType | null) => void;
  title: string;
}) {
  const [productList, setProductList] = React.useState<IOptionType[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [selectedProduct, setSelectedProduct] = React.useState<IOptionType | null>(null);

  const [loadingProducts, bexioProducts] = useProductSearch(searchTerm);
  const [loadingProduct, bexioProduct] = useFetchProduct(productId);

  React.useEffect(() => {
    if (loadingProducts === 'ready') {
      setProductList(
        bexioProducts.map((product: BexioProduct) => ({
          value: '' + product.id,
          label: product.name,
        })),
      );
    }
  }, [loadingProducts, bexioProducts, setProductList]);

  React.useEffect(() => {
    if (bexioProduct) {
      setSelectedProduct({
        label: bexioProduct.name,
        value: '' + bexioProduct.id,
      })
    } else {
      setSelectedProduct(null);
    }
  }, [bexioProduct, setSelectedProduct]);

  const clearTerm = () => setSearchTerm('');

  return (
    loadingProducts === 'ready' && loadingProduct === 'ready' ? (
      <Search
        data={productList}
        placeholder={title}
        resultClicked={(value: IOptionType | null) => {
          setSelectedProduct(value);
          onChange(value);
        }}
        termChange={setSearchTerm}
        currentValue={selectedProduct}
        searchTerm={searchTerm}
        clearTerm={clearTerm}
        icon={null}
      />
    ) : <CircularProgress />
  );
}
