import createRequestTypes from '../../services/requestsTypes';

export const TEST_CONNECTION = createRequestTypes(`TEST_CONNECTION`);
export const DISCONNECT = createRequestTypes(`DISCONNECT`);
export const GET_SETTINGS = createRequestTypes(`GET_SETTINGS`);
export const PUT_SETTINGS = createRequestTypes(`PUT_SETTINGS`);
export const RUN_JOB = createRequestTypes(`RUN_JOB`);
export const GET_JOB = createRequestTypes(`GET_JOB`);

export const testConnectionRequest = (data: any) => ({
  type: TEST_CONNECTION.REQUEST,
  data,
});
export const testConnectionSuccess = (data: any) => ({
  type: TEST_CONNECTION.SUCCESS,
  data,
});
export const testConnectionFailure = (data: any) => ({
  type: TEST_CONNECTION.FAILURE,
  data,
});

export const disconnectRequest = (data: any) => ({
  type: DISCONNECT.REQUEST,
  data,
});
export const disconnectSuccess = (data: any) => ({
  type: DISCONNECT.SUCCESS,
  data,
});
export const disconnectFailure = (data: any) => ({
  type: DISCONNECT.FAILURE,
  data,
});

export const getSettingsRequest = (data: any) => ({
  type: GET_SETTINGS.REQUEST,
  data,
});
export const getSettingsSuccess = (data: any) => ({
  type: GET_SETTINGS.SUCCESS,
  data,
});
export const getSettingsFailure = (data: any) => ({
  type: GET_SETTINGS.FAILURE,
  data,
});

export const putSettingsRequest = (data: any) => ({
  type: PUT_SETTINGS.REQUEST,
  data,
});
export const putSettingsSuccess = (data: any) => ({
  type: PUT_SETTINGS.SUCCESS,
  data,
});
export const putSettingsFailure = (data: any) => ({
  type: PUT_SETTINGS.FAILURE,
  data,
});

export const runJobRequest = (data: any) => ({
  type: RUN_JOB.REQUEST,
  data,
});
export const runJobSuccess = (data: any) => ({
  type: RUN_JOB.SUCCESS,
  data,
});
export const runJobFailure = (data: any) => ({
  type: RUN_JOB.FAILURE,
  data,
});

export const getJobRequest = (data: any) => ({
  type: GET_JOB.REQUEST,
  data,
});
export const getJobSuccess = (data: any) => ({
  type: GET_JOB.SUCCESS,
  data,
});
export const getJobFailure = (data: any) => ({
  type: GET_JOB.FAILURE,
  data,
});
