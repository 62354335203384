// global imports
import { FormControlLabel, Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import * as commonActions from '../../../../store/actions/common_actions';
import * as gastrofixAction from '../../../../store/actions/gastrofix_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Button from '../../../../components/CustomButtons/Button';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { IOptionType } from '../../../../types/ReactSelect';
import { DispatchProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const DebitAccounts = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, gastrofixSettings, debitAccounts, getAccountsRequest, putSettingsRequest, isFetching } = props;

  const [debitCards, setDebitAccounts] = useState(gastrofixSettings.debitCards);
  const [paymentMethods, setPaymentMethods] = useState(gastrofixSettings.debitCards.map(el => el.paymentMethod));

  useEffect(() => {
    if (gastrofixSettings.id) {
      getAccountsRequest({});
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (_.isEqual(debitCards, gastrofixSettings.debitCards)) {
      return;
    }
    for (const account of debitCards) {
      if (account.paymentMethod.length === 0 || account.debitAccountNumber.length === 0) {
        return;
      }
    }

    const newSettings = _.cloneDeep(gastrofixSettings);
    newSettings.debitCards = debitCards;

    putSettingsRequest({ system: 'gastrofix', data: newSettings });
  }, [debitCards]);

  useEffect(() => {
    setDebitAccounts(gastrofixSettings.debitCards);
    setPaymentMethods(gastrofixSettings.debitCards.map(el => el.paymentMethod));
  }, [gastrofixSettings.debitCards]);

  const delayedInput = useRef(
    _.debounce((currentCards, key, value) => {
      setDebitAccounts([
        ...currentCards.slice(0, key),
        {
          ...currentCards[key],
          paymentMethod: value,
        },
        ...currentCards.slice(key + 1),
      ]);
    }, 1000),
  ).current;

  const debitAccountChange = (key: number, newAccount: IOptionType) => {
    setDebitAccounts([
      ...debitCards.slice(0, key),
      {
        ...debitCards[key],
        debitAccountNumber: newAccount.value,
      },
      ...debitCards.slice(key + 1),
    ]);
  };

  const paymentMethodChange = (key: number, newValue: string) => {
    setPaymentMethods([...paymentMethods.slice(0, key), newValue, ...paymentMethods.slice(key + 1)]);
    delayedInput(debitCards, key, newValue);
  };

  const addCard = () => {
    setDebitAccounts([
      ...debitCards,
      {
        paymentMethod: '',
        debitAccountNumber: '',
        isDefault: !hasDefault(),
      },
    ]);

    setPaymentMethods([...paymentMethods, '']);
  };

  const removeDebitCard = (key: number) => {
    const newDebitCards = [...debitCards.slice(0, key), ...debitCards.slice(key + 1)];
    if (debitCards[key].isDefault && newDebitCards[0]) {
      newDebitCards[0].isDefault = true;
    }

    setDebitAccounts(newDebitCards);
    setPaymentMethods([...paymentMethods.slice(0, key), ...paymentMethods.slice(key + 1)]);
  };

  const toggleCardDefault = (key: number) => {
    const checked = debitCards[key].isDefault;

    if (checked) {
      return;
    }

    setDebitAccounts(
      debitCards.map((card, index) => ({
        ...card,
        isDefault: key === index ? !card.isDefault : false,
      })),
    );
  };

  const hasDefault = () => {
    for (const card of debitCards) {
      if (card.isDefault) {
        return true;
      }
    }
    return false;
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('gastrofix.debit_accounts') as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard style={{ display: 'block' }}>
          {debitCards.map((card, key) => (
            <div
              key={key}
              className={cx(
                classes.contentContainer,
                classes.mt10,
                card.debitAccountNumber.length === 0 || card.paymentMethod.length === 0 ? classes.errorBLeft : '',
              )}
            >
              <Grid md={3} style={{ padding: 10 }}>
                <InputLabel className={classes.mb10}>{translate('gastrofix.payment_method')}</InputLabel>
                <TextField
                  placeholder={translate('gastrofix.payment_method') as string}
                  value={paymentMethods[key]}
                  onChange={(event: any) => paymentMethodChange(key, event.target.value)}
                />
              </Grid>
              <Grid md={3} style={{ padding: 10 }}>
                <InputLabel className={classes.mb10}>{translate('gastrofix.debit_account')}</InputLabel>
                <Select
                  isDisabled={isFetching}
                  options={debitAccounts.map(acc => ({ label: acc.name, value: acc.number }))}
                  value={debitAccounts
                    .map(acc => ({ label: acc.name, value: acc.number }))
                    .find(acc => (acc ? acc.value === card.debitAccountNumber : false))}
                  onChange={(value: ValueType<IOptionType, false>) => debitAccountChange(key, value as IOptionType)}
                />
              </Grid>
              å
              <Grid>
                <FormControlLabel
                  control={<Checkbox onChange={() => toggleCardDefault(key)} checked={card.isDefault} />}
                  style={{
                    marginTop: 35,
                    marginLeft: 10,
                  }}
                  label={translate('default')}
                />
              </Grid>
              <Grid>
                <Button
                  style={{ marginTop: 35 }}
                  justIcon={true}
                  round={true}
                  color="transparent"
                  onClick={() => removeDebitCard(key)}
                >
                  <DeleteIcon fontSize="large" />
                </Button>
              </Grid>
            </div>
          ))}
          <Button className={classes.addButton} justIcon={true} round={true} onClick={addCard}>
            <AddIcon />
          </Button>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  debitAccounts: state.gastrofix.accounts.debit,
  gastrofixSettings: state.gastrofix.settings,
  isFetching: state.gastrofix.isFetching,
});

const mapDispatchToProps = {
  getAccountsRequest: gastrofixAction.getAccountsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default connect<StateProps, DispatchProps, {}, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(DebitAccounts));
