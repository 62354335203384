// global imports
import { Grid } from '@material-ui/core';
import React from 'react';

import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import TransferDesc from '../../BexioWidgets/TransferDesc/TransferDesc';
import TransferSalesPrice from '../../BexioWidgets/TransferSalesPrice/TransferSalesPrice';
import TransferVendor from '../../BexioWidgets/TransferVendor/TransferVendor';
import WriteBackStockAmount from '../../BexioWidgets/WriteBackStockAmount/WriteBackStockAmount';

const ProductSettings = (props: { system: 'lightspeed' | 'prestashop' } & LocalizeContextProps) => {
  const { translate, system } = props;

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate(`${system}.options`) as string} desc={translate(`${system}.options-desc`) as string} />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div style={{ width: '100%' }}>
            <TransferDesc />
            <TransferSalesPrice />
            <TransferVendor />
            {(system === 'lightspeed' || system === 'prestashop') && <WriteBackStockAmount system={system} />}
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize<{ system: 'lightspeed' | 'prestashop' } & LocalizeContextProps>(ProductSettings);
