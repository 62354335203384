import { Grid, makeStyles, TextField, Theme, FormControlLabel, Checkbox } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import commonStyles from '../../../assets/jss/commonStyles';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import FormCard from '../../../components/FormCard/FormCard';
import * as commonActions from '../../../store/actions/common_actions';
import { IState } from '../../../store/reducers';
import { GlsSettings } from '../../../types/GlsSettings';
import { IOptionType } from '../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../types/StyleProps';

type GlsWidgetProps = LocalizeContextProps;

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

function GlsWidget(props: GlsWidgetProps) {
  const dispatch = useDispatch();
  const glsSettings = useSelector<IState, GlsSettings>(state => state.gls.settings);
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [settings, setSettings] = React.useState<GlsSettings | null>(glsSettings);

  const countryOptions: IOptionType[] = [
    { label: translate('serviceAccounts.gls.switzerland') as string, value: 'ch' },
    { label: translate('serviceAccounts.gls.greatbritain') as string, value: 'gb' },
  ];

  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'gls' }));
  }, []);

  React.useEffect(() => {
    setSettings(glsSettings);
  }, [glsSettings]);

  const delayedInput = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'gls', data: { [field]: value } }));
    }, 1000),
  ).current;

  const handleValueChange = (field: 'enabled' | 'key' | 'secret') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  const handleValueChangeCountries = (newValue: string[]) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, 'countries': newValue });
    delayedInput('countries', newValue);
  };

  const handleValueChangeEnabled = (newValue: boolean) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, 'enabled': newValue });
    delayedInput('enabled', newValue);
  };

  /*React.useEffect(() => {
    setSettings(enforeSettings);
  }, [enforeSettings]);*/
  return (
    <FormCard>
      <div className={classes.w100}>
        <Grid container={true} style={{ marginBottom: 10 }}>
          <Grid item={true} md={3} sm={3}>
            <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.gls.enabled')}</span>
          </Grid>
          <Grid item={true} md={9} sm={9}>
            <FormControlLabel
              control={<Checkbox color="primary" style={{paddingTop: 0}} />}
              classes={{
                label: classes.label,
                root: classes.checkbox,
              }}
              label=""
              checked={settings ? !!settings.enabled : false}
              onChange={(x: any) => handleValueChangeEnabled(x.target.checked)}
              disabled={false /*!enabled*/}
            />
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginBottom: 10 }}>
          <Grid item={true} md={3} sm={3}>
            <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.gls.key')}</span>
          </Grid>
          <Grid item={true} md={9} sm={9}>
            <TextField
              placeholder={translate('serviceAccounts.gls.key') as string}
              className={classes.w100}
              value={settings ? settings.key : ''}
              onChange={handleValueChange('key')}
              disabled={false /*!enforeSettings*/}
            />
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginBottom: 10 }}>
          <Grid item={true} md={3} sm={3}>
            <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.gls.secret')}</span>
          </Grid>
          <Grid item={true} md={9} sm={9}>
            <TextField
              placeholder={translate('serviceAccounts.gls.secret') as string}
              className={classes.w100}
              value={settings ? settings.secret : ''}
              onChange={handleValueChange('secret')}
              disabled={false /*!enforeSettings*/}
            />
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginBottom: 10 }}>
          <Grid item={true} md={3} sm={3}>
            <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.gls.countries')}</span>
          </Grid>
          <Grid item={true} md={9} sm={9}>
            <Select
              className={classes.w100}
              isSearchable={false}
              options={countryOptions}
              value={settings?.countries?.map(code => countryOptions.find(x => x.value === code)).filter(x => x !== undefined) as IOptionType[] ?? []}
              onChange={opt => {
                handleValueChangeCountries(opt ? opt.map(x => x.value) : []);
              }}
              isDisabled={false /*isDisabled*/}
              isMulti={true}
              styles={colourStyles}
            />
          </Grid>
        </Grid>
      </div>
    </FormCard>
  );
}

export default withLocalize(GlsWidget);
