// global imports
import cx from 'classnames';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import { loginFinished } from '../../store/actions/auth_actions';
import { getDeliveryAddressesRequest, getDeliveryDataRequest } from '../../store/actions/delivery_actions';

// project types imports
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ReactComponent as IconDpd } from '../../assets/img/dpd.svg';
import { ReactComponent as IconPlanzer } from '../../assets/img/planzer.svg';
import { ReactComponent as IconPostCH } from '../../assets/img/post-ch.svg';
import { DpdDialog } from './DpdDialog';
import { PlanzerDialog } from './PlanzerDialog';
import Subscription from './Subscription';
import { useStyles } from './theme';
import { Props, StateProps } from './types';
import { useDpdSettings } from './useDpdSettings';
import { usePlanzerSettings } from './usePlanzerSettings';

function getDateFormatPattern(formatter: Intl.DateTimeFormat) {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'd'.repeat(part.value.length);
      case 'month':
        return 'M'.repeat(part.value.length);
      case 'year':
        return 'y'.repeat(part.value.length);
      case 'literal':
        return part.value;
    }
  };

  return formatter
    .formatToParts(new Date('2024-01-01'))
    .map(getPatternForPart)
    .join('');
}

const DeliveryPage = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    activeLanguage,
    loading,
    deliveryData,
    deliveryAddressesData,
    redirectPath,
    loginFinishedFunc,
    getDeliveryDataRequestFunc,
    getDeliveryAddressDataRequestFunc,
  } = props;

  const formatter = new Intl.DateTimeFormat(activeLanguage ? activeLanguage.code : 'de-CH', {});
  const dateFormat = getDateFormatPattern(formatter);

  const [dpdEnabled, dpdHasSubscription, dpdCheckout] = useDpdSettings();
  const [planzerEnabled, planzerHasSubscription, planzerCheckout] = usePlanzerSettings();

  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const [localDeliveryData, setLocalDeliveryData] = useState<any>(null);
  useEffect(() => {
    if (deliveryData) {
      setLocalDeliveryData(deliveryData.map((x: object) => ({ ...x })));
    }
  }, [deliveryData]);

  const exportToCSV = (csvData: any, fileName: string) => {
    const heading = [['Firma', 'Name', 'Strasse', 'PLZ', 'Ort']];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, heading);
    XLSX.utils.sheet_add_json(ws, csvData, { origin: 'A2', skipHeader: true });

    const wb = { Sheets: { adressen: ws }, SheetNames: ['adressen'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (redirectPath) {
      loginFinishedFunc();
    }
  }, [redirectPath]);
  useEffect(() => {
    getDeliveryDataRequestFunc({
      start: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
    });
  }, []);
  useEffect(() => {
    if (startDate && endDate && moment(startDate).isValid() && moment(endDate).isValid()) {
      getDeliveryDataRequestFunc({
        start: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      });
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (deliveryAddressesData && addressLoading) {
      exportToCSV(deliveryAddressesData, 'delivery_export_swiss_post');
    }
  }, [deliveryAddressesData]);

  const [showDpdDialog, setShowDpdDialog] = useState<boolean>(false);
  const [selectedDpd, setSelectedDpd] = useState<string>('');
  const openDpdDialog = (ids: string) => {
    setSelectedDpd(ids);
    setShowDpdDialog(true);
  };
  const closeDpdDialog = () => {
    setShowDpdDialog(false);
    setSelectedDpd('');
  };

  const [showPlanzerDialog, setShowPlanzerDialog] = useState<boolean>(false);
  const [selectedPlanzer, setSelectedPlanzer] = useState<string>('');
  const openPlanzerDialog = (ids: string) => {
    setSelectedPlanzer(ids);
    setShowPlanzerDialog(true);
  };
  const closePlanzerDialog = () => {
    setShowPlanzerDialog(false);
    setSelectedPlanzer('');
  };

  return (
    <React.Fragment>
      <Subscription />
      <Card>
        <CardHeader color="primary" className={cx(classes.connectRow, classes.flexCenter)}>
          <h4 className={classes.cardTitleWhite}>{translate('delivery-manager.title')}</h4>
        </CardHeader>
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0.5em 0' }}>
            <DatePicker
              dateFormat={dateFormat}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              selectsStart={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText={translate('delivery-manager.start-date') as string}
            />
            <DatePicker
              dateFormat={dateFormat}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              selectsEnd={true}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText={translate('delivery-manager.end-date') as string}
            />
          </div>
          <MuiThemeProvider
            theme={createMuiTheme({
              palette: {
                primary: {
                  main: '#F45D00',
                },
                secondary: {
                  main: '#F45D00',
                },
              },
            })}
          >
            <MaterialTable
              columns={[
                {
                  title: translate('delivery-manager.columns.id') as string,
                  field: 'id',
                },
                {
                  title: translate('delivery-manager.columns.date') as string,
                  field: 'is_valid_from',
                  render: rowData => (rowData.is_valid_from ? formatter.format(new Date(rowData.is_valid_from)) : null),
                },
                {
                  title: translate('delivery-manager.columns.order-id') as string,
                  field: 'document_nr',
                },
                {
                  title: translate('delivery-manager.columns.address') as string,
                  field: 'delivery_address',
                },
              ]}
              data={localDeliveryData}
              title={translate('delivery-manager.table-title') as string}
              options={{
                selection: true,
              }}
              actions={[
                {
                  icon: (): React.ReactElement<any> => <IconPostCH height="1em" width="1em" />,
                  tooltip: translate('delivery-manager.actions.export-post-ch') as string,
                  onClick: (event, rowData) => {
                    const ids = [];
                    if (Array.isArray(rowData)) {
                      for (const row of rowData) {
                        ids.push(row.id);
                      }
                      setAddressLoading(true);
                      getDeliveryAddressDataRequestFunc(ids);
                      setLocalDeliveryData(deliveryData.map((x: object) => ({ ...x })));
                    }
                  },
                },
                {
                  icon: (): React.ReactElement<any> => <IconDpd height="1em" width="1em" />,
                  tooltip: translate('delivery-manager.actions.export-dpd') as string,
                  onClick: (event, rowData) => {
                    if (dpdHasSubscription) {
                      const ids = [];
                      if (Array.isArray(rowData)) {
                        for (const row of rowData) {
                          ids.push(row.id);
                        }
                        openDpdDialog(ids.join(','));
                        setLocalDeliveryData(deliveryData.map((x: object) => ({ ...x })));
                      }
                    } else {
                      dpdCheckout();
                    }
                  },
                  hidden: !dpdEnabled,
                },
                {
                  icon: (): React.ReactElement<any> => <IconPlanzer height="1em" width="1em" />,
                  tooltip: translate('delivery-manager.actions.export-planzer') as string,
                  onClick: (event, rowData) => {
                    if (planzerHasSubscription) {
                      const ids = [];
                      if (Array.isArray(rowData)) {
                        for (const row of rowData) {
                          ids.push(row.id);
                        }
                        openPlanzerDialog(ids.join(','));
                        setLocalDeliveryData(deliveryData.map((x: object) => ({ ...x })));
                      }
                    } else {
                      planzerCheckout();
                    }
                  },
                  hidden: !planzerEnabled,
                },
              ]}
              isLoading={loading}
              localization={{
                body: {
                  emptyDataSourceMessage: translate('delivery-manager.no-records') as string,
                },
                pagination: {
                  labelDisplayedRows: translate('delivery-manager.pagination') as string,
                  labelRowsSelect: translate('delivery-manager.rows') as string,
                  labelRowsPerPage: translate('delivery-manager.rows-per-page') as string,
                  firstAriaLabel: translate('delivery-manager.first-page') as string,
                  firstTooltip: translate('delivery-manager.first-page') as string,
                  lastAriaLabel: translate('delivery-manager.last-page') as string,
                  lastTooltip: translate('delivery-manager.last-page') as string,
                  previousAriaLabel: translate('delivery-manager.previous-page') as string,
                  previousTooltip: translate('delivery-manager.previous-page') as string,
                  nextAriaLabel: translate('delivery-manager.next-page') as string,
                  nextTooltip: translate('delivery-manager.next-page') as string,
                },
                toolbar: {
                  nRowsSelected: translate('delivery-manager.rows-selected') as string,
                  searchTooltip: translate('delivery-manager.search') as string,
                  searchPlaceholder: translate('delivery-manager.search') as string,
                },
              }}
            />
          </MuiThemeProvider>
          <DpdDialog selected={selectedDpd} show={showDpdDialog} onClose={closeDpdDialog} translate={translate} />
          <PlanzerDialog
            selected={selectedPlanzer}
            show={showPlanzerDialog}
            onClose={closePlanzerDialog}
            translate={translate}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  deliveryData: state.delivery.deliveryData,
  deliveryAddressesData: state.delivery.deliveryAddresses,
  loading: state.protocol.isFetching || state.dpd.isFetching || state.planzer.isFetching,
  redirectPath: state.auth.redirectPath,
});

const mapDispatchToProps = {
  getDeliveryDataRequestFunc: getDeliveryDataRequest,
  getDeliveryAddressDataRequestFunc: getDeliveryAddressesRequest,
  loginFinishedFunc: loginFinished,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DeliveryPage),
);
