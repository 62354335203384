import { clientApp } from './index';

export const getProtocolData = ({
  sortField = 'started',
  sortOrder = 'desc',
  page = 1,
  resultsPerPage = 10,
  topicFilter = '',
  exitStateFilter = '',
  objectNameFilter = '',
}) => {
  if (sortField === 'source') sortField = 'source.system';
  if (sortField === 'shop') sortField = 'source.shop';
  return clientApp.get(
    `/ui/user/protocol?sortField=${sortField}&sortOrder=${sortOrder}&page=${page}&resultsPerPage=${resultsPerPage}&topicFilter=${
      topicFilter ? topicFilter : ''
    }&exitStateFilter=${exitStateFilter ? exitStateFilter : ''}&objectNameFilter=${objectNameFilter ? objectNameFilter : ''}`,
  );
}

export const reRunProtocol = (protocolId: string) => clientApp.get(`/ui/user/rerun/${protocolId}`);

export const getProtocolExitStates = () => clientApp.get('/ui/user/protocol/exitstates');

export const getProtocolTopics = () => clientApp.get('/ui/user/protocol/topics');

export const getProtocolLogs = (protocolId: string, token: string, {
    sortField = 'date',
    sortOrder = 'desc',
    page = 1,
    resultsPerPage = 10,
}: {
    sortField?: string;
    sortOrder?: string;
    page?: number;
    resultsPerPage?: number;
}) => {
    const params = new URLSearchParams({ sortField, sortOrder, page: '' + page, resultsPerPage: '' + resultsPerPage });
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return clientApp.get(`/ui/user/admin/protocol/${protocolId}/logs?${params}`, config);
}

export const getProtocolPayload = (protocolId: string, token: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return clientApp.get(`/ui/user/admin/protocol/${protocolId}/payload`, config);
}

export const getProtocolCanonical = (protocolId: string, token: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return clientApp.get(`/ui/user/admin/protocol/${protocolId}/canonical`, config);
}
