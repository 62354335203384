import React from 'react';
import { connect } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { IState } from '../../../store/reducers';
import * as authActions from '../../../store/actions/auth_actions';
import * as commonActions from '../../../store/actions/common_actions';
import ConnectWidget from '../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../components/DisconnectWidget/DisconnectWidget';

type StateProps = {
  isFetching: boolean;
  connectionStatus: any;
  OAuthURL: string;
};

type DispatchProps = {
  fetchOAuthUrlRequest: (data: any) => any;
  testConnectionRequest: (data: any) => any;
};

type PingenWidgetProps = StateProps & DispatchProps & LocalizeContextProps;

function PingenWidget(props: PingenWidgetProps) {
  const {isFetching, connectionStatus, fetchOAuthUrlRequest, testConnectionRequest, OAuthURL} = props;
  React.useEffect(() => {
    testConnectionRequest({ system: 'pingen' });
  }, []);
  React.useEffect(() => {
    if (!OAuthURL) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'pingen' });
      }
    }
  }, [connectionStatus]);
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        {isFetching  ? <CircularProgress /> : (
          connectionStatus === 'ok' ? (
            <DisconnectWidget appName="pingen" />
          ) : (
            <ConnectWidget appName="pingen" />
          )
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  isFetching: state.pingen.isFetching,
  connectionStatus: state.pingen.connectionStatus,
  OAuthURL: state.pingen.OAuthURL,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PingenWidget)
);
