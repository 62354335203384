import moment from 'moment';
import { ActionPattern, all, call, delay, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
  DISCONNECT,
  disconnectFailure,
  disconnectSuccess,
  GET_JOB,
  GET_SETTINGS,
  getJobFailure,
  getJobSuccess,
  getSettingsFailure,
  getSettingsRequest,
  getSettingsSuccess,
  PUT_SETTINGS,
  putSettingsFailure,
  putSettingsSuccess,
  RUN_JOB,
  runJobFailure,
  TEST_CONNECTION,
  testConnectionFailure,
  testConnectionSuccess,
} from '../actions/common_actions';
import { disconnect, getJob, getSettings, putSettings, runJob, testConnection } from '../api/common';

function* testConnectionSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(testConnection, action.data);
    yield put(testConnectionSuccess({ ...data, system: action.data.system }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(testConnectionFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* disconnectSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(disconnect, action.data.system);
    yield put(disconnectSuccess({ ...data, system: action.data.system }));
    yield put(getSettingsRequest({ system: action.data.system }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(disconnectFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* getSettingsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getSettings, action.data);
    yield put(getSettingsSuccess({ data, system: action.data.system, type: action.data.type }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getSettingsFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* putSettingsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(putSettings, action.data);
    yield put(putSettingsSuccess({ data, system: action.data.system, type: action.data.type }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(putSettingsFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* runJobSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const {
      data: { jobNumber },
    } = yield call(runJob, {
      system: action.data.system,
      from: moment(action.data.from).format('YYYY-MM-DD'),
      to: moment(action.data.to).format('YYYY-MM-DD'),
    });
    const { data } = yield call(getJob, jobNumber);
    yield put(getJobSuccess({ system: action.data.system, data, jobNumber }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(runJobFailure({ system: action.data.system, error: processedError || error }));
    yield put(getJobFailure({ system: action.data.system, error: processedError || error }));
  }
}
function* getJobSaga(action: BedayaAction) {
  yield delay(500);
  try {
    // console.log(action.data);
    const { data } = yield call(getJob, action.data.jobNumber);
    yield put(getJobSuccess({ system: action.data.system, data, jobNumber: action.data.jobNumber }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getJobFailure({ error: processedError || error }));
  }
}
function* testConnectionWatcher() {
  yield takeEvery(TEST_CONNECTION.REQUEST as ActionPattern, testConnectionSaga);
}
function* disconnectWatcher() {
  yield takeLatest(DISCONNECT.REQUEST as ActionPattern, disconnectSaga);
}
function* getSettingsWatcher() {
  yield takeEvery(GET_SETTINGS.REQUEST as ActionPattern, getSettingsSaga);
}
function* putSettingsWatcher() {
  yield takeLatest(PUT_SETTINGS.REQUEST as ActionPattern, putSettingsSaga);
}
function* runJobWatcher() {
  yield takeLatest(RUN_JOB.REQUEST as ActionPattern, runJobSaga);
}
function* getJobWatcher() {
  yield takeLatest(GET_JOB.REQUEST as ActionPattern, getJobSaga);
}

export default function* authSaga() {
  yield all([
    fork(testConnectionWatcher),
    fork(disconnectWatcher),
    fork(getSettingsWatcher),
    fork(putSettingsWatcher),
    fork(runJobWatcher),
    fork(getJobWatcher),
  ]);
}
