// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';

import { useDispatch, useSelector } from 'react-redux';

// project imports
import { getSettingsRequest, putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Grid, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import PopupDialog from '../../../../components/PopupDialog/PopupDialog';
import { Props, StateProps } from './types';

const SendInvoice = (props: Props) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);
  const hasEmailSettings = useSelector<IState, boolean>(state => state.email.settings.invoice.recipient !== '');

  const [sendInvoice, setSendInvoice] = useState(bexioSettings ? bexioSettings.sendInvoice : true);
  const [disabledShown, setDisabledShown] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'email', type: 'invoice' }));
  }, [dispatch, getSettingsRequest]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    if (sendInvoice === bexioSettings.sendInvoice) {
      return;
    }
    dispatch(putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, sendInvoice } }));
  }, [sendInvoice]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    setSendInvoice(bexioSettings.sendInvoice);
  }, [bexioSettings]);

  const onChange = (ev: React.ChangeEvent) => {
    if (!hasEmailSettings) {
      setDisabledShown(true);
      return;
    }
    setSendInvoice(!sendInvoice);
  };

  return (
    bexioSettings && (
      <Grid container={true} style={{ alignItems: 'center' }}>
        <Grid item={true} md={4}>
          <InputLabel>{translate('bexio.invoice')}</InputLabel>
        </Grid>
        <Grid item={true} md={8}>
          <StyledSwitch checked={sendInvoice} onChange={onChange} disabled={!bexioSettings} />
        </Grid>
        <PopupDialog
          title={translate('errors.errors') as string}
          open={disabledShown}
          handleClose={() => setDisabledShown(false)}
        >
          <Typography gutterBottom={true} style={{ marginBottom: 20 }}>
            {translate('bexio.invoice-disabled')}
          </Typography>
        </PopupDialog>
      </Grid>
    )
  );
};

export default withLocalize(SendInvoice);
