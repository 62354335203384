import { BitrixSettings } from '../../types/BitrixSettings';

import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { GET_FIELDS } from '../actions/bitrix_actions';
import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';

export interface IBitrixState {
  settings: BitrixSettings;
  fields: {
    product: Array<{
      value: string;
      label: string;
    }>;
    order: Array<{
      value: string;
      label: string;
    }>;
    company: Array<{
      value: string;
      label: string;
    }>;
  };

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;
}

const initialState: IBitrixState = {
  settings: {
    sourceSystem: null,
    productToBitrix: false,
    bitrixUrl: null,

    productMappings: [],
    orderMappings: [],
    companyMappings: [],
    orderUrlField: null,
    orderPdfField: null,
    orderStatusField: null,
    orderInvoiceStatusField: null,
    orderInvoiceDateField: null,
    orderInvoiceUrlField: null,
    orderInvoicePdfField: null,
    orderVatField: null,
    dummyEmail: null,
    bexioOrderAfterTrigger: false,
    bexioOrderPoller: false,
    bexioInvoicePoller: false,
  },
  fields: {
    product: [],
    order: [],
    company: [],
  },

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'loading',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',
};

export default function reducer(state: IBitrixState = initialState, action: any): IBitrixState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['bitrix'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['bitrix'].includes(action.data.system)) {
        console.log('Getting settings');

        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['bitrix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    case GET_FIELDS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FIELDS.SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.fieldType]: action.data,
        },
        isFetching: false,
      };
    case GET_FIELDS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
