import React, { useState } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import InfoArea from '../../components/InfoArea/InfoArea';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';

import styles from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import { bedayaRegisterRequest } from '../../store/actions/auth_actions';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

const useStyles = makeStyles(styles);

function RegisterPage(props) {
  const [checked, setChecked] = React.useState([]);
  const [password, setPassword] = useState('');
  const [email, setEMail] = useState('');
  const [company, setCompany] = useState('');
  const [firstName, setFirstName] = useState('');
  const [name, setName] = useState('');
  const [gtc, setGTC] = useState(false);
  const { translate } = props;

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setGTC(value === 1);
  };

  const handleEMailInput = event => {
    setEMail(event.target.value);
  };

  const handlePasswordInput = event => {
    setPassword(event.target.value);
  };

  const handleCompanyInput = event => {
    setCompany(event.target.value);
  };

  const handleFirstNameInput = event => {
    setFirstName(event.target.value);
  };

  const handleNameInput = event => {
    setName(event.target.value);
  };

  const registerBedaya = () => {
    props.bedayaRegisterRequest({
      email,
      password,
      firstName,
      name,
      company,
      gtc,
    });
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title={translate('register.info-1.title')}
                    description={translate('register.info-1.text')}
                    icon={Timeline}
                    iconColor="rose"
                  />
                  <InfoArea
                    title={translate('register.info-2.title')}
                    description={translate('register.info-2.text')}
                    icon={Code}
                    iconColor="primary"
                  />
                  <InfoArea
                    title={translate('register.info-3.title')}
                    description={translate('register.info-3.text')}
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <form className={classes.form}>
                    <CustomInput
                      id="company"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <BusinessIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: '' + translate('register.company') + '...',
                        onChange: handleCompanyInput,
                      }}
                      error={!!props.error}
                    />
                    <CustomInput
                      id="firstname"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: '' + translate('register.firstname') + '...',
                        onChange: handleFirstNameInput,
                      }}
                      error={!!props.error}
                    />
                    <CustomInput
                      id="lastname"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: '' + translate('register.lastname') + '...',
                        onChange: handleNameInput,
                      }}
                      error={!!props.error}
                    />
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Email...',
                        onChange: handleEMailInput,
                      }}
                      error={!!props.error}
                    />
                    <CustomInput
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        placeholder: '' + translate('register.password') + '...',
                        onChange: handlePasswordInput,
                      }}
                      error={!!props.error}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          error={!!props.error}
                        />
                      }
                      label={
                        <span>
                          {translate('register.agree')}{' '}
                          <a
                            href="http://www.bedaya.es/wp-content/uploads/2020/03/Bedaya-AGB-1.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.link}
                          >
                            {translate('register.gtc')}
                          </a>
                          {translate('register.to')}.
                        </span>
                      }
                    />
                    <div className={classes.submitContainer}>
                      <Button round color="primary" onClick={() => registerBedaya()}>
                        {translate('register.get-started')}
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => ({
  error: state.auth.errors.login,
});

const mapDispatchToProps = {
  bedayaRegisterRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RegisterPage),
);
