import moment from 'moment';
import { ActionPattern, all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_PAYMENT_METHODS,
  GET_GROUPS,
  IMPORT_ENTRIES,
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  getGroupsFailure,
  getGroupsSuccess,
  importEntriesFailure,
  importEntriesSuccess,
} from './../actions/xeniapos_actions';

import { BedayaAction } from '../../types/BedayaAction';
import { getPaymentMethods, getGroups, getGroupsMain, getGroupsUpper, importEntries } from './../api/xeniapos';

function* importEntriesSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(
      importEntries,
      moment(action.data.from).format('YYYY-MM-DD'),
      moment(action.data.to).format('YYYY-MM-DD'),
    );
    yield put(importEntriesSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importEntriesFailure(processedError || error));
  }
}

function* getPaymentMethodsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getPaymentMethods);
    yield put(getPaymentMethodsSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getPaymentMethodsFailure(processedError || error));
  }
}

function* getGroupsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getGroups);
    yield put(getGroupsSuccess({ data, groupType: 'group', done: false }));
    const { data: mainGroups } = yield call(getGroupsMain);
    yield put(getGroupsSuccess({ data: mainGroups, groupType: 'maingroup', done: false }));
    const { data: upperGroups } = yield call(getGroupsUpper);
    yield put(getGroupsSuccess({ data: upperGroups, groupType: 'uppergroup', done: true }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getGroupsFailure(processedError || error));
  }
}

function* importEntriesWatcher() {
  yield takeEvery(IMPORT_ENTRIES.REQUEST as ActionPattern, importEntriesSaga);
}

function* getPaymentMethodsWatcher() {
  yield takeEvery(GET_PAYMENT_METHODS.REQUEST as ActionPattern, getPaymentMethodsSaga);
}

function* getGroupsWatcher() {
  yield takeEvery(GET_GROUPS.REQUEST as ActionPattern, getGroupsSaga);
}

export default function* enforeSaga() {
  yield all([fork(getPaymentMethodsWatcher), fork(getGroupsWatcher), fork(importEntriesWatcher)]);
}
