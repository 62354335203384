// global imports
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';

// project imports
import { getSettingsRequest } from '../../../../../../store/actions/common_actions';
import ApiToken from '../../../../WeclappWidgets/ApiToken/ApiToken';
import CustomerSearch from '../../../../WeclappWidgets/CustomerSearch/CustomerSearch';
import Tenant from '../../../../WeclappWidgets/Tenant/Tenant';
import VatAccounts from '../../../../WeclappWidgets/VatAccounts/VatAccounts';

// project types imports

// local imports
import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import ShippingProductSearch from '../../../../WeclappWidgets/ShippingProductSearch/ShippingProductSearch';
import { Props } from './types';

const WeclappConfig = (props: Props) => {
  const dispatch = useDispatch();

  const { translate } = props;

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'weclapp' }));
  }, []);

  return (
    <>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title="Tenant" desc={translate('bexio.tentant-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <Tenant />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title="API Token" desc={translate('weclapp.token-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <ApiToken />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title="VAT" desc={translate('weclapp.vat-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <VatAccounts />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('default_customer') as string} desc={translate('weclapp.vat-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <CustomerSearch />
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label
            title={translate('bexio.shipping_products') as string}
            desc={translate('bexio.shipping_products-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <ShippingProductSearch />
          </FormCard>
        </Grid>
      </GridRow>
    </>
  );
};

export default withLocalize(WeclappConfig);
