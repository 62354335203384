import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { IOptionType } from '../../../types/ReactSelect';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import { SmsSettingsLevel } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


const languageOptions: IOptionType[] = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
];

type SmsLevelSectionProps = {
  enabled: boolean;
  settings: SmsSettingsLevel;
  setSettings: (data: SmsSettingsLevel) => void;
} & LocalizeContextProps;

function SmsLevelSection(props: SmsLevelSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const { notifications } = settings;
  const setNotifications = (newValue: any) => setSettings({ ...settings, notifications: {...notifications, ...newValue }});
  const classes = useDefaultStyles();
  const [language, setLanguage] = React.useState<string | undefined>(undefined);
  return (
    <Grid container={true} style={{marginBottom: 10, marginTop: 10}}>
      <Grid item={true} xs={12} style={{marginBottom: 10, fontWeight: 'bold'}}>
        {translate('reminders.levels.sms-settings.title')}
      </Grid>
      <Grid item={true} xs={12} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="notifications-enabled"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.levels.sms-settings.enabled") as string}
          checked={notifications.enabled}
          onChange={(event: any, newValue: boolean) => setNotifications({ enabled: newValue })}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.sms-settings.days-before-due-date') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          type="number"
          placeholder={translate('reminders.levels.sms-settings.days-before-due-date') as string}
          value={notifications.daysBeforeDueDate + ''}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setNotifications({daysBeforeDueDate: +value})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.language')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <Select
          isSearchable={false}
          options={languageOptions}
          value={languageOptions.find(opt => opt.value === language)}
          onChange={(value: ValueType<IOptionType, false>) => {
            if (value) {
              if (!notifications.templates.find(it => it.language === value.value)) {
                setNotifications({
                  templates: [...notifications.templates, { text: '', language: value.value }],
                });
              }
              setLanguage(value.value);
            }
          }}
          styles={colourStyles}
          isDisabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.sms-settings.template')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.sms-settings.template') as string}
          value={notifications.templates.find(it => it.language === language)?.text}
          disabled={!enabled || language === undefined}
          className={classes.w100}
          onChange={(value: string) => setNotifications({
            templates: notifications.templates.map(x => x.language !== language ? x : {...x, text: value }),
          })}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(SmsLevelSection);
