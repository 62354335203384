// global imports
import { Link, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioCountry, BexioSettings, BexioTax, BexioTaxType } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import CustomButton from '../../../../components/NewUI/CustomButton';
import styles from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => styles as StyleRules);

const defaultVatList = [
  '0%',
  '0.6%',
  '1.2%',
  '1.3%',
  '2.0%',
  '2.1%',
  '2.5%',
  '2.6%',
  '2.8%',
  '3%',
  '3.5%',
  '3.7%',
  '3.8%',
  '4.3%',
  '4.5%',
  '5%',
  '5.1%',
  '5.3%',
  '5.5%',
  '5.9%',
  '6%',
  '6.2%',
  '6.5%',
  '6.8%',
  '7%',
  '7.7%',
  '8.1%',
  '9%',
  '9.5%',
  '10%',
  '11%',
  '12%',
  '13%',
  '14%',
  '15%',
  '16%',
  '17%',
  '18%',
  '19%',
  '20%',
  '21%',
  '22%',
  '23%',
  '24%',
  '25%',
  '26%',
  '27%',
  '28%',
  '29%',
  '30%',
];

const VatAccounts: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    bexioTaxes,
    bexioSettings,
    bexioCountries,
    connectionStatus,
    getTaxesRequest,
    getCountriesRequest,
    putSettingsRequest,
    handleIsValid,
  } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);
  const [vatOptions] = useState<IOptionType[]>(defaultVatList.map(vat => ({ label: vat, value: vat })));
  const [taxes, setTaxes] = useState<IOptionType[]>([]);
  const [countries, setCountries] = useState<IOptionType[]>([]);

  useEffect(() => {
    if (connectionStatus === 'ok') {
      getTaxesRequest({});
      getCountriesRequest({});
    }
  }, [connectionStatus]);

  // convert taxes data for react-select
  useEffect(() => {
    if (bexioTaxes) {
      setTaxes(
        bexioTaxes.map((tax: BexioTaxType) => ({
          value: tax.id.toString(),
          label: tax.name,
        })),
      );
    }
  }, [bexioTaxes]);

  useEffect(() => {
    if (bexioCountries) {
      setCountries(bexioCountries.map((c: BexioCountry) => ({ value: c.code, label: c.name })));
    }
  }, [bexioCountries]);

  // set settings locally
  useEffect(() => {
    if (bexioSettings) {
      const newSettings = { ...cloneDeep(bexioSettings) };
      if (!newSettings.taxes.length) {
        newSettings.taxes = [
          {
            title: ``,
            value: 0,
            isDefault: true,
            type: 'sales',
            countryIso: null,
          },
        ];
      }
      setSettings(newSettings);
    }
  }, [bexioSettings]);

  // save settings to backend
  useEffect(() => {
    if (
      taxes &&
      bexioSettings &&
      settings &&
      // do not save until all values are valid
      !settings.taxes.find((tax: BexioTax) => checkValues(tax.id, tax.title)) &&
      !isEqual(bexioSettings.taxes, settings.taxes)
    ) {
      putSettingsRequest({ system: 'bexio', data: settings });
    }
  }, [bexioSettings, settings, taxes]);

  useEffect(() => {
    if (handleIsValid) {
      if (settings && settings.taxes.find((tax: BexioTax) => !tax.id || !tax.title)) {
        handleIsValid(false);
      } else {
        handleIsValid(true);
      }
    }
  }, [settings]);

  const addTax = useCallback(
    (key?: number) => {
      if (settings) {
        setSettings({
          ...settings,
          taxes: [
            ...settings.taxes,
            typeof key !== 'undefined'
              ? { ...settings.taxes[key], isDefault: false }
              : {
                  title: ``,
                  value: 0,
                  isDefault: false,
                  type: 'sales',
                  countryIso: null,
                },
          ],
        });
      }
    },
    [settings],
  );
  const removeTax = useCallback(
    (key: number) => {
      if (settings) {
        const newTaxes = [...settings.taxes.slice(0, key), ...settings.taxes.slice(key + 1)];
        const hasDefault = _.find(newTaxes, tax => tax.isDefault);

        if (!newTaxes.length) {
          newTaxes.push({
            title: ``,
            value: 0,
            isDefault: true,
            type: 'sales',
            countryIso: null,
          });
        } else if (!hasDefault) {
          newTaxes[0].isDefault = true;
        }
        setSettings({
          ...settings,
          taxes: newTaxes,
        });
      }
    },
    [settings],
  );
  const toggleTaxesDefault = useCallback(
    (key: number) => {
      if (settings) {
        setSettings({
          ...settings,
          taxes: settings.taxes.map((tax, index) => ({ ...tax, isDefault: index === key })),
        });
      }
    },
    [settings],
  );
  const vatChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          taxes: [
            ...settings.taxes.slice(0, key),
            {
              ...settings.taxes[key],
              title: value.value,
              value: parseFloat(value.value) / 100,
            },
            ...settings.taxes.slice(key + 1),
          ],
        });
      }
    },
    [settings],
  );
  const taxChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          taxes: [
            ...settings.taxes.slice(0, key),
            {
              ...settings.taxes[key],
              id: parseInt(value.value, 10),
            },
            ...settings.taxes.slice(key + 1),
          ],
        });
      }
    },
    [settings],
  );
  const countryChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          taxes: [
            ...settings.taxes.slice(0, key),
            {
              ...settings.taxes[key],
              countryIso: value ? value.value : null,
            },
            ...settings.taxes.slice(key + 1),
          ],
        });
      }
    },
    [settings],
  );
  const toggleCountryBased = useCallback(
    (key: number) => {
      if (settings && bexioCountries && bexioCountries.length) {
        setSettings({
          ...settings,
          taxes: settings.taxes.map((tax, index) => ({
            ...tax,
            countryIso: index === key ? bexioCountries[0].code : tax.countryIso,
          })),
        });
      }
    },
    [settings, bexioCountries],
  );

  const checkValues = (val1: any, val2: any) =>
    val1 === undefined || val1 === null || val2 === undefined || val2 === null;

  return (
    settings && (
      <>
        <FormCard marginBottom={true}>
          <div className={classes.w100}>
            <table style={{ textAlign: 'left', width: '100%' }}>
              <tbody>
                <tr style={{ fontFamily: 'Lato' }}>
                  <th style={{ width: '15%' }}>{translate('percentage')}</th>
                  <th style={{ width: '30%' }}>{translate('revenue')}</th>
                  <th style={{ width: '20%', textAlign: 'center' }}>{translate('default')}</th>
                  <th style={{ width: '25%', textAlign: 'center' }}>{translate('country')}</th>
                  <th />
                </tr>
                {settings.taxes.map((vat: BexioTax, key: number) => (
                  <tr
                    key={key}
                    className={cx({
                      [classes.errorOutline]: checkValues(vat.id, vat.value),
                    })}
                  >
                    <td>
                      <Select
                        isSearchable={false}
                        options={vatOptions}
                        value={vatOptions.find(option => parseFloat(option.value) / 100 === vat.value) || null}
                        onChange={(value: ValueType<IOptionType, false>) => vatChanged(key, value as IOptionType)}
                        styles={colourStyles}
                      />
                    </td>
                    <td>
                      <Select
                        isSearchable={false}
                        options={taxes}
                        value={taxes.find(tax => parseInt(tax.value, 10) === vat.id) || null}
                        onChange={(value: ValueType<IOptionType, false>) => taxChanged(key, value as IOptionType)}
                        styles={colourStyles}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {vat.isDefault ? (
                        <Checkbox
                          onChange={() => {
                            toggleTaxesDefault(key);
                          }}
                          checked={vat.isDefault}
                        />
                      ) : (
                        <Link style={{ cursor: 'pointer' }} color="textPrimary" onClick={() => toggleTaxesDefault(key)}>
                          als Standard definieren
                        </Link>
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {vat.countryIso === null || vat.countryIso === undefined ? (
                        <Checkbox
                          onChange={() => {
                            toggleCountryBased(key);
                          }}
                          checked={false}
                        />
                      ) : (
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          options={countries}
                          value={countries.find(c => c.value === vat.countryIso) || null}
                          onChange={(value: ValueType<IOptionType, false>) => countryChanged(key, value as IOptionType)}
                          styles={colourStyles}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Button justIcon={true} round={true} color="transparent" onClick={() => removeTax(key)}>
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div style={styles.buttonContainer}>
              <CustomButton onClick={addTax}>{translate('bexio.add-vat')}</CustomButton>
            </div>
          </div>
        </FormCard>
      </>
    )
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
  bexioTaxes: state.bexio.taxes,
  bexioCountries: state.bexio.countries,
  connectionStatus: state.bexio.connectionStatus,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  getTaxesRequest: bexioActions.getTaxesRequest,
  getCountriesRequest: bexioActions.getCountriesRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(VatAccounts));
