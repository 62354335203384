import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'PROTOCOL';
export const GET_PROTOCOL_DATA = createRequestTypes(`${filePrefix}_GET_PROTOCOL_DATA`);
export const RERUN_PROTOCOL = createRequestTypes(`${filePrefix}_RERUN_PROTOCOL`);
export const GET_PROTOCOL_EXIT_STATES = createRequestTypes(`${filePrefix}_GET_PROTOCOL_EXIT_STATES`);
export const GET_PROTOCOL_TOPICS = createRequestTypes(`${filePrefix}_GET_PROTOCOL_TOPICS`);

export const getProtocolDataRequest = (data: any) => ({
  type: GET_PROTOCOL_DATA.REQUEST,
  data,
});
export const getProtocolDataSuccess = (data: any) => ({
  type: GET_PROTOCOL_DATA.SUCCESS,
  data,
});
export const getProtocolDataFailure = (error: any) => ({
  type: GET_PROTOCOL_DATA.FAILURE,
  error,
});

export const reRunProtocolRequest = (data: any) => ({
  type: RERUN_PROTOCOL.REQUEST,
  data,
});
export const reRunProtocolSuccess = (data: any) => ({
  type: RERUN_PROTOCOL.SUCCESS,
  data,
});
export const reRunProtocolFailure = (error: any) => ({
  type: RERUN_PROTOCOL.FAILURE,
  error,
});

export const getProtocolExitStatesRequest = (data: any) => ({
  type: GET_PROTOCOL_EXIT_STATES.REQUEST,
  data,
});
export const getProtocolExitStatesSuccess = (data: any) => ({
  type: GET_PROTOCOL_EXIT_STATES.SUCCESS,
  data,
});
export const getProtocolExitStatesFailure = (error: any) => ({
  type: GET_PROTOCOL_EXIT_STATES.FAILURE,
  error,
});

export const getProtocolTopicsRequest = (data: any) => ({
  type: GET_PROTOCOL_TOPICS.REQUEST,
  data,
});
export const getProtocolTopicsSuccess = (data: any) => ({
  type: GET_PROTOCOL_TOPICS.SUCCESS,
  data,
});
export const getProtocolTopicsFailure = (error: any) => ({
  type: GET_PROTOCOL_TOPICS.FAILURE,
  error,
});
