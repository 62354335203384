import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'BEXIO';
export const GET_TAXES = createRequestTypes(`${filePrefix}_GET_TAXES`);
export const GET_BANK_ACCOUNTS = createRequestTypes(`${filePrefix}_GET_BANK_ACCOUNTS`);
export const GET_ACCOUNTING = createRequestTypes(`${filePrefix}_GET_ACCOUNTING`);
export const GET_CUSTOMER = createRequestTypes(`${filePrefix}_GET_CUSTOMER`);
export const SEARCH_CUSTOMER = createRequestTypes(`${filePrefix}_SEARCH_CUSTOMER`);
export const SEARCH_SHIPPING_PRODUCT = createRequestTypes(`${filePrefix}_SEARCH_SHIPPING_PRODUCT`);
export const SEARCH_WRAPPING_PRODUCT = createRequestTypes(`${filePrefix}_SEARCH_WRAPPING_PRODUCT`);
export const GET_SHIPPING_PRODUCT = createRequestTypes(`${filePrefix}_GET_SHIPPING_PRODUCT`);
export const GET_WRAPPING_PRODUCT = createRequestTypes(`${filePrefix}_GET_WRAPPING_PRODUCT`);
export const GET_USERS = createRequestTypes(`${filePrefix}_GET_USERS`);
export const TEST_CONNECTION = createRequestTypes(`${filePrefix}_TEST_CONNECTION`);
export const GET_REMINDER_SETTINGS = createRequestTypes(`${filePrefix}_GET_REMINDER_SETTINGS`);
export const PUT_REMINDER_SETTINGS = createRequestTypes(`${filePrefix}_PUT_REMINDER_SETTINGS`);
export const EXPORT_PRODUCTS = createRequestTypes(`${filePrefix}_EXPORT_PRODUCTS`);
export const GET_PAYMENT_TYPES = createRequestTypes(`${filePrefix}_GET_PAYMENT_TYPES`);
export const GET_COUNTRIES = createRequestTypes(`${filePrefix}_GET_COUNTRIES`);

export const getTaxesRequest = (data: any) => ({
  type: GET_TAXES.REQUEST,
  data,
});
export const getTaxesSuccess = (data: any) => ({
  type: GET_TAXES.SUCCESS,
  data,
});
export const getTaxesFailure = (error: any) => ({
  type: GET_TAXES.FAILURE,
  error,
});

export const getBankAccountsRequest = (data: any) => ({
  type: GET_BANK_ACCOUNTS.REQUEST,
  data,
});
export const getBankAccountsSuccess = (data: any) => ({
  type: GET_BANK_ACCOUNTS.SUCCESS,
  data,
});
export const getBankAccountsFailure = (error: any) => ({
  type: GET_BANK_ACCOUNTS.FAILURE,
  error,
});

export const getAccountingRequest = (data: any) => ({
  type: GET_ACCOUNTING.REQUEST,
  data,
});
export const getAccountingSuccess = (data: any) => ({
  type: GET_ACCOUNTING.SUCCESS,
  data,
});
export const getAccountingFailure = (error: any) => ({
  type: GET_ACCOUNTING.FAILURE,
  error,
});

export const getCustomerRequest = (data: any) => ({
  type: GET_CUSTOMER.REQUEST,
  data,
});
export const getCustomerSuccess = (data: any) => ({
  type: GET_CUSTOMER.SUCCESS,
  data,
});
export const getCustomerFailure = (error: any) => ({
  type: GET_CUSTOMER.FAILURE,
  error,
});

export const searchCustomerRequest = (data: any) => ({
  type: SEARCH_CUSTOMER.REQUEST,
  data,
});
export const searchCustomerSuccess = (data: any) => ({
  type: SEARCH_CUSTOMER.SUCCESS,
  data,
});
export const searchCustomerFailure = (error: any) => ({
  type: SEARCH_CUSTOMER.FAILURE,
  error,
});

export const getShippingProductRequest = (data: any) => ({
  type: GET_SHIPPING_PRODUCT.REQUEST,
  data,
});
export const getShippingProductSuccess = (data: any) => ({
  type: GET_SHIPPING_PRODUCT.SUCCESS,
  data,
});
export const getShippingProductFailure = (error: any) => ({
  type: GET_SHIPPING_PRODUCT.FAILURE,
  error,
});

export const getWrappingProductRequest = (data: any) => ({
  type: GET_WRAPPING_PRODUCT.REQUEST,
  data,
});
export const getWrappingProductSuccess = (data: any) => ({
  type: GET_WRAPPING_PRODUCT.SUCCESS,
  data,
});
export const getWrappingProductFailure = (error: any) => ({
  type: GET_WRAPPING_PRODUCT.FAILURE,
  error,
});

export const searchShippingProductRequest = (data: any) => ({
  type: SEARCH_SHIPPING_PRODUCT.REQUEST,
  data,
});
export const searchShippingProductSuccess = (data: any) => ({
  type: SEARCH_SHIPPING_PRODUCT.SUCCESS,
  data,
});
export const searchShippingProductFailure = (error: any) => ({
  type: SEARCH_SHIPPING_PRODUCT.FAILURE,
  error,
});

export const searchWrappingProductRequest = (data: any) => ({
  type: SEARCH_WRAPPING_PRODUCT.REQUEST,
  data,
});
export const searchWrappingProductSuccess = (data: any) => ({
  type: SEARCH_WRAPPING_PRODUCT.SUCCESS,
  data,
});
export const searchWrappingProductFailure = (error: any) => ({
  type: SEARCH_WRAPPING_PRODUCT.FAILURE,
  error,
});

export const getUsersRequest = (data: any) => ({
  type: GET_USERS.REQUEST,
  data,
});
export const getUsersSuccess = (data: any) => ({
  type: GET_USERS.SUCCESS,
  data,
});
export const getUsersFailure = (error: any) => ({
  type: GET_USERS.FAILURE,
  error,
});

export const testConnectionRequest = (data: any) => ({
  type: TEST_CONNECTION.REQUEST,
  data,
});
export const testConnectionSuccess = (data: any) => ({
  type: TEST_CONNECTION.SUCCESS,
  data,
});
export const testConnectionFailure = (data: any) => ({
  type: TEST_CONNECTION.FAILURE,
  data,
});

export const getReminderSettingsRequest = (data: any) => ({
  type: GET_REMINDER_SETTINGS.REQUEST,
  data,
});
export const getReminderSettingsSuccess = (data: any) => ({
  type: GET_REMINDER_SETTINGS.SUCCESS,
  data,
});
export const getReminderSettingsFailure = (error: any) => ({
  type: GET_REMINDER_SETTINGS.FAILURE,
  error,
});

export const putReminderSettingsRequest = (data: any) => ({
  type: PUT_REMINDER_SETTINGS.REQUEST,
  data,
});
export const putReminderSettingsSuccess = (data: any) => ({
  type: PUT_REMINDER_SETTINGS.SUCCESS,
  data,
});
export const putReminderSettingsFailure = (error: any) => ({
  type: PUT_REMINDER_SETTINGS.FAILURE,
  error,
});

export const exportProductsRequest = (data: any) => ({
  type: EXPORT_PRODUCTS.REQUEST,
  data,
});
export const exportProductsSuccess = (data: any) => ({
  type: EXPORT_PRODUCTS.SUCCESS,
  data,
});
export const exportProductsFailure = (error: any) => ({
  type: EXPORT_PRODUCTS.FAILURE,
  error,
});

export const getPaymentTypesRequest = (data: any) => ({
  type: GET_PAYMENT_TYPES.REQUEST,
  data,
});
export const getPaymentTypesSuccess = (data: any) => ({
  type: GET_PAYMENT_TYPES.SUCCESS,
  data,
});
export const getPaymentTypesFailure = (data: any) => ({
  type: GET_PAYMENT_TYPES.FAILURE,
  data,
});

export const getCountriesRequest = (data: any) => ({
  type: GET_COUNTRIES.REQUEST,
  data,
});
export const getCountriesSuccess = (data: any) => ({
  type: GET_COUNTRIES.SUCCESS,
  data,
});
export const getCountriesFailure = (error: any) => ({
  type: GET_COUNTRIES.FAILURE,
  error,
});
