import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'PRESTASHOP';

export const ADD_SHOP = createRequestTypes(`${filePrefix}_ADD_SHOP`);
export const REMOVE_SHOP = createRequestTypes(`${filePrefix}_REMOVE_SHOP`);
export const CHANGE_OPTION = createRequestTypes(`${filePrefix}_CHANGE_OPTION`);
export const IMPORT_ORDERS = createRequestTypes(`${filePrefix}_IMPORT_ORDERS`);
export const IMPORT_ORDER = createRequestTypes(`${filePrefix}_IMPORT_ORDER`);
export const HAS_SUBSCRIPTION = createRequestTypes(`${filePrefix}_HAS_SUBSCRIPTION`);
export const POST_CHECKOUT = createRequestTypes(`${filePrefix}_POST_CHECKOUT`);

export const addShopRequest = (data: any) => ({
  type: ADD_SHOP.REQUEST,
  data,
});
export const addShopSuccess = () => ({
  type: ADD_SHOP.SUCCESS,
});
export const addShopFailure = (error: any) => ({
  type: ADD_SHOP.FAILURE,
  error,
});

export const removeShopRequest = (data: any) => ({
  type: REMOVE_SHOP.REQUEST,
  data,
});
export const removeShopSuccess = () => ({
  type: REMOVE_SHOP.SUCCESS,
});
export const removeShopFailure = (error: any) => ({
  type: REMOVE_SHOP.FAILURE,
  error,
});

export const changeShopOptionRequest = (data: any) => ({
  type: CHANGE_OPTION.REQUEST,
  data,
});
export const changeShopOptionSuccess = () => ({
  type: CHANGE_OPTION.SUCCESS,
});
export const changeShopOptionFailure = (error: any) => ({
  type: CHANGE_OPTION.FAILURE,
  error,
});
export const importOrdersRequest = (data: any) => ({
  type: IMPORT_ORDERS.REQUEST,
  data,
});
export const importOrdersSuccess = (data: any) => ({
  type: IMPORT_ORDERS.SUCCESS,
  data,
});
export const importOrdersFailure = (error: any) => ({
  type: IMPORT_ORDERS.FAILURE,
  error,
});

export const importOrderRequest = (data: any) => ({
  type: IMPORT_ORDER.REQUEST,
  data,
});
export const importOrderSuccess = (data: any) => ({
  type: IMPORT_ORDER.SUCCESS,
  data,
});
export const importOrderFailure = (error: any) => ({
  type: IMPORT_ORDER.FAILURE,
  error,
});

export const hasSubscriptionRequest = () => ({
  type: HAS_SUBSCRIPTION.REQUEST,
});
export const hasSubscriptionSuccess = (data: any) => ({
  type: HAS_SUBSCRIPTION.SUCCESS,
  data,
});
export const hasSubscriptionFailure = (error: any) => ({
  type: HAS_SUBSCRIPTION.FAILURE,
  error,
});

export const postCheckoutRequest = () => ({
  type: POST_CHECKOUT.REQUEST,
});
export const postCheckoutSuccess = (data: any) => ({
  type: POST_CHECKOUT.SUCCESS,
  data,
});
export const postCheckoutFailure = (error: any) => ({
  type: POST_CHECKOUT.FAILURE,
  error,
});
