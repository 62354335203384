import { ActionPattern, all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_FIELDS, getFieldsFailure, getFieldsSuccess } from './../actions/bitrix_actions';

import { BedayaAction } from '../../types/BedayaAction';
import { getFields } from './../api/bitrix';

function* getFieldsSaga(action: BedayaAction & { fieldType: string }) {
  // yield delay(500);
  try {
    const { data } = yield call(getFields, action.data.fieldType);
    yield put(getFieldsSuccess({ data, fieldType: action.data.fieldType }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getFieldsFailure(processedError || error));
  }
}

function* getFieldsWatcher() {
  yield takeEvery(GET_FIELDS.REQUEST as ActionPattern, getFieldsSaga);
}

export default function* bitrixSaga() {
  yield all([fork(getFieldsWatcher)]);
}
