import { container, cardTitle, blackColor, hexToRgb, grayColor, primaryColor } from '../../material-dashboard-pro-react.js';

import customCheckboxRadioSwitch from '../../material-dashboard-pro-react/customCheckboxRadioSwitch.js';

const registerPageStyle = theme => ( {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
    color: blackColor,
    fontWeight: '500',
    margin: '0',
    fontSize: '25px',
    [theme.breakpoints.up('md')]: {
      fontSize: '33px',
    },
  },
  container: {
    ...container,
    position: 'relative',
    zIndex: '3',
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: '6px',
    boxShadow:
      '0 4px 21px rgba(' +
      hexToRgb(blackColor) +
      ', 0.09)',
    marginBottom: '20px',
    padding: '40px 0px 20px 0px',
    marginTop: '0px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '15vh',
      marginBottom: '100px',
      padding: '70px 0px 40px 0px',
    },
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  form: {
    padding: '0 20px',
    position: 'relative',
  },
  socialTitle: {
    fontSize: '18px',
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: '0 12px',
  },
  checkboxLabelControl: {
    margin: '0',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
  },
  link: {
    color: primaryColor[0],
    '&:hover,&:focus': {
      color: primaryColor[1],
    },
  },
  submitContainer: {
    textAlign: 'center',
    display: 'block',
    paddingTop: '20px',
  },
});

export default registerPageStyle;
