// global imports
import { Grid, makeStyles, StyleRules, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import * as bitrixActions from '../../../../store/actions/bitrix_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import Select from 'react-select';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Button from '../../../../components/CustomButtons/Button';
import FormCard from '../../../../components/FormCard/FormCard';
import CustomButton from '../../../../components/NewUI/CustomButton';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);
const OrderFieldMapping = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    bitrixSettings,
    getFieldsRequest,
    putSettingsRequest,
    canonicalFields,
    fields,
  } = props;

  const [mappings, setMappings] = useState<
    Array<{
      bitrixKey: string;
      canonicalKey: string;
    }>
  >([]);
  const [highlightErrors, setHighlightErrors] = useState(false);

  useEffect(() => {
    getFieldsRequest('order');
  }, []);

  useEffect(() => {
    if (bitrixSettings && bitrixSettings.orderMappings && (!mappings || mappings.length === 0)) {
      setMappings(bitrixSettings.orderMappings);
    }
  }, [bitrixSettings]);

  // save settings to backend
  useEffect(() => {
    if (mappings && bitrixSettings && !isEqual(bitrixSettings.orderMappings, mappings)) {
      if (emptyExists()) {
        return setHighlightErrors(true);
      }
      setHighlightErrors(false);
      putSettingsRequest({
        system: 'bitrix',
        data: {
          orderMappings: mappings,
        },
      });
    }
  }, [mappings]);

  const addMapping = () => {
    if (emptyExists()) {
      return;
    }
    setHighlightErrors(false);
    setMappings([...mappings, { canonicalKey: '', bitrixKey: '' }]);
  };

  const deleteMapping = (index: number) => {
    setMappings([...mappings.slice(0, index), ...mappings.slice(index + 1)]);
  };

  const onChange = (field: 'bitrixKey' | 'canonicalKey', index: number) => ({ value }: any, select: any) => {
    setMappings([
      ...mappings.slice(0, index),
      {
        ...mappings[index],
        [field]: value,
      },
      ...mappings.slice(index + 1),
    ]);
  };

  const emptyExists = () => {
    for (const mapping of mappings) {
      if (!mapping.canonicalKey || !mapping.bitrixKey) {
        return true;
      }
    }
    return false;
  };

  const filterCanonicalSelected = (field: string) => {
    for (const mappedField of mappings) {
      if (field === mappedField.canonicalKey) {
        return false;
      }
    }
    return true;
  };

  const filterBitrixSelected = (field: any) => {
    for (const mappedField of mappings) {
      if (field.value === mappedField.bitrixKey) {
        return false;
      }
    }
    return true;
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('bitrix.order-field-mapping') as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true}>
              <Grid item={true} md={5} className={classes.label}>
                {translate('bitrix.bedaya-order')}
              </Grid>
              <Grid item={true} md={5} className={classes.label}>
                {translate('bitrix.bitrix-order')}
              </Grid>
            </Grid>
            {mappings &&
              mappings.map((field, index) => (
                <Grid key={index} container={true} className={classes.row}>
                  <Grid item={true} md={5} className={classes.col}>
                    <Select
                      options={canonicalFields.filter(filterCanonicalSelected).map(canonicalField => ({
                        label: canonicalField,
                        value: canonicalField,
                      }))}
                      value={
                        field.canonicalKey
                          ? {
                              label: field.canonicalKey,
                              value: field.canonicalKey,
                            }
                          : null
                      }
                      styles={colourStyles}
                      onChange={onChange('canonicalKey', index)}
                      className={highlightErrors && !field.canonicalKey ? classes.errored : ''}
                      placeholder={translate('bitrix.bedaya-field') as string}
                    />
                  </Grid>
                  <Grid item={true} md={5} className={classes.col}>
                    <Select
                      options={fields.filter(filterBitrixSelected)}
                      value={fields.find(bitrixField => bitrixField.value === field.bitrixKey)}
                      styles={colourStyles}
                      onChange={onChange('bitrixKey', index)}
                      className={highlightErrors && !field.bitrixKey ? classes.errored : ''}
                      placeholder={translate('bitrix.bitrix-field') as string}
                    />
                  </Grid>
                  <Grid item={true} md={2} className={classes.label}>
                    <Button
                      style={{ margin: 10 }}
                      justIcon={true}
                      round={true}
                      color="transparent"
                      onClick={() => deleteMapping(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              ))}
            <CustomButton onClick={addMapping}>{translate('bitrix.add-mapping')}</CustomButton>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};
const mapStateToProps = (state: IState): StateProps => ({
  canonicalFields: state.commons.canonicalFields.order,
  fields: state.bitrix.fields.order,
  bitrixSettings: state.bitrix.settings,
  connectionStatus: state.bitrix.connectionStatus,
  isFetching: state.bitrix.isFetching,
});

const mapDispatchToProps = {
  getFieldsRequest: bitrixActions.getFieldsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrderFieldMapping),
);
