import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
    GET_ACCOUNTS,
    getAccountsFailure,
    getAccountsSuccess,
    GET_STATISTICS,
    getStatisticsSuccess,
    getStatisticsFailure,
    GET_SUBSCRIPTION,
    getSubscriptionSuccess,
    getSubscriptionFailure,
} from '../actions/sumup_actions';

import { getAccounts, getStatistics, getSubscription } from '../api/sumup';

function* getAccountsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getAccounts, action.data);
    // console.log('data fetched', action.accountType, data)
    yield put(getAccountsSuccess({ type: action.data, data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getAccountsFailure(processedError || error));
  }
}

function* getStatisticsSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getStatistics);
    // console.log('data fetched', action.accountType, data)
    yield put(getStatisticsSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getStatisticsFailure(processedError || error));
  }
}

function* getSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getSubscription);
    // console.log('data fetched', action.accountType, data)
    yield put(getSubscriptionSuccess({ data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getSubscriptionFailure(processedError || error));
  }
}

function* getAccountsWatcher() {
  yield takeLatest(GET_ACCOUNTS.REQUEST as ActionPattern, getAccountsSaga);
}

function* getStatisticsWatcher() {
  yield takeLatest(GET_STATISTICS.REQUEST as ActionPattern, getStatisticsSaga);
}

function* getSubscriptionWatcher() {
  yield takeLatest(GET_SUBSCRIPTION.REQUEST as ActionPattern, getSubscriptionSaga);
}

export default function* authSaga() {
  yield all([
    fork(getAccountsWatcher),
    fork(getStatisticsWatcher),
    fork(getSubscriptionWatcher),
  ]);
}
