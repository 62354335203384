import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project types imports
import { IState } from '../../../../store/reducers';
import { PrestashopSettings } from '../../../../types/PrestashopSettings';

// local imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import BaseStoreOrderAs from './BaseStoreOrderAs';

const StoreOrderAs = () => {
  const dispatch = useDispatch();

  const prestashopSettings = useSelector<IState, PrestashopSettings>(
    ({ prestashop: { settings } }) => settings,
  );
  const [targetEntity, setTargetEntity] = useState<string | undefined>();

  useEffect(() => {
    setTargetEntity(prestashopSettings.targetEntity);
  }, [prestashopSettings.targetEntity]);

  const handleValueChange = (value: string) => {
    setTargetEntity(value);
    dispatch(putSettingsRequest({ system: 'prestashop', data: { ...prestashopSettings, targetEntity: value } }));
  };

  return (
    <BaseStoreOrderAs
      system="prestashop"
      targetEntity={targetEntity}
      onChange={handleValueChange}
    />
  );
};

export default StoreOrderAs;
