import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import * as authActions from '../../store/actions/auth_actions';
import { IState } from '../../store/reducers';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postOAuthTokenRequest: (loginRequest: any) => any;
  redirectPath: string;
  mailchimpAuthenticated: boolean;
  token: string;
  location: {
    search: string;
    pathname: string;
  };
}

const MailchimpOAuthHandler: React.FC<IProps> = props => {
  const { classes, location, mailchimpAuthenticated, postOAuthTokenRequest } = props;

  const [doRedirect, setDoRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (mailchimpAuthenticated) {
      setDoRedirect(true);
    }
  }, [mailchimpAuthenticated]);

  // didMount fetch data
  useEffect(() => {
    if (location) {
      if (mailchimpAuthenticated) {
        setDoRedirect(true);
      }

      const params = new URLSearchParams(location.search);

      if (params.get('code')) {
        postOAuthTokenRequest({
          system: 'mailchimp',
          code: params.get('code'),
        });
      }
    }
  }, []);

  return (
    <div className={classes.container}>
      {doRedirect && <Redirect to={props.redirectPath ? props.redirectPath : '/admin/mailchimp-app'} />}
      <CircularProgress size={100} />
      {/*<button onClick={() => setRedirect(true)}>Redirect</button>*/}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  mailchimpAuthenticated: state.mailchimp.authenticated,
  redirectPath: state.auth.redirectPath,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(MailchimpOAuthHandler));
