import { ActionPattern, all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
  GET_PROTOCOL_DATA,
  GET_PROTOCOL_EXIT_STATES,
  GET_PROTOCOL_TOPICS,
  getProtocolDataFailure,
  getProtocolDataSuccess,
  getProtocolExitStatesFailure,
  getProtocolExitStatesSuccess,
  getProtocolTopicsFailure,
  getProtocolTopicsSuccess,
  RERUN_PROTOCOL,
  reRunProtocolFailure,
  reRunProtocolSuccess,
} from '../actions/protocol_actions';
import { getProtocolData, getProtocolExitStates, getProtocolTopics, reRunProtocol } from '../api/protocol';

function* getProtocolDataSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getProtocolData, action.data);
    yield put(getProtocolDataSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getProtocolDataFailure(processedError || error));
  }
}

function* getProtocolExitStatesSaga() {
  try {
    const { data } = yield call(getProtocolExitStates);
    yield put(getProtocolExitStatesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getProtocolExitStatesFailure(processedError || error));
  }
}

function* getProtocolTopicsSaga() {
  try {
    const { data } = yield call(getProtocolTopics);
    yield put(getProtocolTopicsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getProtocolTopicsFailure(processedError || error));
  }
}

function* reRunProtocolSaga(action: BedayaAction) {
  try {
    const { data } = yield call(reRunProtocol, action.data);
    yield put(reRunProtocolSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(reRunProtocolFailure(processedError || error));
  }
}

function* getProtocolDataWatcher() {
  yield takeLatest(GET_PROTOCOL_DATA.REQUEST as ActionPattern, getProtocolDataSaga);
}

function* getProtocolExitStatesWatcher() {
  yield takeLatest(GET_PROTOCOL_EXIT_STATES.REQUEST as ActionPattern, getProtocolExitStatesSaga);
}

function* getProtocolTopicsWatcher() {
  yield takeLatest(GET_PROTOCOL_TOPICS.REQUEST as ActionPattern, getProtocolTopicsSaga);
}

function* reRunProtocolWatcher() {
  yield takeLatest(RERUN_PROTOCOL.REQUEST as ActionPattern, reRunProtocolSaga);
}

export default function* authSaga() {
  yield all([
    fork(getProtocolDataWatcher),
    fork(reRunProtocolWatcher),
    fork(getProtocolExitStatesWatcher),
    fork(getProtocolTopicsWatcher),
  ]);
}
