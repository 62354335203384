import React from 'react';

import { Subscription, StateProps, DispatchProps} from '../../../../../../components/Subscription';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import * as prestashopActions from '../../../../../../store/actions/prestashop_actions';

const PrestashopSubscription = () => {
  const dispatch = useAppDispatch();
  const state: StateProps = useAppSelector(state => ({
    hasSubscription: state.prestashop.hasSubscription,
    checkoutUrl: state.prestashop.checkoutUrl,
    isFetching: state.prestashop.isFetching,
  }));
  const dispatchProps: DispatchProps = {
    hasSubscriptionRequest: () => dispatch(prestashopActions.hasSubscriptionRequest()),
    postCheckoutRequest: () => dispatch(prestashopActions.postCheckoutRequest()),
  };
  return <Subscription baseName="prestashop" {...state} {...dispatchProps} />
};

export default PrestashopSubscription;
