import commonStyles from '../../../../assets/jss/commonStyles';
import { IStyleProps } from '../../../../types/StyleProps';

const styles: IStyleProps = {
  ...commonStyles,
  selectSmall: {
    width: '300px',
    display: 'inline-block',
    padding: '0 5px',
  },
  selectSmallCentered: {
    width: '10%',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'center',
  },
  label: {
    margin: '0 auto',
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'lato',
  },
  header: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    paddingBottom: 5,
    marginBottom: 5,
  },
  buttonContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12);',
    paddingTop: 5,
    marginTop: 5,
  },
};

export default styles;
