import { clientApp } from './index';

export const loginBedaya = (data = []) => clientApp.post(`/auth/login`, data);
export const registerBedaya = (data = []) => clientApp.post(`/auth/register`, data);

export const loginBexio = (data = []) => clientApp.post(`/auth/login/bexio/post`, data);

export const fetchBexioLoginInfo = () => clientApp.get(`/auth/login/bexio/`);

export const fetchOAuthUrl = (system = [], data: { [index: string]: any } = {}) => {
  const params = Object.keys(data)
    .filter(key => key !== 'system')
    .map(key => `${key}=${data[key]}`)
    .join('&');
  return clientApp.get(`/ui/user/oauth/${system}${params && `?${params}`}`);
};

export const postOAuthToken = (system: string = '', data: { [index: string]: any } = {}) => {
  const params = Object.keys(data)
    .filter(key => key !== 'system')
    .map(key => `${key}=${data[key]}`)
    .join('&');
  return clientApp.get(`/ui/user/oauth/${system}/callback?${params}`);
};
