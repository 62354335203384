import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import styles from '../../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js';
import LanguageToggle from '../../../components/LanguageToggle';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import BexioIcon from '../../../assets/img/bexio-icon.png';
import BedayaIcon from '../../../assets/img/bedaya-logo-small.png';
import { config } from '../../../config';

const useStyles = makeStyles(styles);

function AuthNavbar(props) {
  // verifies if routeName is the one active (in browser input)
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses} style={{ backgroundColor: 'white' }}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <a href="/">
              <img
                src={config.activeDomain === 'bexio' ? BexioIcon : BedayaIcon}
                style={config.activeDomain === 'bexio' ? { height: 50 } : {}}
                alt="logo"
                className={classes.img}
              />
            </a>
          </div>
        </Hidden>
        <LanguageToggle />
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.string,
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default withLocalize(connect(mapStateToProps)(AuthNavbar));
