// global imports
import { makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';

// project imports
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

// project types imports
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import style from './Apps/styles';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

export default function SystemContainer(props: any) {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader>
          <h4 className={classes.smallTitle}>{props.title}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>{props.children}</GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}
