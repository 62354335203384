import React from 'react';
import { Grid } from '@material-ui/core';

import FormCard from '../../../components/FormCard/FormCard';
import GridRow from '../../../components/NewUI/GridRow';
import Label from '../../../components/NewUI/Label/Label';

function Section(props: any) {
  const { title, desc, children } = props;

  return (
    <GridRow container={true}>
      <Grid item={true} xs={12} md={3}>
        <Label title={title} desc={desc} />
      </Grid>
      <Grid item={true} xs={12} md={9}>
        <FormCard>
          <div style={{ width: '100%' }}>
            {children}
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
}

export default Section;
