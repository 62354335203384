import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { ReminderBehavior } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


type BehaviorSectionProps = {
  enabled: boolean;
  settings: ReminderBehavior;
  setSettings: (item: ReminderBehavior) => void;
} & LocalizeContextProps;

function BehaviorSection(props: BehaviorSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  return (
    <Grid container={true}>
      <Grid item={true} xs={12} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="fix-email-html"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.behavior.summarizeStandardReminderPosition") as string}
          checked={settings.summarizeStandardReminderPosition}
          onChange={(event: any, newValue: boolean) => setSettings({...settings, summarizeStandardReminderPosition: newValue})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.behavior.reminderOffsetDays') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          type="number"
          placeholder={translate('reminders.behavior.reminderOffsetDays') as string}
          value={settings.reminderOffsetDays + ''}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, reminderOffsetDays: +value})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.behavior.ignoreInvoicesBefore') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={settings.ignoreInvoicesBefore ? moment(settings.ignoreInvoicesBefore).toDate() : null}
          onChange={(date: any) => setSettings({
            ...settings,
            ignoreInvoicesBefore: moment(date).format('YYYY-MM-DD'),
          })}
          placeholderText={translate('reminders.behavior.ignoreInvoicesBefore') as string}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(BehaviorSection);
