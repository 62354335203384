// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project types imports
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { IState } from '../../../../store/reducers';
import { ShopifySettings } from '../../../../types/ShopifySettings';

// local imports
import * as shopifyActions from '../../../../store/actions/shopify_actions';
import { Props, StateProps } from './types';

const TransferOrderUpdate = (props: Props) => {
  const { translate, shopifySettings, isFetching, putSettingsForShopRequest, shopifyShops } = props;

  const [settings, setSettings] = useState<ShopifySettings | null>(null);

  useEffect(() => {
    if (shopifySettings) {
      setSettings(shopifySettings);
    }
  }, [shopifySettings]);

  const handleValueChange = (event: any) => {
    if (settings) {
      const newSettings = {
        ...settings,
        invoiceSync: { ...settings.invoiceSync, enabledInvoiceUpdate: event.target.checked },
      };
      setSettings(newSettings);
      const shop = shopifyShops.find(s => s.active);
      if (shop) {
        putSettingsForShopRequest({
          shop: shop.name,
          data: newSettings,
        });
      }
    }
  };

  return (
    <div style={{ paddingLeft: 20 }}>
      <Checkbox
        name="enabledInvoiceUpdate"
        label={translate('shopify.transfer_order_updates') as string}
        onChange={handleValueChange}
        checked={
          settings && settings.invoiceSync.targetEntity !== 'Offer'
            ? settings.invoiceSync.enabledInvoiceUpdate
            : false
        }
        disabled={isFetching || (!!settings && settings.invoiceSync.targetEntity === 'Offer')}
      />
    </div>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shopifySettings: state.shopify.settings,
  shopifyShops: state.shopify.shops,
  isFetching: state.shopify.isFetching,
});

const mapDispatchToProps = {
  putSettingsForShopRequest: shopifyActions.putSettingsForShopRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TransferOrderUpdate),
);
