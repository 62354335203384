// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';

import { useDispatch, useSelector } from 'react-redux';

// project imports
import { getSettingsRequest, putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Grid, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import PopupDialog from '../../../../components/PopupDialog/PopupDialog';
import { Props, StateProps } from './types';

const SendDelivery = (props: Props) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);
  const hasEmailSettings = useSelector<IState, boolean>(state => state.email.settings.delivery.recipient !== '');

  const [sendDelivery, setSendDelivery] = useState(bexioSettings ? bexioSettings.sendDelivery : true);
  const [disabledShown, setDisabledShown] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'email', type: 'delivery' }));
  }, [dispatch, getSettingsRequest]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    if (sendDelivery === bexioSettings.sendDelivery) {
      return;
    }
    dispatch(putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, sendDelivery } }));
  }, [sendDelivery]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    setSendDelivery(bexioSettings.sendDelivery);
  }, [bexioSettings]);

  const onChange = (ev: React.ChangeEvent) => {
    if (!hasEmailSettings) {
      setDisabledShown(true);
      return;
    }
    setSendDelivery(!sendDelivery);
  };

  return (
    bexioSettings && (
      <Grid container={true} style={{ alignItems: 'center' }}>
        <Grid item={true} md={4}>
          <InputLabel>{translate('bexio.delivery')}</InputLabel>
        </Grid>
        <Grid item={true} md={8}>
          <StyledSwitch checked={sendDelivery} onChange={onChange} disabled={!bexioSettings} />
        </Grid>
        <PopupDialog
          title={translate('errors.errors') as string}
          open={disabledShown}
          handleClose={() => setDisabledShown(false)}
        >
          <Typography gutterBottom={true} style={{ marginBottom: 20 }}>
            {translate('bexio.delivery-disabled')}
          </Typography>
        </PopupDialog>
      </Grid>
    )
  );
};

export default withLocalize(SendDelivery);
