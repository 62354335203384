// global imports
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import _ from 'lodash';
import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import {
  getSettingsRequest,
  putSettingsRequest as putSettingsRequestAction,
} from '../../../../../../store/actions/common_actions';
import { IState } from '../../../../../../store/reducers';
import { LightspeedSettings } from '../../../../../../types/LightspeedSettings';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const AuthCreds = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { lightspeedSettings, putSettingsRequest, translate } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<LightspeedSettings | null>(lightspeedSettings);

  const delayedInput = useRef(
    _.debounce(
      (field, value) => dispatch(putSettingsRequest({ system: 'lightspeed', data: { ...settings, [field]: value } })),
      1000,
    ),
  ).current;

  const handleValueChange = (field: 'username' | 'password' | 'shopId') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = field !== 'shopId' ? event.target.value : parseInt(event.target.value, 10);

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  useEffect(() => {
    setSettings(lightspeedSettings);
  }, [lightspeedSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('lightspeed.creds') as string} desc={translate('lightspeed.creds-desc') as string} />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('lightspeed.shopId')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('lightspeed.shopId') as string}
                  className={classes.w100}
                  value={settings ? settings.shopId : ''}
                  onChange={handleValueChange('shopId')}
                  type="number"
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('lightspeed.username')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('lightspeed.username') as string}
                  className={classes.w100}
                  value={settings ? settings.username : ''}
                  onChange={handleValueChange('username')}
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('lightspeed.password')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('lightspeed.password') as string}
                  className={classes.w100}
                  value={settings ? settings.password : ''}
                  onChange={handleValueChange('password')}
                />
              </Grid>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  lightspeedSettings: state.lightspeed.settings,
});

const mapDispatchToProps = {
  getSettingsRequest,
  putSettingsRequest: putSettingsRequestAction,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthCreds),
);
