import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  blackColor,
  primaryColor,
} from '../../material-dashboard-pro-react.js';

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: 4,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  cardHeaderBlank: {
    background: 'whiteColor',
    backgroundImage: 'none',
    padding: '0',
    margin: '20px 20px 0 20px',
    boxShadow: 'none',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  cardName: {
    color: blackColor,
    fontWeight: '500',
    margin: '0',
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  noFlex: {
    display: 'block',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  fullWidth: {
    width: '100%',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: whiteColor,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: 0,
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  link: {
    color: primaryColor[0],
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:focus,&:hover': {
      color: primaryColor[2],
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
});

export default loginPageStyle;
