import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'DELIVERY';
export const GET_DELIVERY_DATA = createRequestTypes(`${filePrefix}_GET_DELIVERY_DATA`);
export const GET_DELIVERY_ADDRESSES = createRequestTypes(`${filePrefix}_GET_DELIVERY_ADDRESSES`);

export const getDeliveryDataRequest = (data: any) => ({
  type: GET_DELIVERY_DATA.REQUEST,
  data,
});
export const getDeliveryDataSuccess = (data: any) => ({
  type: GET_DELIVERY_DATA.SUCCESS,
  data,
});
export const getDeliveryDataFailure = (error: any) => ({
  type: GET_DELIVERY_DATA.FAILURE,
  error,
});

export const getDeliveryAddressesRequest = (data: any) => ({
  type: GET_DELIVERY_ADDRESSES.REQUEST,
  data,
});
export const getDeliveryAddressesSuccess = (data: any) => ({
  type: GET_DELIVERY_ADDRESSES.SUCCESS,
  data,
});
export const getDeliveryAddressesFailure = (error: any) => ({
  type: GET_DELIVERY_ADDRESSES.FAILURE,
  error,
});
