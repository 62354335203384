import { createStyles, Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';

import commonStyles from '../../assets/jss/commonStyles';

import SettingsContainer from '../../components/NewUI/SettingsContainer';
import Section from './Widgets/Section/Section';
import BexioSection from './Widgets/Section/BexioSection';

const styles = ({ palette, spacing }: Theme) => createStyles(commonStyles);

function EmailSettings(props: any) {
  const { translate } = props;
  return (
    <SettingsContainer appName={translate('email.title') as string}>
      <BexioSection />
      <Section type="delivery" />
      <Section type="invoice" />
    </SettingsContainer>
  );
}

EmailSettings.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(withLocalize(EmailSettings));
