import { PlanType } from './types.d';

export const featureNames = [
    'customizable',
    'unlimited',
];

export const plans: PlanType[] = [
  {
    name: 'basic',
    features: [],
    params: {
      price: '27.40',
      num_reminders: '50',
      setup_fee: '119.90',
    },
  },
  {
    name: 'brass',
    features: [],
    params: {
      price: '37.40',
      num_reminders: '250',
      setup_fee: '119.90',
    },
  },
  {
    name: 'bronze',
    features: [
      'customizable',
    ],
    params: {
      price: '54.40',
      num_reminders: '500',
      setup_fee: '119.90',
    },
  },
  {
    name: 'silver',
    features: [
      'customizable',
    ],
    params: {
      price: '79.40',
      num_reminders: '1000',
      setup_fee: '119.90',
    },
  },
  {
    name: 'gold',
    features: [
      'customizable',
    ],
    params: {
      price: '108.40',
      num_reminders: '2000',
      setup_fee: '119.90',
    },
  },
  {
    name: 'diamond',
    features: [
      'customizable',
      'unlimited',
    ],
    params: {
      price: '164.40',
      num_reminders: '',
      setup_fee: '119.90',
    },
  },
];
