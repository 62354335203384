import commonStyles from '../../../../../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  selectSmall: {
    width: '100px',
  },
  selectMedium: {
    width: '150px',
  },
  maskedSpan: {
    font: 'inherit',
    margin: '0',
    padding: '0',
    position: 'absolute',
    height: '0',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
  maskedInput: {
    minWidth: '50px',
  },
  maskedContainer: {
    fontSize: '16px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
};

export default styles;
