import { GastrofixSettings } from '../../types/GastrofixSettings';
import {
  DELETE_CONFIG,
  GET_ACCOUNTS,
  GET_ARTICLE_FAMILIES,
  SET_CURRENT_SETTINGS,
} from './../actions/gastrofix_actions';

import { BedayaAction } from '../../types/BedayaAction';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';

export interface IGastrofixState {
  settings: GastrofixSettings;
  allSettings: GastrofixSettings[];

  articleFamilies: Array<{
    id: number;
    name: string;
    _id?: string;
  }>;

  accounts: { credit: Array<{ number: string; name: string }>; debit: Array<{ number: string; name: string }> };

  connectionStatus: any;

  isFetching: boolean;
  isFetchingDisconnect: any;
  error: any;
}

const initialState: IGastrofixState = {
  settings: {
    id: '',
    destinationSystem: null,
    apiToken: null,
    businessUnitId: null,

    creditCards: [],
    debitCards: [],
  },
  allSettings: [],

  articleFamilies: [],

  accounts: { credit: [], debit: [] },

  connectionStatus: 'loading',

  isFetching: true,
  isFetchingDisconnect: false,
  error: '',
};

export default function reducer(state: IGastrofixState = initialState, action: BedayaAction): IGastrofixState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['gastrofix'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          allSettings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['gastrofix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case GET_ARTICLE_FAMILIES.REQUEST:
    case DELETE_CONFIG.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ARTICLE_FAMILIES.SUCCESS:
      const articleFamilies: any = [];
      for (const value of Object.values(action.data)) {
        articleFamilies.push(value);
      }
      return {
        ...state,
        articleFamilies,
        isFetching: false,
      };

    case GET_ACCOUNTS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ACCOUNTS.SUCCESS:
      return {
        ...state,
        accounts: action.data.data,
        isFetching: false,
      };
    case DELETE_CONFIG.SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_ACCOUNTS.FAILURE:
    case DELETE_CONFIG.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SET_CURRENT_SETTINGS:
      if (action.data.id === 'reset') {
        return {
          ...state,
          settings: {
            id: '',
            destinationSystem: null,
            apiToken: null,
            businessUnitId: null,

            creditCards: [],
            debitCards: [],
          },
        };
      }
      return {
        ...state,
        settings: state.allSettings.find(el => el.id === action.data.id) || state.settings,
      };
    default:
      return state;
  }
}
