import { push } from 'connected-react-router';
import { ActionPattern, all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie/es6';

import { BedayaAction } from '../../types/BedayaAction';

import AuthService from '../../services/auth.service';
import { persistor } from '../storeConfig';

import {
  fetchBexioLoginInfo,
  fetchOAuthUrl,
  loginBedaya,
  loginBexio,
  postOAuthToken,
  registerBedaya,
} from '../api/auth';
import { getDebugMode, putDebugMode } from '../api/debug';
import { getUserInfo, putUserInfo } from '../api/user';

import {
  BEDAYA_LOGIN,
  BEDAYA_REGISTER,
  BEXIO_LOGIN,
  FETCH_OAUTH_URL,
  fetchOAuthUrlFailure,
  fetchOAuthUrlSuccess,
  GET_DEBUG_MODE,
  GET_USER_INFO,
  getDebugModeFailure,
  getDebugModeSuccess,
  getUserInfoFailure,
  getUserInfoSuccess,
  LOGIN,
  loginFailure,
  loginSuccess,
  LOGOUT,
  // putUserInfoFailure,
  logoutFailure,
  logoutSuccess,
  POST_OAUTH_TOKEN,
  postOAuthTokenFailure,
  postOAuthTokenSuccess,
  PUT_DEBUG_MODE,
  PUT_USER_INFO,
  putDebugModeSuccess,
  putUserInfoSuccess,
} from '../actions/auth_actions';
import { IState } from '../reducers';

function* loginSaga(action: BedayaAction) {
  yield delay(500);
  try {
    if (!AuthService.token) {
      const {
        data: { url, codeVerifier },
      } = yield call(fetchBexioLoginInfo);
      const cookies = new Cookies();
      cookies.set('bexioAuthValidation', codeVerifier, { path: '/' });
      cookies.set('flow', 'login', { path: '/' });
      window.location = url;
    }
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(loginFailure(processedError || error));
  }
}

function* bexioLoginSaga(action: BedayaAction) {
  yield delay(500);
  try {
    if (!AuthService.token) {
      const {
        data: { jwt: token },
      } = yield call(loginBexio, action.data);
      AuthService.save({ token });
    }
    yield put(loginSuccess({ token: AuthService.token }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(loginFailure(processedError || error));
  }
}

function* bedayaLoginSaga(action: BedayaAction) {
  yield delay(500);
  try {
    if (!AuthService.token) {
      const {
        data: { jwt: token },
      } = yield call(loginBedaya, action.data);
      AuthService.save({ token });
    }
    const redirectPath = yield select((state: IState) => state.auth.redirectPath);
    yield put(loginSuccess({ token: AuthService.token }));
    yield put(push(redirectPath || '/admin/apps'));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(loginFailure(processedError || error));
  }
}

function* bedayaRegisterSaga(action: BedayaAction) {
  yield delay(500);
  try {
    if (!AuthService.token) {
      const {
        data: { jwt: token },
      } = yield call(registerBedaya, action.data);
      AuthService.save({ token });
    }
    yield put(loginSuccess({ token: AuthService.token }));
    yield put(push('/admin/apps'));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(loginFailure(processedError || error));
  }
}

function* logoutSaga(action: BedayaAction) {
  yield delay(500);
  try {
    AuthService.clear();
    yield put(logoutSuccess());
    yield persistor.purge();
    yield persistor.flush();
    yield persistor.pause();
    yield put(push(action.data && action.data.lastLocation ? `/login?redirect=${action.data.lastLocation}` : '/login'));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(logoutFailure(processedError || error));
  }
}

function* getUserInfoSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getUserInfo);
    yield put(getUserInfoSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getUserInfoFailure(processedError || error));
  }
}

function* putUserInfoSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(putUserInfo, action.data);
    yield put(putUserInfoSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getUserInfoFailure(processedError || error));
  }
}

function* getDebugModeSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getDebugMode);
    yield put(getDebugModeSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getDebugModeFailure(processedError || error));
  }
}

function* putDebugModeSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(putDebugMode, action.data);
    yield put(putDebugModeSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getDebugModeFailure(processedError || error));
  }
}

function* fetchOAuthUrlSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const {
      data: { url, codeVerifier },
    } = yield call(fetchOAuthUrl, action.data.system, action.data);
    const cookies = new Cookies();
    cookies.set('bexioAuthValidation', codeVerifier, { path: '/' });
    const callResult = { system: action.data.system, url, shop: '' };
    if (action.data.system === 'shopify') {
      callResult.shop = action.data.shop;
    }
    yield put(fetchOAuthUrlSuccess(callResult));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(
      fetchOAuthUrlFailure({
        system: action.data.system,
        error: processedError || error,
      }),
    );
  }
}

function* postOAuthTokenSaga(action: BedayaAction) {
  yield delay(500);
  try {
    yield call(postOAuthToken, action.data.system, action.data);
    yield put(postOAuthTokenSuccess(action.data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(
      postOAuthTokenFailure({
        system: action.data.system,
        error: processedError || error,
      }),
    );
  }
}

function* loginWatcher() {
  yield takeLatest(LOGIN.REQUEST as ActionPattern, loginSaga);
}
function* bedayaLoginWatcher() {
  yield takeLatest(BEDAYA_LOGIN.REQUEST as ActionPattern, bedayaLoginSaga);
}
function* bedayaRegisterWatcher() {
  yield takeLatest(BEDAYA_REGISTER.REQUEST as ActionPattern, bedayaRegisterSaga);
}
function* bexioLoginWatcher() {
  yield takeLatest(BEXIO_LOGIN.REQUEST as ActionPattern, bexioLoginSaga);
}
function* logoutWatcher() {
  yield takeLatest(LOGOUT.REQUEST as ActionPattern, logoutSaga);
}
function* getUserInfoWatcher() {
  yield takeLatest(GET_USER_INFO.REQUEST as ActionPattern, getUserInfoSaga);
}
function* putUserInfoWatcher() {
  yield takeLatest(PUT_USER_INFO.REQUEST as ActionPattern, putUserInfoSaga);
}
function* fetchOAuthUrlWatcher() {
  yield takeLatest(FETCH_OAUTH_URL.REQUEST as ActionPattern, fetchOAuthUrlSaga);
}
function* postOAuthTokenWatcher() {
  yield takeLatest(POST_OAUTH_TOKEN.REQUEST as ActionPattern, postOAuthTokenSaga);
}
function* getDebugModeWatcher() {
  yield takeLatest(GET_DEBUG_MODE.REQUEST as ActionPattern, getDebugModeSaga);
}
function* putDebugModeWatcher() {
  yield takeLatest(PUT_DEBUG_MODE.REQUEST as ActionPattern, putDebugModeSaga);
}

export default function* authSaga() {
  yield all([
    fork(loginWatcher),
    fork(bedayaLoginWatcher),
    fork(bedayaRegisterWatcher),
    fork(bexioLoginWatcher),
    fork(logoutWatcher),
    fork(getUserInfoWatcher),
    fork(putUserInfoWatcher),
    fork(getDebugModeWatcher),
    fork(putDebugModeWatcher),
    fork(fetchOAuthUrlWatcher),
    fork(postOAuthTokenWatcher),
  ]);
}
