import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as bexioActions from '../../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../../store/actions/common_actions';
import { IState } from '../../../../../store/reducers';
import { BexioAccountingType } from '../../../../../types/BexioSettings';
import { EnforeCategoryMapping, EnforePaymentMapping, EnforeSettings } from '../../../../../types/EnforeSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import PaymentMapping from './PaymentMapping';
import SalesCategories from './SalesCategories';
import Voucher from './Voucher';

const Accounting = () => {
  const dispatch = useDispatch();
  const enforeConnectionStatus = useSelector<IState, string>(state => state.enfore.connectionStatus);
  const bexioConnectionStatus = useSelector<IState, string>(state => state.bexio.connectionStatus);
  const bexioAccounting = useSelector<IState, BexioAccountingType[]>(state => state.bexio.accounting);
  const enforeSettings = useSelector<IState, EnforeSettings>(state => state.enfore.settings);

  const [settings, setSettings] = React.useState<EnforeSettings | null>(enforeSettings);
  React.useEffect(() => {
    if (enforeSettings) {
      setSettings(enforeSettings);
    }
  }, [enforeSettings]);

  const [accountsList, setAccountsList] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (bexioConnectionStatus === 'ok') {
      dispatch(bexioActions.getAccountingRequest({}));
    }
  }, [bexioConnectionStatus]);
  React.useEffect(() => {
    if (bexioAccounting) {
      setAccountsList(
        bexioAccounting.map((account: BexioAccountingType) => ({
          value: account.account_no,
          label: `${account.account_no} ${account.name}`,
        })),
      );
    }
  }, [bexioAccounting]);

  // const [paymentMapping, setPaymentMapping] = React.useState<EnforePaymentMapping[]>([]);
  // const [categoryMapping, setCategoryMapping] = React.useState<EnforeCategoryMapping>({
  //  mapping: [],
  //  allCategoriesAccountNo: null,
  //  isSingle: false,
  // });
  // const [voucher, setVoucher] = React.useState<number | null>(null);

  if (bexioConnectionStatus !== 'ok' || enforeConnectionStatus !== 'ok' || !settings) {
    return null;
  }
  const paymentMapping: EnforePaymentMapping[] = settings.paymentMapping ? settings.paymentMapping : [];
  const categoryMapping: EnforeCategoryMapping = settings.categoryMapping
    ? settings.categoryMapping
    : {
        mapping: [],
        allCategoriesAccountNo: null,
        isSingle: false,
      };
  const voucher = settings.voucherMapping ? settings.voucherMapping : null;
  const setPaymentMapping = (pm: EnforePaymentMapping[]) => {
    setSettings({ ...settings, paymentMapping: pm });
    dispatch(commonActions.putSettingsRequest({ system: 'enfore', data: { paymentMapping: pm } }));
  };
  const setCategoryMapping = (cm: EnforeCategoryMapping) => {
    setSettings({ ...settings, categoryMapping: cm });
    dispatch(commonActions.putSettingsRequest({ system: 'enfore', data: { categoryMapping: cm } }));
  };
  const setVoucher = (v: number | null) => {
    setSettings({ ...settings, voucherMapping: v });
    dispatch(commonActions.putSettingsRequest({ system: 'enfore', data: { voucherMapping: v } }));
  };
  return (
    <>
      <PaymentMapping accountsList={accountsList} settings={paymentMapping} setSettings={setPaymentMapping} />
      <SalesCategories accountsList={accountsList} settings={categoryMapping} setSettings={setCategoryMapping} />
      <Voucher accountsList={accountsList} settings={voucher} setSettings={setVoucher} />
    </>
  );
};

export default Accounting;
