import { BedayaAction } from '../../types/BedayaAction';

import {
  GET_PROTOCOL_DATA,
  GET_PROTOCOL_EXIT_STATES,
  GET_PROTOCOL_TOPICS,
  RERUN_PROTOCOL,
} from '../actions/protocol_actions';

export interface IProtocolState {
  protocolData: any;
  exitStates: string[];
  topics: string[];

  isFetching: any;
  reRunning: boolean;
  error: any;
}

const initialState: IProtocolState = {
  protocolData: [],
  exitStates: [],
  topics: [],

  isFetching: false,
  reRunning: false,
  error: '',
};

export default function reducer(state: IProtocolState = initialState, action: BedayaAction): IProtocolState {
  switch (action.type) {
    case GET_PROTOCOL_DATA.REQUEST:
      return { ...state, isFetching: true, error: '' };

    case RERUN_PROTOCOL.REQUEST:
      return { ...state, reRunning: true, error: '' };

    case GET_PROTOCOL_DATA.SUCCESS:
      return { ...state, protocolData: action.data, isFetching: false };

    case RERUN_PROTOCOL.SUCCESS:
      return { ...state, reRunning: false };

    case GET_PROTOCOL_EXIT_STATES.SUCCESS:
      return { ...state, exitStates: action.data };

    case GET_PROTOCOL_TOPICS.SUCCESS:
      return { ...state, topics: action.data };

    case GET_PROTOCOL_DATA.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}
