import { createStyles, Grid, Theme } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch } from 'react-redux';

import commonStyles from '../../../../assets/jss/commonStyles';

import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { getSettingsRequest } from '../../../../store/actions/common_actions';
import EmailField from './../EmailField/EmailField';

const styles = ({ palette, spacing }: Theme) => createStyles(commonStyles);
type FieldOptions = {
  text: string;
  multiple?: boolean;
  hiddenFor: string[];
};

const fields: FieldOptions[] = [
  { text: 'recipient', multiple: true, hiddenFor: ['invoice_by_bexio'] },
  { text: 'CC', multiple: true, hiddenFor: ['invoice_by_bexio'] },
  { text: 'subject', hiddenFor: [] },
  { text: 'text', hiddenFor: [] },
];

function Section(props: any) {
  const { translate, type } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Section -> type', type);
    dispatch(getSettingsRequest({ system: 'email', type }));
  }, []);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate(`email.${type}`) as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div style={{ width: '100%' }}>
            {fields
              .filter(el => !el.hiddenFor.includes(type))
              .map((field, index) => (
                <Grid key={index} container={true} style={{ marginBottom: 15 }}>
                  <Grid item={true} md={3}>
                    <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                      {translate(`email.${field.text}`)}
                    </span>
                  </Grid>
                  <Grid item={true} md={9}>
                    <EmailField type={type} field={field.text as 'sender' | 'recipient' | 'subject' | 'text' | 'CC'} />
                    {field.multiple && (
                      <span style={{ color: 'gray', fontSize: 14 }}>{translate('email.multiple')}</span>
                    )}
                  </Grid>
                </Grid>
              ))}
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
}

export default withStyles(styles)(withLocalize(Section));
