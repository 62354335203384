// global imports
import { Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import commonStyles from '../../assets/jss/commonStyles';
import * as commonActions from '../../store/actions/common_actions';
import { IState } from '../../store/reducers';

// project types imports
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import { StyledSwitch } from '../StyledSwitch';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

export const isUrl = (url: string | null) => {
  if (!url) {
    return false;
  }
  return url.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  );
};

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const WebhookSettings = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { bexioSettings, putSettingsRequest, translate } = props;
  const [enabled, setEnabled] = useState(bexioSettings ? !!bexioSettings.enableWebhookInvoiceUrl : false);

  const [currentValue, setCurrentValue] = useState(bexioSettings && bexioSettings.webhookInvoiceUrl);
  const delayedInput = useRef(
    _.debounce(value => {
      if (isUrl(value)) {
        if (bexioSettings) {
          putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, webhookInvoiceUrl: value } });
        }
      }
    }, 1000),
  ).current;

  useEffect(() => {
    setEnabled(bexioSettings ? bexioSettings.enableWebhookInvoiceUrl : false);
    setCurrentValue(bexioSettings ? bexioSettings.webhookInvoiceUrl : null);
  }, [bexioSettings]);

  const handleValueChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const value = ev.target.value;

    setCurrentValue(value);
    delayedInput(value);
  };

  const handleCheckedChange = () => {
    const newValue = !enabled;
    setEnabled(newValue);
    putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, enableWebhookInvoiceUrl: newValue } });
  }

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={4}>
          <InputLabel className={classes.pt10}>{translate('bexio.enable-webhook')}</InputLabel>
        </Grid>
        <Grid item={true} xs={8}>
          <StyledSwitch
            checked={enabled}
            onChange={handleCheckedChange}
            disabled={!bexioSettings}
          />
        </Grid>
      </Grid>

      {enabled && (
        <Grid container={true}>
          <Grid item={true} xs={4}>
            <InputLabel className={classes.pt10}>{translate('bexio.webhook-url')}</InputLabel>
          </Grid>
          <Grid item={true} xs={8}>
            <TextField
              className={classes.w100}
              placeholder={translate('bexio.webhook-url') as string}
              error={!isUrl(currentValue)}
              value={currentValue}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
  connectionStatus: state.bexio.connectionStatus,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(WebhookSettings));
