// global imports
import { Grid, IconButton, Input, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import _ from 'lodash';
import FormCard from '../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../components/NewUI/GridRow';
import Label from '../../../../../components/NewUI/Label/Label';
import { putSettingsRequest } from '../../../../../store/actions/common_actions';
import { IState } from '../../../../../store/reducers';
import { XeniaPosSettings } from '../../../../../types/XeniaPosSettings';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';
import commonStyles from '../../../../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  selectSmall: {
    width: '100px',
  },
  selectMedium: {
    width: '150px',
  },
  maskedSpan: {
    font: 'inherit',
    margin: '0',
    padding: '0',
    position: 'absolute',
    height: '0',
    overflow: 'hidden',
    whiteSpace: 'pre',
  },
  maskedInput: {
    minWidth: '50px',
  },
  maskedContainer: {
    fontSize: '16px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
};

const useStyles = makeStyles<Theme, IStyleProps>(styles as StyleRules);

type StateProps = {
  xeniaPosSettings: XeniaPosSettings | null;
};

type Props = StateProps & LocalizeContextProps;

const Credentials = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { xeniaPosSettings, translate } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<XeniaPosSettings | null>(xeniaPosSettings);

  const delayedInput = useRef(
    _.debounce((field, value) => dispatch(putSettingsRequest({ system: 'xeniapos', data: { [field]: value } })), 1000),
  ).current;

  const handleValueChange = (field: 'user' | 'password' | 'dbName') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    setSettings(xeniaPosSettings);
  }, [xeniaPosSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('xenia-pos.credentials') as string}
          desc={translate('xenia-pos.credentials-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('xenia-pos.user')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('xenia-pos.user') as string}
                  className={classes.w100}
                  value={settings ? settings.user : ''}
                  onChange={handleValueChange('user')}
                  disabled={!xeniaPosSettings}
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('xenia-pos.password')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <Input
                  placeholder={translate('xenia-pos.password') as string}
                  className={classes.w100}
                  value={settings ? settings.password : ''}
                  onChange={handleValueChange('password')}
                  disabled={!xeniaPosSettings}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('xenia-pos.db-name')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('xenia-pos.db-name') as string}
                  className={classes.w100}
                  value={settings ? settings.dbName : ''}
                  onChange={handleValueChange('dbName')}
                  disabled={!xeniaPosSettings}
                />
              </Grid>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  xeniaPosSettings: state.xeniaPos.settings,
});

export default withLocalize(
  connect(mapStateToProps)(Credentials),
);
