import { clientApp } from './index';

export const getDeliveryData = ({ start, end }: { start: string | null; end: string | null }) => {
  if (!start || !end) {
    return clientApp.get(`/ui/bexio/delivery/list`);
  }
  return clientApp.get(`/ui/bexio/delivery/search?start=${start}&end=${end}`);
};

export const getDeliveryStructuredAddressData = (ids: number[]) => {
  console.log(`/ui/bexio/delivery/addresses/${ids.join()}`);
  return clientApp.get(`/ui/bexio/delivery/addresses/${ids.join()}`);
};
