// global imports
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import { cloneDeep, find, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as commonActions from '../../../../store/actions/common_actions';
import * as sumupActions from '../../../../store/actions/sumup_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import { SumupSettings } from '../../../../types/SumupSettings';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

const cardTypes: IOptionType[] = [
  { value: 'cash', label: 'Cash' },
  { value: 'maestro', label: 'Maestro' },
  { value: 'visa', label: 'Visa' },
  { value: 'mastercard', label: 'Mastercard' },
  { value: 'amex', label: 'Amex' },
  { value: 'visa_electron', label: 'VISA ELECTRON' },
  { value: 'visa_vpay', label: 'VISA VPAY' },
];

const DebitAccounts: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, sumupSettings, sumupAccounts } = props;

  const [settings, setSettings] = useState<SumupSettings | null>(null);

  const [debitAccounts, setDebitAccounts] = useState<IOptionType[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<Array<IOptionType | undefined>>([]);
  const [selectedDebits, setSelectedDebits] = useState<Array<IOptionType | undefined>>([]);

  // didMount fetch data
  useEffect(() => {
    // props.getSettingsRequest({ system: 'sumup' });
    props.getAccountsRequest('debit');
  }, []);

  // convert account data for react-select
  useEffect(() => {
    if (sumupAccounts.debit) {
      setDebitAccounts(
        sumupAccounts.debit.map((account: any) => ({
          value: parseInt(account.number, 10),
          label: `${account.number}: ${account.name}`,
        })),
      );
    }
  }, [sumupAccounts]);

  // update settings
  useEffect(() => {
    if (sumupSettings) {
      const newSettings = { ...cloneDeep(sumupSettings) };
      if (!newSettings.transaction.debitAccounts.length) {
        newSettings.transaction.debitAccounts.push({
          number: 0,
          payMethod: '',
          isDefault: true,
        });
      }
      setSettings(newSettings);
      if (debitAccounts && sumupSettings.transaction.debitAccounts) {
        setSelectedDebits(
          sumupSettings.transaction.debitAccounts.map((account: any) =>
            debitAccounts.find(accountOption => accountOption.value === account.number),
          ),
        );
        setSelectedTypes(
          sumupSettings.transaction.debitAccounts.map((account: any) =>
            cardTypes.find(typeOption => typeOption.value === account.payMethod),
          ),
        );
      }
    }
  }, [sumupSettings, debitAccounts]);

  // save settings to backend
  useEffect(() => {
    // only save when all data available
    if (
      debitAccounts.length &&
      sumupSettings &&
      settings &&
      // do not save until all values are valid
      !settings.transaction.debitAccounts.find(account => !account.number || !account.payMethod) &&
      !isEqual(sumupSettings, settings)
    ) {
      const updatedSettings = {
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: [
            ...settings.transaction.debitAccounts,
            // .filter(account => account.number && account.payMethod)
          ],
        },
      };
      if (!isEqual(sumupSettings, updatedSettings)) {
        props.putSettingsRequest({ system: 'sumup', data: updatedSettings });
      }
    }
  }, [settings]);

  const addDebitAccount = (key?: number) => {
    if (settings) {
      setSelectedDebits([...selectedDebits, typeof key !== 'undefined' ? selectedDebits[key] : undefined]);
      setSelectedTypes([...selectedTypes, typeof key !== 'undefined' ? selectedTypes[key] : undefined]);
      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: [
            ...settings.transaction.debitAccounts,
            typeof key !== 'undefined'
              ? { ...settings.transaction.debitAccounts[key], isDefault: false }
              : {
                  number: 0,
                  payMethod: '',
                  isDefault: !settings.transaction.debitAccounts.length,
                },
          ],
        },
      });
    }
  };
  const removeDebitAccount = (key: any) => {
    if (settings) {
      let newDebits = [
        ...settings.transaction.debitAccounts.slice(0, key),
        ...settings.transaction.debitAccounts.slice(key + 1),
      ];

      if (newDebits.find(account => !account.number || !account.payMethod) || newDebits.length === 0) {
        newDebits = settings.transaction.debitAccounts;
      } else {
        setSelectedTypes([...selectedTypes.slice(0, key), ...selectedTypes.slice(key + 1)]);
        setSelectedDebits([...selectedDebits.slice(0, key), ...selectedDebits.slice(key + 1)]);
      }

      const hasDefault = find(newDebits, debit => debit && debit.isDefault);

      if (!hasDefault) {
        newDebits[0].isDefault = true;
      }

      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: newDebits,
        },
      });
    }
  };

  const toggleDefault = (key: any) => {
    if (settings) {
      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: settings.transaction.debitAccounts.map((account, index) => ({
            ...account,
            isDefault: index === key,
          })),
        },
      });
    }
  };

  const typeChanged = (key: number, value: IOptionType) => {
    const selectedOption = cardTypes.find(typeOption => typeOption.value === value.value);
    if (selectedOption) {
      setSelectedTypes([...selectedTypes.slice(0, key), selectedOption, ...selectedTypes.slice(key + 1)]);
    }
    if (settings) {
      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: [
            ...settings.transaction.debitAccounts.slice(0, key),
            { ...settings.transaction.debitAccounts[key], payMethod: value.value },
            ...settings.transaction.debitAccounts.slice(key + 1),
          ],
        },
      });
    }
  };
  const debitChanged = (key: number, value: IOptionType) => {
    const selectedOption = debitAccounts.find(accountOption => accountOption.value === value.value);
    if (selectedOption) {
      setSelectedDebits([...selectedDebits.slice(0, key), selectedOption, ...selectedDebits.slice(key + 1)]);
    }
    if (settings) {
      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          debitAccounts: [
            ...settings.transaction.debitAccounts.slice(0, key),
            { ...settings.transaction.debitAccounts[key], number: parseInt(value.value, 10) },
            ...settings.transaction.debitAccounts.slice(key + 1),
          ],
        },
      });
    }
  };

  return debitAccounts.length ? (
    <div className={classes.contentLarge}>
      {settings && (
        <>
          {settings.transaction.debitAccounts.map((account, key) => (
            <div
              key={key}
              className={cx({
                [classes.errorOutline]: !account.number || !account.payMethod,
              })}
            >
              {key === 0 && (
                <div key={'header'}>
                  <InputLabel className={classes.selectSmall}>{translate('payment_method')}</InputLabel>
                  <InputLabel className={classes.selectSmall}>{translate('debit_account')}</InputLabel>
                  <InputLabel className={classes.selectSmallerCenteredFixed}>{translate('default')}</InputLabel>
                </div>
              )}
              <Select
                isSearchable={false}
                className={classes.selectSmallFixed}
                options={cardTypes}
                value={selectedTypes[key] || null}
                onChange={(value: any) => typeChanged(key, value)}
                styles={colourStyles}
              />
              <Select
                isSearchable={false}
                className={classes.selectSmallFixed}
                options={debitAccounts}
                value={selectedDebits[key] || null}
                onChange={(value: any) => debitChanged(key, value)}
                styles={colourStyles}
              />
              <div className={classes.selectSmallerCenteredFixed}>
                <Checkbox onChange={() => toggleDefault(key)} checked={account.isDefault} />
              </div>
              <Button justIcon={true} round={true} color="transparent" onClick={() => removeDebitAccount(key)}>
                <DeleteIcon />
              </Button>
            </div>
          ))}
          <Button
            className={classes.addButton}
            justIcon={true}
            round={true}
            disabled={!debitAccounts.length}
            onClick={() => addDebitAccount()}
          >
            <AddIcon />
          </Button>
        </>
      )}
    </div>
  ) : (
    <LinearProgress className={classes.contentLarge} />
  );
};

const defaultProps = {
  quickLoad: false,
};
DebitAccounts.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  sumupSettings: state.sumup.settings,
  sumupAccounts: state.sumup.accounts,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  getAccountsRequest: sumupActions.getAccountsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(DebitAccounts));
