// global imports
import { FormControl, Grid, InputLabel, makeStyles, RadioGroup, Theme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { useSelector } from 'react-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { StyledRadio } from '../../../../components/StyledRadio';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import { BexioSettings } from '../../../../types/BexioSettings';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import AfterOrderCreation from '../../BexioWidgets/AfterOrderCreation/AfterOrderCreation';
import WriteBackPaidInvoices from '../../BexioWidgets/WriteBackPaidInvoices/WriteBackPaidInvoices';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

type Props = LocalizeContextProps & {
    targetEntity?: string;
    onChange: (targetEntity: string) => void;
    system: string;
};

const BaseStoreOrderAs = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, targetEntity, onChange, system } = props;

  const bexioSettings = useSelector<IState, BexioSettings | null>(({ bexio: { settings } }) => settings);
  const [generateInvoiceFromOrder, setGenerateInvoiceFromOrder] = useState<boolean | null>(null);
  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    setGenerateInvoiceFromOrder(bexioSettings.generateInvoiceFromOrder);
  }, [bexioSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('prestashop.basic-settings') as string}
          desc={translate('prestashop.basic-desc', { system }) as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard style={{ display: 'block' }}>
          <Grid container={true}>
            <Grid container={true}>
              <Grid item={true} md={4} style={{ padding: '13px 15px' }}>
                <InputLabel>{translate('prestashop.what-create')}</InputLabel>
              </Grid>
              <Grid item={true} md={8} style={{ padding: '0 23px' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={targetEntity ?? 'invoice'}
                    onChange={(_, value) => onChange(value)}
                    className={classes.radio}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <FormControlLabel value="invoice" control={<StyledRadio />} label={translate('shopify.invoice')} />
                    {(system !== "wix") && (
                      <FormControlLabel value="offer" control={<StyledRadio />} label={translate('shopify.offer')} />
                    )}
                    <FormControlLabel value="order" control={<StyledRadio />} label={translate('shopify.order')} />
                  </RadioGroup>
                  {targetEntity === 'order' && <AfterOrderCreation />}
                </FormControl>
              </Grid>
            </Grid>
            {(system === "prestashop") && (targetEntity === 'invoice' || (targetEntity === 'order' && generateInvoiceFromOrder)) && (
              <WriteBackPaidInvoices system={system} />
            )}
          </Grid>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(BaseStoreOrderAs);
