import { BedayaAction } from '../../types/BedayaAction';
import {
  BexioAccountingType,
  BexioAccountType,
  BexioCountry,
  BexioPaymentType,
  BexioSettings,
  BexioTaxType,
} from '../../types/BexioSettings';

import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import {
  EXPORT_PRODUCTS,
  GET_ACCOUNTING,
  GET_BANK_ACCOUNTS,
  GET_COUNTRIES,
  GET_CUSTOMER,
  GET_PAYMENT_TYPES,
  GET_REMINDER_SETTINGS,
  GET_SHIPPING_PRODUCT,
  GET_TAXES,
  GET_USERS,
  GET_WRAPPING_PRODUCT,
  PUT_REMINDER_SETTINGS,
  SEARCH_CUSTOMER,
  SEARCH_SHIPPING_PRODUCT,
  SEARCH_WRAPPING_PRODUCT,
  TEST_CONNECTION,
} from '../actions/bexio_actions';
import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB } from '../actions/common_actions';

export interface IBexioState {
  settings: BexioSettings | null;

  taxes: BexioTaxType[];
  bankAccounts: BexioAccountType[];
  accounting: BexioAccountingType[];
  customerFetchResult: any;
  customersSearchResult: any;
  countries: BexioCountry[];

  shippingProductFetchResult: any;
  shippingProductSearchResult: any;
  wrappingProductFetchResult: any;
  wrappingProductSearchResult: any;

  usersFetchResult: any;

  lastRun: any;
  lastJob: any;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;

  reminderSettings: any;
  exportProductsProgress: boolean;
  paymentTypes: BexioPaymentType[];
}

const initialState: IBexioState = {
  settings: null,

  taxes: [],
  bankAccounts: [],
  accounting: [],
  customerFetchResult: null,
  customersSearchResult: [],
  shippingProductFetchResult: null,
  shippingProductSearchResult: [],
  wrappingProductFetchResult: null,
  wrappingProductSearchResult: [],
  usersFetchResult: [],
  countries: [],

  lastRun: null,
  lastJob: null,

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'nook',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',

  reminderSettings: null,
  exportProductsProgress: false,
  paymentTypes: [],
};

try {
  const lastRun = localStorage.getItem('bexioLastRun');
  const lastJob = localStorage.getItem('bexioLastJob');
  initialState.lastRun = lastRun ? JSON.parse(lastRun) : null;
  initialState.lastJob = lastJob ? JSON.parse(lastJob) : null;
} catch (e) {
  console.warn(e);
}

export default function reducer(state: IBexioState = initialState, action: BedayaAction): IBexioState {
  switch (action.type) {
    // protocol actions
    case TEST_CONNECTION.REQUEST:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['bexio'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          lastRun: null,
          lastJob: null,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case RUN_JOB.SUCCESS:
    case GET_JOB.SUCCESS:
      if (['bexio'].includes(action.data.system)) {
        localStorage.setItem(`${action.data.system}LastRun`, JSON.stringify(action.data.data));
        localStorage.setItem(`${action.data.system}LastJob`, JSON.stringify(action.data.jobNumber));
        return {
          ...state,
          lastRun: action.data.data,
          lastJob: action.data.jobNumber,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case RUN_JOB.FAILURE:
    case GET_JOB.FAILURE:
      if (['bexio'].includes(action.data.system)) {
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          lastRun: null,
          lastJob: null,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['bexio'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    // own actions
    case GET_TAXES.REQUEST:
    case GET_BANK_ACCOUNTS.REQUEST:
    case GET_ACCOUNTING.REQUEST:
    case GET_CUSTOMER.REQUEST:
    case GET_SHIPPING_PRODUCT.REQUEST:
    case GET_WRAPPING_PRODUCT.REQUEST:
    case SEARCH_CUSTOMER.REQUEST:
    case SEARCH_SHIPPING_PRODUCT.REQUEST:
    case SEARCH_WRAPPING_PRODUCT.REQUEST:
    case GET_USERS.REQUEST:
    case GET_REMINDER_SETTINGS.REQUEST:
    case PUT_REMINDER_SETTINGS.REQUEST:
    case GET_PAYMENT_TYPES.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_TAXES.SUCCESS:
      return {
        ...state,
        taxes: action.data,
        isFetching: false,
      };
    case GET_BANK_ACCOUNTS.SUCCESS:
      return {
        ...state,
        bankAccounts: action.data,
        isFetching: false,
      };
    case GET_ACCOUNTING.SUCCESS:
      return {
        ...state,
        accounting: action.data,
        isFetching: false,
      };
    case GET_CUSTOMER.SUCCESS:
      return {
        ...state,
        customerFetchResult: action.data,
        isFetching: false,
      };
    case SEARCH_CUSTOMER.SUCCESS:
      return {
        ...state,
        customersSearchResult: action.data,
        isFetching: false,
      };
    case GET_SHIPPING_PRODUCT.SUCCESS:
      return {
        ...state,
        shippingProductFetchResult: action.data,
        isFetching: false,
      };
    case GET_WRAPPING_PRODUCT.SUCCESS:
      return {
        ...state,
        wrappingProductFetchResult: action.data,
        isFetching: false,
      };
    case SEARCH_SHIPPING_PRODUCT.SUCCESS:
      return {
        ...state,
        shippingProductSearchResult: action.data,
        isFetching: false,
      };
    case SEARCH_WRAPPING_PRODUCT.SUCCESS:
      return {
        ...state,
        wrappingProductSearchResult: action.data,
        isFetching: false,
      };
    case GET_USERS.SUCCESS:
      return {
        ...state,
        usersFetchResult: action.data,
        isFetching: false,
      };
    case GET_REMINDER_SETTINGS.SUCCESS:
    case PUT_REMINDER_SETTINGS.SUCCESS:
      return {
        ...state,
        reminderSettings: action.data,
        isFetching: false,
      };
    case GET_PAYMENT_TYPES.SUCCESS:
      return {
        ...state,
        paymentTypes: action.data,
        isFetching: false,
      };
    case GET_TAXES.FAILURE:
    case GET_BANK_ACCOUNTS.FAILURE:
    case GET_ACCOUNTING.FAILURE:
    case GET_CUSTOMER.FAILURE:
    case GET_SHIPPING_PRODUCT.FAILURE:
    case GET_WRAPPING_PRODUCT.FAILURE:
    case SEARCH_CUSTOMER.FAILURE:
    case GET_USERS.FAILURE:
    case GET_REMINDER_SETTINGS.FAILURE:
    case PUT_REMINDER_SETTINGS.FAILURE:
    case SEARCH_SHIPPING_PRODUCT.FAILURE:
    case SEARCH_WRAPPING_PRODUCT.FAILURE:
    case GET_PAYMENT_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case EXPORT_PRODUCTS.REQUEST:
      return {
        ...state,
        exportProductsProgress: true,
      };
    case EXPORT_PRODUCTS.FAILURE:
    case EXPORT_PRODUCTS.SUCCESS:
      return {
        ...state,
        exportProductsProgress: false,
      };
    case GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: action.data,
      };
    default:
      return state;
  }
}
