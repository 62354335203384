// global imports
import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import * as commonsActions from '../../../../store/actions/commons_actions';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Button from '../../../../components/CustomButtons/Button';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { getPlatformName } from '../../../../services/utils';
import { IState } from '../../../../store/reducers';
import BitrixUrl from '../../BitrixWidgets/BitrixUrl/BitrixUrl';
import ProductFieldMapping from '../../BitrixWidgets/ProductFieldMapping/ProductFieldMapping';
import OrderFieldMapping from '../../BitrixWidgets/OrderFieldMapping/OrderFieldMapping';
import CompanyFieldMapping from '../../BitrixWidgets/CompanyFieldMapping/CompanyFieldMapping';
import SwitchWidget from '../../BitrixWidgets/SwitchWidget/SwitchWidget';
import DummyEmail from '../../BitrixWidgets/DummyEmail/DummyEmail';
import SourceSystem from '../../BitrixWidgets/SourceSystem/SourceSystem';
import SingleFieldMapping from '../../BitrixWidgets/SingleFieldMapping/SingleFieldMapping';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const BitrixApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    OAuthURL,
    connectionStatus,
    fetchOAuthUrlRequest,
    testConnectionRequest,
    getSettingsRequest,
    bitrixSettings,
    getCanonicalFieldsRequest,
  } = props;

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL) {
      fetchOAuthUrlRequest({ system: 'bitrix' });
    }

    testConnectionRequest({ system: 'bitrix' });

    getSettingsRequest({ system: 'bitrix' });
  }, []);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'bitrix' });
      }
    }
  }, [connectionStatus]);

  const startAssistant = () => {
    props.history.push(`/admin/apps/bitrix`);
  };

  useEffect(() => {
    getCanonicalFieldsRequest({});
  }, []);

  return (
    <SettingsContainer appName="Bitrix 24">
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('bitrix.status', { platform: getPlatformName() }) as string} desc="" />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            {connectionStatus !== 'loading' && !props.loading ? (
              connectionStatus === 'ok' ? (
                <div className={classes.connectedContainer}>
                  <span className={classes.connectedStatus}>{translate('connected')}</span>
                  <DisconnectWidget appName="bitrix" />
                </div>
              ) : (
                <ConnectWidget
                  appName="bitrix"
                  error={bitrixSettings && !bitrixSettings.bitrixUrl ? 'Bitrix URL required' : undefined}
                  disabled={!bitrixSettings || !bitrixSettings.bitrixUrl}
                />
              )
            ) : (
              <CircularProgress />
            )}
          </FormCard>
        </Grid>
      </GridRow>
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('bexio_app_name') as string} desc="" />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <Button onClick={() => props.history.push("/admin/bexio-app")} color="primary">
              {translate('bexio_app_name')}
            </Button>
          </FormCard>
        </Grid>
      </GridRow>

      {bitrixSettings ? (
        true ? (
          <>
            <BitrixUrl />
            <SwitchWidget fieldName="productToBitrix" labelName="bitrix.product-to-bitrix" />
            <SourceSystem />
            <ProductFieldMapping />
            <OrderFieldMapping />
            <CompanyFieldMapping />
            <SingleFieldMapping fieldName="orderUrlField" entityName="order" labelName="bitrix.order-url-field" />
            <SingleFieldMapping fieldName="orderPdfField" entityName="order" labelName="bitrix.order-pdf-field" />
            <SingleFieldMapping fieldName="orderStatusField" entityName="order" labelName="bitrix.order-status-field" />
            <SingleFieldMapping fieldName="orderInvoiceStatusField" entityName="order" labelName="bitrix.order-invoice-status-field" />
            <SingleFieldMapping fieldName="orderInvoiceDateField" entityName="order" labelName="bitrix.order-invoice-date-field" />
            <SingleFieldMapping fieldName="orderInvoiceUrlField" entityName="order" labelName="bitrix.order-invoice-url-field" />
            <SingleFieldMapping fieldName="orderInvoicePdfField" entityName="order" labelName="bitrix.order-invoice-pdf-field" />
            <SingleFieldMapping fieldName="orderVatField" entityName="order" labelName="bitrix.order-vat-field" />
            <DummyEmail />
            <SwitchWidget fieldName="bexioOrderAfterTrigger" labelName="bitrix.order-after-trigger" />
            <SwitchWidget fieldName="bexioOrderPoller" labelName="bitrix.order-poller" />
            <SwitchWidget fieldName="bexioInvoicePoller" labelName="bitrix.invoice-poller" />
          </>
        ) : (
          <>
            <p>{translate('connect-bitrix-to-see-settings')}</p>
            <Button type="button" color="primary" onClick={startAssistant}>
              {translate('start-configurator')}
            </Button>
          </>
        )
      ) : (
        <CircularProgress />
      )}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  OAuthURL: state.bitrix.OAuthURL,
  bitrixSettings: state.bitrix.settings,
  connectionStatus: state.bitrix.connectionStatus,
});

const mapDispatchToProps = {
  getCanonicalFieldsRequest: commonsActions.getCanonicalFieldsRequest,
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BitrixApp),
);
