import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'WIX';
export const GET_ACCOUNTS = createRequestTypes(`${filePrefix}_GET_ACCOUNTS`);
export const HAS_SUBSCRIPTION = createRequestTypes(`${filePrefix}_HAS_SUBSCRIPTION`);
export const POST_CHECKOUT = createRequestTypes(`${filePrefix}_POST_CHECKOUT`);

export const getAccountsRequest = (data: any) => ({
  type: GET_ACCOUNTS.REQUEST,
  data,
});
export const getAccountsSuccess = (data: any) => ({
  type: GET_ACCOUNTS.SUCCESS,
  data,
});
export const getAccountsFailure = (error: any) => ({
  type: GET_ACCOUNTS.FAILURE,
  error,
});

export const hasSubscriptionRequest = () => ({
  type: HAS_SUBSCRIPTION.REQUEST,
});
export const hasSubscriptionSuccess = (data: any) => ({
  type: HAS_SUBSCRIPTION.SUCCESS,
  data,
});
export const hasSubscriptionFailure = (error: any) => ({
  type: HAS_SUBSCRIPTION.FAILURE,
  error,
});

export const postCheckoutRequest = () => ({
  type: POST_CHECKOUT.REQUEST,
});
export const postCheckoutSuccess = (data: any) => ({
  type: POST_CHECKOUT.SUCCESS,
  data,
});
export const postCheckoutFailure = (error: any) => ({
  type: POST_CHECKOUT.FAILURE,
  error,
});
