import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as bexioActions from '../../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../../store/actions/common_actions';
import { IState } from '../../../../../store/reducers';
import { BexioAccountingType } from '../../../../../types/BexioSettings';
import { XeniaPosGroupMapping, XeniaPosPaymentMapping, XeniaPosSettings, XeniaPosVoucherMapping } from '../../../../../types/XeniaPosSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import PaymentMapping from './PaymentMapping';
import GroupsMapping from './Groups';
import Voucher from './Voucher';

const Accounting = () => {
  const dispatch = useDispatch();
  const xeniaPosConnectionStatus = useSelector<IState, string>(state => state.xeniaPos.connectionStatus);
  const bexioConnectionStatus = useSelector<IState, string>(state => state.bexio.connectionStatus);
  const bexioAccounting = useSelector<IState, BexioAccountingType[]>(state => state.bexio.accounting);
  const xeniaPosSettings = useSelector<IState, XeniaPosSettings>(state => state.xeniaPos.settings);

  const [settings, setSettings] = React.useState<XeniaPosSettings | null>(xeniaPosSettings);
  React.useEffect(() => {
    if (xeniaPosSettings) {
      setSettings(xeniaPosSettings);
    }
  }, [xeniaPosSettings]);

  const [accountsList, setAccountsList] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (bexioConnectionStatus === 'ok') {
      dispatch(bexioActions.getAccountingRequest({}));
    }
  }, [bexioConnectionStatus]);
  React.useEffect(() => {
    if (bexioAccounting) {
      setAccountsList(
        bexioAccounting.map((account: BexioAccountingType) => ({
          value: account.account_no,
          label: `${account.account_no} ${account.name}`,
        })),
      );
    }
  }, [bexioAccounting]);

  if (bexioConnectionStatus !== 'ok' || xeniaPosConnectionStatus !== 'ok' || !settings) {
    return null;
  }
  const paymentMapping: XeniaPosPaymentMapping[] = settings.paymentMapping ? settings.paymentMapping : [];
  const groupMapping: XeniaPosGroupMapping = settings.groupMapping
    ? settings.groupMapping
    : {
        mapping: [],
        allGroupAccountNo: null,
        isSingle: false,
      };
  const voucher = settings.voucherMapping ? settings.voucherMapping : null;
  const setPaymentMapping = (pm: XeniaPosPaymentMapping[]) => {
    setSettings({ ...settings, paymentMapping: pm });
    dispatch(commonActions.putSettingsRequest({ system: 'xeniapos', data: { paymentMapping: pm } }));
  };
  const setGroupMapping = (gm: XeniaPosGroupMapping) => {
    setSettings({ ...settings, groupMapping: gm });
    dispatch(commonActions.putSettingsRequest({ system: 'xeniapos', data: { groupMapping: gm } }));
  };
  const setVoucher = (v: XeniaPosVoucherMapping | null) => {
    setSettings({ ...settings, voucherMapping: v });
    dispatch(commonActions.putSettingsRequest({ system: 'xeniapos', data: { voucherMapping: v } }));
  };
  return (
    <>
      <PaymentMapping accountsList={accountsList} settings={paymentMapping} setSettings={setPaymentMapping} />
      <GroupsMapping accountsList={accountsList} settings={groupMapping} setSettings={setGroupMapping} />
      <Voucher accountsList={accountsList} settings={voucher} setSettings={setVoucher} />
    </>
  );
};

export default Accounting;
