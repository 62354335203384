import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link, CircularProgress } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { withLocalize } from 'react-localize-redux';

import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import { IState } from '../../store/reducers';
import * as commonActions from '../../store/actions/common_actions';
import * as sumupActions from '../../store/actions/sumup_actions';
import * as sumupApi from '../../store/api/sumup';
import { PlanType, CardProps, PlansPageProps, StateProps } from './types.d';
import { plans, featureNames } from './data';


const featureHeights: Record<string, string> = {
  'import_orders': '2rem',
  'support': '2rem',
};

const PlanCard = ({plan, translate, loading, onSelect, isCurrent}: CardProps) => (
  <Card style={{
    height: 'auto',
    margin: '1rem 0.5rem',
    width: '13rem',
  }}>
    <CardHeader color="primary">
      <span style={{fontWeight: 'bold'}}>
        {translate(`sumup.plan-names.${plan.name}`, plan.params, { renderInnerHtml: true })}
      </span>
      <br />
      <span style={{fontWeight: 'bold'}}>
        ({translate('sumup.price_per_month', plan.params, { renderInnerHtml: true })})
      </span>
    </CardHeader>
    <CardBody>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {featureNames.map((name, idx) => (
          <div
            key={idx}
            style={{
              height: featureHeights[name] ? featureHeights[name] : '2rem',
              paddingBottom: '0.5rem',
              marginBottom: '1rem',
              display: 'flex',
            }}
          >
            {plan.features[name] ? <Check style={{ color: 'green' }} /> : null}
            {plan.features[name] ? translate(`sumup.features.${plan.features[name]}`, plan.params, { renderInnerHtml: true }) : ' '}
          </div>
        ))}
      </div>
    </CardBody>
    <Button
      type="button"
      color={isCurrent ? "secondary" : "primary"}
      disabled={loading}
      onClick={onSelect}
    >
      {isCurrent ? translate('sumup.current_plan') : translate('sumup.button_order')}
    </Button>
  </Card>
);

const PlansPage = (props: PlansPageProps) => {
  const {
    loading,
    translate,
    connectionStatus,
    subscription,
    testConnectionRequest,
    getSubscriptionRequest,
  } = props;
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const [requesting, setRequesting] = React.useState<boolean>(false);

  React.useEffect(() => {
    testConnectionRequest({ system: 'sumup' });
    getSubscriptionRequest({});
  }, []);
  React.useEffect(() => {
    if (connectionStatus === 'nok') {
      setRedirect("/admin/sumup-app");
    }
  }, [connectionStatus]);

  const onSelectPlan = (plan: PlanType) => {
    if (!subscription) {
      return;
    }
    if (!subscription.planName) {
      setRequesting(true);
      sumupApi.postSubscriptionCheckout('/admin/sumup-app', plan.name)
        .then(response => {
          setRequesting(false);
          window.location.href = response.data.url;
        })
        .catch(err => {
          setRequesting(false);
          console.log(err);
        });
    } else {
      setRequesting(true);
      sumupApi.postSubscriptionChange(plan.name)
        .then(response => {
          setRequesting(false);
          setRedirect('/admin/sumup-app');
        })
        .catch(err => {
          console.log(err);
          setRequesting(false);
        });
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />
  }
  if (loading || requesting || !subscription) {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        color: 'black',
        maxWidth: '1170px',
        margin: '0 auto',
        zIndex: 10,
        marginBottom: '5rem',
        position: 'relative',
      }}
    >
      <Link
        href="/admin/sumup-app"
        style={{
          position: 'absolute',
          right: 0,
        }}
      >
        {translate('back')}
      </Link>
      <div style={{ marginBottom: '1rem', fontSize: '1.25rem' }}>
        {translate('sumup.trial', undefined, { renderInnerHtml: true })}
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {plans.map((plan, idx) => (
          <PlanCard
            key={idx}
            plan={plan}
            translate={translate}
            loading={loading}
            onSelect={() => onSelectPlan(plan)}
            isCurrent={subscription.planName === plan.name}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  subscription: state.sumup.subscription,
  loading: state.sumup.subscriptionFetching || state.sumup.isFetching,
  connectionStatus: state.sumup.connectionStatus,
});

const mapDispatchToProps = {
  testConnectionRequest: commonActions.testConnectionRequest,
  getSubscriptionRequest: sumupActions.getSubscriptionRequest,
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(PlansPage)
);
