// global imports
import { Grid, makeStyles, Theme } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import GridItem from '../../../../components/Grid/GridItem';
import assign from '../../../../services/assign';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { ShopifySettings } from '../../../../types/ShopifySettings';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import * as shopifyActions from '../../../../store/actions/shopify_actions';
import style from './styles';
import { Props, StateProps } from './types';

const currencies: IOptionType[] = [{ label: 'CHF', value: 'chf' }, { label: 'EUR', value: 'eur' }];

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const ShopCurrency = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, shopifySettings, putSettingsForShopRequest, shopifyShops } = props;

  const [settings, setSettings] = useState<ShopifySettings | null>(null);

  useEffect(() => {
    if (shopifySettings) {
      setSettings(shopifySettings);
    }
  }, [shopifySettings]);

  const handleSelectChange = (name: any, value: IOptionType) => {
    if (settings && value) {
      const newSettings = assign({ ...settings }, name, value.value);
      setSettings(newSettings);
      const shop = shopifyShops.find(s => s.active);
      if (shop) {
        putSettingsForShopRequest({
          data: newSettings,
          shop: shop.name,
        });
      }
    }
  };

  return (
    <Grid container={true} className={classes.w100}>
      <GridItem xs={4} className={classes.gridItem}>
        <InputLabel>{translate('shopify.shop_currency')}</InputLabel>
      </GridItem>
      <GridItem xs={8} className={classes.gridItem}>
        <Select
          isSearchable={false}
          className={classes.w100}
          disabled={!settings}
          options={currencies}
          value={(settings && currencies.find(curr => curr.value === settings.shopCurrencyIso)) || currencies[0]}
          onChange={(value: ValueType<IOptionType, false>) =>
            handleSelectChange('shopCurrencyIso', value as IOptionType)
          }
          styles={colourStyles}
        />
      </GridItem>
    </Grid>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shopifySettings: state.shopify.settings,
  shopifyShops: state.shopify.shops,
});

const mapDispatchToProps = {
  putSettingsForShopRequest: shopifyActions.putSettingsForShopRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ShopCurrency),
);
