// global imports
import { Grid, Input, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Select from 'react-select';
import commonStyles from '../../../../assets/jss/commonStyles';
import * as prestashopActions from '../../../../store/actions/prestashop_actions';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const ImportOrder: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const [orderID, setOrderID] = useState<number>();
  const [shop, setShop] = useState<string>();

  const { translate, prestashopSettings, importOrderRequest } = props;

  const importOrder = () => {
    importOrderRequest({ id: orderID, shop });
    toast.success(translate('import-order-success') as string);
  };

  return (
    <>
      <h3 className={classes.bodyLg}>{translate('import_order_by_id')}</h3>
      <Grid container={true}>
        {prestashopSettings.shops.length > 1 && (
          <Grid md={2} style={{ padding: 10 }}>
            <InputLabel style={{ marginBottom: 10 }}>Shop</InputLabel>
            <Select
              isSearchable={false}
              options={prestashopSettings.shops.map(s => ({ value: s.name, label: s.name }))}
              value={prestashopSettings.shops.map(s => ({ value: s.name, label: s.name })).find(s => s.value === shop)}
              onChange={(value: any) => setShop(value ? value.value : '')}
            />
          </Grid>
        )}
        <Grid style={{ padding: 10 }}>
          <InputLabel style={{ marginBottom: 10 }}>{translate('order_id')}</InputLabel>
          <Input type="number" onChange={(evt: any) => setOrderID(evt.target.value as number)} />
        </Grid>
        <Grid style={{ padding: 5, paddingTop: 30 }}>
          <Button
            className={classes.formButton}
            type="button"
            color="primary"
            onClick={importOrder}
            disabled={!orderID || (prestashopSettings.shops.length > 1 && !shop)}
          >
            {translate('import')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const defaultProps: OwnProps = {
  testConnection: false,
  testConnectionTimeout: 3000,
  showSpinner: false,
};
ImportOrder.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  prestashopSettings: state.prestashop.settings,
});

const mapDispatchToProps = {
  importOrderRequest: prestashopActions.importOrderRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ImportOrder));
