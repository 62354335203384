// global imports
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';

// local imports
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import Accounting from './components/Accounting';
import AuthCreds from './components/AuthCreds/AuthCreds';
import Status from './components/Status';

const EnforeApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'enfore' }));
  }, []);

  return (
    <SettingsContainer appName="Swisscom POS">
      <AuthCreds />
      <Status />
      <Accounting />
      <BexioConfig system="enfore" simple={true} />;
    </SettingsContainer>
  );
};

export default EnforeApp;
