import { clientApp } from './index';

export const getAccounts = (type: string) => clientApp.get(`/ui/sumup/settings/account/${type}`);

export const getStatistics = () => clientApp.get('/ui/sumup/statistics');

export const getSubscription = () => clientApp.get('/ui/sumup/subscription');

export const postSubscriptionCheckout = (returnUrl: string, planName: string) => clientApp.post('/ui/sumup/subscription/checkout', { url: process.env.REACT_APP_FE_URL + returnUrl, planName });

export const postSubscriptionChange = (planName: string) => clientApp.post('/ui/sumup/subscription/change', { planName });

export const postSubscriptionCancel = () => clientApp.post('/ui/sumup/subscription/cancel');
