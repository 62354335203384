import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close, NavigateNext } from '@material-ui/icons';
import * as FileSaver from 'file-saver';
import Select from 'react-select';
import { useStyles } from './theme';
import { Loading, DeliveryRequest, DialogProps } from './types';
import Button from '../../components/CustomButtons/Button';
import { DpdSettings } from '../../types/DpdSettings';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';
import { IOptionType } from '../../types/ReactSelect';
import { getDpdDelivery } from '../../store/api/bexio';
import { getSettings } from '../../store/api/common';

function useDpdSettings(): [DpdSettings | null, Loading] {
  const [settings, setSettings] = useState<DpdSettings | null>(null);
  const [loading, setLoading] = useState<Loading>('initial');
  useEffect(() => {
    setLoading('pending');
    getSettings({ system: 'dpd' })
      .then(response => {
        setSettings(response.data as DpdSettings);
        setLoading('ready');
      })
      .catch(err => {
        console.log(err);
        setLoading('error');
      });
  }, [setLoading]);
  return [settings, loading];
}

function useDpdDelivery(request: DeliveryRequest | null): Loading {
  const [loading, setLoading] = useState<Loading>('initial');
  useEffect(() => {
    if (request) {
      setLoading('pending');
      getDpdDelivery(request.ids, request.quantity, request.product ?? '')
        .then(response => {
          const filename = response.headers['content-disposition']
            .split(';')
            .find((name: string) => name.includes('filename='))
            .replace('filename=', '')
            .trim()
            .replace(/^"(.*)"$/g, '$1')
            .trim();
          FileSaver.saveAs(response.data, filename);
          setLoading('ready');
        })
        .catch(err => {
          console.log(err);
          setLoading('error');
        });
    } else {
      setLoading('initial');
    }
  }, [request, setLoading]);
  return loading;
}

export function DpdDialog({ onClose, selected, show, translate }: DialogProps) {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const productOptions: IOptionType[] = [
    { value: 'B2B', label: 'DPD CLASSIC' },
    { value: 'B2BP', label: 'DPD CLASSIC Predict' },
    { value: 'PM2', label: 'DPD CLASSIC Guarantee' },
    { value: 'B2C', label: 'DPD COMFORT' },
    { value: 'AM1', label: 'DPD EXPRESS 10:00' },
    { value: 'AM2', label: 'DPD EXPRESS 12:00' },
    { value: 'IE2', label: 'DPD EXPRESS' },
    { value: 'HOME', label: 'DPD HOME' },
    { value: 'PSD', label: 'DPD SHOP' },
    { value: 'PL', label: 'DPD PARCELLETTER' },
    { value: 'PBOX', label: 'DPD PARCELBOX' },
  ];

  const [settings] = useDpdSettings();
  const [request, setRequest] = useState<DeliveryRequest | null>(null);
  const [quantity, setQuantity] = useState<string>('1');
  const [product, setProduct] = useState<string>('');
  const loading = useDpdDelivery(request);
  const closeDialog = () => {
    setRequest(null);
    setQuantity('1');
    onClose();
  };
  React.useEffect(() => {
    if (loading === 'ready') {
      closeDialog();
    }
  }, [loading]);
  React.useEffect(() => {
    if (settings && settings.product) {
      setProduct(settings.product);
    }
  }, [settings]);
  return (
    <Dialog onClose={closeDialog} aria-labelledby="customized-dialog-title" open={show}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h6">{translate('serviceAccounts.dpd.title')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        {loading === 'initial' && (
          <form
            onSubmit={e => {
              e.preventDefault();
              setRequest({ ids: selected, quantity: +quantity, product: product });
            }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1.5em', marginTop: '1.5em', width: '100%' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1em' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5em', color: 'black' }}>
                {translate('serviceAccounts.dpd.product')}
              </label>
              <Select
                isSearchable={false}
                options={productOptions}
                value={productOptions.find(x => x.value === product)}
                onChange={opt => {
                  setProduct(opt ? opt.value : '');
                }}
                isDisabled={false /*isDisabled*/}
                menuPortalTarget={document.body}
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    width: '100%',
                    maxWidth: '12.5em',
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5em', color: 'black' }}>
                {translate('serviceAccounts.dpd.quantity')}
              </label>
              <input
                type="number"
                style={{ padding: '0.5em' }}
                min={1}
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
              />
            </div>
            <div>
              <Button aria-label="copy" color="primary" type="submit">
                <NavigateNext />
              </Button>
            </div>
          </form>
        )}
        {loading === 'pending' && <CircularProgress />}
        {loading === 'error' && <span>Error</span>}
      </DialogContent>
    </Dialog>
  );
}
