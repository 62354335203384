import { PlanType } from './types.d';

export const featureNames = [
  'import_orders',
  'support',
];

export const plans: PlanType[] = [
  {
    name: 'basic',
    features: {
      import_orders: 'import_orders',
      support: 'paid_support',
    },
    params: {
      price: '4,90 CHF',
      num_orders: '50',
      support_price: '50,-',
    },
  },
  {
    name: 'bronze',
    features: {
      import_orders: 'import_orders',
      support: 'paid_support',
    },
    params: {
      price: '24,90 CHF',
      num_orders: '500',
      support_price: '50,-',
    },
  },
  {
    name: 'silver',
    features: {
      import_orders: 'import_orders',
      support: 'paid_support',
    },
    params: {
      price: '49,90 CHF',
      num_orders: '1000',
      support_price: '50,-',
    },
  },
  {
    name: 'gold',
    features: {
      import_orders: 'import_orders',
      support: 'paid_support',
    },
    params: {
      price: '74,90 CHF',
      num_orders: '2000',
      support_price: '50,-',
    },
  },
  {
    name: 'diamond',
    features: {
      import_orders: 'import_orders_unlimited',
      support: 'paid_support',
    },
    params: {
      price: '99,90 CHF',
      support_price: '50,-',
    },
  },
];
