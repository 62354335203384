import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  StyleRules,
  Theme,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';
import Button from '../CustomButtons/Button';
import style from './styles';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

type OwnProps = { onClose: () => void; onConfirm: () => void; text: string; visible: boolean } & LocalizeContextProps;

function ConfirmDialog({ onClose, onConfirm, text, visible, translate }: OwnProps) {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={visible}>
      <DialogTitle disableTypography={true} className={classes.root}>
        <Typography variant="h6">{translate('confirmation')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography gutterBottom={true} style={{ marginBottom: 20 }}>
          {text}
        </Typography>

        <Button type="button" color="primary" size="sm" onClick={onConfirm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Check fontSize="large" /> {translate('yes')}
          </div>
        </Button>
        <Button type="button" color="white" size="sm" onClick={onClose}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Close fontSize="large" /> {translate('no')}
          </div>
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default withLocalize(ConfirmDialog);
