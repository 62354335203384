import { Grid } from '@material-ui/core';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import FormCard from '../../../../../components/FormCard/FormCard';
import Label from '../../../../../components/NewUI/Label/Label';
import * as xeniaPosActions from '../../../../../store/actions/xeniapos_actions';
import { IState } from '../../../../../store/reducers';
import { XeniaPosPaymentMapping, XeniaPosPaymentMethod } from '../../../../../types/XeniaPosSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import { mapDisabled, MappingItem } from './MappingItem';

const PaymentMapping = (props: LocalizeContextProps & OwnProps) => {
  const { accountsList, translate, settings, setSettings } = props;
  const xeniaPosConnectionStatus = useSelector<IState, string>(state => state.xeniaPos.connectionStatus);
  const isFetching = useSelector<IState, boolean>(state => state.xeniaPos.isFetching);
  const xeniaPosPaymentMethods = useSelector<IState, XeniaPosPaymentMethod[]>(state => state.xeniaPos.paymentMethods);
  const dispatch = useDispatch();

  const [paymentMethods, setPaymentMethods] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (xeniaPosConnectionStatus === 'ok') {
      dispatch(xeniaPosActions.getPaymentMethodsRequest({}));
    }
  }, [xeniaPosConnectionStatus]);
  React.useEffect(() => {
    if (xeniaPosPaymentMethods) {
      let arr = xeniaPosPaymentMethods.map((method: XeniaPosPaymentMethod) => ({
        value: method.id + '',
        label: method.name,
        isDisabled: false,
      }));
      if (settings) {
        arr = mapDisabled(arr, settings.map(x => x.paymentMethodId + ''));
      }
      setPaymentMethods(arr);
    }
  }, [xeniaPosPaymentMethods, settings]);

  const [newIsDefault, setNewIsDefault] = React.useState<boolean>(false);

  return (
    <Grid container={true}>
      <Grid item={true} md={4} sm={12}>
        <Label
          title={translate('xenia-pos.payment-methods') as string}
          desc={translate('xenia-pos.payment-methods-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8} sm={12}>
        <FormCard>
          <Grid container={true}>
            <Grid container={true}>
              <Grid item={true} md={4} style={{textAlign: 'center'}}>
                {translate('xenia-pos.payment-methods-method-column') as string}
              </Grid>
              <Grid item={true} md={4} style={{textAlign: 'center'}}>
                {translate('xenia-pos.payment-methods-account-column') as string}
              </Grid>
              <Grid item={true} md={4}>
              </Grid>
            </Grid>
            {settings.map((m, idx) => (
              <MappingItem
                key={idx}
                aOptions={accountsList}
                bOptions={paymentMethods}
                a={m.accountNo + ''}
                b={m.paymentMethodId + ''}
                isDefault={m.isDefault}
                onChange={(a, b) => {
                  setSettings(settings.map((it, i) => (idx === i ? { ...it, accountNo: a, paymentMethodId: +b } : it)));
                }}
                isDisabled={isFetching}
                translate={translate}
                onToggleDefault={() => {
                  setSettings(
                    settings.map((it, i) =>
                      idx === i ? { ...it, isDefault: !it.isDefault } : { ...it, isDefault: false },
                    ),
                  );
                  setNewIsDefault(false);
                }}
                onDelete={() => {
                  setSettings(settings.filter((it, i) => i !== idx));
                }}
              />
            ))}
            <MappingItem
              key={settings.length}
              aOptions={accountsList}
              bOptions={paymentMethods}
              isDefault={newIsDefault}
              onChange={(a, b) => {
                const newItem = { accountNo: a, paymentMethodId: +b, isDefault: newIsDefault };
                setSettings([...settings, newItem]);
                setNewIsDefault(false);
              }}
              isDisabled={isFetching}
              translate={translate}
              onToggleDefault={() => {
                setSettings(settings.map(it => ({ ...it, isDefault: false })));
                setNewIsDefault(!newIsDefault);
              }}
            />
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

type OwnProps = {
  accountsList: IOptionType[];
  settings: XeniaPosPaymentMapping[];
  setSettings: (setting: XeniaPosPaymentMapping[]) => void;
};

export default withLocalize(PaymentMapping);
