import React from 'react';
import { Grid, CircularProgress  } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { searchBexioCustomer, getBexioCustomer } from '../../../../store/api/bexio';
import { BexioCustomer } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';
import Search from '../../../Search/Search';
import { Person } from '@material-ui/icons';

type Loading = 'initial' | 'pending' | 'ready' | 'error';

function useCustomerSearch(searchTerm: string): [Loading, BexioCustomer[]] {
  const [loading, setLoading] = React.useState<Loading>('initial');
  const [results, setResults] = React.useState<BexioCustomer[]>([]);
  React.useEffect(() => {
    setLoading('pending');
    searchBexioCustomer({ searchTerm }).then(response => {
      setResults(response.data as BexioCustomer[]);
      setLoading('ready');
    }).catch(() => {
      setLoading('error');
    });
  }, [searchTerm, setLoading, setResults]);
  return [loading, results];
}

function useFetchCustomer(customerId: number | undefined): [Loading, BexioCustomer | null] {
  const [loading, setLoading] = React.useState<Loading>('initial');
  const [results, setResults] = React.useState<BexioCustomer | null>(null);
  React.useEffect(() => {
    if (customerId) {
      setLoading('pending');
      getBexioCustomer({ customerId: '' + customerId }).then(response => {
        setResults(response.data as BexioCustomer);
        setLoading('ready');
      }).catch(() => {
        setLoading('error');
      });
    } else {
      setResults(null);
      setLoading('ready');
    }
  }, [customerId, setLoading, setResults]);
  return [loading, results];
}

type CustomerSearchProps = {
  enabled: boolean;
  customerId: number | undefined;
  setCustomerId: (customerId: number) => void;
} & LocalizeContextProps;

function CustomerSearch(props: CustomerSearchProps) {
  const {customerId, setCustomerId, enabled, translate} = props;

  const [customerList, setCustomerList] = React.useState<IOptionType[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [selectedCustomer, setSelectedCustomer] = React.useState<IOptionType | null>(null);

  const [loadingCustomers, bexioCustomers] = useCustomerSearch(searchTerm);
  const [loadingCustomer, bexioCustomer] = useFetchCustomer(customerId);

  React.useEffect(() => {
    if (loadingCustomers === 'ready') {
      setCustomerList(
        bexioCustomers.map((customer: BexioCustomer) => ({
          value: '' + customer.id,
          label: customer.name + (customer.firstname ? ' ' + customer.firstname : ''),
        })),
      );
    }
  }, [loadingCustomers, bexioCustomers, setCustomerList]);

  const selectCustomer = (value: IOptionType | null) => {
    setSelectedCustomer(value);
    setCustomerId(value ? +value.value : 0);
  }

  React.useEffect(() => {
    if (bexioCustomer) {
      setSelectedCustomer({
        value: '' + bexioCustomer.id,
        label: bexioCustomer.name + (bexioCustomer.firstname ? ' ' + bexioCustomer.firstname : ''),
      });
    } else {
      setSelectedCustomer(null);
    }
  }, [bexioCustomer, setSelectedCustomer]);

  const clearTerm = () => setSearchTerm('');

  return (
    <Grid container={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item={true} xs={12}>
        {enabled ? (
          loadingCustomer === 'ready' ? (
            <Search
              data={customerList}
              placeholder={translate('customer') as string}
              resultClicked={selectCustomer}
              termChange={setSearchTerm}
              currentValue={selectedCustomer}
              searchTerm={searchTerm}
              clearTerm={clearTerm}
              icon={<Person />}
            />
          ) : <CircularProgress />
        ) : null}
      </Grid>
    </Grid>
  );
}

export default withLocalize(CustomerSearch);
