// global imports
import { Grid, makeStyles, Theme, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _, { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { EmailTranslation } from '../../../../types/EmailSettings';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import CustomButton from '../../../../components/NewUI/CustomButton';
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const languageOptions: IOptionType[] = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
];

const BexioEmailTranslations: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, translations, putSettingsRequest } = props;

  const [settings, setSettings] = useState<EmailTranslation[]>([]);

  // set settings locally
  useEffect(() => {
    if (translations) {
      setSettings(translations.map((tr: EmailTranslation) => ({ ...tr })));
    }
  }, [translations]);

  const saveSettings = useCallback(
    _.debounce((newValue: EmailTranslation[]) => {
      if (
        translations &&
        newValue &&
        !newValue.find((tr: EmailTranslation) => !tr.language || !tr.text || !tr.subject || !tr.text.includes('[Network Link]')) &&
        !isEqual(translations, newValue)
      ) {
        putSettingsRequest(newValue);
      }
    }, 1000),
    [translations]);

  // save settings to backend
  const setAndSaveSettings = useCallback((newValue: EmailTranslation[]) => {
    setSettings(newValue);
    saveSettings(newValue);
  }, [translations]);


  const addTranslation = useCallback(
    (key?: number) => {
      if (settings) {
        const existing = settings.map(tr => tr.language);
        const allPossible = languageOptions.filter(opt => opt.value).map(opt => opt.value);
        const available = allPossible.filter(lang => !existing.includes(lang));
        const hasEmpty = existing.includes('');
        const hasDefault = settings.find(tr => tr.isDefault) !== undefined;
        if (!hasEmpty && available.length) {
          setAndSaveSettings([
            ...settings,
            {
              language: '',
              isDefault: hasDefault ? false : true,
              text: '',
              subject: '',
            }
          ]);
        }
      }
    },
    [settings],
  );

  const removeTranslation = useCallback(
    (key: number) => {
      if (settings) {
        const newSettings = settings.filter((_, i) => i !== key);
        setAndSaveSettings(newSettings);
      }
    },
    [settings],
  );

  const toggleDefault = useCallback(
    (key: number) => {
      if (settings) {
        const newValue = !settings[key].isDefault;
        if (newValue) {
          const newSettings = settings
            .map(tr => ({ ...tr, isDefault: false }))
            .map((tr, i) => i !== key ? tr : { ...tr, isDefault: !tr.isDefault });
          setAndSaveSettings(newSettings);
        } else {
          const newSettings = settings
            .map(tr => ({ ...tr, isDefault: false }))
            .map((tr, i) => i !== 0 ? tr : { ...tr, isDefault: true });
          setAndSaveSettings(newSettings);
        }
      }
    },
    [settings],
  );

  const languageChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        const newSettings = settings
          .map(tr => tr.language !== value.value ? tr : { ...tr, language: '' })
          .map((tr, i) => i !== key ? tr : { ...tr, language: value.value });
        setAndSaveSettings(newSettings);
      }
    },
    [settings],
  );

  const textChanged = useCallback(
    (key: number, value: string) => {
      if (settings) {
        const newSettings = settings
          .map((tr, i) => i !== key ? tr : { ...tr, text: value });
        setAndSaveSettings(newSettings);
      }
    },
    [settings],
  );

  const subjectChanged = useCallback(
    (key: number, value: string) => {
      if (settings) {
        const newSettings = settings
          .map((tr, i) => i !== key ? tr : { ...tr, subject: value });
        setAndSaveSettings(newSettings);
      }
    },
    [settings],
  );

  return (
    settings && (
      <div className={classes.w100}>
        {settings.map((tr: EmailTranslation, key: number) => (
          <React.Fragment key={key}>
            <Grid
              container={true}
              key={key}
              className={cx({
                [classes.errorBLeft]: !tr.text || !tr.subject || !tr.language || !tr.text.includes('[Network Link]'),
              })}
            >
              <Grid item={true} sm={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ padding: 10 }}>
                  <InputLabel className={classes.selectSmall}>{translate('email.language')}</InputLabel>
                  <Select
                    isSearchable={false}
                    options={languageOptions}
                    value={languageOptions.find(opt => opt.value === tr.language)}
                    onChange={(value: ValueType<IOptionType, false>) => languageChanged(key, value as IOptionType)}
                    styles={colourStyles}
                  />
                </div>
                <div style={{ padding: 10 }}>
                  <FormControlLabel style={{ marginLeft: 10 }}
                    control={<Checkbox onChange={() => toggleDefault(key)} checked={tr.isDefault} />}
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={translate('default')}
                  />
                </div>
                <div style={{ padding: 10 }}>
                  <TextField
                    placeholder={translate(`email.subject`) as string}
                    className={classes.w100}
                    value={settings[key].subject}
                    onChange={e => subjectChanged(key, e.target.value)}
                    multiline={false}
                    rows={3}
                  />
                </div>
                <div style={{ padding: 10 }}>
                  <TextField
                    placeholder={translate(`email.text`) as string}
                    className={classes.w100}
                    value={settings[key].text}
                    onChange={e => textChanged(key, e.target.value)}
                    multiline
                    rows={3}
                  />
                  {(!settings[key].text || !settings[key].text.includes('[Network Link]')) && <span style={{ fontSize: 14 }}>{translate('email.network_link_error')}</span>}
                </div>
                <Button
                  style={{ padding: 10 }}
                  justIcon={true}
                  round={true}
                  color="transparent"
                  onClick={() => removeTranslation(key)}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
            <Divider
              component="section"
              style={{
                width: '80%',
                marginLeft: '10%',
                marginTop: 20,
                marginBottom: 20,
              }}
            />
          </React.Fragment>
        ))}
        <CustomButton onClick={addTranslation}>{translate('email.add_language')}</CustomButton>
      </div>
    )
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  translations: state.email.settings?.invoice_by_bexio.translations,
});

const mapDispatchToProps = {
  putSettingsRequest: (translations: EmailTranslation[]) => commonActions.putSettingsRequest({ system: 'email', type: 'invoice_by_bexio', data: { translations }})
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(BexioEmailTranslations));
