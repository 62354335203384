import { Link } from '@material-ui/core';
import React from 'react';

export default function Label({
  title,
  desc,
  help,
}: {
  title: string;
  desc?: string;
  help?: { text: string; link: string; inNewLine?: boolean };
}) {
  return (
    <div style={{ padding: '29px 47px 0 0' }}>
      <span style={{ fontSize: 15, fontWeight: 'bold' }}>{title}</span>
      <p style={{ margin: 0, fontSize: 14, fontWeight: 'normal' }}>
        {desc ? desc : null}
        {help && help.link && (
          <Link color="primary" href={help.link} style={{ display: help.inNewLine ? 'flex' : 'initial' }} target="_blank">
            {help.text}
          </Link>
        )}
      </p>
    </div>
  );
}
