// global imports
import { CircularProgress, Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { getPlatformName } from '../../../../services/utils';
import { IState } from '../../../../store/reducers';
import TransferDesc from '../../BexioWidgets/TransferDesc/TransferDesc';
import TransferSalesPrice from '../../BexioWidgets/TransferSalesPrice/TransferSalesPrice';
import TransferVendor from '../../BexioWidgets/TransferVendor/TransferVendor';
import WriteBackStockAmount from '../../BexioWidgets/WriteBackStockAmount/WriteBackStockAmount';
import DestinationSystem from '../../CommonWidgets/DestinationSystem/DestinationSystem';
import StoreOrderAs from '../../WixWidgets/StoreOrderAs/StoreOrderAs';
import style from './styles';
import Subscription from './Subscription';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const WixApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    OAuthURL,
    connectionStatus,
    fetchOAuthUrlRequest,
    testConnectionRequest,
    getSettingsRequest,
  } = props;

  // didMount fetch data
  useEffect(() => {
    testConnectionRequest({ system: 'wix' });
    getSettingsRequest({ system: 'wix' });
  }, []);

  // test connection status
  useEffect(() => {
    if (connectionStatus === 'nok' || connectionStatus === 'nook') {
      fetchOAuthUrlRequest({ system: 'wix' });
    }
  }, [connectionStatus]);

  return (
    <SettingsContainer appName="Wix Stores">
      <Subscription />
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('configurator.connect-wix', { platform: getPlatformName() }) as string} desc="" />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            {connectionStatus !== 'loading' ? (
              connectionStatus === 'ok' ? (
                <>
                  <div className={classes.connectedContainer}>
                    <span className={classes.connectedStatus}>{translate('connected')}</span>
                    <DisconnectWidget appName="wix" />
                  </div>
                </>
              ) : (
                OAuthURL ? (
                  <ConnectWidget appName="wix" redirect="/admin/wix-app" />
                ) : null
              )
            ) : (
              <CircularProgress />
            )}
          </FormCard>
        </Grid>
      </GridRow>
      <DestinationSystem system="wix" />
      <GridRow container={true}>
        <Grid item={true} md={4}>
          <Label title={translate('wix.options') as string} desc={translate('wix.options-desc') as string} />
        </Grid>
        <Grid item={true} md={8}>
          <FormCard>
            <div className={classes.w100}>
              <TransferDesc />
              <TransferSalesPrice />
              <TransferVendor />
              <WriteBackStockAmount system="wix" />
            </div>
          </FormCard>
        </Grid>
      </GridRow>
      <StoreOrderAs />
      <BexioConfig system="wix" />
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  OAuthURL: state.wix.OAuthURL,
  wixSettings: state.wix.settings,
  connectionStatus: state.wix.connectionStatus,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WixApp),
);
