import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import Select from 'react-select';

import commonStyles from '../../../../../assets/jss/commonStyles';
import colourStyles from '../../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import Button from '../../../../../components/CustomButtons/Button.jsx';
import { IOptionType } from '../../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../../types/StyleProps';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

export interface IRowProps {
  aOptions: IOptionType[];
  bOptions: IOptionType[];
  a?: string;
  b?: string;
  isDefault: boolean;
  onToggleDefault: () => void;
  onChange: (a: string, b: string) => void;
  onDelete?: () => void;
  isDisabled: boolean;
  translate: (a: string) => any;
}

export function findOption(options: IOptionType[], value?: string): IOptionType | null {
  if (value === undefined) {
    return null;
  }
  const found = options.find(opt => opt.value === value);
  return found ? found : null;
}

export const mapDisabled = (arr: IOptionType[], selected: string[]) =>
  arr.map(item => ({
    ...item,
    isDisabled: selected.find(i => i === item.value) !== undefined,
  }));

export function MappingItem(props: IRowProps): JSX.Element {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    aOptions,
    bOptions,
    a: initA,
    b: initB,
    onChange,
    onDelete,
    isDisabled,
    isDefault,
    onToggleDefault,
    translate,
  } = props;
  const [a, setA] = React.useState<IOptionType | null>(null);
  const [b, setB] = React.useState<IOptionType | null>(null);
  React.useEffect(() => {
    setA(findOption(aOptions, initA));
  }, [initA, aOptions]);
  React.useEffect(() => {
    setB(findOption(bOptions, initB));
  }, [initB, bOptions]);
  return (
    <Grid container={true}>
      <Grid item={true} md={4} style={{ padding: 10 }}>
        <Select
          className={classes.w100}
          isSearchable={false}
          options={bOptions}
          value={b}
          onChange={opt => {
            setB(opt ? opt : null);
            if (opt && a) {
              onChange(a.value, opt.value);
            }
          }}
          isDisabled={isDisabled}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} md={4} style={{ padding: 10 }}>
        <Select
          className={classes.w100}
          isSearchable={false}
          options={aOptions}
          value={a}
          onChange={opt => {
            setA(opt ? opt : null);
            if (opt && b) {
              onChange(opt.value, b.value);
            }
          }}
          isDisabled={isDisabled}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} md={2} style={{ padding: 10 }}>
        <Checkbox onChange={() => onToggleDefault()} checked={isDefault} label={translate('default') as string} />
      </Grid>
      <Grid item={true} md={2} style={{ padding: 10 }}>
        {a && b && onDelete && (
          <Button justIcon={true} round={true} color="transparent" onClick={() => onDelete()}>
            <DeleteIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default MappingItem;
