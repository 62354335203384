import React from 'react';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { IOptionType } from '../../../types/ReactSelect';
import { useDefaultStyles } from '../styles';
import { ReminderCosts } from '../types';
import TextFieldDelayed from './TextFieldDelayed';
import { ProductSearch } from './ProductSearch';

type ProductSectionProps = {
  enabled: boolean;
  settings: ReminderCosts;
  setSettings: (data: ReminderCosts) => void;
} & LocalizeContextProps;

function ProductSection(props: ProductSectionProps) {
  const {settings, setSettings, enabled, translate} = props;
  const classes = useDefaultStyles();

  const selectProduct = React.useCallback((value: IOptionType | null) => {
    setSettings({
      ...settings,
      productPosition: {
        ...(settings.productPosition ?? {}),
        productId: value ? +value.value : 0,
      },
    })
  }, [settings, setSettings]);

  const setPrice = React.useCallback((value: string) => {
    setSettings({
      ...settings,
      productPosition: {
        ...(settings.productPosition ?? {}),
        price: value,
      },
    })
  }, [settings, setSettings]);

  return (
    <Grid container={true} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item={true} xs={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
        {enabled ? (
          <ProductSearch
            productId={settings.productPosition?.productId}
            onChange={selectProduct}
            title={translate('reminders.levels.reminderCosts.product.title') as string}
          />
        ) : null}
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5 }}>
        {translate('reminders.levels.reminderCosts.standardPosition.price')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{ paddingTop: 5, paddingBottom: 5 }}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.reminderCosts.standardPosition.price') as string}
          value={settings.productPosition?.price}
          disabled={!enabled}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={setPrice}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(ProductSection);
