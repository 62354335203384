// global imports
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Checkbox from '../../../../components/Checkbox/Checkbox';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioSettings } from '../../../../types/BexioSettings';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';
import { ProductSearch } from '../../../ReminderSettings/Widgets/ProductSearch';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

function StandardPosition(props: Props) {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    bexioSettings,
    putSettingsRequest,
  } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);

  useEffect(() => {
    if (bexioSettings) {
      if (!bexioSettings.standardPosition) {}
      setSettings({
        ...bexioSettings,
        standardPosition: (bexioSettings.standardPosition ?? ({
          enabled: false,
          id: 0,
        }))
      });
    }
  }, [bexioSettings]);


  const delayedUpdate = useCallback(
    _.debounce((newSettings) => {
      putSettingsRequest({ system: 'bexio', data: newSettings });
    }, 1000),
    [],
  );

  return (
    settings && (
      <div className={classes.w100}>
        <Grid container={true}>
          <Grid item={true} md={12} sm={12} style={{ padding: 10 }}>
            <Checkbox
              onChange={ev => {
                const newSettings = {
                  ...settings,
                  standardPosition: {
                    ...settings.standardPosition,
                    enabled: ev.target.checked,
                  },
                };
                setSettings(newSettings);
                delayedUpdate(newSettings);
              }}
              checked={settings.standardPosition.enabled}
              label={translate('bexio.standard-position-enabled') as string}
            />
          </Grid>
          <Grid md={12} sm={12} style={{ padding: 10 }}>
            {(settings.standardPosition.enabled && settings.standardPosition.type !== "custom") ? (
              <ProductSearch
                productId={settings?.standardPosition?.id}
                onChange={(opt) => {
                  const newSettings = {
                    ...settings,
                    standardPosition: {
                      ...(settings.standardPosition ?? {}),
                      id: opt ? +opt.value : 0,
                      type: "product",
                    },
                  };
                  setSettings(newSettings);
                  delayedUpdate(newSettings);
                }}
                title={translate('bexio.standard-position-product-id') as string}
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
    )
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(StandardPosition));
