type IVersionedApp = {
  name: string;
  version: number;
};

function comparator(str: string, app: string | IVersionedApp) {
  if (typeof app !== 'string') {
    return str === app.name;
  } else {
    return str === app;
  }
}

const isAllowed = (list: string[] | string, availableApps: Array<string | IVersionedApp>) =>
  availableApps &&
  (typeof list === 'string'
    ? availableApps.find(app => comparator(list, app))
    : list.some((appName: string) => availableApps.find(app => comparator(appName, app))));

export default isAllowed;
