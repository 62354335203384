import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import { config } from '../config';

// core components
import AuthNavbar from './components/Navbars/AuthNavbar';
import Footer from './components/Footer/Footer';

import routes from './routes';

import BexioOAuthHandler from '../components/OAuthHandler/BexioOAuthHandler';
import ShopifyOAuthHandler from '../components/OAuthHandler/ShopifyOAuthHandler';
import ZohoOAuthHandler from '../components/OAuthHandler/ZohoOAuthHandler';
import DebitoorOAuthHandler from '../components/OAuthHandler/DebitoorOAuthHandler';
import BitrixOAuthHandler from '../components/OAuthHandler/BitrixOAuthHandler';
import SumupOAuthHandler from '../components/OAuthHandler/SumupOAuthHandler';
import PingenOAuthHandler from '../components/OAuthHandler/PingenOAuthHandler';
import LightspeedROAuthHandler from '../components/OAuthHandler/LightspeedROAuthHandler';

import styles from '../assets/jss/material-dashboard-pro-react/layouts/authStyle.js';

import login from '../assets/img/bedaya-landing-bg.svg';
import loginbexio from '../assets/img/bexio-landing-bg.svg';
import lock from '../assets/img/lock.jpeg';
import error from '../assets/img/clint-mckoy.jpg';
import pricing from '../assets/img/bg-pricing.jpeg';
import AuthService from '../services/auth.service';
import VendOAuthHandler from '../components/OAuthHandler/VendOAuthHandler';
import MailchimpOAuthHandler from '../components/OAuthHandler/MailchimpOAuthHandler';
import WixOAuthHandler from '../components/OAuthHandler/WixOAuthHandler';

const useStyles = makeStyles(styles);

const Auth = props => {
  const { history, translate, ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  useEffect(() => {
    if (AuthService.isAuthenticated && props.location.pathname === '/auth/login') {
      history.push('/admin/apps');
    }
  }, [history]);

  React.useEffect(() => {
    document.body.style.overflow = 'unset';
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBgImage = () => {
    if (window.location.pathname.indexOf('/auth/login') !== -1) {
      if (config.activeDomain === 'bexio') {
        return loginbexio;
      } else {
        return login;
      }
    } else if (window.location.pathname.indexOf('/auth/shopify-onboarding') !== -1) {
      if (config.activeDomain === 'bexio') {
        return loginbexio;
      } else {
        return login;
      }
    } else if (window.location.pathname.indexOf('/auth/pricing-page') !== -1) {
      return pricing;
    } else if (window.location.pathname.indexOf('/auth/lock-screen-page') !== -1) {
      return lock;
    } else if (window.location.pathname.indexOf('/auth/error-page') !== -1) {
      return error;
    }
  };

  const getActiveRoute = routes => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return typeof routes[i].name === 'string' ? routes[i].name : routes[i].name(translate);
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: 'url(' + getBgImage() + ')' }}>
          <Switch>
            {getRoutes(routes)}
            <Route path="/oauth/bexio/callback" component={BexioOAuthHandler} />
            <Route path="/oauth/shopify/callback" component={ShopifyOAuthHandler} />
            <Route path="/oauth/zoho/callback" component={ZohoOAuthHandler} />
            <Route path="/oauth/debitoor/callback" component={DebitoorOAuthHandler} />
            <Route path="/oauth/bitrix/callback" component={BitrixOAuthHandler} />
            <Route path="/oauth/sumup/callback" component={SumupOAuthHandler} />
            <Route path="/oauth/wix/callback" component={WixOAuthHandler} />
            <Route path="/oauth/vend/callback" component={VendOAuthHandler} />
            <Route path="/oauth/mailchimp/callback" component={MailchimpOAuthHandler} />
            <Route path="/oauth/pingen/callback" component={PingenOAuthHandler} />
            <Route path="/oauth/ls-r/callback" component={LightspeedROAuthHandler} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
});

export default withLocalize(connect(mapStateToProps)(Auth));
