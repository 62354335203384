// @material-ui/icons
import { Build } from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import LocalShipping from '@material-ui/icons/LocalShipping';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import BexioIcon from '../assets/img/bexio-icon.png';
import BitrixIcon from '../assets/img/bitrix-icon.png';
import DebitoorIcon from '../assets/img/debitoor-icon.png';
import FlmIcon from '../assets/img/flm-icon.png';
import ForceManagerIcon from '../assets/img/forcemanager-icon.png';
import GastrofixIcon from '../assets/img/gastrofix-icon.png';
import LightspeedIcon from '../assets/img/lightspeed-icon.png';
import MailchimpIcon from '../assets/img/mailchimp-icon.png';
import PrestashopIcon from '../assets/img/prestashop-icon.png';
import ShopifyIcon from '../assets/img/shopify-icon.png';
import SumupIcon from '../assets/img/sumup-icon.png';
import SwisscomIcon from '../assets/img/swisscom-icon.png';
import VendIcon from '../assets/img/vend-icon.png';
import WeclappIcon from '../assets/img/weclapp-icon.png';
import WixIcon from '../assets/img/wix-icon.png';
import ZohoCrmIcon from '../assets/img/zoho-crm-icon.png';
import ZohoIcon from '../assets/img/zoho-icon.png';
import EcwidIcon from '../assets/img/ecwid-icon.png';
import WooCommerceIcon from '../assets/img/woocommerce.png';

import AclService from '../services/acl.service';

import Actions from '../views/Actions/Actions/Actions';
import Apps from '../views/Apps/Apps/Apps';
import BexioApp from '../views/Apps/Apps/BexioApp/BexioApp';
import DebitoorApp from '../views/Apps/Apps/DebitoorApp/DebitoorApp';
import EnforeApp from '../views/Apps/Apps/EnforeApp/EnforeApp';
import MailchimpApp from '../views/Apps/Apps/MailchimpApp/MailchimpApp';
import PrestashopApp from '../views/Apps/Apps/PrestashopApp/PrestashopApp';
import ShopifyApp from '../views/Apps/Apps/ShopifyApp/ShopifyApp';
import SumupApp from '../views/Apps/Apps/SumupApp/SumupApp';
import VendApp from '../views/Apps/Apps/VendApp/VendApp';
import WeclappApp from '../views/Apps/Apps/WeclappApp/WeclappApp';
import XeniaPosApp from '../views/Apps/Apps/XeniaPosApp/XeniaPosApp';
import ZohoBooksApp from '../views/Apps/Apps/Zoho/ZohoBooksApp/ZohoBooksApp';
import ZohoCrmApp from '../views/Apps/Apps/Zoho/ZohoCrmApp/ZohoCrmApp';
import DeliveryPage from '../views/Delivery/Delivery';
import EmailSettings from '../views/EmailSettings/EmailSettings';
import BexioLoginPage from '../views/Pages/BexioLoginPage';
import LoginPage from '../views/Pages/LoginPage';
import RegisterPage from '../views/Pages/RegisterPage.js';
import ProtocolPage from '../views/Protocol/Protocol';
import ReminderSettings from '../views/ReminderSettings/ReminderSettings';
import ServiceAccounts from '../views/ServiceAccounts/ServiceAccounts';
import UserProfile from '../views/UserProfile/UserProfile';
import Users from '../views/Users/Users';

import { config } from '../config';
import AnwrSettings from '../views/AnwrSettings/AnwrSettings';
import BitrixApp from '../views/Apps/Apps/BitrixApp/BitrixApp';
import ForceManagerApp from '../views/Apps/Apps/ForceManagerApp/ForceManagerApp';
import GastrofixApp from '../views/Apps/Apps/GastrofixApp/GastrofixApp';
import LightspeedApp from '../views/Apps/Apps/LightpseedApp/LightspeedApp';
import LightspeedRApp from '../views/Apps/Apps/LightspeedRApp/LightspeedRApp';
import WixApp from '../views/Apps/Apps/WixApp/WixApp';
import Plans from '../views/Plans/Plans';
import SumupPlans from '../views/SumupPlans/SumupPlans';
import ReminderPlans from '../views/ReminderSettings/Plans/Plans';
import EcwidApp from '../views/Apps/Apps/EcwidApp/EcwidApp';
import WooCommerceApp from '../views/Apps/Apps/WooCommerceApp/WooCommerceApp';

const dashRoutes = [
  {
    path: '/login',
    name: (activeDomain => {
      switch (activeDomain) {
        case 'bexio': {
          return (translate: any) => translate('bexio_login_page');
        }
        default:
          return (translate: any) => translate('bedaya_login_page');
      }
    })(config.activeDomain),
    rtlName: 'هعذاتسجيل الدخول',
    mini: 'L',
    rtlMini: 'هعذا',
    component: (activeDomain => {
      switch (activeDomain) {
        case 'bexio': {
          return BexioLoginPage;
        }
        default:
          return LoginPage;
      }
    })(config.activeDomain),
    layout: '/auth',
    // do not show this route in list
    enabled: (auth: any, apps: any): boolean => false,
  },
  {
    path: '/plans/:shop',
    name: 'Plans',
    rtlName: 'Plans',
    mini: 'Plans',
    rtlMini: 'Plans',
    component: Plans,
    layout: '/auth',
    enabled: (auth: any, apps: any): boolean => false,
  },
  {
    path: '/sumup-plans',
    name: 'SumUp Plans',
    rtlName: 'SumUp Plans',
    mini: 'SumUp Plans',
    rtlMini: 'SumUp Plans',
    component: SumupPlans,
    layout: '/auth',
    enabled: (auth: any, apps: any): boolean => false,
  },
  {
    path: '/register',
    name: 'register',
    rtlName: 'تسجيل',
    mini: 'R',
    rtlMini: 'صع',
    component: RegisterPage,
    layout: '/auth',
    enabled: (auth: any, apps: any): boolean => false,
  },
  {
    path: '/profile',
    name: (translate: any) => translate('profile.profile'),
    icon: PersonIcon,
    component: UserProfile,
    layout: '/admin',
    doNotShowInSidebar: true,
    enabled: (auth: any, apps: any): boolean =>
      AclService.hasPermission(auth, 'bedaya_basic') ||
      AclService.hasPermission(auth, 'bedaya-basic') ||
      AclService.hasPermission(auth, 'bexio_basic') ||
      AclService.hasPermission(auth, 'bexio-basic'),
  },
  {
    path: '/email',
    name: (translate: any) => translate('emails.emails'),
    icon: PersonIcon,
    component: EmailSettings,
    layout: '/admin',
    doNotShowInSidebar: true,
  },
  {
    path: '/apps',
    name: (translate: any) => translate('apps'),
    icon: SubscriptionsIcon,
    component: Apps,
    layout: '/admin',
  },
  {
    path: '/protocol',
    name: (translate: any) => translate('protocol'),
    icon: SettingsInputComponentIcon,
    component: ProtocolPage,
    layout: '/admin',
  },
  {
    path: '/actions',
    name: (translate: any) => translate('actions'),
    icon: LowPriorityIcon,
    component: Actions,
    layout: '/admin',
  },
  {
    path: '/service-accounts',
    name: (translate: any) => translate('serviceAccounts.menu_title'),
    icon: SettingsApplicationsIcon,
    component: ServiceAccounts,
    layout: '/admin',
  },
  {
    collapse: true,
    name: (translate: any) => translate('settings'),
    rtlName: 'صفحات',
    icon: SettingsIcon,
    state: 'pageCollapse1',
    views: [
      {
        path: '/bexio-app',
        name: (translate: any) => translate('bexio_app_name'),
        rtlName: 'عالتسعير',
        mini: 'Be',
        icon: BexioIcon,
        iconType: 'img',
        rtlMini: 'ع',
        component: BexioApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => false,
        appName: 'bexio',
      },
      {
        path: '/debitoor-app',
        name: (translate: any) => translate('debitoor_app_name'),
        rtlName: 'صودعم رتل',
        mini: 'D',
        icon: DebitoorIcon,
        iconType: 'img',
        rtlMini: 'صو',
        component: DebitoorApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('debitoor'),
        appName: 'debitoor',
      },
      {
        path: '/shopify-app',
        name: (translate: any) => translate('shopify_app_name'),
        rtlName: 'تيالجدول الزمني',
        mini: 'S',
        icon: ShopifyIcon,
        iconType: 'img',
        rtlMini: 'تي',
        component: ShopifyApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('shopify'),
        appName: 'shopify',
      },
      {
        path: '/sumup-app',
        name: (translate: any) => translate('sumup_app_name'),
        icon: SumupIcon,
        iconType: 'img',
        component: SumupApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('sumup'),
        appName: 'sumup',
      },
      {
        path: '/wix-app',
        name: (translate: any) => translate('wix_app_name'),
        icon: WixIcon,
        iconType: 'img',
        component: WixApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('wix'),
        appName: 'wix',
      },
      {
        path: '/bitrix-app',
        name: (translate: any) => translate('bitrix_app_name'),
        icon: BitrixIcon,
        iconType: 'img',
        component: BitrixApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('bitrix'),
        appName: 'bitrix',
      },
      {
        path: '/gastrofix-app',
        name: (translate: any) => translate('gastrofix_app_name'),
        icon: GastrofixIcon,
        iconType: 'img',
        component: GastrofixApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('gastrofix'),
        appName: 'gastrofix',
      },
      {
        path: '/vend-app',
        name: (translate: any) => translate('vend_app_name'),
        icon: VendIcon,
        iconType: 'img',
        component: VendApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('vend'),
        appName: 'vend',
      },
      {
        path: '/mailchimp-app',
        name: (translate: any) => translate('mailchimp_app_name'),
        icon: MailchimpIcon,
        iconType: 'img',
        component: MailchimpApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('mailchimp'),
        appName: 'mailchimp',
      },
      {
        path: '/prestashop-app',
        name: (translate: any) => translate('prestashop.prestashop-app-name'),
        icon: PrestashopIcon,
        iconType: 'img',
        component: PrestashopApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('prestashop'),
        appName: 'prestashop',
      },
      {
        path: '/zoho-books',
        name: (translate: any) => translate('zoho-books_app_name'),
        icon: ZohoIcon,
        iconType: 'img',
        component: ZohoBooksApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('zoho-books'),
        appName: 'zoho-books',
      },
      {
        path: '/zoho-crm',
        name: (translate: any) => translate('zoho-crm_app_name'),
        icon: ZohoCrmIcon,
        iconType: 'img',
        component: ZohoCrmApp,
        layout: '/admin',
        redirect: config.activeDomain === 'bexio',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('zoho-crm'),
        appName: 'zoho-crm',
      },
      {
        path: '/weclapp-app',
        name: (translate: any) => translate('weclapp_app_name'),
        icon: WeclappIcon,
        iconType: 'img',
        component: WeclappApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('weclapp'),
        appName: 'weclapp',
      },
      {
        path: '/lightspeed-app',
        name: (translate: any) => translate('lightspeed_app_name'),
        icon: LightspeedIcon,
        iconType: 'img',
        component: LightspeedApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('lightspeed'),
        appName: 'lightspeed',
      },
      {
        path: '/lightspeed-r-app',
        name: (translate: any) => translate('lightspeed-r_app_name'),
        icon: LightspeedIcon,
        iconType: 'img',
        component: LightspeedRApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('lightspeed-r'),
        appName: 'lightspeed-r',
      },
      {
        path: '/forcemanager-app',
        name: (translate: any) => translate('forcemanager_app_name'),
        icon: ForceManagerIcon,
        iconType: 'img',
        component: ForceManagerApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('forcemanager'),
        appName: 'forcemanager',
      },
      {
        path: '/anwr-settings',
        name: (translate: any) => 'Anwr',
        component: AnwrSettings,
        layout: '/admin',
        icon: SettingsIcon,
        enabled: (auth: any, apps: any): boolean => AclService.hasPermission(auth, 'bedaya-anwr'),
      },
      {
        path: '/enfore-app',
        name: (translate: any) => translate('enfore_app_name'),
        icon: SwisscomIcon,
        iconType: 'img',
        component: EnforeApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('enfore'),
        appName: 'enfore',
      },
      {
        path: '/xeniapos-app',
        name: (translate: any) => translate('xeniapos_app_name'),
        icon: FlmIcon,
        iconType: 'img',
        component: XeniaPosApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean =>
          apps &&
          apps.includes('xeniapos') &&
          (AclService.hasPermission(auth, 'bedaya-admin') || AclService.hasPermission(auth, 'bedaya-gastro')),
        appName: 'xeniapos',
      },
      {
        path: '/ecwid-app',
        name: (translate: any) => translate('ecwid_app_name'),
        icon: EcwidIcon,
        iconType: 'img',
        component: EcwidApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('ecwid'),
        appName: 'ecwid',
      },
      {
        path: '/woocommerce-app',
        name: (translate: any) => translate('woocommerce_app_name'),
        icon: WooCommerceIcon,
        iconType: 'img',
        component: WooCommerceApp,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => apps && apps.includes('woocommerce'),
        appName: 'woocommerce',
      },
    ],
  },
  {
    path: '/reminders/plans',
    name: 'Plans',
    rtlName: 'Plans',
    mini: 'Plans',
    rtlMini: 'Plans',
    component: ReminderPlans,
    layout: '/auth',
    enabled: (auth: any, apps: any): boolean => false,
  },
  {
    collapse: true,
    name: 'bexio Tools',
    rtlName: 'صفحات',
    icon: Build,
    state: 'pageCollapse2',
    views: [
      {
        path: '/delivery',
        name: (translate: any) => translate('delivery-manager.menu-title'),
        icon: LocalShipping,
        component: DeliveryPage,
        layout: '/admin',

        enabled: (auth: any, apps: any): boolean => process.env.REACT_APP_ACTIVE_DOMAIN === 'bedaya',
      },
      {
        path: '/reminders',
        name: (translate: any) => translate('reminders.menu_title'),
        icon: EmailIcon,
        component: ReminderSettings,
        layout: '/admin',
        enabled: (auth: any, apps: any): boolean => true,
      },
    ],
  },
  {
    path: '/users',
    name: (translate: any) => 'Users Admin',
    icon: SupervisorAccountIcon,
    component: Users,
    layout: '/admin',
    enabled: (auth: any, apps: any): boolean => AclService.hasPermission(auth, 'bedaya-admin'),
  },
];

// const routes = dashRoutes.concat(demoRoutes);
export default dashRoutes;
