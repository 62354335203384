import React from 'react';
import { connect } from 'react-redux';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import { IState } from '../../store/reducers';
import { getReminderSettingsRequest, putReminderSettingsRequest } from '../../store/actions/bexio_actions';

import { StyledSwitch } from '../../components/StyledSwitch';
import SettingsContainer from '../../components/NewUI/SettingsContainer';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import Section from './Widgets/Section';
import ReminderLevelsSection from './Widgets/ReminderLevelsSection';
import FixEmailSection from './Widgets/FixEmailSection';
import ScheduleSection from './Widgets/ScheduleSection';
import ReportSection from './Widgets/ReportSection';
import BehaviorSection from './Widgets/BehaviorSection';
import SmsSection from './Widgets/SmsSection';
import DebtCollectionSection from './Widgets/DebtCollection';
import { ReminderSettings, Schedule, Report, FixEmail, Level, ReminderBehavior, SmsSettings, KsDebtCollection } from './types';
import { Subscription } from './Plans/Plans';

type StateProps = {
  settings: ReminderSettings | null;
  isFetching: boolean;
}

type DispatchProps = {
  getSettingsRequest: () => void;
  putSettingsRequest: (data: any) => void;
}

type ReminderSettingsPageProps = StateProps & DispatchProps & LocalizeContextProps;

const initialSettings: ReminderSettings = {
  fixEmailSettings: {
    email: '',
    subject: '',
    template: '',
    html: false,
  },
  cron: {
    days: [],
    time: ''
  },
  testModeEnabled: false,
  report: {
    reportEMail: '',
  },
  behavior: {
    summarizeStandardReminderPosition: false,
    reminderOffsetDays: 0,
    ignoreInvoicesBefore: null,
  },
  reminderLevels: [],
  sms: {
    testPhoneNumber: '',
  },
  ksDebtCollection: {
    gtc: {
      accepted: null,
    },
  },
};

function ReminderSettingsPage(props: ReminderSettingsPageProps) {
  const { translate, settings: data, isFetching, getSettingsRequest, putSettingsRequest } = props;

  const [settings, setSettings] = React.useState<ReminderSettings>(data ? data: initialSettings);

  React.useEffect(() => {
    getSettingsRequest();
  }, [getSettingsRequest])

  React.useEffect(() => {
    setSettings(data ? data: initialSettings);
  }, [data, setSettings]);

  const onChangeSettings = React.useCallback((s: ReminderSettings) => {
    setSettings(s);
    putSettingsRequest(s);
  }, [setSettings, putSettingsRequest])

  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);

  return (
    <SettingsContainer appName={translate('reminders.title') as string}>
      <Subscription />
      <Section title={translate('reminders.test_mode') as string} desc="">
        <StyledSwitch
          checked={settings.testModeEnabled}
          onChange={event => {
            if (settings.testModeEnabled) {
              setShowConfirmation(true);
            } else {
              onChangeSettings({...settings, testModeEnabled: event.target.checked});
            }
          }}
        />
      </Section>
      <ConfirmDialog
        text={translate('reminders.confirmation') as string}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          onChangeSettings({...settings, testModeEnabled: false });
          setShowConfirmation(false);
        }}
        visible={showConfirmation}
      />
      <Section title={translate('reminders.schedule.title') as string} desc="">
        <ScheduleSection
          enabled={!isFetching}
          settings={settings.cron}
          setSettings={(data: Schedule) => onChangeSettings({...settings, cron: data })}
        />
      </Section>
      <Section title={translate('reminders.report.title') as string} desc="">
        <ReportSection
          enabled={!isFetching}
          settings={settings.report}
          setSettings={(data: Report) => onChangeSettings({...settings, report: data})}
        />
      </Section>
      <Section title={translate('reminders.fixEmail.title') as string} desc="">
        <FixEmailSection
          enabled={!isFetching}
          settings={settings.fixEmailSettings}
          setSettings={(data: FixEmail) => onChangeSettings({...settings, fixEmailSettings: data})}
        />
      </Section>
      <Section title={translate('reminders.behavior.title') as string} desc="">
        <BehaviorSection
          enabled={!isFetching}
          settings={settings.behavior ?? {}}
          setSettings={(data: ReminderBehavior) => onChangeSettings({...settings, behavior: data})}
        />
      </Section>
      <Section title={translate('reminders.sms.title') as string} desc="">
        <SmsSection
          enabled={!isFetching}
          settings={settings.sms ?? {}}
          setSettings={(data: SmsSettings) => onChangeSettings({...settings, sms: data})}
        />
      </Section>
      <Section title={translate('reminders.debt-collection.title') as string} desc="">
        <DebtCollectionSection
          enabled={!isFetching}
          settings={settings.ksDebtCollection ?? { gtc: { accepted: null }}}
          setSettings={(data: KsDebtCollection) => onChangeSettings({...settings, ksDebtCollection: data})}
        />
      </Section>
      <Section title={translate('reminders.levels.title') as string} desc="">
        <ReminderLevelsSection
          enabled={!isFetching}
          settings={settings.reminderLevels}
          setSettings={(data: Level[]) => onChangeSettings({...settings, reminderLevels: data.map((x, i) => ({...x, level: i + 1, lastLevel: i === data.length - 1 }))})}
        />
      </Section>
    </SettingsContainer>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  settings: state.bexio.reminderSettings,
  isFetching: state.bexio.isFetching,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSettingsRequest: () => dispatch(getReminderSettingsRequest({})),
  putSettingsRequest: (data: ReminderSettings) => dispatch(putReminderSettingsRequest(data)),
});

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReminderSettingsPage)
);
