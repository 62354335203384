// global imports
import { Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props } from './types';

export const isUrl = (url: string) => {
  return url.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  );
};

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const ShopUrl = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { url, onChange } = props;

  const [currentValue, setCurrentValue] = useState(url);
  const delayedInput = useRef(_.debounce(value => onChange('url', value), 1000)).current;

  const handleValueChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const value = ev.target.value;

    setCurrentValue(value);
    delayedInput(value);
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={4}>
        <InputLabel className={classes.pt10}>Shop URL</InputLabel>
      </Grid>
      <Grid item={true} xs={8}>
        <TextField
          className={classes.w100}
          placeholder="Shop URL"
          error={!isUrl(currentValue)}
          value={currentValue}
          onChange={handleValueChange}
        />
      </Grid>
    </Grid>
  );
};

export default withLocalize(ShopUrl);
