// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import * as commonActions from '../../../../store/actions/common_actions';
import * as weclappActions from '../../../../store/actions/weclapp_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';

// local imports
import { LocalShipping } from '@material-ui/icons';
import { WeclappSettings } from '../../../../types/WeclappSettings';
import Search from '../../../Search/Search';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const ShippingProductSearch: React.FC<Props> = props => {
  const {
    translate,
    getProductRequest,
    weclappSettings,
    productFetchResult,
    productSearchResult,
    putSettingsRequest,
  } = props;

  const [productList, setProductList] = useState<IOptionType[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<IOptionType | null>(null);

  useEffect(() => {
    if (weclappSettings) {
      if (weclappSettings.shipping.productId && weclappSettings.shipping.productId.length > 0) {
        getProductRequest({ productId: weclappSettings.shipping.productId });
      }
      props.searchProductRequest({ searchTerm: '' });
    }
  }, [weclappSettings, getProductRequest]);

  // convert product search result for react-select
  useEffect(() => {
    if (weclappSettings && productFetchResult) {
      const updatedSettings: WeclappSettings = {
        ...weclappSettings,
        shipping: {
          productId: productFetchResult.id,
        },
      };
      if (weclappSettings.shipping.productId !== updatedSettings.shipping.productId) {
        putSettingsRequest({ system: 'weclapp', data: updatedSettings });
      }
      setSelectedProduct({
        label: productFetchResult.name,
        value: productFetchResult.id,
      });
    }
    if (productSearchResult) {
      setProductList(
        productSearchResult.map((product: any) => ({
          value: product.id,
          label: product.name,
        })),
      );
    }
  }, [
    setProductList,
    setSelectedProduct,
    putSettingsRequest,
    weclappSettings,
    productSearchResult,
    productFetchResult,
  ]);

  const searchProduct = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3 || newSearchTerm.length === 0) {
      props.searchProductRequest({ searchTerm: newSearchTerm });
    }
  };

  const searchProductChange = (value: IOptionType) => {
    setSelectedProduct(value);
    props.getProductRequest({ productId: value.value });
  };

  const clearTerm = () => {
    setSearchTerm('');
  };

  return (
    <Search
      data={productList}
      placeholder={translate('select_prompt') as string}
      resultClicked={searchProductChange}
      termChange={searchProduct}
      currentValue={selectedProduct}
      searchTerm={searchTerm}
      icon={<LocalShipping />}
      clearTerm={clearTerm}
    />
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  weclappSettings: state.weclapp.settings,
  productFetchResult: state.weclapp.productFetchResult,
  productSearchResult: state.weclapp.productSearchResult,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  searchProductRequest: weclappActions.searchProductRequest,
  getProductRequest: weclappActions.getProductRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ShippingProductSearch));
