import { ActionPattern, all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
  HAS_SUBSCRIPTION,
  hasSubscriptionFailure,
  hasSubscriptionSuccess,
  POST_CHECKOUT,
  postCheckoutFailure,
  postCheckoutSuccess,
} from '../actions/dpd_actions';

import { checkSubscription, postCheckout } from '../api/dpd';

function* hasSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(checkSubscription);
    yield put(hasSubscriptionSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(hasSubscriptionFailure(processedError || error));
  }
}

function* hasSubscriptionWatcher() {
  yield takeLatest(HAS_SUBSCRIPTION.REQUEST as ActionPattern, hasSubscriptionSaga);
}

function* postCheckoutSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postCheckout);
    yield put(postCheckoutSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postCheckoutFailure(processedError || error));
  }
}

function* postCheckoutWatcher() {
  yield takeLatest(POST_CHECKOUT.REQUEST as ActionPattern, postCheckoutSaga);
}

export default function* dpdSaga() {
  yield all([fork(hasSubscriptionWatcher), fork(postCheckoutWatcher)]);
}
