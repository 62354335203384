import { BedayaAction } from '../../types/BedayaAction';
import { PlanzerSettings } from '../../types/PlanzerSettings';
import { GET_SETTINGS, PUT_SETTINGS } from '../actions/common_actions';
import { HAS_SUBSCRIPTION, POST_CHECKOUT } from '../actions/planzer_actions';

export interface IPlanzerState {
  settings: PlanzerSettings;
  isFetching: boolean;
  error: any;
  hasSubscription: boolean | undefined;
  checkoutUrl: string;
}

const initialState: IPlanzerState = {
  settings: {
    sender: {},
    receiver: {},
  },
  isFetching: false,
  error: '',
  hasSubscription: undefined,
  checkoutUrl: '',
};

export default function reducer(state: IPlanzerState = initialState, action: BedayaAction): IPlanzerState {
  switch (action.type) {
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['planzer'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
      if (['planzer'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.SUCCESS:
      if (['planzer'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case HAS_SUBSCRIPTION.REQUEST:
    case POST_CHECKOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case HAS_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        hasSubscription: action.data.subscription,
        isFetching: false,
      };
    case POST_CHECKOUT.SUCCESS:
      return {
        ...state,
        checkoutUrl: action.data.url,
        isFetching: false,
      };
    case HAS_SUBSCRIPTION.FAILURE:
    case POST_CHECKOUT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error.toString(),
      };
    default:
      return state;
  }
}
