// global imports
import { Grid, IconButton, Input, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// local imports
import _ from 'lodash';
import FormCard from '../../../../../../components/FormCard/FormCard';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import {
  getSettingsRequest,
  putSettingsRequest,
} from '../../../../../../store/actions/common_actions';
import { IState } from '../../../../../../store/reducers';
import { EnforeSettings } from '../../../../../../types/EnforeSettings';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const AuthCreds = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { enforeSettings, translate } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<EnforeSettings | null>(enforeSettings);

  const delayedInput = useRef(
    _.debounce((field, value) => dispatch(putSettingsRequest({ system: 'enfore', data: { [field]: value } })), 1000),
  ).current;

  const handleValueChange = (field: 'user' | 'password' | 'orgId') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, [field]: newValue });
    delayedInput(field, newValue);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    setSettings(enforeSettings);
  }, [enforeSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('enfore.credentials') as string}
          desc={translate('enfore.credentials-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <div className={classes.w100}>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('enfore.user')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('enfore.user') as string}
                  className={classes.w100}
                  value={settings ? settings.user : ''}
                  onChange={handleValueChange('user')}
                  disabled={!enforeSettings}
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('enfore.password')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <Input
                  placeholder={translate('enfore.password') as string}
                  className={classes.w100}
                  value={settings ? settings.password : ''}
                  onChange={handleValueChange('password')}
                  disabled={!enforeSettings}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid container={true} style={{ marginBottom: 10 }}>
              <Grid item={true} md={3}>
                <span style={{ fontWeight: 'bold' }}>{translate('enfore.org-id')}</span>
              </Grid>
              <Grid item={true} md={9}>
                <TextField
                  placeholder={translate('enfore.org-id') as string}
                  className={classes.w100}
                  value={settings ? settings.orgId : ''}
                  onChange={handleValueChange('orgId')}
                  disabled={!enforeSettings}
                />
              </Grid>
            </Grid>
          </div>
        </FormCard>
      </Grid>
    </GridRow>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  enforeSettings: state.enfore.settings,
});

const mapDispatchToProps = {
  getSettingsRequest,
  putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthCreds),
);
