// global imports
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import * as commonActions from '../../../../store/actions/common_actions';
import * as sumupActions from '../../../../store/actions/sumup_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import { SumupSettings as SumupSettingsType } from '../../../../types/SumupSettings';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

const CreditAccount: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { sumupAccounts, sumupSettings, quickLoad, getAccountsRequest, putSettingsRequest } = props;

  const [settings, setSettings] = useState<SumupSettingsType | null>(null);

  const [creditAccounts, setCreditAccounts] = useState<IOptionType[]>([]);
  const [selectedCredit, setSelectedCredit] = useState<IOptionType | null>(null);

  // didMount fetch data
  useEffect(() => {
    // getSettingsRequest({ system: 'sumup' });
    if (quickLoad) {
      getAccountsRequest('credit');
    } else {
      setTimeout(() => getAccountsRequest('credit'), 3000);
    }
  }, []);

  // convert account data for react-select
  useEffect(() => {
    if (sumupAccounts.credit) {
      setCreditAccounts(
        sumupAccounts.credit.map((account: any) => ({
          value: parseInt(account.number, 10),
          label: `${account.number}: ${account.name}`,
        })),
      );
    }
  }, [sumupAccounts]);

  // update settings
  useEffect(() => {
    if (sumupSettings) {
      const newSettings = { ...cloneDeep(sumupSettings) };
      setSettings(newSettings);
      if (creditAccounts && sumupSettings.transaction.creditAccount) {
        const selectedOption = creditAccounts.find(
          (accountOption: any) => accountOption.value === sumupSettings.transaction.creditAccount,
        );
        if (selectedOption) {
          setSelectedCredit(selectedOption);
        }
      }
    }
  }, [sumupSettings, creditAccounts]);

  // save settings to backend
  useEffect(() => {
    // only save when all data available
    if (creditAccounts.length && sumupSettings && settings && !isEqual(sumupSettings, settings)) {
      const updatedSettings = {
        ...settings,
        transaction: {
          ...settings.transaction,
          creditAccount: settings.transaction.creditAccount,
        },
      };
      if (!isEqual(sumupSettings, updatedSettings)) {
        putSettingsRequest({ system: 'sumup', data: updatedSettings });
      }
    }
  }, [settings]);

  const creditChanged = (value: IOptionType) => {
    if (settings) {
      setSelectedCredit(value);
      setSettings({
        ...settings,
        transaction: {
          ...settings.transaction,
          creditAccount: parseInt(value.value, 10),
        },
      });
    }
  };

  return creditAccounts.length ? (
    <Select
      isSearchable={false}
      className={classes.selectSmall}
      options={creditAccounts}
      value={selectedCredit}
      onChange={(value: ValueType<IOptionType, false>) => creditChanged(value as IOptionType)}
      styles={colourStyles}
    />
  ) : (
    <LinearProgress className={classes.contentLarge} />
  );
};

const defaultProps = {
  quickLoad: false,
};
CreditAccount.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  sumupSettings: state.sumup.settings,
  sumupAccounts: state.sumup.accounts,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
  getAccountsRequest: sumupActions.getAccountsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(CreditAccount));
