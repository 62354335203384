// global imports
import { FormControl, makeStyles, RadioGroup, Theme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import { StyledRadio } from '../../../../components/StyledRadio';
import { StyledSwitch } from '../../../../components/StyledSwitch';
import assign from '../../../../services/assign';
import * as commonActions from '../../../../store/actions/common_actions';
import { SumupSettings } from '../../../../types/SumupSettings';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const AdvancedSettings: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, sumupSettings } = props;

  const [settings, setSettings] = useState<SumupSettings | null>(null);

  const handleSwitchChange = (name: any) => (event: any) => {
    if (settings) {
      const newSettings = assign({ ...settings }, name, event.target.checked);
      setSettings(newSettings);
      updateSettings(newSettings);
    }
  };

  const handleValueChange = (name: any) => (event: any) => {
    if (settings) {
      const newSettings = assign({ ...settings }, name, event.target.value);
      setSettings(newSettings);
      updateSettings(newSettings);
    }
  };

  const updateSettings = (newSettings: SumupSettings) => {
    if (props.sumupSettings) {
      const updatedSettings = {
        ...props.sumupSettings,
        advanced: {
          ...props.sumupSettings.advanced,
          useDescriptionAsProductCode: newSettings.useDescriptionAsProductCode,
          targetEntity: newSettings.targetEntity,
        },
      };

      props.putSettingsRequest({ system: 'sumup', data: updatedSettings });
    }
  };

  useEffect(() => {
    if (sumupSettings) {
      setSettings({
        ...sumupSettings,
        useDescriptionAsProductCode: sumupSettings.advanced && sumupSettings.advanced.useDescriptionAsProductCode,
        targetEntity:
          sumupSettings.advanced && sumupSettings.advanced.targetEntity === 'invoice'
            ? 'Invoice'
            : sumupSettings.advanced.targetEntity === 'order'
            ? 'Order'
            : '',
      });
    }
  }, []);

  return (
    <GridContainer>
      <GridItem xs={6} className={classes.gridItem}>
        <InputLabel>{translate('sumup-advanced-settings.label-1')}</InputLabel>
      </GridItem>
      <GridItem xs={3} className={classes.gridItem}>
        <StyledSwitch
          checked={settings ? settings.useDescriptionAsProductCode : false}
          onChange={handleSwitchChange('useDescriptionAsProductCode')}
          value="useDescriptionAsProductCode"
        />
      </GridItem>
      <GridItem xs={6} className={classes.gridItem}>
        <InputLabel>{translate('sumup-advanced-settings.label-2')}</InputLabel>
      </GridItem>
      <GridItem xs={3} className={classes.gridItem}>
        <FormControl component="fieldset">
          <RadioGroup
            // disabled={!settings}
            value={settings ? settings.targetEntity : 'Invoice'}
            onChange={handleValueChange('targetEntity')}
            className={classes.radio}
          >
            <FormControlLabel value="Order" control={<StyledRadio />} label={translate('shopify.order')} />
            <FormControlLabel value="Invoice" control={<StyledRadio />} label={translate('shopify.invoice')} />
          </RadioGroup>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
};

const defaultProps = {};
AdvancedSettings.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  sumupSettings: state.sumup.settings,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(AdvancedSettings));
