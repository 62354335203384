import React from 'react';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Report } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';

type ReportSectionProps = {
  enabled: boolean;
  settings: Report;
  setSettings: (item: Report) => void;
} & LocalizeContextProps;

function ReportSection(props: ReportSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  return (
    <Grid container={true}>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center'}}>
        {translate('reminders.report.email') as string}
      </Grid>
      <Grid item={true} xs={9}>
        <TextFieldDelayed
          placeholder={translate('reminders.report.email') as string}
          className={classes.w100}
          value={settings.reportEMail}
          onChange={value => setSettings({...settings, reportEMail: value})}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(ReportSection);
