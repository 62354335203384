import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
import * as authActions from '../../store/actions/auth_actions';

import { IState } from '../../store/reducers';
import style from './styles';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  postOAuthTokenRequest: (bexioLoginRequest: any) => any;
  setRedirectBack: (data: any) => any;
  shopifyAuthenticated: boolean;
  location: {
    search: string;
    pathname: string;
  };
  isLoading: boolean;
  isAuthenticated: boolean;
  error: any;
}

interface OAuthParams {
  code: string | null;
  host: string | null;
  hmac: string | null;
  shop: string | null;
  state: string | null;
  timestamp: string | null;
}

function readOAuthFromParams(params: URLSearchParams): OAuthParams {
  return {
    code: params.get('code'),
    host: params.get('host'),
    hmac: params.get('hmac'),
    shop: params.get('shop'),
    state: params.get('state'),
    timestamp: params.get('timestamp'),
  };
}

function readOAuthFromCookies(cookies: Cookies): OAuthParams {
  return {
    code: cookies.get('shopify_code'),
    host: cookies.get('shopify_host'),
    hmac: cookies.get('shopify_hmac'),
    shop: cookies.get('shopify_shop'),
    state: cookies.get('shopify_state'),
    timestamp: cookies.get('shopify_timestamp'),
  };
}

function clearCookies(cookies: Cookies) {
  cookies.remove('shopify_code', { path: '/' });
  cookies.remove('shopify_host', { path: '/' });
  cookies.remove('shopify_hmac', { path: '/' });
  cookies.remove('shopify_shop', { path: '/' });
  cookies.remove('shopify_state', { path: '/' });
  cookies.remove('shopify_timestamp', { path: '/' });
}

function storeOAuthInCookies(params: OAuthParams, cookies: Cookies) {
  cookies.set('shopify_code', params.code, { path: '/' });
  cookies.set('shopify_host', params.host, { path: '/' });
  cookies.set('shopify_hmac', params.hmac, { path: '/' });
  cookies.set('shopify_shop', params.shop, { path: '/' });
  cookies.set('shopify_state', params.state, { path: '/' });
  cookies.set('shopify_timestamp', params.timestamp, { path: '/' });
}

const ShopifyOAuthHandler: React.FC<IProps> = props => {
  const { classes, location, isAuthenticated, shopifyAuthenticated, isLoading, error } = props;
  const { postOAuthTokenRequest, setRedirectBack } = props;

  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    const cookies = new Cookies();
    const params = new URLSearchParams(location.search);
    let oauth = readOAuthFromParams(params);

    if (!isAuthenticated) {
      if (oauth.code) {
        storeOAuthInCookies(oauth, cookies);
        setRedirectBack('/oauth/shopify/callback');
      }
      setRedirect('/admin/login');
    } else if (shopifyAuthenticated || error) {
      clearCookies(cookies);
      setRedirect('/admin/shopify-app');
    } else if (!isLoading) {
      if (!oauth.code) {
        oauth = readOAuthFromCookies(cookies);
        clearCookies(cookies);
      }
      if (oauth.code) {
        postOAuthTokenRequest({
          system: 'shopify',
          ...oauth,
        });
      } else {
        clearCookies(cookies);
        setRedirect('/admin/shopify-app');
      }
    }
  }, [location, isAuthenticated, isLoading, shopifyAuthenticated, error]);

  return (
    <div className={classes.container}>
      {redirect ? <Redirect to={redirect} /> : null}
      <CircularProgress size={100} />
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  shopifyAuthenticated: state.shopify.authenticated,
  isLoading: state.shopify.isFetching,
  isAuthenticated: !!state.auth.token,
  error: state.shopify.error,
});

const mapDispatchToProps = {
  postOAuthTokenRequest: authActions.postOAuthTokenRequest,
  setRedirectBack: authActions.setRedirect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(ShopifyOAuthHandler));
