// global imports
import { Link, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/CustomButtons/Button.jsx';

import colourStyles from '../../../../assets/jss/reactSelectStyle';
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { getTaxesRequest } from '../../../../store/actions/weclapp_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import { TTax, WeclappSettings } from '../../../../types/WeclappSettings';

// local imports
import CustomButton from '../../../../components/NewUI/CustomButton';
import styles from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => styles as StyleRules);

const defaultVatList = ['0%', '2.5%', '7%', '7.7%', '10%', '19%', '21%'];

const VatAccounts: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  const { translate } = props;
  // tslint:disable-next-line: no-shadowed-variable
  const weclappSettings = useSelector<IState, StateProps['weclappSettings']>(({ weclapp: { settings } }) => settings);
  // tslint:disable-next-line: no-shadowed-variable
  const weclappTaxes = useSelector<IState, StateProps['weclappTaxes']>(({ weclapp: { taxes } }) => taxes);

  const [settings, setSettings] = useState<WeclappSettings | null>(null);
  const [vatOptions] = useState<IOptionType[]>(defaultVatList.map(vat => ({ label: vat, value: vat })));
  const [taxes, setTaxes] = useState<IOptionType[]>([]);

  const { apiToken } = weclappSettings;

  // didMount fetch data
  useEffect(() => {
    if (apiToken) {
      dispatch(getTaxesRequest({}));
    }
  }, [apiToken]);

  // convert taxes data for react-select
  useEffect(() => {
    if (weclappTaxes) {
      setTaxes(weclappTaxes.map(tax => ({
        value: tax.id.toString(),
        label: tax.name,
      })) as IOptionType[]);
    }
  }, [weclappTaxes]);

  // set settings locally
  useEffect(() => {
    if (weclappSettings) {
      const newSettings = { ...cloneDeep(weclappSettings) };
      if (!newSettings.sales.taxes.length) {
        newSettings.sales.taxes = [
          {
            title: '',
            value: 0,
            isDefault: true,
          },
        ];
      }
      setSettings(newSettings);
    }
  }, [weclappSettings]);

  // save settings to backend
  useEffect(() => {
    if (
      taxes &&
      weclappSettings &&
      settings &&
      // do not save until all values are valid
      !settings.sales.taxes.find((tax: TTax) => !tax.id || !tax.value) &&
      !isEqual(weclappSettings, settings)
    ) {
      const updatedSettings = {
        ...settings,
        taxes: [
          ...settings.sales.taxes,
          // .filter(tax => tax.id && tax.title)
        ],
      };
      if (!isEqual(weclappSettings, updatedSettings)) {
        dispatch(putSettingsRequest({ system: 'weclapp', data: updatedSettings }));
      }
    }
  }, [settings]);

  const addTax = (key?: number) => {
    if (settings) {
      setSettings({
        ...settings,
        sales: {
          taxes: [
            ...settings.sales.taxes,
            typeof key !== 'undefined'
              ? { ...settings.sales.taxes[key], isDefault: false }
              : {
                  title: '',
                  value: 0,
                  isDefault: false,
                },
          ],
        },
      });
    }
  };
  const removeTax = (key: number) => {
    if (settings) {
      const newTaxes = [...settings.sales.taxes.slice(0, key), ...settings.sales.taxes.slice(key + 1)];
      const hasDefault = _.find(newTaxes, tax => tax.isDefault);

      if (!newTaxes.length) {
        newTaxes.push({
          title: '',
          value: 0,
          isDefault: true,
        });
      } else if (!hasDefault) {
        newTaxes[0].isDefault = true;
      }
      setSettings({
        ...settings,
        sales: {
          taxes: newTaxes,
        },
      });
    }
  };
  const toggleTaxesDefault = (key: number) => {
    if (settings) {
      setSettings({
        ...settings,
        sales: {
          taxes: settings.sales.taxes.map((tax, index) => ({ ...tax, isDefault: index === key })),
        },
      });
    }
  };
  const vatChanged = (key: number, value: IOptionType) => {
    if (settings) {
      setSettings({
        ...settings,
        sales: {
          taxes: [
            ...settings.sales.taxes.slice(0, key),
            {
              ...settings.sales.taxes[key],
              title: value.value,
              value: parseFloat(value.value) / 100,
            },
            ...settings.sales.taxes.slice(key + 1),
          ],
        },
      });
    }
  };
  const taxChanged = (key: number, value: IOptionType) => {
    if (settings) {
      setSettings({
        ...settings,
        sales: {
          taxes: [
            ...settings.sales.taxes.slice(0, key),
            {
              ...settings.sales.taxes[key],
              id: parseInt(value.value, 10),
            },
            ...settings.sales.taxes.slice(key + 1),
          ],
        },
      });
    }
  };

  return (
    settings && (
      <div className={classes.w100}>
        <table style={{ textAlign: 'left', width: '100%' }}>
          <tr style={{ fontFamily: 'Lato' }}>
            <th style={{ width: '15%' }}>{translate('percentage')}</th>
            <th style={{ width: '50%' }}>{translate('revenue')}</th>
            <th>{translate('default')}</th>
            <th />
          </tr>
          {settings.sales.taxes.map((vat: TTax, key: number) => (
            <tr
              key={key}
              className={cx({
                [classes.errorOutline]: !vat.id || !vat.value,
              })}
            >
              <td>
                <Select
                  isDisabled={!apiToken}
                  isSearchable={false}
                  options={vatOptions}
                  value={vatOptions.find(option => parseFloat(option.value) / 100 === vat.value) || null}
                  onChange={(value: ValueType<IOptionType, false>) => vatChanged(key, value as IOptionType)}
                  styles={colourStyles}
                />
              </td>
              <td>
                <Select
                  isDisabled={!apiToken}
                  isSearchable={false}
                  options={taxes}
                  value={taxes.find(tax => parseInt(tax.value, 10) === vat.id) || null}
                  onChange={(value: ValueType<IOptionType, false>) => taxChanged(key, value as IOptionType)}
                  styles={colourStyles}
                />
              </td>
              <td>
                {vat.isDefault ? (
                  <Checkbox
                    onChange={() => {
                      toggleTaxesDefault(key);
                    }}
                    checked={vat.isDefault}
                    disabled={!apiToken}
                  />
                ) : (
                  <Link style={{ cursor: 'pointer' }} color="textPrimary" onClick={() => toggleTaxesDefault(key)}>
                    als Standard definieren
                  </Link>
                )}
              </td>
              <td style={{ textAlign: 'center' }}>
                <Button
                  disabled={!apiToken}
                  justIcon={true}
                  round={true}
                  color="transparent"
                  onClick={() => removeTax(key)}
                >
                  <DeleteIcon />
                </Button>
              </td>
            </tr>
          ))}
        </table>
        <div style={styles.buttonContainer}>
          <CustomButton onClick={addTax}>{translate('bexio.add-vat')}</CustomButton>
        </div>
      </div>
    )
  );
};

export default withLocalize(VatAccounts);
