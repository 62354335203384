import commonStyles from '../../assets/jss/commonStyles';
import { IStyleProps } from '../../types/StyleProps';

const styles: IStyleProps = {
  ...commonStyles,
  connectError: {
    color: 'red',
    position: 'absolute',
  },
};

export default styles;
