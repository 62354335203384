import { WixSettings } from '../../types/WixSettings';

import { BedayaAction } from '../../types/BedayaAction';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';
import { HAS_SUBSCRIPTION, POST_CHECKOUT } from '../actions/wix_actions';

export interface IWixState {
  settings: WixSettings;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;

  hasSubscription: boolean | undefined;
  checkoutUrl: string;
}

const initialState: IWixState = {
  settings: {
    destinationSystem: null,
    instanceId: null,
    targetEntity: 'invoice',
  },

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'loading',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',

  hasSubscription: undefined,
  checkoutUrl: '',
};

export default function reducer(state: IWixState = initialState, action: BedayaAction): IWixState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['wix'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['wix'].includes(action.data.system)) {
        console.log('Getting settings');

        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['wix'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case HAS_SUBSCRIPTION.REQUEST:
    case POST_CHECKOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case HAS_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        hasSubscription: action.data.subscription,
        isFetching: false,
      };
    case POST_CHECKOUT.SUCCESS:
      return {
        ...state,
        checkoutUrl: action.data.url,
        isFetching: false,
      };
    case HAS_SUBSCRIPTION.FAILURE:
    case POST_CHECKOUT.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error.toString(),
      };
    default:
      return state;
  }
}
