import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { DebtCollection } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


type DebtCollectionLevelSectionProps = {
  enabled: boolean;
  settings: DebtCollection;
  setSettings: (data: DebtCollection) => void;
} & LocalizeContextProps;

function DebtCollectionLevelSection(props: DebtCollectionLevelSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const { notifications } = settings;
  const setNotifications = (newValue: any) => setSettings({ ...settings, notifications: {...notifications, ...newValue }});
  const classes = useDefaultStyles();
  return (
    <Grid container={true} style={{marginBottom: 10, marginTop: 10}}>
      <Grid item={true} xs={12} style={{marginBottom: 10, fontWeight: 'bold'}}>
        {translate('reminders.levels.debt-collection.title')}
      </Grid>
      <Grid item={true} xs={12} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="debt-collection-enabled"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.levels.debt-collection.enabled") as string}
          checked={settings.enabled}
          onChange={(event: any, newValue: boolean) => setSettings({...settings, enabled: newValue })}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={12} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="creditor-notification-enabled"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.levels.debt-collection.creditor-notification-enabled") as string}
          checked={notifications.creditorNotificationEnabled}
          onChange={(event: any, newValue: boolean) => setNotifications({ creditorNotificationEnabled: newValue })}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.debt-collection.creditor-notification-email') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          type="text"
          placeholder={translate('reminders.levels.debt-collection.creditor-notification-email') as string}
          value={notifications.creditorNotificationEMail}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setNotifications({creditorNotificationEMail: value})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.debt-collection.creditor-notification-offset-days') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          type="number"
          placeholder={translate('reminders.levels.debt-collection.creditor-notification-offset-days') as string}
          value={notifications.creditorNotificationOffsetDays + ''}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setNotifications({creditorNotificationOffsetDays: +value})}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(DebtCollectionLevelSection);
