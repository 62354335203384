// global imports
import { CircularProgress, makeStyles, Theme, Grid, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import moment from 'moment';

// project imports
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import FormCard from '../../../../components/FormCard/FormCard';

import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import * as sumupActions from '../../../../store/actions/sumup_actions';
import DestinationSystem from '../../CommonWidgets/DestinationSystem/DestinationSystem';
import AdvancedSettings from '../../SumupWidgets/AdvancedSettings/AdvancedSettings';
import CreditAccount from '../../SumupWidgets/CreditAccount/CreditAccount';
import DailySyncOptions from '../../SumupWidgets/DailySyncOptions/DailySyncOptions';
import DebitAccounts from '../../SumupWidgets/DebitAccounts/DebitAccounts';
import FeeAccount from '../../SumupWidgets/FeeAccount/FeeAccount';
import TransferOptions from '../../SumupWidgets/TransferOptions/TransferOptions';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { IState } from '../../../../store/reducers';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const clamp = (x: number, a: number, b: number): number => x < a ? a : (x > b ? b : x);

const SumupApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    OAuthURL,
    connectionStatus,
    fetchOAuthUrlRequest,
    testConnectionRequest,
    getSettingsRequest,
    sumupSettings,
    getStatisticsRequest,
    statistics,
  } = props;

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL) {
      fetchOAuthUrlRequest({ system: 'sumup' });
    }

    testConnectionRequest({ system: 'sumup' });

    getSettingsRequest({ system: 'sumup' });

    getStatisticsRequest({});
  }, []);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'sumup' });
      }
    }
  }, [connectionStatus]);

  const hasWarning = statistics && statistics.maxOrders > 0 && statistics.numOrders >= statistics.maxOrders;
  const percentOrders = (statistics && statistics.maxOrders > 0) ? clamp(statistics.numOrders / statistics.maxOrders * 100, 0, 100).toFixed(2) : 0;

  return (
    <SettingsContainer appName={translate('sumup_app_name') as string}>
      {connectionStatus === 'ok' && hasWarning && (
        <Alert variant="outlined" severity="warning">
          {translate('sumup.plan-alert') + ' '}
          <Link href="/auth/sumup-plans">{translate('sumup.update-plan')}</Link>
        </Alert>
      )}
      {connectionStatus === 'ok' && statistics && (
        <GridRow container={true}>
          <Grid item={true} style={{width: '100%'}}>
            <FormCard>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{fontWeight: 'bold'}}>{statistics.planName ? translate(`sumup.plan-names.${statistics.planName}`) + '' : ''}</div>
                <div style={{flexGrow: 1}}></div>
                {statistics.maxOrders >= 0 ? (
                  <div>{translate('sumup.order-statistics', statistics) + ''}</div>
                ) : (
                  <div>{translate('sumup.order-statistics-unlimited', statistics) + ''}</div>
                )}
              </div>
              <div style={{backgroundColor: '#e5e1e6', width: '100%', height: '0.25rem', position: 'relative', margin: '0.5rem 0'}}>
                <div style={{backgroundColor: hasWarning ? '#D6001C' : '#31D618', width: `${percentOrders}%`, position: 'absolute', left: 0, top: 0, height: '0.25rem'}}>
                </div>
              </div>
              <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <div>{translate('sumup.order-statistics-since', { date: moment(statistics.lastPayment).format('DD.MM.YYYY')}) + ''}</div>
                <div style={{flexGrow: 1}}></div>
                <Link href="/auth/sumup-plans">
                  {translate('sumup.update-plan')}
                </Link>
              </div>
            </div>
           </FormCard>
          </Grid>
        </GridRow>
      )}
      <GridRow container={true}>
        <Grid item={true} xs={12} md={4}>
          <Label title={translate('status') as string} desc="" />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <FormCard>
            {OAuthURL && connectionStatus !== 'loading' ? (
              connectionStatus === 'ok' ? (
                <>
                  <div className={classes.connectedContainer}>
                    <span className={classes.connectedStatus}>{translate('connected')}</span>
                    <DisconnectWidget appName="sumup" />
                  </div>
                </>
              ) : (
                <ConnectWidget appName="sumup" redirect="/admin/sumup-app" />
              )
            ) : (
              <CircularProgress />
            )}
          </FormCard>
        </Grid>
      </GridRow>
      {sumupSettings && connectionStatus !== 'nok' ? (
        <>
          <DestinationSystem system="sumup" />
          <GridRow container={true}>
            <Grid item={true} xs={12} md={4}>
              <Label title={translate('Sync Option') as string} desc="" />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <FormCard>
                <TransferOptions />
              </FormCard>
            </Grid>
          </GridRow>
          {sumupSettings.transaction && sumupSettings.transaction.enabled ? (
            <>
              <GridRow container={true}>
                <Grid item={true} xs={12} md={4}>
                  <Label title={translate('debit_accounts') as string} desc="" />
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <FormCard>
                    <DebitAccounts quickLoad={false} />
                  </FormCard>
                </Grid>
              </GridRow>
              <GridRow container={true}>
                <Grid item={true} xs={12} md={4}>
                  <Label title={translate('credit_account') as string} desc="" />
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <FormCard>
                    <CreditAccount quickLoad={false} />
                  </FormCard>
                </Grid>
              </GridRow>
              <GridRow container={true}>
                <Grid item={true} xs={12} md={4}>
                  <Label title={translate('fee_account') as string} desc="" />
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <FormCard>
                    <FeeAccount quickLoad={false} />
                  </FormCard>
                </Grid>
              </GridRow>
              <GridRow container={true}>
                <Grid item={true} xs={12} md={4}>
                  <Label title={translate('daily_sync') as string} desc="" />
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <FormCard>
                    <DailySyncOptions />
                  </FormCard>
                </Grid>
              </GridRow>
              <BexioConfig system="Sumup" simple />
            </>
          ) : sumupSettings.advanced && sumupSettings.advanced.enabled ? (
            <>
              <GridRow container={true}>
                <Grid item={true} xs={12} md={4}>
                  <Label title={translate('credit_account') as string} desc="" />
                </Grid>
                <Grid item={true} xs={12} md={8}>
                  <FormCard>
                    <AdvancedSettings />
                  </FormCard>
                </Grid>
              </GridRow>
              <BexioConfig system="Sumup" />
            </>
          ) : null}
        </>
      ) : null}
    </SettingsContainer>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  OAuthURL: state.sumup.OAuthURL,
  sumupSettings: state.sumup.settings,
  connectionStatus: state.sumup.connectionStatus,
  subscription: state.sumup.subscription,
  statistics: state.sumup.statistics,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  getSettingsRequest: commonActions.getSettingsRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
  getStatisticsRequest: sumupActions.getStatisticsRequest,
  getSubscriptionRequest: sumupActions.getSubscriptionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SumupApp),
);
