import React, { useState } from 'react';

import { Grid, InputLabel, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { withLocalize } from 'react-localize-redux';
import { toast } from 'react-toastify';
import commonStyles from '../../../../../../assets/jss/commonStyles';
import Button from '../../../../../../components/CustomButtons/Button';
import GridRow from '../../../../../../components/NewUI/GridRow';
import { clientApp } from '../../../../../../store/api';
import { IStyleProps, PropsClasses } from '../../../../../../types/StyleProps';
import style from './styles';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const UploadCSV = (props: any) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const newFile = e.target.files[0];
    reader.onloadend = () => {
      setFile(newFile);
    };
    reader.readAsDataURL(newFile);
  };

  const uploadFile = async () => {
    const formData = new FormData();

    formData.append('file', file);
    setLoading(true);
    const response = await clientApp.post('/ui/ibelsa/csv', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setLoading(false);
    if (response.data.success) {
      toast.success(translate('ibelsa.upload-success') as string);
      return;
    }
    if (!response || !response.data) {
      return;
    }
    toast.error(response.data.error);
  };

  return (
    <GridRow container={true} style={style.row}>
      <Grid item={true} style={style.paddingRight}>
        <InputLabel style={style.inputLabel}>{translate('ibelsa.upload-csv')}</InputLabel>
        <input type="file" onChange={e => handleImageChange(e)} accept=".csv" />
      </Grid>
      <Grid item={true}>
        <Button
          className={classes.formButton}
          type="button"
          color="primary"
          disabled={!file || loading}
          onClick={uploadFile}
        >
          {translate('import')}
        </Button>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(UploadCSV);
