// global imports
import { makeStyles, Theme } from '@material-ui/core';
import GridContainer from '@material-ui/core/Grid';
import { StyleRules } from '@material-ui/core/styles';
import React from 'react';

// project imports
import BexioIcon from '../../assets/img/bexio-icon.png';
import DebitoorIcon from '../../assets/img/debitoor-icon.png';
import GastrofixIcon from '../../assets/img/gastrofix-icon.png';
import IbelsaIcon from '../../assets/img/ibelsa-logo.png';
import PrestashopIcon from '../../assets/img/prestashop-icon.png';
import ShopifyIcon from '../../assets/img/shopify-icon.png';
import SumupIcon from '../../assets/img/sumup-icon.png';
import ZohoIcon from '../../assets/img/zoho-icon.png';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridItem from '../../components/Grid/GridItem';
import FlmIcon from '../../assets/img/flm-icon.png';

// project types imports
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import style from './Actions/styles';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

export default function SystemContainer(props: any) {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { appName } = props;

  let itemIcon;
  switch (appName) {
    case 'bexio': {
      itemIcon = BexioIcon;
      break;
    }
    case 'debitoor': {
      itemIcon = DebitoorIcon;
      break;
    }
    case 'shopify': {
      itemIcon = ShopifyIcon;
      break;
    }
    case 'sumup': {
      itemIcon = SumupIcon;
      break;
    }
    case 'zoho': {
      itemIcon = ZohoIcon;
      break;
    }
    case 'prestashop': {
      itemIcon = PrestashopIcon;
      break;
    }
    case 'gastrofix': {
      itemIcon = GastrofixIcon;
      break;
    }
    case 'ibelsa': {
      itemIcon = IbelsaIcon;
      break;
    }
    case 'xeniapos': {
      itemIcon = FlmIcon;
      break;
    }
    default: {
      itemIcon = '';
    }
  }

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader>
          <img className={classes.itemIcon} src={itemIcon} alt="" />
          <h4 className={classes.smallTitle}>{props.title}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer className={classes.container}>{props.children}</GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}
