// global imports
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Cookies from 'universal-cookie/es6';

// project imports
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader.jsx';
import Button from '../../../../components/CustomButtons/Button.jsx';
import { config } from '../../../../config';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

function fetchOAuthUrl(system: string) {
  fetch(config.backendUrl + '/ui/user/oauth/' + system, {
    mode: 'cors',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
    .then(response => response.json())
    .then(url => {
      const cookies = new Cookies();
      cookies.set('flow', 'connect', { path: '/' });
      window.open(url ? '' + url.url : '', '_self');
    });
}

const DebitoorApp = () => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Connections</h4>
        <p className={classes.cardCategoryWhite}>Connect your apps</p>
      </CardHeader>
      <CardBody>
        <Button type="button" color="primary" onClick={() => fetchOAuthUrl('bexio')}>
          Bexio
        </Button>
        <Button type="button" color="primary" onClick={() => fetchOAuthUrl('shopify')}>
          Shopify
        </Button>
        <Button type="button" color="primary" onClick={() => fetchOAuthUrl('zoho-books')}>
          Zoho Books
        </Button>
        <Button type="button" color="primary" onClick={() => fetchOAuthUrl('debitoor')}>
          Debitoor
        </Button>
        <Button type="button" color="primary" onClick={() => fetchOAuthUrl('bitrix')}>
          Bitrix
        </Button>
      </CardBody>
    </Card>
  );
};

export default DebitoorApp;
