import React from 'react';
import { Grid, Link, CircularProgress } from '@material-ui/core';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Alert } from '@material-ui/lab';

import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import Button from '../../../components/CustomButtons/Button.jsx';
import FormCard from '../../../components/FormCard/FormCard';
import { PlanType, CardProps, PlansPageProps } from './types.d';
import { plans } from './data';
import { useReminderSubscriptionState, useReminderStatisticsState, useReminderCheckout } from './hooks';

const clamp = (x: number, a: number, b: number): number => x < a ? a : (x > b ? b : x);

const PlanCard = ({plan, translate, loading, onSelect, isCurrent, hasSubscription}: CardProps) => (
  <Card style={{
    height: 'auto',
    margin: '1rem 0.5rem',
    width: '10rem',
  }}>
    <CardHeader color="primary">
      <span style={{fontWeight: 'bold'}}>
        {translate(`reminders.plans.names.${plan.name}`, plan.params, { renderInnerHtml: true })}
      </span>
      <br />
      <span style={{fontWeight: 'bold'}}>
        ({translate('reminders.plans.price', plan.params, { renderInnerHtml: true })})
      </span>
    </CardHeader>
    <CardBody>
        <div style={{
          width: '100%',
          height: '100%',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {!hasSubscription && (
            <div
              style={{
                height: '2rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                display: 'flex',
              }}
            >
              {translate(`reminders.plans.features.setup_fee`, plan.params, { renderInnerHtml: true })}
            </div>
          )}
          {plan.features.includes('unlimited') ? (
            <div
              style={{
                height: '2rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                display: 'flex',
              }}
            >
              {translate(`reminders.plans.features.unlimited`, plan.params, { renderInnerHtml: true })}
            </div>
          ) : (
            <div
              style={{
                height: '2rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                display: 'flex',
              }}
            >
              {translate(`reminders.plans.features.limited`, plan.params, { renderInnerHtml: true })}
            </div>
          )}
          {plan.features.includes('customizable') && (
            <div
              style={{
                height: '2rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                display: 'flex',
              }}
            >
              {translate(`reminders.plans.features.customizable`, plan.params, { renderInnerHtml: true })}
            </div>
          )}
        </div>
    </CardBody>
    <Button
      type="button"
      color="primary"
      disabled={loading || isCurrent}
      onClick={onSelect}
    >
      {isCurrent ? translate('reminders.plans.current') : translate('reminders.plans.button_order')}
    </Button>
  </Card>
);

const PlansPage = ({ translate }: PlansPageProps) => {
  const [loading, subscription] = useReminderSubscriptionState();
  const { request, response } = useReminderCheckout();

  const onSelectPlan = (plan: PlanType) => {
    request(plan.name, process.env.REACT_APP_FE_URL + '/admin/reminders');
  };
  React.useEffect(() => {
      if (response.data) {
          if (response.data.url) {
              window.location.href = response.data.url;
          } else {
              window.location.href = process.env.REACT_APP_FE_URL + '/admin/reminders';
          }
      }
  }, [response.data]);
  if (loading === 'pending') {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
      );
  }
  if (loading === 'error' || response.loading === 'error') {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{color: 'red'}}>Error</span>
        </div>
      );
  }
  if (!subscription) {
      return null;
  }
  const hasSubscription = subscription.subscription;
  const promoActive = (!hasSubscription && new Date() < new Date('2024-02-01'));
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        color: 'black',
        maxWidth: '1170px',
        margin: '0 auto',
        zIndex: 10,
        marginBottom: '5rem',
        position: 'relative',
      }}
    >
      <Link
        href="/admin/reminders"
        style={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
        }}
      >
        {translate('back')}
      </Link>
      {promoActive && (
        <div style={{ marginBottom: '1rem', fontSize: '1.25rem' }}>
          {translate('reminders.plans.promo', undefined, { renderInnerHtml: true })}
        </div>
      )}
      <div style={{ marginBottom: '1rem', fontSize: '1rem' }}>
        {translate('reminders.plans.trial', undefined, { renderInnerHtml: true })}
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {plans.map((plan, idx) => (
          <PlanCard
            key={idx}
            plan={plan}
            translate={translate}
            loading={response.loading === 'pending'}
            onSelect={() => onSelectPlan(plan)}
            isCurrent={subscription.plan === plan.name}
            hasSubscription={subscription.subscription}
          />
        ))}
      </div>
    </div>
  );
}

let SubscriptionWidget = ({ translate, activeLanguage }: LocalizeContextProps) => {
  const formatter = new Intl.DateTimeFormat(activeLanguage ? activeLanguage.code : 'de-CH', {});
  const [loading, subscription] = useReminderSubscriptionState();
  const [statsLoading, statistics] = useReminderStatisticsState();
  const isFetching = loading === 'pending' || statsLoading === 'pending';
  const plan = subscription ? plans.find(p => p.name === subscription.plan) : undefined;
  const hasSubscription = subscription ? subscription.subscription : false;
  const unlimitedPlan = plan ? plan.features.includes('unlimited') : false;
  const hasWarning = (plan && statistics) ? (
    !unlimitedPlan ? statistics.count >= +plan.params.num_reminders : false
  ) : false;
  const percent = (plan && statistics) ? (
    !unlimitedPlan ? clamp(statistics.count / +plan.params.num_reminders * 100, 0, 100).toFixed(2) : 0
  ) : 0;
  return (
    <Grid item>
      <FormCard>
      {isFetching ? (
        <CircularProgress />
      ) : !hasSubscription ? (
        <Alert variant="outlined" severity="warning">
          <Link href="/auth/reminders/plans">{translate('reminders.plans.not-subscribed')}</Link>
        </Alert>
      ) : (plan && statistics && (
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
              <div style={{fontWeight: 'bold'}}>{translate(`reminders.plans.names.${plan.name}`, plan.params, { renderInnerHtml: true })}</div>
              <div style={{flexGrow: 1}}></div>
              {unlimitedPlan ? (
                <div>{translate('reminders.plans.statistics-unlimited', { count: statistics.count }) + ''}</div>
              ) : (
                <div>{translate('reminders.plans.statistics', { num_reminders: plan.params.num_reminders, count: statistics.count }) + ''}</div>
              )}
            </div>
            <div style={{backgroundColor: '#e5e1e6', width: '100%', height: '0.25rem', position: 'relative', margin: '0.5rem 0'}}>
              <div style={{backgroundColor: hasWarning ? '#D6001C' : '#31D618', width: `${percent}%`, position: 'absolute', left: 0, top: 0, height: '0.25rem'}}>
              </div>
            </div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
              <div>{translate('reminders.plans.statistics-since', { date: formatter.format(new Date(statistics.periodStart))}) + ''}</div>
              <div style={{flexGrow: 1}}></div>
              <Link href="/auth/reminders/plans">
                {translate('reminders.plans.update-plan')}
              </Link>
            </div>
           </div>
      ))}
      </FormCard>
    </Grid>
  );
};

export const Subscription = withLocalize(SubscriptionWidget);

export default withLocalize(PlansPage);
