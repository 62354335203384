// global imports
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import { getSettingsRequest } from '../../../../store/actions/common_actions';

// local imports
import Label from '../../../../components/NewUI/Label/Label';
import SettingsContainer from '../../../../components/NewUI/SettingsContainer';
import { config } from '../../../../config';
import { IState } from '../../../../store/reducers';
import DestinationSystem from '../../CommonWidgets/DestinationSystem/DestinationSystem';
import ProductSettings from '../../CommonWidgets/ProductSettings/ProductSettings';
import BexioConfig from '../PrestashopApp/components/BexioConfig/BexioConfig';
import WeclappConfig from '../PrestashopApp/components/WeclappConfig/WeclappConfig';
import AuthCreds from './components/AuthCreds/AuthCreds';
import TransferOptions from './components/TransferOptions/TransferOptions';
import { Props, StateProps } from './types';

const LightspeedApp = (props: Props) => {
  const dispatch = useDispatch();
  const lightspeedSettings = useSelector<IState, StateProps['lightspeedSettings']>(
    ({ lightspeed: { settings } }) => settings,
  );

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'lightspeed' }));
  }, []);

  const getDestinationSystemSettings = () => {
    if (config.activeDomain === 'bexio' || lightspeedSettings.destinationSystem === 'bexio') {
      return <BexioConfig system="Lightspeed" />;
    }

    switch (lightspeedSettings.destinationSystem) {
      case 'zoho-books':
        return <Label title="Zoho Books" desc="Coming soon" />;
      case 'weclapp':
        return <WeclappConfig />;
      default:
        return <BexioConfig system="Lightspeed" />;
    }
  };

  return (
    <SettingsContainer appName="Lightspeed">
      <AuthCreds />
      <TransferOptions />
      <ProductSettings system="lightspeed" />
      {config.activeDomain !== 'bexio' && <DestinationSystem system="lightspeed" />}
      {getDestinationSystemSettings()}
    </SettingsContainer>
  );
};

export default withLocalize(LightspeedApp);
