// global imports
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Card from '../../../components/Card/Card.jsx';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader.jsx';
import GridContainer from '../../../components/Grid/GridContainer';
import AclService from '../../../services/acl.service';
import assign from '../../../services/assign';
import * as appsActions from '../../../store/actions/apps_actions';
import * as commonActions from '../../../store/actions/common_actions';
import * as commonsActions from '../../../store/actions/commons_actions';

// project types imports
import { IState } from '../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../types/StyleProps';

// local imports
import { config } from '../../../config';
import isAllowed from '../isAllowed';
import SystemContainer from '../SystemContainer';
import SystemWidget from '../SystemWidget';
import style from './styles';
import { Props, StateProps } from './types';

enum IAppsList {
  'sumup',
  'shopify',
  'prestashop',
  'bexio',
  'debitoor',
  'zoho-books',
  'zoho-crm',
  'vend',
  'weclapp',
  'wix',
  'bitrix',
  'lightspeed-r',
  'enfore',
  'xeniapos',
  'ecwid',
  'woocommerce',
}

type AppsSettings = {
  [x in IAppsList]: boolean;
};

type IVersionedApp = {
  name: string;
  version: number;
};

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const AppsPage = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, getAppsRequest, putAppsRequest, apps, userInfo, hasSubscriptionRequest, checkoutUrl } = props;

  const isBedayaUser =
    AclService.hasPermission(props.auth, 'bedaya_basic') || AclService.hasPermission(props.auth, 'bedaya-basic');

  const [settings, setSettings] = useState<AppsSettings | null>(null);

  const [expanded, setExpanded] = React.useState('');

  const isExpanded = (list: string[] | string) =>
    !expanded || (typeof list === 'string' ? expanded === list : list.some((appName: string) => expanded === appName));

  // didMount fetch data
  useEffect(() => {
    getAppsRequest({});
    console.log('env', process.env.REACT_APP_ACTIVE_DOMAIN);
    if (process.env.REACT_APP_ACTIVE_DOMAIN !== 'bexio') {
      console.log('get sub');
      hasSubscriptionRequest();
    }
  }, []);

  // update expanded configurator from url
  useEffect(() => {
    const match = props.location.pathname.match(/\/admin\/apps\/(\w+)\/?(\d)?/);
    if (match && match[1]) {
      setExpanded(match[1]);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  // set settings locally
  useEffect(() => {
    if (props.apps && props.userInfo && props.userInfo.availableApps) {
      const newSettings = props.userInfo.availableApps.reduce((acc: any, cur: IVersionedApp, i: any) => {
        acc[cur.name] = props.apps.includes(cur.name);
        return acc;
      }, {});
      setSettings(newSettings);
    }
  }, [props.apps, props.userInfo]);

  // save settings to backend
  useEffect(() => {
    if (apps && userInfo && userInfo.availableApps && settings) {
      const updatedSettings = [
        ...Object.keys(settings).filter((app: string) => settings[(app as unknown) as IAppsList]),
      ];
      if (!isEqual(apps, updatedSettings)) {
        putAppsRequest(updatedSettings);
      }
    }
  }, [settings]);

  const handleSwitchChange = (name: any) => (event: any) => {
    if (settings) {
      setSettings(assign({ ...settings }, name, event.target.checked));
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{translate('apps')}</h4>
          <p className={classes.cardCategoryWhite}>{translate('activate-apps')}</p>
        </CardHeader>
        <CardBody>
          {props.userInfo.availableApps ? (
            <GridContainer>
              {isAllowed(['sumup', 'vend', 'lightspeed-r', 'gastrofix', 'enfore', 'xeniapos'], props.userInfo.availableApps) &&
                isExpanded(['sumup']) && (
                  <SystemContainer title={translate('pos')} {...props}>
                    {isExpanded(['sumup']) && (
                      <SystemWidget
                        {...props}
                        appName="sumup"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'sumup'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'sumup' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['vend']) && (
                      <SystemWidget
                        {...props}
                        appName="vend"
                        expanded={expanded === 'vend'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'vend' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['lightspeed-r']) && (
                      <SystemWidget
                        {...props}
                        appName="lightspeed-r"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'lightspeed-r'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'lightspeed-r' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['gastrofix']) && (
                      <SystemWidget
                        {...props}
                        appName="gastrofix"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'gastrofix'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'gastrofix' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['ibelsa']) && (
                      <SystemWidget
                        {...props}
                        appName="ibelsa"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'ibelsa'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'ibelsa' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['enfore']) && (
                      <SystemWidget
                        {...props}
                        appName="enfore"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'enfore'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'enfore' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['xeniapos']) && (AclService.hasPermission(props.auth, 'bedaya-admin') || AclService.hasPermission(props.auth, 'bedaya-gastro')) && (
                      <SystemWidget
                        {...props}
                        appName="xeniapos"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'xeniapos'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'xeniapos' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                  </SystemContainer>
                )}
              {isAllowed(['shopify', 'prestashop'], props.userInfo.availableApps) &&
                isExpanded(['shopify', 'prestashop']) && (
                  <SystemContainer title={translate('webshops')} {...props}>
                    {isExpanded(['shopify']) && (
                      <SystemWidget
                        {...props}
                        appName="shopify"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'shopify'}
                        handleSwitchChange={handleSwitchChange}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'shopify' : '');
                        }}
                      />
                    )}
                    {isExpanded(['prestashop']) && (
                      <SystemWidget
                        {...props}
                        appName="prestashop"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'prestashop'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'prestashop' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['wix']) ? (
                      <SystemWidget
                        {...props}
                        appName="wix"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'wix'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'wix' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    ) : null}
                    {isExpanded(['lightspeed']) && (
                      <SystemWidget
                        {...props}
                        appName="lightspeed"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'lightspeed'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'lightspeed' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['ecwid']) && (
                      <SystemWidget
                        {...props}
                        appName="ecwid"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'ecwid'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'ecwid' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['woocommerce']) && (
                      <SystemWidget
                        {...props}
                        appName="woocommerce"
                        isConfiguratorDisabled={true}
                        expanded={expanded === 'woocommerce'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'woocommerce' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                  </SystemContainer>
                )}
              {config.activeDomain !== 'bexio' &&
                isAllowed(['zoho-crm', 'mailchimp', 'bitrix'], props.userInfo.availableApps) && (
                  <SystemContainer title={translate('crm')} {...props}>
                    {config.activeDomain !== 'bexio' && isExpanded(['bitrix']) ? (
                      <SystemWidget
                        {...props}
                        appName="bitrix"
                        expanded={expanded === 'bitrix'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'bitrix' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                        isConfiguratorDisabled={true}
                      />
                    ) : null}
                    {isExpanded(['zoho-crm']) && (
                      <SystemWidget
                        {...props}
                        appName="zoho-crm"
                        expanded={expanded === 'zoho-crm'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'zoho-crm' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                        isConfiguratorDisabled={true}
                      />
                    )}
                    {isExpanded(['mailchimp']) && (
                      <SystemWidget
                        {...props}
                        appName="mailchimp"
                        expanded={expanded === 'mailchimp'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'mailchimp' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                        isConfiguratorDisabled={true}
                      />
                    )}
                    {isExpanded(['forcemanager']) && (
                      <SystemWidget
                        {...props}
                        appName="forcemanager"
                        expanded={expanded === 'forcemanager'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'forcemanager' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                        isConfiguratorDisabled={true}
                      />
                    )}
                  </SystemContainer>
                )}
              {isBedayaUser &&
                isAllowed(['bexio', 'debitoor', 'zoho-books', 'weclapp'], props.userInfo.availableApps) &&
                isExpanded(['bexio', 'debitoor', 'zoho-books', 'weclapp']) && (
                  <SystemContainer title={translate('erp')} {...props}>
                    {isExpanded(['bexio']) && (
                      <SystemWidget
                        {...props}
                        appName="bexio"
                        expanded={expanded === 'bexio'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'bexio' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['debitoor']) && (
                      <SystemWidget
                        {...props}
                        appName="debitoor"
                        expanded={expanded === 'debitoor'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'debitoor' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['zoho-books']) && (
                      <SystemWidget
                        {...props}
                        appName="zoho-books"
                        expanded={expanded === 'zoho-books'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'zoho-books' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                    {isExpanded(['weclapp']) && (
                      <SystemWidget
                        {...props}
                        appName="weclapp"
                        expanded={expanded === 'weclapp'}
                        onFullScreen={(isFullscreen: boolean) => {
                          setExpanded(isFullscreen ? 'weclapp' : '');
                        }}
                        handleSwitchChange={handleSwitchChange}
                      />
                    )}
                  </SystemContainer>
                )}
            </GridContainer>
          ) : (
            <LinearProgress className={classes.contentLarge} />
          )}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  auth: state.auth,
  apps: state.apps.apps,
  userInfo: state.auth.userInfo,
  isFetching: state.apps.isFetching,
  hasSubscription: state.commons.stripe.hasSubscription,
  checkoutUrl: state.commons.stripe.checkoutUrl,
});

const mapDispatchToProps = {
  getAppsRequest: appsActions.getAppsRequest,
  putAppsRequest: appsActions.putAppsRequest,
  disconnectRequest: commonActions.disconnectRequest,
  hasSubscriptionRequest: commonsActions.userHasSubscriptionRequest,
  postStripeCheckoutRequest: commonsActions.postStripeCheckoutRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppsPage),
);
