// global imports
import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import shortid from 'shortid';

// project imports
import {
  addShopRequest,
  changeShopOptionRequest,
  removeShopRequest,
} from '../../../../../../store/actions/prestashop_actions';

// project types imports
import { IState } from '../../../../../../store/reducers';
import { TShop } from '../../../../../../types/PrestashopSettings';

// local imports
import { withLocalize } from 'react-localize-redux';
import CustomButton from '../../../../../../components/NewUI/CustomButton';
import GridRow from '../../../../../../components/NewUI/GridRow';
import Label from '../../../../../../components/NewUI/Label/Label';
import { StateProps } from '../../types';
import ShopItem from '../ShopItem/ShopItem';

const ShopsList = (props: any) => {
  const { translate } = props;

  const dispatch = useDispatch();

  const shopsList = useSelector<IState, StateProps['shops']>(
    ({
      prestashop: {
        settings: { shops },
      },
    }) => shops,
  );

  const addShop = () => {
    const newShop: TShop = {
      id: shortid.generate(),
      webServiceKey: '',
      vatInc: false,
      shopCurrencyIso: 'chf',
      url: '',
      name: '',
    };

    dispatch(addShopRequest(newShop));
  };

  const removeShop = (id: string) => {
    dispatch(removeShopRequest(id));
  };

  const onChangeOption = (id: string, optionName: string, value: string | boolean) => {
    dispatch(changeShopOptionRequest({ id, optionName, value }));
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate('prestashop.settings') as string}
          desc={translate('prestashop.settings-desc') as string}
          help={{ text: translate('prestashop.help') as string, link: '#' }}
        />
      </Grid>
      <Grid item={true} md={8}>
        <div>
          {shopsList &&
            shopsList.map(shop => (
              <ShopItem key={shop.id} shop={shop} onDeleteShop={removeShop} onChangeOption={onChangeOption} />
            ))}
          <CustomButton onClick={addShop}>{translate('prestashop.add-shop')}</CustomButton>
        </div>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(ShopsList);
