import { Switch } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  switchBase: {
    '&$checked': {
      color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#fa6100 !important',
    },
    '&$checked + $track': {
      backgroundColor: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#f3a77e !important',
    },
  },
};

export const StyledSwitch = withStyles({
  switchBase: style.switchBase,
  checked: {},
  track: {},
})(Switch);
