import { clientApp } from './index';

export const getWeclappTaxes = () => clientApp.get(`/ui/weclapp/settings/tax/sale`);

export const getWeclappCustomer = ({ customerId = '' }) => clientApp.get(`/ui/weclapp/settings/customer/${customerId}`);

export const searchWeclappCustomer = ({ searchTerm = '' }) =>
  clientApp.get(`/ui/weclapp/settings/customer/search/${searchTerm}`);

export const getProduct = ({ productId = '' }) => clientApp.get(`/ui/weclapp/settings/product/${productId}`);

export const searchProduct = () => clientApp.get(`/ui/weclapp/settings/product/shipping`);
