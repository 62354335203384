// global imports
import { Grid, Input, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import { StyleRules } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import cx from 'classnames';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';
import * as shopifyActions from '../../../../store/actions/shopify_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioAccountType, BexioBankAccount, BexioSettings } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import CustomButton from '../../../../components/NewUI/CustomButton';
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);
const paymentMethods: IOptionType[] = [
  { value: 'cash', label: 'Cash' },
  { value: 'visa', label: 'Visa' },
  { value: 'mastercard', label: 'Mastercard' },
  { value: 'amex', label: 'Amex' },
  { value: 'postcard', label: 'Postcard' },
  { value: 'twint', label: 'Twint' },
  { value: 'paypal', label: 'PayPal' },
  { value: 'invoice', label: 'invoice' },
  { value: 'postfinance', label: 'Postfinance' },
  { value: 'advance-payment', label: 'advance-payment' },
  { value: 'sofort', label: 'Sofort' },
  { value: 'credit-card', label: 'Credit Cards' },
  { value: 'bank-transfer', label: 'Bank Transfer' },
  { value: 'stripe', label: 'Stripe' },
  { value: 'swissbilling', label: 'Swissbilling' },
  { value: 'payrexx', label: 'Payrexx' },
  { value: 'klarna', label: 'Klarna' },
  { value: 'giftcard', label: 'Gift Card' },
  { value: 'cash-on-delivery', label: 'Cash On Delivery' },
  { value: 'saferpay_wosaferpay_worldlinerldline', label: 'Saferpay Worldline' },
  { value: 'alma', label: 'Alma' },
  { value: 'heidipay', label: 'HeidiPay' },
  { value: 'powerPay', label: 'PowerPay' },
  { value: 'manual', label: 'Manual' },
  { value: 'shopify-payments', label: 'Shopify Payments' },
  { value: 'sumup', label: 'SumUp' },
  { value: 'pos-cash', label: 'POS Cash' },
  { value: 'pos-twint', label: 'POS Twint' },
  { value: 'pos-maestro', label: 'POS Maestro' },
  { value: 'pos-postcard', label: 'POS Postcard' },
  { value: 'pos-mastercard', label: 'POS Mastercard' },
  { value: 'pos-visa', label: 'POS Visa' },
  { value: 'pos-giftcard', label: 'POS Gift Card' },
  { value: 'pos-amex', label: 'POS Amex' },
  { value: 'pos-sumup', label: 'POS Sumup' },
  { value: 'pos-invoice', label: 'POS invoice' },
  { value: 'fee', label: 'Fee', isDisabled: true },
];

const currencies: IOptionType[] = [
  { label: 'CHF', value: 'chf' },
  { label: 'Euro', value: 'eur' },
  { label: 'USD', value: 'usd' },
];

const BankAccounts: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    languages,
    bexioSettings,
    connectionStatus,
    getBankAccountsRequest,
    putSettingsRequest,
    getPaymentTypesRequest,
    system,
  } = props;
  const { shopifyConnected, shopifyShopLocationsRequest, shopifyLocations } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);
  const [accountsList, setAccountsList] = useState<IOptionType[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<IOptionType[]>([]);
  const [locations, setLocations] = useState<IOptionType[]>([]);

  useEffect(() => {
    const invoiceIndex = _.findIndex(paymentMethods, method => method.value === 'invoice');
    const advancedPaymentIndex = _.findIndex(paymentMethods, method => method.value === 'advance-payment');
    const bankTransferIndex = _.findIndex(paymentMethods, method => method.value === 'bank-transfer');
    const creditCardIndex = _.findIndex(paymentMethods, method => method.value === 'credit-card');
    const cashOnDeliveryIndex = _.findIndex(paymentMethods, method => method.value === 'cash-on-delivery');
    const giftCardIndex = _.findIndex(paymentMethods, method => method.value === 'giftcard');
    const posGiftCardIndex = _.findIndex(paymentMethods, method => method.value === 'pos-giftcard');
    const feeIndex = _.findIndex(paymentMethods, method => method.value === 'fee');

    paymentMethods[invoiceIndex].label = translate('invoice') as string;
    paymentMethods[advancedPaymentIndex].label = translate('advance-payment') as string;
    paymentMethods[bankTransferIndex].label = translate('bank-transfer') as string;
    paymentMethods[creditCardIndex].label = translate('credit-card') as string;
    paymentMethods[cashOnDeliveryIndex].label = translate('cash-on-delivery') as string;
    paymentMethods[giftCardIndex].label = translate('gift-card') as string;
    paymentMethods[posGiftCardIndex].label = translate('pos-gift-card') as string;
    paymentMethods[feeIndex].label = translate('fees') as string;
  }, [languages]);

  useEffect(() => {
    if (settings) {
      const feeIndex = _.findIndex(paymentMethods, method => method.value === 'fee');
      paymentMethods[feeIndex].isDisabled = !settings.bankAccounts.find((account: BexioBankAccount) => account.hasFee);
    }
  }, [settings]);

  // didMount fetch data
  useEffect(() => {
    if (connectionStatus === 'ok') {
      getBankAccountsRequest({});
      getPaymentTypesRequest({});
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (system.toLowerCase() === 'shopify' && shopifyConnected) {
      shopifyShopLocationsRequest({});
    }
  }, [shopifyConnected, system, shopifyShopLocationsRequest]);

  useEffect(() => {
    if (system.toLowerCase() === 'shopify' && shopifyConnected && shopifyLocations) {
      setLocations(shopifyLocations.map(name => ({ label: name, value: name })));
    }
  }, [shopifyConnected, system, shopifyLocations, setLocations]);

  // convert accounts data for react-select
  useEffect(() => {
    if (props.bexioAccounts) {
      setAccountsList(
        props.bexioAccounts.map((account: BexioAccountType) => ({
          value: account.id.toString(),
          label: account.name,
          currency: (account as any).currency,
        })),
      );
    }
  }, [props.bexioAccounts]);

  useEffect(() => {
    if (props.paymentTypes) {
      setPaymentTypes(
        props.paymentTypes.map(pt => ({
          value: pt.id.toString(),
          label: pt.name,
        })),
      );
    }
  }, [props.paymentTypes]);

  // set settings locally
  useEffect(() => {
    if (props.bexioSettings) {
      const newSettings = { ...cloneDeep(props.bexioSettings) };
      if (!newSettings.bankAccounts.length) {
        newSettings.bankAccounts = [
          {
            paymentMethod: ``,
            id: 0,
            isDefault: true,
            dependCurrency: false,
            dependLocation: false,
            location: '',
            customPaymentMethod: false,
            hasFee: false,
            feeFix: 0,
            feePercentage: 0,
          },
        ];
      }
      setSettings(newSettings);
    }
  }, [props.bexioSettings]);

  // save settings to backend
  useEffect(() => {
    if (
      accountsList &&
      bexioSettings &&
      settings &&
      !settings.bankAccounts.find((account: BexioBankAccount) => !account.id || !account.paymentMethod) &&
      !isEqual(bexioSettings.bankAccounts, settings.bankAccounts)
    ) {
      putSettingsRequest({ system: 'bexio', data: settings });
    }
  }, [bexioSettings, settings, accountsList]);

  const addAccount = useCallback(
    (key?: number) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: [
            ...settings.bankAccounts,
            typeof key !== 'undefined'
              ? { ...settings.bankAccounts[key], isDefault: false }
              : {
                  id: 0,
                  isDefault: false,
                  paymentMethod: '',
                  dependCurrency: false,
                  dependLocation: false,
                  location: '',
                  customPaymentMethod: false,
                  hasFee: false,
                  feeFix: 0,
                  feePercentage: 0,
                },
          ],
        });
      }
    },
    [settings],
  );
  const removeAccount = useCallback(
    (key: number) => {
      if (settings) {
        /* tslint:disable-next-line */
        const newAccounts = settings.bankAccounts.filter((_, i) => i !== key);
        const hasDefault = newAccounts.find(account => account.isDefault);

        if (!newAccounts.length) {
          newAccounts.push({
            id: 0,
            isDefault: true,
            paymentMethod: '',
            dependCurrency: false,
            dependLocation: false,
            location: '',
            customPaymentMethod: false,
            hasFee: false,
            feeFix: 0,
            feePercentage: 0,
          });
        } else if (!hasDefault) {
          newAccounts[0].isDefault = true;
        }
        setSettings({
          ...settings,
          bankAccounts: newAccounts,
        });
      }
    },
    [settings],
  );
  const toggleAccountsDefault = useCallback(
    (key: number) => {
      if (settings) {
        let newAccounts = settings.bankAccounts.map((account, index) =>
          index !== key ? account : { ...account, isDefault: !account.isDefault },
        );
        const defaultAccounts = newAccounts.filter(account => account.isDefault);
        if (defaultAccounts.length === 0) {
          newAccounts[0].isDefault = true;
        } else if (defaultAccounts.length > 1) {
          newAccounts = settings.bankAccounts.map((account, index) =>
            index !== key ? { ...account, isDefault: false } : { ...account, isDefault: true },
          );
        }
        setSettings({ ...settings, bankAccounts: newAccounts });
      }
    },
    [settings],
  );
  const toggleAccountsDependCurrency = useCallback(
    (key: number) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key
              ? account
              : {
                  ...account,
                  dependCurrency: !account.dependCurrency,
                  currency: !account.dependCurrency ? currencies[0].value : undefined,
                },
          ),
        });
      }
    },
    [settings],
  );
  const toggleAccountsDependLocation = useCallback(
    (key: number) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, dependLocation: !account.dependLocation },
          ),
        });
      }
    },
    [settings],
  );
  const paymentMethodChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, paymentMethod: value.value },
          ),
        });
      }
    },
    [settings],
  );
  const toggleCustomPaymentMethod = useCallback(
    (key: number) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key
              ? account
              : { ...account, customPaymentMethod: !account.customPaymentMethod, paymentMethod: '' },
          ),
        });
      }
    },
    [settings],
  );
  const toggleFee = useCallback(
    (key: number) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, hasFee: !account.hasFee },
          ),
        });
      }
    },
    [settings],
  );
  const accountChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, id: parseInt(value.value, 10) },
          ),
        });
      }
    },
    [settings],
  );
  const paymentTypeChanged = useCallback(
    (key: number, value?: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            // tslint:disable-next-line:radix
            index !== key
              ? account
              : // tslint:disable-next-line:radix
                { ...account, paymentType: value !== null && value !== undefined ? parseInt(value.value) : undefined },
          ),
        });
      }
    },
    [settings],
  );
  const setCustomPaymentMethod = useCallback(
    (key: number, value: string) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, paymentMethod: value },
          ),
        });
      }
    },
    [settings],
  );
  const [tempValue, setTempValue] = React.useState('');
  const delayedInput = useCallback(
    _.debounce((key, value) => {
      setCustomPaymentMethod(key, value);
      setTempValue('');
    }, 1000),
    [settings],
  );

  const setFixedFee = useCallback(
    (key: number, value: number | null) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, feeFix: value !== null ? value : 0 },
          ),
        });
      }
    },
    [settings],
  );
  const [fixedFeeValue, setFixedFeeValue] = React.useState<number | null>(null);
  const delayedInputFixedFee = useCallback(
    _.debounce((key, value) => {
      setFixedFee(key, value);
      setFixedFeeValue(null);
    }, 2500),
    [settings],
  );

  const setPercentageFee = useCallback(
    (key: number, value: number | null) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key ? account : { ...account, feePercentage: value !== null ? value / 100 : 0 },
          ),
        });
      }
    },
    [settings],
  );
  const [percentageFeeValue, setPercentageFeeValue] = React.useState<number | null>(null);
  const delayedInputPercFee = useCallback(
    _.debounce((key, value) => {
      setPercentageFee(key, value);
      setPercentageFeeValue(null);
    }, 2500),
    [settings],
  );

  const currencyChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key
              ? account
              : { ...account, currency: value.value, id: account.currency !== value.value ? 0 : account.id },
          ),
        });
      }
    },
    [settings],
  );

  const locationChanged = useCallback(
    (key: number, value: IOptionType) => {
      if (settings) {
        setSettings({
          ...settings,
          bankAccounts: settings.bankAccounts.map((account, index) =>
            index !== key
              ? account
              : { ...account, location: value.value, id: account.location !== value.value ? 0 : account.id },
          ),
        });
      }
    },
    [settings],
  );

  const showDependOnLocation =
    system.toLowerCase() === 'shopify' && shopifyConnected && shopifyLocations && shopifyLocations.length >= 2;

  return (
    settings && (
      <div className={classes.w100}>
        {settings.bankAccounts.map((account: BexioBankAccount, key: number) => (
          <React.Fragment key={key}>
            <Grid
              container={true}
              className={cx({
                [classes.errorBLeft]: !account.id || !account.paymentMethod,
              })}
            >
              <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                <InputLabel className={classes.selectSmall}>{translate('payment_method')}</InputLabel>
                {account.customPaymentMethod ? (
                  <TextField
                    className={classes.w100}
                    placeholder={translate('custom-payment-method') as string}
                    value={tempValue ? tempValue : account.paymentMethod}
                    onChange={ev => {
                      setTempValue(ev.target.value);
                      delayedInput(key, ev.target.value);
                    }}
                  />
                ) : (
                  <Select
                    isSearchable={false}
                    options={paymentMethods}
                    value={paymentMethods.find(option => option.value === account.paymentMethod) || null}
                    onChange={(value: ValueType<IOptionType, false>) => paymentMethodChanged(key, value as IOptionType)}
                    styles={colourStyles}
                  />
                )}
              </Grid>
              <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                <InputLabel className={classes.selectSmall}>{translate('bank_account')}</InputLabel>
                <Select
                  isSearchable={false}
                  options={accountsList.filter(acc =>
                    account.dependCurrency && account.currency ? (acc as any).currency === account.currency : true,
                  )}
                  value={accountsList.find(acc => parseInt(acc.value, 10) === account.id) || null}
                  onChange={(value: ValueType<IOptionType, false>) => accountChanged(key, value as IOptionType)}
                  styles={colourStyles}
                />
              </Grid>
              {account.dependCurrency && (
                <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                  <InputLabel className={classes.selectSmall}>{translate('currency')}</InputLabel>
                  <Select
                    options={currencies}
                    value={
                      currencies.find(curr => curr.value === account.currency) ||
                      (account.dependCurrency ? currencies[0] : null)
                    }
                    onChange={(value: ValueType<IOptionType, false>) => currencyChanged(key, value as IOptionType)}
                    styles={colourStyles}
                  />
                </Grid>
              )}
              {showDependOnLocation && account.dependLocation && (
                <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                  <InputLabel className={classes.selectSmall}>{translate('location')}</InputLabel>
                  <Select
                    options={locations}
                    value={
                      locations.find(loc => loc.value === account.location) ||
                      (account.dependLocation ? locations[0] : null)
                    }
                    onChange={(value: ValueType<IOptionType, false>) => locationChanged(key, value as IOptionType)}
                    styles={colourStyles}
                  />
                </Grid>
              )}
              <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                <InputLabel className={classes.selectSmall}>{translate('bexio.payment-type')}</InputLabel>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  options={paymentTypes}
                  value={
                    account !== undefined && account.paymentType !== undefined
                      ? paymentTypes.find(option => option.value === (account.paymentType || '').toString())
                      : null
                  }
                  onChange={(value: ValueType<IOptionType, false>) => paymentTypeChanged(key, value as IOptionType)}
                  styles={colourStyles}
                />
              </Grid>
              {account.hasFee && (
                <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                  <InputLabel className={classes.selectSmall} style={{ width: 'auto' }}>
                    {translate('fixed_fees')}
                  </InputLabel>
                  <Input
                    type="number"
                    className={classes.w100}
                    value={fixedFeeValue !== null ? fixedFeeValue : account.feeFix ? account.feeFix : ''}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      const value = !Number.isNaN(ev.target.valueAsNumber) ? ev.target.valueAsNumber : null;
                      setFixedFeeValue(value);
                      delayedInputFixedFee(key, value);
                    }}
                  />
                </Grid>
              )}
              {account.hasFee && (
                <Grid item={true} md={6} sm={12} style={{ padding: 10 }}>
                  <InputLabel className={classes.selectSmall} style={{ width: 'auto' }}>
                    {translate('percentage_fees')}
                  </InputLabel>
                  <Input
                    type="number"
                    className={classes.w100}
                    startAdornment={<InputAdornment position="start">%</InputAdornment>}
                    value={
                      percentageFeeValue !== null
                        ? percentageFeeValue
                        : account.feePercentage
                        ? account.feePercentage * 100
                        : ''
                    }
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      const value = !Number.isNaN(ev.target.valueAsNumber) ? ev.target.valueAsNumber : null;
                      setPercentageFeeValue(value);
                      delayedInputPercFee(key, value);
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container={true}>
              <Grid item={true} md={4} sm={6} style={{ padding: 10 }}>
                <Checkbox
                  onChange={() => toggleAccountsDefault(key)}
                  checked={account.isDefault}
                  label={translate('default') as string}
                />
              </Grid>
              <Grid item={true} md={4} sm={6} style={{ padding: 10 }}>
                <Checkbox
                  onChange={() => toggleAccountsDependCurrency(key)}
                  checked={account.dependCurrency}
                  label={translate('depend-on-currency') as string}
                />
              </Grid>
              <Grid item={true} md={4} sm={6} style={{ padding: 10 }}>
                <Checkbox
                  onChange={() => toggleCustomPaymentMethod(key)}
                  checked={!!account.customPaymentMethod}
                  label={translate('custom-payment-method') as string}
                />
              </Grid>
              <Grid item={true} md={4} sm={6} style={{ padding: 10 }}>
                <Checkbox
                  onChange={() => toggleFee(key)}
                  checked={!!account.hasFee}
                  label={translate('fees') as string}
                />
              </Grid>
              <Grid item={true} md={4} sm={6} style={{ padding: 10 }}>
                {showDependOnLocation && (
                  <Checkbox
                    onChange={() => toggleAccountsDependLocation(key)}
                    checked={account.dependLocation || false}
                    label={translate('depend-on-location') as string}
                  />
                )}
              </Grid>
              <Grid item={true} md={4} sm={6} style={{ padding: 10, display: 'flex', justifyContent: 'end' }}>
                <Button justIcon={true} round={true} color="transparent" onClick={() => removeAccount(key)}>
                  <DeleteIcon />
                </Button>
              </Grid>
            </Grid>
            <Divider
              component="section"
              style={{
                width: '80%',
                marginLeft: '10%',
                marginTop: 20,
                marginBottom: 20,
              }}
            />
          </React.Fragment>
        ))}
        <CustomButton onClick={addAccount}>{translate('bexio.add-bank')}</CustomButton>
      </div>
    )
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  shopifyConnected: state.shopify.connectionStatus === 'ok',
  shopifyLocations: state.shopify.locations,
  shopifyLoading: state.shopify.isFetching,
  bexioSettings: state.bexio.settings,
  bexioAccounts: state.bexio.bankAccounts,
  connectionStatus: state.bexio.connectionStatus,
  paymentTypes: state.bexio.paymentTypes,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  getBankAccountsRequest: bexioActions.getBankAccountsRequest,
  getPaymentTypesRequest: bexioActions.getPaymentTypesRequest,
  shopifyShopLocationsRequest: shopifyActions.shopLocationsRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(BankAccounts));
