import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from '../storeConfig';

import { config } from '../../config';

import { logoutRequest } from '../actions/auth_actions';

export const clientApp = axios.create({
  baseURL: config.backendUrl,
  responseType: 'json',
});

const errorHandler = (res: any): any => {
  if (res.response) {
    const {
      status,
      data: { message },
    } = res.response;
    const state = store.getState();
    const { location } = state.router;

    switch (status) {
      case 400:
        break;
      case 401:
        store.dispatch(logoutRequest({ lastLocation: location.pathname }));
        break;
      case 409:
        break;
      case 500:
        const firstMessage = message ? (message instanceof Array && message.length ? message[0] : message) : 'Internal server error';
        toast.error(`API: ${firstMessage}`);
        break;
      default:
        toast.error(`API: Unknown error ${status}`);
        break;
    }
  }
  return Promise.reject(res);
};

clientApp.interceptors.response.use(response => response, errorHandler);

clientApp.interceptors.request.use(request => {
  const updatedRequest = { ...request };

  const isLoginUrl = updatedRequest.url && updatedRequest.url.includes('/auth/login');
  const hasAuth = !!updatedRequest.headers.Authorization;
  if (!isLoginUrl && !hasAuth) {
    const accessToken = localStorage.getItem('token');
    updatedRequest.headers.Authorization = `Bearer ${accessToken}`;
  }

  return updatedRequest;
});
