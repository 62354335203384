import { ActionPattern, all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import {
  GET_DELIVERY_ADDRESSES,
  GET_DELIVERY_DATA,
  getDeliveryAddressesFailure,
  getDeliveryAddressesSuccess,
  getDeliveryDataFailure,
  getDeliveryDataSuccess,
} from '../actions/delivery_actions';
import { getDeliveryData, getDeliveryStructuredAddressData } from '../api/delivery';

function* getDeliveryDataSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getDeliveryData, { start: action.data.start, end: action.data.end });
    yield put(getDeliveryDataSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getDeliveryDataFailure(processedError || error));
  }
}

function* getDeliveryAddressSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getDeliveryStructuredAddressData, action.data);
    yield put(getDeliveryAddressesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getDeliveryAddressesFailure(processedError || error));
  }
}

function* getDeliveryDataWatcher() {
  yield takeLatest(GET_DELIVERY_DATA.REQUEST as ActionPattern, getDeliveryDataSaga);
}

function* getDeliveryAddressDataWatcher() {
  yield takeLatest(GET_DELIVERY_ADDRESSES.REQUEST as ActionPattern, getDeliveryAddressSaga);
}

export default function* authSaga() {
  yield all([fork(getDeliveryDataWatcher), fork(getDeliveryAddressDataWatcher)]);
}
