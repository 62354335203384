// global imports
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';

// project imports
import Tenant from '../../WeclappWidgets/Tenant/Tenant';
import ApiToken from '../../WeclappWidgets/ApiToken/ApiToken';
import VatAccounts from '../../WeclappWidgets/VatAccounts/VatAccounts';
import CustomerSearch from '../../WeclappWidgets/CustomerSearch/CustomerSearch';

import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import Card from '../../../../components/Card/Card';
import { getSettingsRequest } from '../../../../store/actions/common_actions';

import commonStyles from '../../../../assets/jss/commonStyles';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const WeclappApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  const { translate } = props;

  useEffect(() => {
    dispatch(getSettingsRequest({ system: 'weclapp' }));
  }, []);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Weclapp</h4>
      </CardHeader>
      <CardBody>
        <h3>Settings</h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Tenant />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <ApiToken />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.contentContainer}>
              <InputLabel className={cx(classes.selectSmall, classes.pt2)}>{translate('vat')}</InputLabel>
              <div className={cx(classes.contentLarge, classes.bexioVat)}>
                <VatAccounts />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.contentContainer}>
              <InputLabel className={cx(classes.selectSmall, classes.pt10)}>{translate('default_customer')}</InputLabel>
              <div className={cx(classes.contentLarge, classes.w45)}>
                <CustomerSearch />
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default withLocalize(WeclappApp);
