import { PlanType } from './types.d';

export const featureNames = [
  'import_orders',
  'import_products',
  'import_customers',
  'send_invoices',
  'write_back_invoice_statuses',
  'write_back_product_statuses',
  'configuration_and_changes',
  'support',
  'installation',
];

export const plans: PlanType[] = [
  {
    name: 'basic',
    features: {
      import_orders: 'import_orders',
      import_products: 'import_products',
      import_customers: 'import_customers',
      send_invoices: 'send_invoices',
      write_back_invoice_statuses: 'write_back_invoice_statuses',
      write_back_product_statuses: null,
      configuration_and_changes: null,
      support: 'paid_support',
      installation: 'paid_installation',
    },
    params: {
      price: '24.99',
      num_orders: '50',
      support_price: '30.-',
      installation_price: '60.-',
    },
  },
  {
    name: 'bronze',
    features: {
      import_orders: 'import_orders',
      import_products: 'import_products',
      import_customers: 'import_customers',
      send_invoices: 'send_invoices',
      write_back_invoice_statuses: 'write_back_invoice_statuses',
      write_back_product_statuses: 'write_back_product_statuses',
      configuration_and_changes: 'configuration_and_changes',
      support: 'free_support_chat_and_email',
      installation: 'paid_installation',
    },
    params: {
      price: '49.99',
      num_orders: '500',
      installation_price: '30.-',
    },
  },
  {
    name: 'silver',
    features: {
      import_orders: 'import_orders',
      import_products: 'import_products',
      import_customers: 'import_customers',
      send_invoices: 'send_invoices',
      write_back_invoice_statuses: 'write_back_invoice_statuses',
      write_back_product_statuses: 'write_back_product_statuses',
      configuration_and_changes: 'configuration_and_changes',
      support: 'free_support_chat_email_and_phone',
      installation: 'paid_installation',
    },
    params: {
      price: '74.99',
      num_orders: '1000',
      installation_price: '30.-',
    },
  },
  {
    name: 'gold',
    features: {
      import_orders: 'import_orders',
      import_products: 'import_products',
      import_customers: 'import_customers',
      send_invoices: 'send_invoices',
      write_back_invoice_statuses: 'write_back_invoice_statuses',
      write_back_product_statuses: 'write_back_product_statuses',
      configuration_and_changes: 'configuration_and_changes',
      support: 'free_support_chat_email_and_phone',
      installation: 'free_installation',
    },
    params: {
      price: '99.99',
      num_orders: '2000',
    },
  },
  {
    name: 'diamond',
    features: {
      import_orders: 'import_orders_unlimited',
      import_products: 'import_products',
      import_customers: 'import_customers',
      send_invoices: 'send_invoices',
      write_back_invoice_statuses: 'write_back_invoice_statuses',
      write_back_product_statuses: 'write_back_product_statuses',
      configuration_and_changes: 'configuration_and_changes',
      support: 'free_support_chat_email_and_phone',
      installation: 'free_installation',
    },
    params: {
      price: '149.99',
      reminder_discount: '25%',
    },
  },
];
