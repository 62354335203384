import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import { BedayaAction } from '../../types/BedayaAction';

import { getApps, putApps } from '../api/apps';

import {
  GET_APPS,
  getAppsFailure,
  getAppsSuccess,
  PUT_APPS,
  putAppsFailure,
  putAppsSuccess,
} from '../actions/apps_actions';

function* getAppsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getApps);
    yield put(getAppsSuccess(data.filter((app: any) => app.enabled).map((app: any) => app.name)));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getAppsFailure(processedError || error));
  }
}

function* putAppsSaga(action: BedayaAction) {
  yield delay(200);
  try {
    const { data } = yield call(putApps, action.data.map((app: any) => ({ name: app, enabled: true })));
    yield put(putAppsSuccess(data.filter((app: any) => app.enabled).map((app: any) => app.name)));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(putAppsFailure(processedError || error));
  }
}

function* getAppsWatcher() {
  yield takeLatest(GET_APPS.REQUEST as ActionPattern, getAppsSaga);
}
function* putAppsWatcher() {
  yield takeLatest(PUT_APPS.REQUEST as ActionPattern, putAppsSaga);
}

export default function* authSaga() {
  yield all([fork(getAppsWatcher), fork(putAppsWatcher)]);
}
