import moment from 'moment';
import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CHECK_CONFIRMATION,
  GET_SETTINGS_FOR_SHOP,
  getSettingsForShopFailure,
  getSettingsForShopSuccess,
  IMPORT_ORDER,
  importOrderFailure,
  importOrderSuccess,
  CREATE_CHARGE,
  createChargeFailure,
  createChargeSuccess,
  POST_CHARGE,
  postChargeIDFailure,
  postChargeIDSuccess,
  PUT_SETTINGS_FOR_SHOP,
  putSettingsForShopFailure,
  putSettingsForShopSuccess,
  SET_ACTIVE_SHOP,
  setActiveShopSuccess,
  SHOPS,
  shopsFailure,
  shopsSuccess,
  TEST_CONNECTION_FOR_SHOP,
  testConnectionForShopFailure,
  testConnectionForShopSuccess,
  SHOP_LOCATIONS,
  shopLocationsFailure,
  shopLocationsSuccess,
  GET_SHOP_STATISTICS,
  getShopStatisticsFailure,
  getShopStatisticsSuccess,
  GET_SHOP_STATISTICS_ALL,
  getShopStatisticsAllFailure,
  getShopStatisticsAllSuccess,
  GET_SHOP_STATISTICS_DETAILED,
  getShopStatisticsDetailedFailure,
  getShopStatisticsDetailedSuccess,
} from './../actions/shopify_actions';
import {
  getSettingsForShop,
  getShops,
  importOrder,
  createCharge,
  postCharge,
  putSettingsForShop,
  testConnectionForShop,
  shopLocations,
  getShopStatistics,
  getShopStatisticsAll,
  getShopStatisticsDetailed,
} from './../api/shopify';

import { BedayaAction } from '../../types/BedayaAction';

import {
  checkConfirmationFailure,
  checkConfirmationSuccess,
  IMPORT_INVOICES,
  IMPORT_PRODUCTS,
  importInvoicesFailure,
  importInvoicesSuccess,
  importProductsFailure,
  importProductsSuccess,
} from '../actions/shopify_actions';
import { checkShopifyConfirmation, importInvoices, importProducts } from '../api/shopify';

function* importInvoicesSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(
      importInvoices,
      moment(action.data.from).format('YYYY-MM-DD'),
      moment(action.data.to).format('YYYY-MM-DD'),
      action.data.shop,
    );
    yield put(importInvoicesSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importInvoicesFailure(processedError || error));
  }
}

function* importOrderSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(importOrder, action.data.name, action.data.shop);
    yield put(importOrderSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importOrderFailure(processedError || error));
  }
}
function* importProductsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(importProducts, action.data.shop);
    yield put(importProductsSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(importProductsFailure(processedError || error));
  }
}

function* checkConfirmationSaga(action: BedayaAction) {
  try {
    const { data } = yield call(checkShopifyConfirmation, action.data.shop);
    yield put(checkConfirmationSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(checkConfirmationFailure(processedError || error));
  }
}

function* createChargeSaga(action: BedayaAction) {
  try {
    const { data } = yield call(createCharge, action.data.shop, action.data.plan);
    yield put(createChargeSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(createChargeFailure(processedError || error));
  }
}

function* postChargeSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postCharge, action.data.id);
    yield put(postChargeIDSuccess({ ...data }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postChargeIDFailure(processedError || error));
  }
}
function* getShopsSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getShops);
    yield put(shopsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(shopsFailure(processedError || error));
  }
}

function* getSettingsForShopSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getSettingsForShop, action.data.shop);
    yield put(getSettingsForShopSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getSettingsForShopFailure(processedError || error));
  }
}

function* putSettingsForShopSaga(action: BedayaAction) {
  try {
    const { data } = yield call(putSettingsForShop, action.data.shop, action.data.data);
    yield put(putSettingsForShopSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(putSettingsForShopFailure(processedError || error));
  }
}

function* setActiveShopSaga(action: BedayaAction) {
  yield put(setActiveShopSuccess(action.data));
}

function* getTestConnectionForShopSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(testConnectionForShop, action.data.shop);
    yield put(testConnectionForShopSuccess({ ...data, system: 'shopify' }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(testConnectionForShopFailure(processedError || error));
  }
}

function* shopLocationsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(shopLocations);
    yield put(shopLocationsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(shopLocationsFailure(processedError || error));
  }
}

function* getShopStatisticsSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getShopStatistics, action.data.shop);
    yield put(getShopStatisticsSuccess({ ...data, system: 'shopify' }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getShopStatisticsFailure(processedError || error));
  }
}

function* getShopStatisticsAllSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getShopStatisticsAll);
    yield put(getShopStatisticsAllSuccess({ ...data, system: 'shopify' }));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getShopStatisticsAllFailure(processedError || error));
  }
}

function* getShopStatisticsDetailedSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getShopStatisticsDetailed);
    yield put(getShopStatisticsDetailedSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getShopStatisticsDetailedFailure(processedError || error));
  }
}

function* importInvoicesWatcher() {
  yield takeLatest(IMPORT_INVOICES.REQUEST as ActionPattern, importInvoicesSaga);
}
function* importOrderWatcher() {
  yield takeLatest(IMPORT_ORDER.REQUEST as ActionPattern, importOrderSaga);
}
function* importProductsWatcher() {
  yield takeLatest(IMPORT_PRODUCTS.REQUEST as ActionPattern, importProductsSaga);
}
function* checkConfirmationWatcher() {
  yield takeLatest(CHECK_CONFIRMATION.REQUEST as ActionPattern, checkConfirmationSaga);
}
function* createChargeWatcher() {
  yield takeLatest(CREATE_CHARGE.REQUEST as ActionPattern, createChargeSaga);
}
function* postChargeWatcher() {
  yield takeLatest(POST_CHARGE.REQUEST as ActionPattern, postChargeSaga);
}
function* getShopsWatcher() {
  yield takeLatest(SHOPS.REQUEST as ActionPattern, getShopsSaga);
}
function* getSettingsForShopWatcher() {
  yield takeLatest(GET_SETTINGS_FOR_SHOP.REQUEST as ActionPattern, getSettingsForShopSaga);
}
function* putSettingsForShopWatcher() {
  yield takeLatest(PUT_SETTINGS_FOR_SHOP.REQUEST as ActionPattern, putSettingsForShopSaga);
}
function* setActiveShopWatcher() {
  yield takeLatest(SET_ACTIVE_SHOP.REQUEST as ActionPattern, setActiveShopSaga);
}
function* getTestConnectionForShopWatcher() {
  yield takeLatest(TEST_CONNECTION_FOR_SHOP.REQUEST as ActionPattern, getTestConnectionForShopSaga);
}
function* shopLocationsWatcher() {
  yield takeLatest(SHOP_LOCATIONS.REQUEST as ActionPattern, shopLocationsSaga);
}
function* getShopStatisticsWatcher() {
  yield takeLatest(GET_SHOP_STATISTICS.REQUEST as ActionPattern, getShopStatisticsSaga);
}
function* getShopStatisticsAllWatcher() {
  yield takeLatest(GET_SHOP_STATISTICS_ALL.REQUEST as ActionPattern, getShopStatisticsAllSaga);
}
function* getShopStatisticsDetailedWatcher() {
  yield takeLatest(GET_SHOP_STATISTICS_DETAILED.REQUEST as ActionPattern, getShopStatisticsDetailedSaga);
}

export default function* authSaga() {
  yield all([
    fork(importInvoicesWatcher),
    fork(importOrderWatcher),
    fork(importProductsWatcher),
    fork(checkConfirmationWatcher),
    fork(createChargeWatcher),
    fork(postChargeWatcher),
    fork(getShopsWatcher),
    fork(getSettingsForShopWatcher),
    fork(putSettingsForShopWatcher),
    fork(setActiveShopWatcher),
    fork(getTestConnectionForShopWatcher),
    fork(shopLocationsWatcher),
    fork(getShopStatisticsWatcher),
    fork(getShopStatisticsAllWatcher),
    fork(getShopStatisticsDetailedWatcher),
  ]);
}
