import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'SHOPIFY';
export const IMPORT_INVOICES = createRequestTypes(`${filePrefix}_IMPORT_INVOICES`);
export const IMPORT_ORDER = createRequestTypes(`${filePrefix}_IMPORT_ORDER`);
export const IMPORT_PRODUCTS = createRequestTypes(`${filePrefix}_IMPORT_PRODUCTS`);
export const CHECK_CONFIRMATION = createRequestTypes(`${filePrefix}_CHECK_CONFIRMATION`);
export const CREATE_CHARGE = createRequestTypes(`${filePrefix}_CREATE_CHARGE`);
export const POST_CHARGE = createRequestTypes(`${filePrefix}_POST_CHARGE`);
export const SHOPS = createRequestTypes(`${filePrefix}_SHOPS`);
export const GET_SETTINGS_FOR_SHOP = createRequestTypes(`${filePrefix}_GET_SETTINGS_FOR_SHOP`);
export const PUT_SETTINGS_FOR_SHOP = createRequestTypes(`${filePrefix}_PUT_SETTINGS_FOR_SHOP`);
export const SET_ACTIVE_SHOP = createRequestTypes(`${filePrefix}_SET_ACTIVE_SHOP`);
export const TEST_CONNECTION_FOR_SHOP = createRequestTypes(`${filePrefix}_TEST_CONNECTION_FOR_SHOP`);
export const SHOP_LOCATIONS = createRequestTypes(`${filePrefix}_SHOP_LOCATIONS`);
export const GET_SHOP_STATISTICS = createRequestTypes(`${filePrefix}_GET_SHOP_STATISTICS`);
export const GET_SHOP_STATISTICS_ALL = createRequestTypes(`${filePrefix}_GET_SHOP_STATISTICS_ALL`);
export const GET_SHOP_STATISTICS_DETAILED = createRequestTypes(`${filePrefix}_GET_SHOP_STATISTICS_DETAILED`);

export const importInvoicesRequest = (data: any) => ({
  type: IMPORT_INVOICES.REQUEST,
  data,
});
export const importInvoicesSuccess = (data: any) => ({
  type: IMPORT_INVOICES.SUCCESS,
  data,
});
export const importInvoicesFailure = (error: any) => ({
  type: IMPORT_INVOICES.FAILURE,
  error,
});

export const importOrderRequest = (data: any) => ({
  type: IMPORT_ORDER.REQUEST,
  data,
});
export const importOrderSuccess = (data: any) => ({
  type: IMPORT_ORDER.SUCCESS,
  data,
});
export const importOrderFailure = (error: any) => ({
  type: IMPORT_ORDER.FAILURE,
  error,
});

export const checkConfirmationRequest = (data: any) => ({
  type: CHECK_CONFIRMATION.REQUEST,
  data,
});
export const checkConfirmationSuccess = (data: any) => ({
  type: CHECK_CONFIRMATION.SUCCESS,
  data,
});
export const checkConfirmationFailure = (error: any) => ({
  type: CHECK_CONFIRMATION.FAILURE,
  error,
});

export const createChargeRequest = (data: any) => ({
  type: CREATE_CHARGE.REQUEST,
  data,
});
export const createChargeSuccess = (data: any) => ({
  type: CREATE_CHARGE.SUCCESS,
  data,
});
export const createChargeFailure = (data: any) => ({
  type: CREATE_CHARGE.FAILURE,
  data,
});

export const postChargeIDRequest = (data: any) => ({
  type: POST_CHARGE.REQUEST,
  data,
});
export const postChargeIDSuccess = (data: any) => ({
  type: POST_CHARGE.SUCCESS,
  data,
});
export const postChargeIDFailure = (error: any) => ({
  type: POST_CHARGE.FAILURE,
  error,
});

export const importProductsRequest = (data: any) => ({
  type: IMPORT_PRODUCTS.REQUEST,
  data,
});
export const importProductsSuccess = (data: any) => ({
  type: IMPORT_PRODUCTS.SUCCESS,
  data,
});
export const importProductsFailure = (error: any) => ({
  type: IMPORT_PRODUCTS.FAILURE,
  error,
});

export const shopsRequest = () => ({
  type: SHOPS.REQUEST,
});
export const shopsSuccess = (data: any) => ({
  type: SHOPS.SUCCESS,
  data,
});
export const shopsFailure = (error: any) => ({
  type: SHOPS.FAILURE,
  error,
});

export const getSettingsForShopRequest = (data: any) => ({
  type: GET_SETTINGS_FOR_SHOP.REQUEST,
  data,
});
export const getSettingsForShopSuccess = (data: any) => ({
  type: GET_SETTINGS_FOR_SHOP.SUCCESS,
  data,
});
export const getSettingsForShopFailure = (error: any) => ({
  type: GET_SETTINGS_FOR_SHOP.FAILURE,
  error,
});

export const putSettingsForShopRequest = (data: any) => ({
  type: PUT_SETTINGS_FOR_SHOP.REQUEST,
  data,
});
export const putSettingsForShopSuccess = (data: any) => ({
  type: PUT_SETTINGS_FOR_SHOP.SUCCESS,
  data,
});
export const putSettingsForShopFailure = (error: any) => ({
  type: PUT_SETTINGS_FOR_SHOP.FAILURE,
  error,
});

export const setActiveShopRequest = (data: any) => ({
  type: SET_ACTIVE_SHOP.REQUEST,
  data,
});
export const setActiveShopSuccess = (data: any) => ({
  type: SET_ACTIVE_SHOP.SUCCESS,
  data,
});

export const testConnectionForShopRequest = (data: any) => ({
  type: TEST_CONNECTION_FOR_SHOP.REQUEST,
  data,
});
export const testConnectionForShopSuccess = (data: any) => ({
  type: TEST_CONNECTION_FOR_SHOP.SUCCESS,
  data,
});
export const testConnectionForShopFailure = (data: any) => ({
  type: TEST_CONNECTION_FOR_SHOP.FAILURE,
  data,
});

export const shopLocationsRequest = (data: any) => ({
  type: SHOP_LOCATIONS.REQUEST,
  data,
});
export const shopLocationsSuccess = (data: any) => ({
  type: SHOP_LOCATIONS.SUCCESS,
  data,
});
export const shopLocationsFailure = (data: any) => ({
  type: SHOP_LOCATIONS.FAILURE,
  data,
});

export const getShopStatisticsRequest = (data: any) => ({
  type: GET_SHOP_STATISTICS.REQUEST,
  data,
});
export const getShopStatisticsSuccess = (data: any) => ({
  type: GET_SHOP_STATISTICS.SUCCESS,
  data,
});
export const getShopStatisticsFailure = (data: any) => ({
  type: GET_SHOP_STATISTICS.FAILURE,
  data,
});

export const getShopStatisticsAllRequest = (data: any) => ({
  type: GET_SHOP_STATISTICS_ALL.REQUEST,
  data,
});
export const getShopStatisticsAllSuccess = (data: any) => ({
  type: GET_SHOP_STATISTICS_ALL.SUCCESS,
  data,
});
export const getShopStatisticsAllFailure = (data: any) => ({
  type: GET_SHOP_STATISTICS_ALL.FAILURE,
  data,
});

export const getShopStatisticsDetailedRequest = (data: any) => ({
  type: GET_SHOP_STATISTICS_DETAILED.REQUEST,
  data,
});
export const getShopStatisticsDetailedSuccess = (data: any) => ({
  type: GET_SHOP_STATISTICS_DETAILED.SUCCESS,
  data,
});
export const getShopStatisticsDetailedFailure = (data: any) => ({
  type: GET_SHOP_STATISTICS_DETAILED.FAILURE,
  data,
});
