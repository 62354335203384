import commonStyles from '../../assets/jss/commonStyles';

const styles = {
  ...commonStyles,
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
  buttonsColumn: {},
  iconBasic: {
    width: '2rem',
    height: '2rem',
  },
  iconGray: {
    '&:hover': {
      color: 'black',
    },
    color: 'gray',
  },
  dialogContent: {
    width: '20rem',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;
