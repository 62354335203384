export default class AuthService {
  static get isAuthenticated() {
    return !!AuthService.token;
  }

  static get isImpersonated() {
    return !!AuthService.token && !!AuthService.adminToken;
  }

  static get adminToken() {
    return localStorage.getItem('admin-token');
  }

  static get token() {
    return localStorage.getItem('token');
  }

  static impersonate({ token }) {
    if (AuthService.isAuthenticated && !AuthService.isImpersonated) {
      localStorage.setItem('admin-token', AuthService.token);
      localStorage.setItem('token', token);
      window.dispatchEvent(new Event('local-storage'));
    }
  }

  static stopImpersonate() {
    if (AuthService.isAuthenticated && AuthService.isImpersonated) {
      localStorage.setItem('token', AuthService.adminToken);
      localStorage.removeItem('admin-token');
      window.dispatchEvent(new Event('local-storage'));
    }
  }

  static save({ token }) {
    if (AuthService.isImpersonated) {
      localStorage.removeItem('admin-token');
    }
    localStorage.setItem('token', token);
    window.dispatchEvent(new Event('local-storage'));
  }

  static clear() {
    localStorage.clear();
    window.dispatchEvent(new Event('local-storage'));
  }
}
