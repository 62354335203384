// global imports
import { Grid, makeStyles, StyleRules, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/src/types';

// project imports
import * as bitrixActions from '../../../../store/actions/bitrix_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import Select from 'react-select';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);
const SingleFieldMapping = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const {
    translate,
    bitrixSettings,
    getFieldsRequest,
    putSettingsRequest,
    fields,
    fieldName,
    entityName,
    labelName,
  } = props;

  useEffect(() => {
    getFieldsRequest(entityName);
  }, []);

  const handleSelectChange = (value: IOptionType) => {
    if (bitrixSettings && value) {
      putSettingsRequest({
        system: 'bitrix',
        data: {
          [fieldName]: value.value
        },
      });
    }
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate(labelName) as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <Select
            isSearchable={false}
            className={classes.w100}
            disabled={!bitrixSettings}
            options={fields[entityName]}
            value={bitrixSettings && fields[entityName].find(x => x.value === bitrixSettings[fieldName])}
            onChange={(value: ValueType<IOptionType, false>) =>
              handleSelectChange(value as IOptionType)
            }
            styles={colourStyles}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};
const mapStateToProps = (state: IState): StateProps => ({
  fields: state.bitrix.fields,
  bitrixSettings: state.bitrix.settings,
  connectionStatus: state.bitrix.connectionStatus,
  isFetching: state.bitrix.isFetching,
});

const mapDispatchToProps = {
  getFieldsRequest: bitrixActions.getFieldsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SingleFieldMapping),
);
