// global imports
import { Checkbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props, StateProps, TOptions } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

const AfterOrderCreation = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;

  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);

  const [options, setOptions] = useState({
    generateDeliveryFromOrder: bexioSettings ? bexioSettings.generateDeliveryFromOrder : undefined,
    issueDeliveryAfterGenerating: bexioSettings ? bexioSettings.issueDeliveryAfterGenerating : undefined,
    generateInvoiceFromOrder: bexioSettings ? bexioSettings.generateInvoiceFromOrder : undefined,
  });

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }

    if (
      options.generateDeliveryFromOrder !== bexioSettings.generateDeliveryFromOrder ||
      options.generateInvoiceFromOrder !== bexioSettings.generateInvoiceFromOrder ||
      options.issueDeliveryAfterGenerating !== bexioSettings.issueDeliveryAfterGenerating
    ) {
      dispatch(putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, ...options } }));
    }
  }, [options]);

  const onChange = (ev: any, newValue: boolean) => {
    const changedOption: TOptions = ev.target.getAttribute('name') as TOptions;
    const checked = !options[changedOption];

    if (changedOption === 'generateDeliveryFromOrder' && !checked) {
      setOptions({ ...options, [changedOption]: checked, issueDeliveryAfterGenerating: false });
    } else {
      setOptions({ ...options, [changedOption]: checked });
    }
  };

  const { generateDeliveryFromOrder, issueDeliveryAfterGenerating, generateInvoiceFromOrder } = options;

  return (
    bexioSettings && (
      <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          name="generateInvoiceFromOrder"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate('prestashop.make-bill')}
          checked={generateInvoiceFromOrder || false}
          onChange={onChange}
        />
        <FormControlLabel
          name="generateDeliveryFromOrder"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate('prestashop.delivery-note')}
          checked={generateDeliveryFromOrder || false}
          onChange={onChange}
        />
        <FormControlLabel
          name="issueDeliveryAfterGenerating"
          disabled={!generateDeliveryFromOrder}
          style={{ marginLeft: 15 }}
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate('prestashop.mark-completed')}
          checked={generateDeliveryFromOrder ? issueDeliveryAfterGenerating : false}
          onChange={onChange}
        />
      </div>
    )
  );
};

export default withLocalize(AfterOrderCreation);
