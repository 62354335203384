import { BedayaAction } from '../../types/BedayaAction';

import { GET_DELIVERY_ADDRESSES, GET_DELIVERY_DATA } from '../actions/delivery_actions';

export interface IDeliveryState {
  deliveryData: any;
  deliveryAddresses: any;
  isFetching: any;
  error: any;
}

const initialState: IDeliveryState = {
  deliveryData: [],
  deliveryAddresses: [],
  isFetching: false,
  error: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: IDeliveryState = initialState, action: BedayaAction): IDeliveryState {
  switch (action.type) {
    case GET_DELIVERY_DATA.REQUEST:
      return { ...state, isFetching: true, error: '' };
    case GET_DELIVERY_DATA.SUCCESS:
      return { ...state, deliveryData: action.data, isFetching: false };
    case GET_DELIVERY_DATA.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case GET_DELIVERY_ADDRESSES.REQUEST:
      return { ...state, isFetching: true, error: '' };
    case GET_DELIVERY_ADDRESSES.SUCCESS:
      return { ...state, deliveryAddresses: action.data, isFetching: false };

    case GET_DELIVERY_ADDRESSES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}
