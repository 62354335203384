import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close, FileCopy, NavigateNext } from '@material-ui/icons';
import * as FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { useStyles } from './theme';
import { Loading, DeliveryRequest, DialogProps, PlanzerResponse } from './types';
import Button from '../../components/CustomButtons/Button';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';
import { getPlanzerDelivery, getPlanzerDeliveryDoc } from '../../store/api/bexio';

function usePlanzerDelivery(request: DeliveryRequest | null): [Loading, PlanzerResponse | null] {
  const [loading, setLoading] = useState<Loading>('initial');
  const [data, setData] = useState<PlanzerResponse | null>(null);
  useEffect(() => {
    if (request) {
      setLoading('pending');
      setData(null);
      getPlanzerDelivery(request.ids, request.quantity)
        .then(response => {
          setData(response.data as PlanzerResponse);
          setLoading('ready');
        })
        .catch(err => {
          console.log(err);
          setLoading('error');
        });
    } else {
      setLoading('initial');
    }
  }, [request, setLoading]);
  return [loading, data];
}

function usePlanzerDeliveryDoc(ref: string): Loading {
  const [loading, setLoading] = useState<Loading>('initial');
  useEffect(() => {
    if (ref && ref.length) {
      setLoading('pending');
      getPlanzerDeliveryDoc(ref)
        .then(response => {
          const filename = response.headers['content-disposition']
            .split(';')
            .find((name: string) => name.includes('filename='))
            .replace('filename=', '')
            .trim()
            .replace(/^"(.*)"$/g, '$1')
            .trim();
          FileSaver.saveAs(response.data, filename);
          setLoading('ready');
        })
        .catch(err => {
          console.log(err);
          setLoading('error');
        });
    } else {
      setLoading('initial');
    }
  }, [ref, setLoading]);
  return loading;
}

export function PlanzerDialog({ onClose, selected, show, translate }: DialogProps) {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const [docRef, setDocRef] = useState<string>('');
  const [request, setRequest] = useState<DeliveryRequest | null>(null);
  const [loading, response] = usePlanzerDelivery(request);
  const [quantity, setQuantity] = useState<string>('1');
  const loadingDoc = usePlanzerDeliveryDoc(docRef);
  const closePlanzerDialog = () => {
    setDocRef('');
    setRequest(null);
    setQuantity('1');
    onClose();
  };
  React.useEffect(() => {
    if (loading === 'ready' && response) {
      setDocRef(response.downloadReference);
    }
  }, [loading, response]);
  const copyUrl = (url: string) => async () => {
    try {
      if (url) {
        await window.navigator.clipboard.writeText(url);
        toast.success('Copied');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getTrackingCode = (url: string) => {
    try {
      const code = new URL(url).searchParams.get('packageNumber');
      return code ? code : 'Link';
    } catch (err) {
      console.log(err);
      return 'Link';
    }
  };
  return (
    <Dialog onClose={closePlanzerDialog} aria-labelledby="customized-dialog-title" open={show} fullWidth maxWidth="sm">
      <DialogTitle disableTypography={true}>
        <Typography variant="h6">{translate('serviceAccounts.planzer.title')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={closePlanzerDialog}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        {loading === 'initial' && (
          <form
            onSubmit={e => {
              e.preventDefault();
              setRequest({ ids: selected, quantity: +quantity });
            }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ fontWeight: 'bold', marginRight: '0.5em', color: 'black' }}>
                {translate('serviceAccounts.planzer.quantity')}
              </label>
              <input
                type="number"
                style={{ padding: '0.5em' }}
                min={1}
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
              />
            </div>
            <div>
              <Button aria-label="copy" color="primary" type="submit">
                <NavigateNext />
              </Button>
            </div>
          </form>
        )}
        {(loading === 'pending' || loadingDoc === 'pending') && <CircularProgress />}
        {loading === 'error' && <span>Error</span>}
        {loading === 'ready' && response && (
          <ul
            style={{
              width: '100%',
              listStyleType: 'none',
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {response.trackingLinks.map((link, idx) => (
              <li key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                {translate('serviceAccounts.planzer.copy-tracking', { document_nr: link.document_nr })}
                <IconButton type="button" color="primary" onClick={copyUrl(link.url)} title={getTrackingCode(link.url)}>
                  <FileCopy />
                </IconButton>
              </li>
            ))}
          </ul>
        )}
      </DialogContent>
    </Dialog>
  );
}
