import { Grid } from '@material-ui/core';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import FormCard from '../../../../../components/FormCard/FormCard';
import Label from '../../../../../components/NewUI/Label/Label';
import * as enforeActions from '../../../../../store/actions/enfore_actions';
import { IState } from '../../../../../store/reducers';
import { EnforePaymentMapping, EnforePaymentMethod } from '../../../../../types/EnforeSettings';
import { IOptionType } from '../../../../../types/ReactSelect';
import { mapDisabled, MappingItem } from './MappingItem';

const PaymentMapping = (props: LocalizeContextProps & OwnProps) => {
  const { accountsList, translate, settings, setSettings } = props;
  const enforeConnectionStatus = useSelector<IState, string>(state => state.enfore.connectionStatus);
  const isFetching = useSelector<IState, boolean>(state => state.enfore.isFetching);
  const enforePaymentMethods = useSelector<IState, EnforePaymentMethod[]>(state => state.enfore.paymentMethods);
  const dispatch = useDispatch();

  const [paymentMethods, setPaymentMethods] = React.useState<IOptionType[]>([]);
  React.useEffect(() => {
    if (enforeConnectionStatus === 'ok') {
      dispatch(enforeActions.getPaymentMethodsRequest({}));
    }
  }, [enforeConnectionStatus]);
  React.useEffect(() => {
    if (enforePaymentMethods) {
      let arr = enforePaymentMethods.map((method: EnforePaymentMethod) => ({
        value: method,
        label: method,
        isDisabled: false,
      }));
      if (settings) {
        arr = mapDisabled(arr, settings.map(x => x.paymentMethod));
      }
      setPaymentMethods(arr);
    }
  }, [enforePaymentMethods, settings]);

  const [newIsDefault, setNewIsDefault] = React.useState<boolean>(false);

  return (
    <Grid container={true}>
      <Grid item={true} md={4} sm={12}>
        <Label
          title={translate('enfore.payment-methods') as string}
          desc={translate('enfore.payment-methods-desc') as string}
        />
      </Grid>
      <Grid item={true} md={8} sm={12}>
        <FormCard>
          <Grid container={true}>
            {settings.map((m, idx) => (
              <MappingItem
                key={idx}
                aOptions={accountsList}
                bOptions={paymentMethods}
                a={m.accountNo + ''}
                b={m.paymentMethod}
                isDefault={m.isDefault}
                onChange={(a, b) => {
                  setSettings(settings.map((it, i) => (idx === i ? { ...it, accountNo: +a, paymentMethod: b } : it)));
                }}
                isDisabled={isFetching}
                translate={translate}
                onToggleDefault={() => {
                  setSettings(
                    settings.map((it, i) =>
                      idx === i ? { ...it, isDefault: !it.isDefault } : { ...it, isDefault: false },
                    ),
                  );
                  setNewIsDefault(false);
                }}
                onDelete={() => {
                  setSettings(settings.filter((it, i) => i !== idx));
                }}
              />
            ))}
            <MappingItem
              key={settings.length}
              aOptions={accountsList}
              bOptions={paymentMethods}
              isDefault={newIsDefault}
              onChange={(a, b) => {
                const newItem = { accountNo: +a, paymentMethod: b, isDefault: newIsDefault };
                setSettings([...settings, newItem]);
                setNewIsDefault(false);
              }}
              isDisabled={isFetching}
              translate={translate}
              onToggleDefault={() => {
                setSettings(settings.map(it => ({ ...it, isDefault: false })));
                setNewIsDefault(!newIsDefault);
              }}
            />
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};

type OwnProps = {
  accountsList: IOptionType[];
  settings: EnforePaymentMapping[];
  setSettings: (setting: EnforePaymentMapping[]) => void;
};

export default withLocalize(PaymentMapping);
