// global imports
import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import Moment from 'react-moment';
import { connect } from 'react-redux';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

interface IErrorMessage {
  _id: string;
  created: string;
  message: string;
}

const Run: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    OAuthURL,
    testConnection,
    testConnectionTimeout,
    testConnectionRequest,
    fetchOAuthUrlRequest,
    sumupSettings,
  } = props;

  const [testDelayActive, setTestDelayActive] = useState<any>(false);

  const [intervalId, setIntervalId] = useState<any>(undefined);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, 'days')
      .toDate(),
  );
  const [accountsErrors, setAccountsErrors] = useState<any>({
    debitAccountError: '',
    creditAccountError: '',
    feeAccountError: '',
  });

  // didMount fetch data
  useEffect(() => {
    if (testConnection) {
      if (testConnectionTimeout !== undefined) {
        setTestDelayActive(true);
      }
      setTimeout(
        () => {
          testConnectionRequest({ system: 'sumup' });
          if (!OAuthURL) {
            fetchOAuthUrlRequest({ system: 'sumup' });
          }
          setTestDelayActive(false);
        },
        testConnectionTimeout !== undefined ? testConnectionTimeout : 3000,
      );
    }
  }, []);

  // test connection status
  useEffect(() => {
    if (props.connectionStatus) {
      if (props.connectionStatus === 'nok' || props.connectionStatus === 'nook') {
        props.fetchOAuthUrlRequest({ system: 'sumup' });
      }
    }
  }, [props.connectionStatus]);

  // re-fetch job
  useEffect(() => {
    if (props.lastJob && !props.lastRun.exitState) {
      if (intervalId) {
        clearTimeout(intervalId);
        setIntervalId(
          setTimeout(() => {
            props.getJobRequest({ jobNumber: props.lastJob, system: 'sumup' });
          }, 1000),
        );
      } else {
        setIntervalId(
          setTimeout(() => {
            props.getJobRequest({ jobNumber: props.lastJob, system: 'sumup' });
          }, 1000),
        );
      }
    }
    if (props.lastRun && props.lastRun.exitState) {
      if (intervalId) {
        clearTimeout(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
      }
    };
  }, [props.lastJob, props.lastRun]);

  const runSumup = () => {
    if (!sumupSettings.transaction.debitAccounts.length) {
      setAccountsErrors({ ...accountsErrors, debitAccountError: translate('sumup.debit_account_mandatory') });
    } else if (!sumupSettings.transaction.creditAccount) {
      setAccountsErrors({
        ...accountsErrors,
        creditAccountError: translate('sumup.credit_account_mandatory'),
      });
    } else if (!sumupSettings.transaction.feeAccount) {
      setAccountsErrors({ ...accountsErrors, feeAccountError: translate('sumup.fee_account_mandatory') });
    } else {
      setAccountsErrors({
        debitAccountError: '',
        creditAccountError: '',
        feeAccountError: '',
      });
      props.runJobRequest({ from: startDate, to: endDate, system: 'sumup' });
    }
  };

  const LastRunBlock = () =>
    props.lastRun && (
      <div className={cx(classes.runStatusContainer, classes.flexColumn, classes.pl5)}>
        <div className={cx(classes.runStatusContainer, classes.mt10, classes.pl0, classes.bodyText)}>
          {translate('last_run')}: {!props.lastRun.exitState && <CircularProgress className={classes.spacedIcon} />}
          {props.lastRun.exitState === 'success' && (
            <CheckCircleIcon className={cx(classes.iconGreen, classes.spacedIcon)} />
          )}
          {props.lastRun.exitState === 'error' && <CancelIcon className={cx(classes.iconRed, classes.spacedIcon)} />}
          <span className={props.lastRun.message.length ? classes.iconRed : classes.iconGreen}>
            <Moment format="DD.MM.YYYY HH:mm:ss">{props.lastRun.started}</Moment>
          </span>
          {props.lastRun.destination.id !== -1 && (
            <a
              href={`https://office.bexio.com/index.php/accounting/manualEntries/id/${props.lastRun.destination.id}`}
              className={classes.linkStyle}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('run.see-entry')}
            </a>
          )}
        </div>
        {props.lastRun.exitState === 'error' && (
          <div>
            {props.lastRun.message.length > 0 && (
              <ul>
                {props.lastRun.message.map((err: IErrorMessage, index: number) => (
                  <li key={index}>{err.message}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    );

  const DateSelectBlock = () => (
    <div
      className={cx({
        [classes.selectSmall]: true,
        [classes.contentContainer]: props.isRow,
        [classes.flexCenter]: props.isRow,
        [classes.widthAuto]: props.isRow,
      })}
    >
      <label className={classes.datepickerLabel}>{translate('from')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart={true}
        startDate={startDate}
        endDate={endDate}
      />
      <br />
      <label className={classes.datepickerLabel}>{translate('to')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd={true}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );

  return (
    <>
      {testDelayActive || (OAuthURL && props.connectionStatus !== 'loading') ? (
        props.connectionStatus === 'ok' &&
        (props.isRow ? (
          <>
            <div className={classes.pl5}>
              {Object.values(accountsErrors).map((err: any, index: number) => (
                <p key={index} className={classes.mt15}>
                  <Typography variant="body2" component="span" color="error">
                    {err}
                  </Typography>
                </p>
              ))}
            </div>
            <LastRunBlock />
            <div
              className={cx(classes.runLabel, {
                [classes.flexCenter]: !props.lastRun || props.lastRun.exitState !== 'error',
              })}
            >
              <DateSelectBlock />
              <Button className={classes.formButton} type="button" color="primary" onClick={runSumup}>
                {translate('run.run')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div
              className={cx(classes.runLabel, {
                [classes.flexCenter]: !props.lastRun || props.lastRun.exitState !== 'error',
              })}
            >
              <Button className={classes.formButton} type="button" color="primary" onClick={runSumup}>
                {translate('run.run')}
              </Button>
              <LastRunBlock />
            </div>
            <DateSelectBlock />
          </>
        ))
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const defaultProps = {
  isRow: false,
  testConnection: false,
  testConnectionTimeout: 3000,
};
Run.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: state.sumup.OAuthURL,
  lastRun: state.sumup.lastRun,
  lastJob: state.sumup.lastJob,
  connectionStatus: state.sumup.connectionStatus,
  sumupSettings: state.sumup.settings,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  runJobRequest: commonActions.runJobRequest,
  getJobRequest: commonActions.getJobRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize<Props>(Run));
