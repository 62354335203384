// global imports
import { Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { useDispatch, useSelector } from 'react-redux';
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { IState } from '../../../../store/reducers';
import { BitrixSettings } from '../../../../types/BitrixSettings';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const DummyEmail = (props: LocalizeContextProps) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const bitrixSettings = useSelector<IState, BitrixSettings>(({ bitrix: { settings } }) => settings);

  const [currentValue, setCurrentValue] = useState(bitrixSettings.dummyEmail);
  const delayedInput = useRef(_.debounce(value => onChange(value), 1000)).current;

  const handleValueChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const value = ev.target.value;

    setCurrentValue(value);
    delayedInput(value);
  };

  const onChange = (dummyEmail: string) => {
    dispatch(putSettingsRequest({ system: 'bitrix', data: { dummyEmail } }));
  };

  useEffect(() => {
    setCurrentValue(bitrixSettings.dummyEmail);
  }, [bitrixSettings]);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('bitrix.dummy-email') as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <TextField
            className={classes.w100}
            placeholder="a@a.com"
            value={currentValue}
            onChange={handleValueChange}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(DummyEmail);
