import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie/es6';

import { CircularProgress } from '@material-ui/core';
import { config } from '../../config';
import { bexioLoginRequest } from '../../store/actions/auth_actions';

import withStyles from '@material-ui/core/styles/withStyles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles';
import { IState } from '../../store/reducers';
import style from './styles';

// import useRedirect from './RedirectHook';

interface IProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  bexioLoginRequest: (bexioLoginRequest: any) => any;
  redirectPath: string;
  authenticated: boolean;
  token: string;
  location: {
    search: string;
    pathname: string;
  };
}

const BexioOAuthHandler: React.FC<IProps> = props => {
  const { classes } = props;

  const [doRedirect, setDoRedirect] = useState<boolean>(false);
  const [flow, setFlow] = useState<string>('');
  const [error, setError] = useState(false);

  // didMount fetch data
  useEffect(() => {
    if (props.location) {
      const urlParams = new URLSearchParams(window.location.search);

      const errorParam = urlParams.get('error');
      if (errorParam) {
        toast.error('An error occured while logging you in.');
        setError(true);
        setDoRedirect(true);
        return;
      }

      const cookies = new Cookies();

      const data = {
        url: config.frontendUlr + props.location.pathname + props.location.search,
        codeVerifier: cookies.get('bexioAuthValidation'),
      };

      const flowValue = cookies.get('flow');
      setFlow(cookies.get('flow'));

      if (flowValue && flowValue.indexOf('login') > -1) {
        props.bexioLoginRequest(data);
      }
      if (flowValue === 'connect') {
        const url = config.backendUrl + '/ui/user/oauth/callback';
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          mode: 'cors',
          headers,
        }).then(response => setDoRedirect(true));
      }
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      setDoRedirect(true);
    }
  }, [props.token]);

  useEffect(() => {
    if (props.authenticated) {
      setDoRedirect(true);
    }
  }, [props.authenticated]);

  return (
    <div className={classes.container}>
      {doRedirect && (
        <Redirect
          to={
            error
              ? '/auth/login'
              : flow === 'login-onboarding-shopify'
              ? '/oauth/shopify/callback'
              : props.redirectPath
              ? props.redirectPath
              : '/admin/bexio-app'
          }
        />
      )}
      <CircularProgress size={100} />
      {/*<button onClick={() => setDoRedirect(true)}>Redirect</button>*/}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  authenticated: state.bexio.authenticated,
  redirectPath: state.auth.redirectPath,
  token: state.auth.token,
});

const mapDispatchToProps = {
  bexioLoginRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(style)(BexioOAuthHandler));
