import { BedayaAction } from '../../types/BedayaAction';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { DISCONNECT, TEST_CONNECTION } from '../actions/common_actions';

export interface IPingenState {
  OAuthURL: string;
  authenticated: boolean;
  connectionStatus: any;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;
}

const initialState: IPingenState = {
  OAuthURL: '',
  authenticated: false,
  connectionStatus: 'loading',

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',
};

export default function reducer(state: IPingenState = initialState, action: BedayaAction): IPingenState {
  switch (action.type) {
    // protocol actions
    case TEST_CONNECTION.REQUEST:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['pingen'].includes(action.data.system)) {
        const status = action.data.status || 'nook';
        return {
          ...state,
          connectionStatus: status,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;
    case TEST_CONNECTION.FAILURE:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['pingen'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    default:
      return state;
  }
}
