import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'XENIAPOS';
export const GET_PAYMENT_METHODS = createRequestTypes(`${filePrefix}_GET_PAYMENT_METHODS`);
export const GET_GROUPS = createRequestTypes(`${filePrefix}_GET_GROUPS`);
export const IMPORT_ENTRIES = createRequestTypes(`${filePrefix}_IMPORT_ENTRIES`);

export const getPaymentMethodsRequest = (data: any) => ({
  type: GET_PAYMENT_METHODS.REQUEST,
  data,
});
export const getPaymentMethodsSuccess = (data: any) => ({
  type: GET_PAYMENT_METHODS.SUCCESS,
  data,
});
export const getPaymentMethodsFailure = (error: any) => ({
  type: GET_PAYMENT_METHODS.FAILURE,
  error,
});

export const getGroupsRequest = (data: any) => ({
  type: GET_GROUPS.REQUEST,
  data,
});
export const getGroupsSuccess = (data: any) => ({
  type: GET_GROUPS.SUCCESS,
  data,
});
export const getGroupsFailure = (error: any) => ({
  type: GET_GROUPS.FAILURE,
  error,
});

export const importEntriesRequest = (data: any) => ({
  type: IMPORT_ENTRIES.REQUEST,
  data,
});
export const importEntriesSuccess = (data: any) => ({
  type: IMPORT_ENTRIES.SUCCESS,
  data,
});
export const importEntriesFailure = (error: any) => ({
  type: IMPORT_ENTRIES.FAILURE,
  error,
});
