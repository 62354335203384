import { Box, createStyles, InputLabel, Theme, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import { StyledSwitch } from '../../components/StyledSwitch';

import commonStyles from '../../assets/jss/commonStyles';

import { putDebugModeRequest } from '../../store/actions/auth_actions';
import { getStripePortalRequest } from '../../store/actions/commons_actions';

const styles = ({ palette, spacing }: Theme) => createStyles(commonStyles);

function UserProfile(props: any) {
  const { classes, translate } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const isDebugActivated = useSelector((state: any) => state.auth.isDebugActivated);
  const stripePortalUrl = useSelector((state: any) => state.commons.stripe.portalUrl);

  const showInvoices = () => {
    dispatch(getStripePortalRequest());
  };

  useEffect(() => {
    if (stripePortalUrl) {
      window.location.href = stripePortalUrl;
    }
  }, [stripePortalUrl]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
              <p className={classes.cardCategoryWhite}>Complete your profile</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <Box mt={1}>
                    <Typography variant="h6" gutterBottom={true}>
                      {translate('profile.basics')}
                    </Typography>
                    <Typography variant="body1" gutterBottom={true}>
                      {translate('profile.basic_desc')}
                    </Typography>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="first-name"
                        inputProps={{
                          value: userInfo.firstname,
                          readOnly: true,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="last-name"
                        inputProps={{
                          value: userInfo.lastname,
                          readOnly: true,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Email address"
                        id="email-address"
                        inputProps={{
                          value: userInfo.email,
                          readOnly: true,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <Box mt={1}>
                    <Typography variant="h6" gutterBottom={true}>
                      {translate('profile.advanced')}
                    </Typography>
                    <Typography variant="body1" gutterBottom={true}>
                      {translate('profile.advanced_desc')}
                    </Typography>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer alignItems={'center'}>
                        <GridItem xs={3} className={classes.gridItem}>
                          <InputLabel> {translate('profile.debug_mode')}</InputLabel>
                        </GridItem>
                        <GridItem xs={9} className={classes.gridItem}>
                          <StyledSwitch
                            checked={isDebugActivated}
                            onChange={event =>
                              dispatch(putDebugModeRequest({ isDebugActivated: event.target.checked }))
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <Box mt={1}>
                    <Typography variant="h6" gutterBottom={true}>
                      {translate('profile.invoices')}
                    </Typography>
                    <Typography variant="body1" gutterBottom={true}>
                      {translate('profile.invoices_desc')}
                    </Typography>
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button type="button" color="primary" onClick={showInvoices}>
                        {translate('profile.manage_invoices')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(withLocalize(UserProfile));
