import React from 'react';
import { InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Schedule, Weekday } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';

const weekdays: Weekday[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

type ScheduleSectionProps = {
  enabled: boolean;
  settings: Schedule;
  setSettings: (item: Schedule) => void;
} & LocalizeContextProps;

const padTime = (hh: any): string => String(hh).padStart(2, '0');
const makeTime = (hh: any, mm: any): string => padTime(hh) + ':' + padTime(mm);

const parseTime = (str: string): number[] => {
  const match = str.match(/(\d\d):(\d\d)/);
  if (match) {
    return [parseInt(match[1]), parseInt(match[2])];
  }
  return [0, 0];
}

function ScheduleSection(props: ScheduleSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  const [hours, minutes] = parseTime(settings.time);
  return (
    <>
      <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'row' }}>
        {weekdays.map(name => (
          <FormControlLabel
            key={name}
            name={`schedule_${name}`}
            control={<Checkbox color="primary" />}
            classes={{
              label: classes.label,
              root: classes.checkbox,
            }}
            label={translate(`reminders.schedule.weekdays.${name}`) as string}
            checked={settings.days.includes(name)}
            onChange={(event: any, newValue: boolean) => setSettings({...settings, days: newValue ? [...settings.days, name] : settings.days.filter(x => x !== name)})}
            disabled={!enabled}
          />
        ))}
      </div>
      <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <InputLabel>{translate('reminders.schedule.hours') as string}</InputLabel>
          <TextFieldDelayed
            value={'' + hours}
            onChange={(value: string) => setSettings({...settings, time: makeTime(value, minutes)})}
            type="number"
            inputProps={{ 'min': 0, 'max': 23 }}
            disabled={!enabled}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 15 }}>
          <InputLabel>{translate('reminders.schedule.minutes') as string}</InputLabel>
          <TextFieldDelayed
            value={'' + minutes}
            onChange={(value: string) => setSettings({...settings, time: makeTime(hours, value)})}
            type="number"
            inputProps={{ 'min': 0, 'max': 59 }}
            disabled={!enabled}
          />
        </div>
      </div>
    </>
  );
}

export default withLocalize(ScheduleSection);
