import { whiteColor } from '../../material-dashboard-pro-react.js';

const pagesStyle = theme => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
  },
  fullPage: {
    padding: '120px 0',
    position: 'relative',
    //minHeight: '100vh',
    display: 'flex!important',
    margin: '0',
    border: '0',
    color: whiteColor,
    alignItems: 'center',
    backgroundSize: '90vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: '#f3f6f9',
    //height: 'calc(100vh - 240px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'fit-content!important',
      height: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '60vw',
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      border: 'none !important',
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '2',
    },
  },
});

export default pagesStyle;
