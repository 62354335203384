import commonStyles from '../../assets/jss/commonStyles';
import { IStyleProps } from '../../types/StyleProps';

const styles: IStyleProps = {
  ...commonStyles,
  contentLarge: {
    width: '100%',
  },
};

export default styles;
