// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader.jsx';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import ConnectWidget from '../../../../components/ConnectWidget/ConnectWidget';
import { IState } from '../../../../store/reducers';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const MailchimpApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, OAuthURL, connectionStatus, fetchOAuthUrlRequest } = props;

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL) {
      fetchOAuthUrlRequest({ system: 'mailchimp' });
    }
  }, []);

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>{translate('mailchimp_app_name')}</h4>
      </CardHeader>
      <CardBody>
        <h3>{translate('status')}</h3>
        <div className={classes.connectRow}>
          {OAuthURL && connectionStatus !== 'loading' ? (
            connectionStatus === 'ok' ? (
              <div className={classes.connectedContainer}>
                <span className={classes.connectedStatus}>{translate('connected')}</span>
                <DisconnectWidget appName="mailchimp" />
              </div>
            ) : (
              <ConnectWidget appName="mailchimp" redirect="/admin/mailchimp-app" />
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </CardBody>
      <CardBody>
        <h3>{translate('settings')}</h3>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: state.mailchimp.OAuthURL,
  connectionStatus: state.mailchimp.connectionStatus,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MailchimpApp),
);
