import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, fork, take } from 'redux-saga/effects';

import appsSagas from './apps_sagas';
import authSagas from './auth_sagas';
import bexioSagas from './bexio_sagas';
import bitrixSaga from './bitrix_sagas';
import commonSagas from './common_sagas';
import commonsSaga from './commons_sagas';
import deliverySagas from './delivery_sagas';
import dpdSagas from './dpd_sagas';
import planzerSagas from './planzer_sagas';
import enforeSagas from './enfore_sagas';
import gastrofixSagas from './gastrofix_sagas';
import prestashopSagas from './prestashop_sagas';
import protocolSagas from './protocol_sagas';
import shopifySagas from './shopify_sagas';
import sumupSagas from './sumup_sagas';
import weclappSagas from './weclapp_sagas';
import wixSagas from './wix_sagas';
import xeniaPosSagas from './xeniapos_sagas';

export default function* rootSaga() {
  yield take(REHYDRATE); // Wait for rehydrate to prevent sagas from running with empty store
  yield all([
    fork(authSagas),
    fork(appsSagas),
    fork(protocolSagas),
    fork(sumupSagas),
    fork(shopifySagas),
    fork(bexioSagas),
    fork(prestashopSagas),
    fork(weclappSagas),
    fork(commonSagas),
    fork(gastrofixSagas),
    fork(commonsSaga),
    fork(bitrixSaga),
    fork(deliverySagas),
    fork(wixSagas),
    fork(enforeSagas),
    fork(xeniaPosSagas),
    fork(dpdSagas),
    fork(planzerSagas),
  ]);
}
