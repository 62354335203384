// global imports
import { Checkbox, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles);

const PayInvoice = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;

  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);

  const [options, setOptions] = useState({
    payInvoice: bexioSettings ? bexioSettings.payInvoice : undefined,
  });

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }

    if (
      options.payInvoice !== bexioSettings.payInvoice
    ) {
      dispatch(putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, ...options } }));
    }
  }, [options]);

  const onChange = (ev: any, newValue: boolean) => {
    setOptions({ ...options, payInvoice: !options.payInvoice });
  };

  const { payInvoice } = options;

  return (
    bexioSettings && (
      <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          name="payInvoice"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate('payInvoice')}
          checked={!payInvoice}
          onChange={onChange}
        />
      </div>
    )
  );
};

export default withLocalize(PayInvoice);
