import { ActionPattern, all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { getProduct, searchProduct } from './../api/weclapp';

import { BedayaAction } from '../../types/BedayaAction';

import {
  GET_CUSTOMER,
  GET_PRODUCT,
  GET_TAXES,
  getCustomerFailure,
  getCustomerSuccess,
  getProductFailure,
  getProductSuccess,
  getTaxesFailure,
  getTaxesSuccess,
  SEARCH_CUSTOMER,
  SEARCH_PRODUCT,
  searchCustomerFailure,
  searchCustomerSuccess,
  searchProductFailure,
  searchProductSuccess,
} from '../actions/weclapp_actions';
import { getWeclappCustomer, getWeclappTaxes, searchWeclappCustomer } from '../api/weclapp';

function* getWeclappTaxesSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getWeclappTaxes);
    yield put(getTaxesSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getTaxesFailure(processedError || error));
  }
}
function* getWeclappCustomerSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(getWeclappCustomer, { customerId: action.data.customerId });
    yield put(getCustomerSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getCustomerFailure(processedError || error));
  }
}
function* searchWeclappCustomerSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(searchWeclappCustomer, { searchTerm: action.data.searchTerm });
    yield put(searchCustomerSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(searchCustomerFailure(processedError || error));
  }
}
function* getProductSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getProduct, { productId: action.data.productId });
    yield put(getProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getProductFailure(processedError || error));
  }
}
function* searchProductSaga(action: BedayaAction) {
  yield delay(500);
  try {
    const { data } = yield call(searchProduct);
    yield put(searchProductSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(searchProductFailure(processedError || error));
  }
}
function* getBexioTaxesWatcher() {
  yield takeLatest(GET_TAXES.REQUEST as ActionPattern, getWeclappTaxesSaga);
}
function* getBexioCustomerWatcher() {
  yield takeLatest(GET_CUSTOMER.REQUEST as ActionPattern, getWeclappCustomerSaga);
}
function* searchBexioCustomerWatcher() {
  yield takeLatest(SEARCH_CUSTOMER.REQUEST as ActionPattern, searchWeclappCustomerSaga);
}
function* getProductWatcher() {
  yield takeLatest(GET_PRODUCT.REQUEST as ActionPattern, getProductSaga);
}
function* searchProductWatcher() {
  yield takeLatest(SEARCH_PRODUCT.REQUEST as ActionPattern, searchProductSaga);
}
export default function* authSaga() {
  yield all([
    fork(getBexioTaxesWatcher),
    fork(getBexioCustomerWatcher),
    fork(searchBexioCustomerWatcher),
    fork(getProductWatcher),
    fork(searchProductWatcher),
  ]);
}
