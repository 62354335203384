// global imports
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { ValueType } from 'react-select/src/types';
import { IState } from '../../../../store/reducers';
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import FormCard from '../../../../components/FormCard/FormCard';
import GridRow from '../../../../components/NewUI/GridRow';
import Label from '../../../../components/NewUI/Label/Label';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const filterErpApps = (available: any) => (app: string) => {
  const availableApps: Array<{ name: string; category: string }> = available;
  const availableApp = availableApps.find(a => a.name === app && a.category === 'erp');
  return availableApp !== undefined;
};

const SourceSystem = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  const { translate } = props;

  // tslint:disable-next-line:no-shadowed-variable
  const { bitrixSettings, apps, userInfo } = useSelector<IState, StateProps>(
    // tslint:disable-next-line:no-shadowed-variable
    ({ bitrix: { settings }, apps: { apps }, auth: { userInfo } }) => ({
      bitrixSettings: settings,
      apps,
      userInfo,
    }),
  );

  const [appsList, setAppsList] = useState<IOptionType[]>([]);
  const [selectedSystem, setSelectedSystem] = useState<IOptionType | null>(null);

  useEffect(() => {
    if (apps && userInfo.availableApps) {
      setAppsList(
        apps.filter(filterErpApps(userInfo.availableApps)).map((app: string) => ({
          value: app,
          label: app,
        })),
      );
    }
  }, [apps, userInfo.availableApps]);

  useEffect(() => {
    const { sourceSystem } = bitrixSettings;

    if (sourceSystem) {
      setSelectedSystem({
        label: sourceSystem,
        value: sourceSystem,
      });
    }
  }, [bitrixSettings.sourceSystem]);

  const handleValueChange = (value: ValueType<IOptionType, false>) => {
    if (value && 'value' in value) {
      const updatedSettings = {
        sourceSystem: value.value,
      };

      setSelectedSystem({
        label: value.label,
        value: value.value,
      });

      dispatch(putSettingsRequest({ system: 'bitrix', data: updatedSettings }));
    }
  };

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label title={translate('bitrix.source-system') as string} desc="" />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          <Select
            isSearchable={false}
            className={cx(classes.pl0, classes.w100)}
            options={appsList}
            value={selectedSystem}
            onChange={handleValueChange}
            styles={colourStyles}
          />
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(SourceSystem);
