// global imports
import { Grid, InputLabel, makeStyles, StyleRules, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import GridItem from '../../../../components/Grid/GridItem';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import style from './styles';

// project imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { StyledSwitch } from '../../../../components/StyledSwitch';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const TransferSalesPrice = (props: Props) => {
  const { translate } = props;
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();
  const bexioSettings = useSelector<IState, StateProps>(({ bexio: { settings } }) => settings);

  const [transferPrice, setTransferPrice] = useState(bexioSettings ? !bexioSettings.doNotTransferSalesPrice : true);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    if (transferPrice === !bexioSettings.doNotTransferSalesPrice) {
      return;
    }
    dispatch(
      putSettingsRequest({ system: 'bexio', data: { ...bexioSettings, doNotTransferSalesPrice: !transferPrice } }),
    );
  }, [transferPrice]);

  useEffect(() => {
    if (!bexioSettings) {
      return;
    }
    setTransferPrice(!bexioSettings.doNotTransferSalesPrice);
  }, [bexioSettings]);

  const onChange = (ev: React.ChangeEvent) => {
    setTransferPrice(!transferPrice);
  };

  return (
    <Grid container={true}>
      <GridItem xs={4} className={classes.gridItem}>
        <InputLabel>{translate('bexio.transfer_sales_price')}</InputLabel>
      </GridItem>
      <GridItem xs={8} className={classes.gridItem}>
        <StyledSwitch checked={transferPrice} onChange={onChange} disabled={!bexioSettings} />
      </GridItem>
    </Grid>
  );
};

export default withLocalize(TransferSalesPrice);
