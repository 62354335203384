import { IStyleProps } from '../../types/StyleProps';

const styles: IStyleProps = {
  button: {
    position: 'absolute',
    top: '-12px',
    right: '-10px',
    background: '#43a047',
    color: 'white',
    padding: '0px 5px',
    borderRadius: '5px',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  small: {
    right: '50px',
    top: '15px',
  },
  blue: {
    background: '#0091ea',
  },
};

export default styles;
