// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie/es6';

// project imports
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody';
import CardHeader from '../../../../components/Card/CardHeader.jsx';
import Button from '../../../../components/CustomButtons/Button';
import DisconnectWidget from '../../../../components/DisconnectWidget/DisconnectWidget';
import * as authActions from '../../../../store/actions/auth_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { IState } from '../../../../store/reducers';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const VendApp = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, OAuthURL, fetchOAuthUrlRequest, testConnectionRequest } = props;

  // didMount fetch data
  useEffect(() => {
    if (!OAuthURL.bexio) {
      fetchOAuthUrlRequest({ system: 'vend' });
    }
    testConnectionRequest({ system: 'vend' });
  }, []);

  const connectVend = () => {
    const url = OAuthURL.vend;
    const cookies = new Cookies();
    cookies.set('flow', 'connect', { path: '/' });
    window.open(url ? '' + url : '', '_self');
  };

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>{translate('vend_app_name')}</h4>
      </CardHeader>
      <CardBody>
        <h3>{translate('status')}</h3>
        <div className={classes.connectRow}>
          {OAuthURL.vend && props.connectionStatus !== 'loading' ? (
            props.connectionStatus === 'ok' ? (
              <>
                <div className={classes.connectedContainer}>
                  <span className={classes.connectedStatus}>{translate('connected')}</span>
                  <DisconnectWidget appName="vend" />
                </div>
              </>
            ) : (
              <>
                <div className={classes.selectSmall}>
                  <Button type="button" color="primary" onClick={connectVend}>
                    {translate('connect')}
                  </Button>
                </div>
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </CardBody>
      <CardBody>
        <h3>{translate('settings')}</h3>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: {
    vend: state.vend.OAuthURL,
    bexio: state.bexio.OAuthURL,
  },
  connectionStatus: state.vend.connectionStatus,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  testConnectionRequest: commonActions.testConnectionRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(VendApp),
);
