import { Grid, makeStyles, TextField, Theme, Checkbox, FormControlLabel } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import commonStyles from '../../../assets/jss/commonStyles';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import FormCard from '../../../components/FormCard/FormCard';
import Label from '../../../components/NewUI/Label/Label';
import Button from '../../../components/CustomButtons/Button';
import * as commonActions from '../../../store/actions/common_actions';
import { IState } from '../../../store/reducers';
import { PlanzerSettings } from '../../../types/PlanzerSettings';
import { IOptionType } from '../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../types/StyleProps';
import { setPlanzerTemplate, getPlanzerTemplate, getPlanzerDefaultTemplate } from '../../../store/api/bexio';
import * as FileSaver from 'file-saver';

type PlanzerWidgetProps = LocalizeContextProps;

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

function PlanzerWidget(props: PlanzerWidgetProps) {
  const dispatch = useDispatch();
  const planzerSettings = useSelector<IState, PlanzerSettings>(state => state.planzer.settings);
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate } = props;
  const [settings, setSettings] = React.useState<PlanzerSettings | null>(planzerSettings);

  const countryOptions: IOptionType[] = [
    { label: translate('serviceAccounts.planzer.switzerland') as string, value: 'ch' },
  ];

  const notificationOptions: IOptionType[] = [
    { label: translate('serviceAccounts.planzer.receiver.notifications.email') as string, value: 'EMail' },
    { label: translate('serviceAccounts.planzer.receiver.notifications.none') as string, value: 'None' },
  ];

  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'planzer' }));
  }, []);

  React.useEffect(() => {
    setSettings(planzerSettings);
  }, [planzerSettings]);

  const delayedInputReceiver = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'planzer', data: { receiver: { [field]: value } } }));
    }, 1000),
  ).current;

  const handleValueChangeReceiver = (field: 'email' | 'phone' | 'mobile') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.checked;
    setSettings({ ...settings, receiver: { ...settings.receiver, [field]: newValue } });
    delayedInputReceiver(field, newValue);
  };

  const handleValueChangeReceiverNotification = (newValue: string) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, receiver: { ...settings.receiver, notification: newValue } });
    delayedInputReceiver('notification', newValue);
  };

  const delayedInputAuth = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'planzer', data: { [field]: value } }));
    }, 1000),
  ).current;

  const handleValueChangeAuth = (field: 'customerNumber' | 'account' | 'department') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, [field]: newValue });
    delayedInputAuth(field, newValue);
  };

  const delayedInputSender = React.useRef(
    _.debounce((field, value) => {
      dispatch(commonActions.putSettingsRequest({ system: 'planzer', data: { sender: { [field]: value } } }));
    }, 1000),
  ).current;

  const handleValueChangeSender = (field: 'name' | 'street' | 'zip' | 'city' | 'country') => (event: any) => {
    if (!settings) {
      return;
    }
    const newValue = event.target.value;

    setSettings({ ...settings, sender: { ...settings.sender, [field]: newValue } });
    delayedInputSender(field, newValue);
  };

  const handleValueChangeCountry = (newValue: string) => {
    if (!settings) {
      return;
    }
    setSettings({ ...settings, sender: { ...settings.sender, country: newValue } });
    delayedInputSender('country', newValue);
  };
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      await setPlanzerTemplate(file);
      toast.success('Uploaded');
    }
  }
  const getFilename = (contentDisposition: string) => {
    const filename = contentDisposition.split(';').find((name: string) => name.includes('filename='));
    if (!filename) {
      return "template.docx";
    }
    return filename
      .replace('filename=', '')
      .trim()
      .replace(/^"(.*)"$/g, '$1')
      .trim();
  }
  const downloadCurrent = async () => {
    const resp = await getPlanzerTemplate();
    const filename = getFilename(resp.headers['content-disposition']);
    FileSaver.saveAs(resp.data, filename);
  }
  const downloadDefault = async () => {
    const resp = await getPlanzerDefaultTemplate();
    const filename = getFilename(resp.headers['content-disposition']);
    FileSaver.saveAs(resp.data, filename);
  }
  return (
    <Grid container={true}>
      <Grid container={true}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.planzer.credentials') as string}
            desc={translate('serviceAccounts.planzer.credentials-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.customerNumber')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.customerNumber') as string}
                    className={classes.w100}
                    value={settings ? settings.customerNumber : ''}
                    onChange={handleValueChangeAuth('customerNumber')}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.account')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.account') as string}
                    className={classes.w100}
                    value={settings ? settings.account : ''}
                    onChange={handleValueChangeAuth('account')}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.department')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.department') as string}
                    className={classes.w100}
                    value={settings ? settings.department : ''}
                    onChange={handleValueChangeAuth('department')}
                    disabled={false}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
      <Grid container={true} style={{ marginTop: '2em' }}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.planzer.sender') as string}
            desc={translate('serviceAccounts.planzer.sender-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.name')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.name') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.name : ''}
                    onChange={handleValueChangeSender('name')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.street')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.street') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.street : ''}
                    onChange={handleValueChangeSender('street')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.zip')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.zip') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.zip : ''}
                    onChange={handleValueChangeSender('zip')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.city')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <TextField
                    placeholder={translate('serviceAccounts.planzer.city') as string}
                    className={classes.w100}
                    value={settings ? settings.sender.city : ''}
                    onChange={handleValueChangeSender('city')}
                    disabled={false /*!enforeSettings*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.country')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <Select
                    className={classes.w100}
                    isSearchable={false}
                    options={countryOptions}
                    value={settings ? countryOptions.find(x => x.value === settings.sender.country) : null}
                    onChange={opt => {
                      handleValueChangeCountry(opt ? opt.value : '');
                    }}
                    isDisabled={false /*isDisabled*/}
                    styles={colourStyles}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
      <Grid container={true} style={{ marginTop: '2em' }}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.planzer.template') as string}
            desc={translate('serviceAccounts.planzer.template-desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.template-current')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <Button type="button" color="primary" onClick={downloadCurrent}>{translate('serviceAccounts.planzer.template-download')}</Button>
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.template-default')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <Button type="button" color="primary" onClick={downloadDefault}>{translate('serviceAccounts.planzer.template-download')}</Button>
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={3} sm={3}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.template-upload')}</span>
                </Grid>
                <Grid item={true} md={9} sm={9}>
                  <input type="file" onChange={handleFileChange} accept=".docx" />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
      <Grid container={true} style={{ marginTop: '2em' }}>
        <Grid item={true} md={4} sm={4}>
          <Label
            title={translate('serviceAccounts.planzer.receiver.title') as string}
            desc={translate('serviceAccounts.planzer.receiver.desc') as string}
          />
        </Grid>
        <Grid item={true} md={8} sm={8}>
          <FormCard>
            <div className={classes.w100}>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={12} sm={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    classes={{
                      label: classes.label,
                      root: classes.checkbox,
                    }}
                    label={translate("serviceAccounts.planzer.receiver.email") as string}
                    checked={settings ? !!settings.receiver.email : false}
                    onChange={handleValueChangeReceiver('email')}
                    disabled={false /*!enabled*/}
                  />
                </Grid>
                <Grid item={true} md={12} sm={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    classes={{
                      label: classes.label,
                      root: classes.checkbox,
                    }}
                    label={translate("serviceAccounts.planzer.receiver.phone") as string}
                    checked={settings ? !!settings.receiver.phone : false}
                    onChange={handleValueChangeReceiver('phone')}
                    disabled={false /*!enabled*/}
                  />
                </Grid>
                <Grid item={true} md={12} sm={12}>
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    classes={{
                      label: classes.label,
                      root: classes.checkbox,
                    }}
                    label={translate("serviceAccounts.planzer.receiver.mobile") as string}
                    checked={settings ? !!settings.receiver.mobile : false}
                    onChange={handleValueChangeReceiver('mobile')}
                    disabled={false /*!enabled*/}
                  />
                </Grid>
              </Grid>
              <Grid container={true} style={{ marginBottom: 10 }}>
                <Grid item={true} md={12}>
                  <span style={{ fontWeight: 'bold' }}>{translate('serviceAccounts.planzer.receiver.notification')}</span>
                </Grid>
                <Grid item={true} md={12}>
                  <Select
                    className={classes.w100}
                    isSearchable={false}
                    options={notificationOptions}
                    value={settings ? notificationOptions.find(x => x.value === settings.receiver.notification) : null}
                    onChange={opt => {
                      handleValueChangeReceiverNotification(opt ? opt.value : '');
                    }}
                    isDisabled={false /*isDisabled*/}
                    styles={colourStyles}
                  />
                </Grid>
              </Grid>
            </div>
          </FormCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withLocalize(PlanzerWidget);
