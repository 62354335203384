import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { FixEmail } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


type FixEmailSectionProps = {
  enabled: boolean;
  settings: FixEmail;
  setSettings: (item: FixEmail) => void;
} & LocalizeContextProps;

function FixEmailSection(props: FixEmailSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  return (
    <Grid container={true}>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.fixEmail.email') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.fixEmail.email') as string}
          value={settings.email}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, email: value})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.fixEmail.subject') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.fixEmail.subject') as string}
          value={settings.subject}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, subject: value})}
          disabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.fixEmail.template') as string}
      </Grid>
      <Grid item={true} xs={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.fixEmail.template') as string}
          value={settings.template}
          className={classes.w100}
          multiline={true}
          rows={3}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, template: value})}
          disabled={!enabled}
        />
        <FormControlLabel
          name="fix-email-html"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.fixEmail.html") as string}
          checked={settings.html}
          onChange={(event: any, newValue: boolean) => setSettings({...settings, html: newValue})}
          disabled={!enabled}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(FixEmailSection);
