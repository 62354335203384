import _ from 'lodash';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import { blackColor, grayColor } from '../../assets/jss/material-dashboard-pro-react';

type TLinks = { [key: string]: string };

const bedayaPrivacy: TLinks = {
  de: 'http://www.bedaya.es/datenschutz/',
  fr: 'http://www.bedaya.es/politique-de-confidentialite/',
  others: 'http://www.bedaya.es/data-protection-policy/',
};
const bexioPrivacy: TLinks = {
  de: 'https://www.bexio.com/de-CH/richtlinien/datenschutz',
  fr: 'https://www.bexio.com/fr-CH/directives/protection-des-donnees',
  others: 'https://www.bexio.com/en-CH/policies/privacy-policy',
};

const CookieConsentBanner = ({ translate, languages }: LocalizeContextProps) => {
  const activeLang = languages.length && _.find(languages, lang => lang.active);
  const currentLocale = activeLang ? activeLang.code : 'en';

  return (
    <CookieConsent
      style={{ zIndex: 1050, background: grayColor[4], color: blackColor, justifyContent: 'flex-start' }}
      contentStyle={{ flex: '0 0 auto' }}
      buttonText={translate('cookie-consent.button-text')}
    >
      {translate('cookie-consent.text')}
      <a
        href={
          process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio'
            ? bexioPrivacy[currentLocale] || bexioPrivacy.others
            : bedayaPrivacy[currentLocale] || bedayaPrivacy.others
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {translate('cookie-consent.privacy-policy')}
      </a>
    </CookieConsent>
  );
};

export default withLocalize(CookieConsentBanner);
