import { BedayaAction } from '../../types/BedayaAction';

import { GET_APPS, PUT_APPS } from '../actions/apps_actions';

export interface IAppsState {
  apps: any;
  isFetching: boolean;
  error: string;
}

const initialState: IAppsState = {
  apps: null,
  isFetching: false,
  error: '',
};

export default function reducer(state: IAppsState = initialState, action: BedayaAction): IAppsState {
  switch (action.type) {
    case GET_APPS.REQUEST:
    case PUT_APPS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_APPS.SUCCESS:
    case PUT_APPS.SUCCESS:
      return {
        ...state,
        apps: action.data,
        isFetching: false,
      };
    case GET_APPS.FAILURE:
    case PUT_APPS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
