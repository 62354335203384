// global imports
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import { getCustomerRequest, searchCustomerRequest } from '../../../../store/actions/weclapp_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioContactType } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';
import { WeclappSettings } from '../../../../types/WeclappSettings';

// local imports
import { Person } from '@material-ui/icons';
import Search from '../../../Search/Search';
import { Props, StateProps } from './types';

const CustomerSearch: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const { translate } = props;

  const weclappSettings = useSelector<IState, StateProps['weclappSettings']>(({ weclapp: { settings } }) => settings);
  const weclappCustomerFetchResult = useSelector<IState, StateProps['weclappCustomerFetchResult']>(
    ({ weclapp: { customerFetchResult } }) => customerFetchResult,
  );
  const weclappCustomersSearchResult = useSelector<IState, StateProps['weclappCustomersSearchResult']>(
    ({ weclapp: { customersSearchResult } }) => customersSearchResult,
  );

  const [localSettings, setLocalSettings] = useState<WeclappSettings | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [customersList, setCustomersList] = useState<IOptionType[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<IOptionType | null>(null);

  const { apiToken } = weclappSettings;

  useEffect(() => {
    if (apiToken) {
      dispatch(searchCustomerRequest({ searchTerm: '' }));
    }
  }, [apiToken]);

  // convert customer search result for react-select
  useEffect(() => {
    if (weclappCustomerFetchResult && !selectedCustomer) {
      setSelectedCustomer({
        value: weclappCustomerFetchResult.id,
        label:
          weclappCustomerFetchResult.name +
          (weclappCustomerFetchResult.firstname ? ' ' + weclappCustomerFetchResult.firstname : ''),
      });
    }
    if (weclappCustomersSearchResult) {
      setCustomersList(
        weclappCustomersSearchResult.map((account: BexioContactType) => ({
          value: account.id,
          label: account.name + (account.firstname ? ' ' + account.firstname : ''),
        })),
      );
    }
  }, [weclappCustomersSearchResult, weclappCustomerFetchResult]);

  // set settings locally
  useEffect(() => {
    if (weclappSettings) {
      const newSettings = { ...cloneDeep(weclappSettings) };
      // fetch actual data from id
      if (newSettings.defaultCustomerId) {
        dispatch(getCustomerRequest({ customerId: newSettings.defaultCustomerId }));
      }
      setLocalSettings(newSettings);
    }
  }, [weclappSettings]);

  // save settings to backend
  useEffect(() => {
    if (weclappSettings && localSettings && !isEqual(weclappSettings, localSettings)) {
      dispatch(putSettingsRequest({ system: 'weclapp', data: localSettings }));
    }
  }, [localSettings]);

  const searchCustomers = (newSearchTerm: string, data: any) => {
    const { action } = data;
    if (action === 'menu-close' || action === 'input-blur' || action === 'set-value') {
      return;
    } else {
      setSearchTerm(newSearchTerm);
      if (newSearchTerm.length >= 4) {
        dispatch(searchCustomerRequest({ searchTerm: newSearchTerm }));
      }
    }
  };

  const searchCustomerChange = (value: IOptionType) => {
    setSelectedCustomer(value);
    if (localSettings) {
      setLocalSettings({
        ...localSettings,
        defaultCustomerId: parseInt(value.value, 10),
      });
    }
  };

  const clearTerm = () => {
    setSearchTerm('');
  };

  return (
    <div style={{ width: '100%' }}>
      {localSettings && (
        <Search
          data={customersList}
          placeholder={translate('select_prompt') as string}
          resultClicked={searchCustomerChange}
          termChange={searchCustomers}
          currentValue={selectedCustomer}
          searchTerm={searchTerm}
          icon={<Person />}
          clearTerm={clearTerm}
        />
      )}
    </div>
  );
};

export default withLocalize(CustomerSearch);
