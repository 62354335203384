// global imports
import { Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as prestashopActions from '../../../../store/actions/prestashop_actions';
// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import Select from 'react-select';
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const ImportOrders: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const [shopName, setShopName] = useState<string>();

  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, 'days')
      .toDate(),
  );

  const { translate, prestashopSettings, importOrdersRequest } = props;

  const DateSelectBlock = () => (
    <div
      className={cx({
        [classes.selectSmall]: true,
        [classes.contentContainer]: true,
        [classes.flexCenter]: true,
        [classes.widthAuto]: true,
      })}
    >
      <label className={classes.datepickerLabel}>{translate('from')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart={true}
        startDate={startDate}
        endDate={endDate}
      />
      <br />
      <label className={classes.datepickerLabel}>{translate('to')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd={true}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );

  const importOrders = () => {
    importOrdersRequest({ from: startDate, to: endDate, shop: shopName });
    toast.success(translate('import-order-success') as string);
  };

  return (
    <>
      <h3 className={classes.bodyLg}>{translate('import_orders_in_range')}</h3>
      <Grid container={true}>
        {prestashopSettings.shops.length > 1 && (
          <Grid item={true} md={2} style={{ padding: 10 }}>
            <InputLabel style={{ marginBottom: 10 }}>Shop</InputLabel>
            <Select
              isSearchable={false}
              options={prestashopSettings.shops.map(shop => ({ value: shop.name, label: shop.name }))}
              value={prestashopSettings.shops
                .map(shop => ({ value: shop.name, label: shop.name }))
                .find(shop => shop.value === shop + '')}
              onChange={(value: any) => setShopName(value ? value.value : '')}
            />
          </Grid>
        )}
        <Grid item={true} style={{ padding: 10 }}>
          <InputLabel style={{ marginBottom: 10 }}>{translate('order_range')}</InputLabel>
          <DateSelectBlock />
        </Grid>
        <Grid item={true} style={{ paddingTop: 30 }}>
          <Button
            className={classes.formButton}
            type="button"
            color="primary"
            onClick={importOrders}
            disabled={prestashopSettings.shops.length > 1 && !shopName}
          >
            {translate('import')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const defaultProps: OwnProps = {
  testConnection: false,
  testConnectionTimeout: 3000,
  showSpinner: false,
};
ImportOrders.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  prestashopSettings: state.prestashop.settings,
});

const mapDispatchToProps = {
  importOrdersRequest: prestashopActions.importOrdersRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ImportOrders));
