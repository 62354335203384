/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import _ from 'lodash';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// core components
import footerStyle from '../../../assets/jss/material-dashboard-pro-react/components/footerStyle.js';

const bedayaPrivacy = {
  de: 'http://www.bedaya.es/datenschutz/',
  fr: 'http://www.bedaya.es/politique-de-confidentialite/',
  others: 'http://www.bedaya.es/data-protection-policy/',
};
const bedayaGTC = {
  others: 'http://www.bedaya.es/wp-content/uploads/2020/03/Bedaya-AGB-1.pdf',
};

const bexioPrivacy = {
  de: 'https://www.bexio.com/de-CH/richtlinien/datenschutz',
  fr: 'https://www.bexio.com/fr-CH/directives/protection-des-donnees',
  others: 'https://www.bexio.com/en-CH/policies/privacy-policy',
};
const bexioGTC = {
  de: 'https://cdn.www.bexio.com/assets/content/documents/legal/bexio_agb_DE.pdf',
  fr: 'https://cdn.www.bexio.com/assets/content/documents/legal/bexio_agb_FR.pdf',
  others: 'https://cdn.www.bexio.com/assets/content/documents/legal/bexio_agb_EN.pdf',
};

function Footer({ ...props }) {
  const { classes, translate, languages } = props;
  const lang = languages.length ? _.find(languages, lang => lang.active) : null;
  const currentLang = lang ? lang.code : 'en';

  return (
    <footer style={props.style} className={classes.footer}>
      <div className={classes.container} style={{ zIndex: 0 }}>
        <div className={classes.left}>
          <span className={classes.footerCopy}>
            Copyright &copy; {1900 + new Date().getYear()}{' '}
            {process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? 'Bexio AG' : 'Bedaya GmbH'}
          </span>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href={
                  process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio'
                    ? bexioPrivacy[currentLang] || bexioPrivacy.others
                    : bedayaPrivacy[currentLang] || bedayaPrivacy.others
                }
                className={classes.footerLink}
                target="_blank"
              >
                {translate('footer.data-protection-policy')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={
                  process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio'
                    ? bexioGTC[currentLang] || bexioGTC.others
                    : bedayaGTC[currentLang] || bedayaGTC.others
                }
                className={classes.footerLink}
                target="_blank"
              >
                {translate('footer.gtc')}
              </a>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(footerStyle)(withLocalize(Footer));
