import * as jwtJsDecode from 'jwt-js-decode';

import AuthService from '../../services/auth.service';
import { BedayaAction } from '../../types/BedayaAction';
import {
  GET_DEBUG_MODE,
  GET_USER_INFO,
  LOGIN,
  LOGIN_FINISHED,
  LOGOUT,
  PUT_DEBUG_MODE,
  PUT_USER_INFO,
  SET_REDIRECT,
} from '../actions/auth_actions';

export interface IAuthState {
  token: any;
  tokenInfo: any;
  userInfo: any;
  redirectPath: any;
  isFetching: any;
  errors: any;
  isDebugActivated: boolean;
}

const initialState: IAuthState = {
  token: AuthService.token,
  tokenInfo: {
    payload: {
      language: 'de_DE',
    },
  },
  userInfo: {
    // "firstname": "Alexey",
    // "lastname": "Lukin",
    // "email": "alexey.lukin@da-14.com",
    // "companies": [ {"name": "Company - 5e1d975a02b479a7812d778c", "id": "5e1d975a02b479a7812d778c"} ],
    // "language": "de-CH",
    // "availableApps": [ "bexio", "sumup", "shopify", "prestashop", "debitoor", "zoho" ],
    // "privacyPolicies": [{"version": "bexio_1", "confirmed": "1583855368129"}, {"version": "bexio_2", "confirmed": "1583855368129"}]
  },
  redirectPath: '',
  isFetching: false,
  errors: {
    login: '',
    userInfo: '',
  },
  isDebugActivated: false,
};
try {
  if (AuthService.token) {
    initialState.tokenInfo = jwtJsDecode.jwtDecode(AuthService.token) as any;
  }
} catch (e) {
  console.warn(e);
}

export default function reducer(state: IAuthState = initialState, action: BedayaAction): IAuthState {
  switch (action.type) {
    case SET_REDIRECT.REQUEST:
      return {
        ...state,
        redirectPath: action.data,
      };
    case LOGIN.REQUEST:
    case LOGOUT.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_FINISHED.REQUEST:
      return {
        ...state,
        redirectPath: state.redirectPath || '',
      };
    case LOGIN.SUCCESS:
      let tokenInfo = '';
      try {
        tokenInfo = jwtJsDecode.jwtDecode(action.data.token) as any;
      } catch (e) {
        console.error(e);
      }
      return {
        ...state,
        token: action.data.token,
        tokenInfo,
        redirectPath: state.redirectPath || '/admin/apps',
        isFetching: false,
      };
    case LOGIN.FAILURE:
    case LOGOUT.SUCCESS:
    case LOGOUT.FAILURE:
      return {
        ...state,
        token: null,
        isFetching: false,
        userInfo: {},
        errors: {
          ...state.errors,
          login: action.error,
        },
      };

    case PUT_USER_INFO.REQUEST:
    case GET_USER_INFO.REQUEST:
    case GET_DEBUG_MODE.REQUEST:
    case PUT_DEBUG_MODE.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PUT_USER_INFO.SUCCESS:
    case GET_USER_INFO.SUCCESS:
      return {
        ...state,
        isFetching: false,
        userInfo: action.data,
      };
    case PUT_DEBUG_MODE.SUCCESS:
    case GET_DEBUG_MODE.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isDebugActivated: action.data.isDebugActivated,
      };
    case PUT_USER_INFO.FAILURE:
    case GET_USER_INFO.FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          userInfo: action.error,
        },
      };
    case PUT_DEBUG_MODE.FAILURE:
    case GET_DEBUG_MODE.FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          isDebugActivated: action.error,
        },
      };
    default:
      return state;
  }
}
