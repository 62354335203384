import { BedayaAction } from '../../types/BedayaAction';

import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';

export interface IVendState {
  settings: any;

  lastRun: any;
  lastJob: any;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: string;

  error: any;
  isFetching: boolean;
  isFetchingDisconnect: boolean;
}

const initialState: IVendState = {
  settings: {},

  lastRun: null,
  lastJob: null,

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'nook',

  error: '',
  isFetching: false,
  isFetchingDisconnect: false,
};

try {
  const lastRun = localStorage.getItem('vendLastRun');
  const lastJob = localStorage.getItem('vendLastJob');
  initialState.lastRun = lastRun ? JSON.parse(lastRun) : null;
  initialState.lastJob = lastJob ? JSON.parse(lastJob) : null;
} catch (e) {
  console.warn(e);
}

export default function reducer(state: IVendState = initialState, action: BedayaAction): any {
  switch (action.type) {
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case RUN_JOB.SUCCESS:
    case GET_JOB.SUCCESS:
      if (['vend'].includes(action.data.system)) {
        localStorage.setItem('vendLastRun', JSON.stringify(action.data.data));
        localStorage.setItem('vendLastJob', JSON.stringify(action.data.jobNumber));
        return {
          ...state,
          lastRun: action.data.data,
          lastJob: action.data.jobNumber,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case RUN_JOB.FAILURE:
    case GET_JOB.FAILURE:
      if (['vend'].includes(action.data.system)) {
        localStorage.removeItem('vendLastRun');
        localStorage.removeItem('vendLastJob');
        return {
          ...state,
          lastRun: null,
          lastJob: null,
          isFetching: false,
        };
      }
      return state;
    case TEST_CONNECTION.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['vend'].includes(action.data.system)) {
        const status = action.data.status || 'nook';
        localStorage.removeItem('vendLastRun');
        localStorage.removeItem('vendLastJob');
        return {
          ...state,
          connectionStatus: status,
          isFetchingDisconnect: false,
        };
      }
      return state;

    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
    case DISCONNECT.FAILURE:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['vend'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    default:
      return state;
  }
}
