// global imports
import { Grid, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import colourStyles from '../../../../assets/jss/reactSelectStyle';

// project types imports
import { IOptionType } from '../../../../types/ReactSelect';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props } from './types';

const currencies: IOptionType[] = [{ label: 'CHF', value: 'chf' }, { label: 'EUR', value: 'eur' }];

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const ShopCurrency = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, shopCurrencyIso, onChange } = props;

  const [selectedCurrency, setSelectedCurrency] = useState(shopCurrencyIso);

  const handleValueChange = (value: ValueType<IOptionType, false>) => {
    if (value && 'value' in value) {
      setSelectedCurrency(value.value as typeof shopCurrencyIso);
      onChange('shopCurrencyIso', value.value);
    }
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={4}>
        <InputLabel className={classes.pt10}>{translate('prestashop.shop-currency')}</InputLabel>
      </Grid>
      <Grid item={true} xs={8}>
        <Select
          isSearchable={false}
          className={cx(classes.selectSmall, classes.pl0)}
          options={currencies}
          value={(selectedCurrency && currencies.find(curr => curr.value === selectedCurrency)) || currencies[0]}
          onChange={handleValueChange}
          styles={colourStyles}
        />
      </Grid>
    </Grid>
  );
};

export default withLocalize(ShopCurrency);
