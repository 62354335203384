// global imports
import { Accordion, AccordionSummary, CardActions, Divider, makeStyles, Theme } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { StyleRules } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import cx from 'classnames';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useRef } from 'react';

// project imports
import BexioIcon from '../../assets/img/bexio-icon.png';
import BitrixIcon from '../../assets/img/bitrix-icon.png';
import DebitoorIcon from '../../assets/img/debitoor-icon.png';
import ForceManagerIcon from '../../assets/img/forcemanager-icon.png';
import GastrofixIcon from '../../assets/img/gastrofix-icon.png';
import IbelsaIcon from '../../assets/img/ibelsa-logo.png';
import LightspeedIcon from '../../assets/img/lightspeed-icon.png';
import MailchimpIcon from '../../assets/img/mailchimp-icon.png';
import PrestashopIcon from '../../assets/img/prestashop-icon.png';
import ShopifyIcon from '../../assets/img/shopify-icon.png';
import SumupIcon from '../../assets/img/sumup-icon.png';
import SwisscomIcon from '../../assets/img/swisscom-icon.png';
import FlmIcon from '../../assets/img/flm-icon.png';
import VendIcon from '../../assets/img/vend-icon.png';
import WeclappIcon from '../../assets/img/weclapp-icon.png';
import WixIcon from '../../assets/img/wix-icon.png';
import ZohoCrmIcon from '../../assets/img/zoho-crm-icon.png';
import ZohoIcon from '../../assets/img/zoho-icon.png';
import EcwidIcon from '../../assets/img/ecwid-icon.png';
import WooCommerceIcon from '../../assets/img/woocommerce.png';
import BetaOverlay from '../../components/BetaOverlay/BetaOverlay';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';

import { StyledSwitch } from '../../components/StyledSwitch';

// project types imports
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import style from './Apps/styles';
import isAllowed from './isAllowed';

type IVersionedApp = {
  name: string;
  version: number;
};

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

export default function SystemWidget(props: any) {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { translate, appName, onFullScreen, isConfiguratorDisabled } = props;

  const [expanded, setExpanded] = React.useState(false);
  const assistantButton = useRef<HTMLDivElement>();

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  let itemIcon;
  switch (appName) {
    case 'bexio': {
      itemIcon = BexioIcon;
      break;
    }
    case 'debitoor': {
      itemIcon = DebitoorIcon;
      break;
    }
    case 'shopify': {
      itemIcon = ShopifyIcon;
      break;
    }
    case 'sumup': {
      itemIcon = SumupIcon;
      break;
    }
    case 'zoho-crm': {
      itemIcon = ZohoCrmIcon;
      break;
    }
    case 'zoho-books': {
      itemIcon = ZohoIcon;
      break;
    }
    case 'prestashop': {
      itemIcon = PrestashopIcon;
      break;
    }
    case 'vend': {
      itemIcon = VendIcon;
      break;
    }
    case 'weclapp': {
      itemIcon = WeclappIcon;
      break;
    }
    case 'mailchimp': {
      itemIcon = MailchimpIcon;
      break;
    }
    case 'wix': {
      itemIcon = WixIcon;
      break;
    }
    case 'bitrix': {
      itemIcon = BitrixIcon;
      break;
    }
    case 'gastrofix': {
      itemIcon = GastrofixIcon;
      break;
    }
    case 'lightspeed': {
      itemIcon = LightspeedIcon;
      break;
    }
    case 'forcemanager': {
      itemIcon = ForceManagerIcon;
      break;
    }
    case 'ibelsa': {
      itemIcon = IbelsaIcon;
      break;
    }
    case 'lightspeed-r': {
      itemIcon = LightspeedIcon;
      break;
    }
    case 'enfore': {
      itemIcon = SwisscomIcon;
      break;
    }
    case 'xeniapos': {
      itemIcon = FlmIcon;
      break;
    }
    case 'ecwid': {
      itemIcon = EcwidIcon;
      break;
    }
    case 'woocommerce': {
      itemIcon = WooCommerceIcon;
      break;
    }
    default: {
      itemIcon = '';
    }
  }

  const handleChange = (event: any) => {
    if (props.apps && !props.apps.includes(appName)) {
      props.handleSwitchChange(appName)(event);
    }
  };

  const goToSettings = () => {
    props.history.push(`/admin/${appName}-app`);
  };

  const startAssistant = () => {
    setExpanded(true);
    props.history.push(`/admin/apps/${appName}${appName === 'shopify' ? '/2' : ''}`);
    onFullScreen(true);
  };
  const handlePanelExpand = (e: React.ChangeEvent<{}>) => {
    const buttonCurrent = assistantButton.current;
    if (buttonCurrent && (e.target === buttonCurrent || buttonCurrent.contains(e.target as Node))) {
      startAssistant();
    } else {
      setExpanded(expanded);
    }
  };

  const availableApp =
    props.userInfo &&
    props.userInfo.availableApps &&
    props.userInfo.availableApps.find((app: IVersionedApp) => app.name === appName);

  const version = availableApp && availableApp.version;

  return isAllowed(appName, props.userInfo.availableApps) ? (
    <GridItem
      xs={12}
      sm={6}
      lg={4}
      className={cx({
        [classes.smallCard]: true,
        [classes.smallCardExpanded]: expanded,
      })}
    >
      <Accordion
        expanded={expanded}
        onChange={handlePanelExpand}
        className={cx({
          [classes.collapsedPanel]: !expanded,
          [classes.expandedPanel]: expanded,
        })}
      >
        {!expanded && (
          <AccordionSummary
            classes={{
              content: expanded ? classes.summaryExpandedContent : classes.summaryCollapsedContent,
            }}
            className={expanded ? classes.summaryExpanded : classes.summaryCollapsed}
          >
            <Card
              className={cx({
                [classes.smallCard]: true,
                [classes.summaryExpandedContent]: expanded,
                [classes.summaryCollapsedContent]: !expanded,
              })}
            >
              <CardHeader className={classes.cardHeader}>
                <img className={classes.itemIcon} src={itemIcon} alt="" />
                <CardActions className={classes.overflow}>
                  <label className={classes.cardTitle}>{translate(`${appName}_app_name`)}</label>
                  {availableApp && version > 0 && version < 1 && <BetaOverlay isBeta={true} />}
                  {availableApp && version === 0 && <BetaOverlay />}
                  {availableApp && version > 0 && (
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {popupState => (
                        <div className={classes.switch}>
                          <StyledSwitch
                            checked={props.apps ? props.apps.includes(appName) : false}
                            onChange={handleChange}
                            value={appName}
                            {...(props.apps && props.apps.includes(appName) && bindTrigger(popupState))}
                          />
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.confirmationContainer}>
                              <h5 className={classes.confirmationHeading}>
                                {translate('disable_confirmation', { appName: translate(`${appName}_app_name`) })}
                              </h5>
                              <div className={classes.buttonsContainer}>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={(event: any) => {
                                    props.handleSwitchChange(appName)(event);
                                    props.disconnectRequest({ system: appName });
                                    popupState.close();
                                  }}
                                >
                                  {translate('yes')}
                                </Button>
                                <Button color="info" size="sm" onClick={popupState.close}>
                                  {translate('no')}
                                </Button>
                              </div>
                            </div>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  )}
                </CardActions>
              </CardHeader>
              {props.apps && props.apps.includes(appName) && (
                <>
                  <Divider />
                  <CardActions className={classes.overflowActions}>
                    <Button link={true} onClick={goToSettings} className={cx(classes.linkButton, classes.smallButton)}>
                      {translate('go-to-settings')}
                    </Button>
                    {availableApp.category !== 'erp' && !isConfiguratorDisabled && (
                      <>
                        <Divider className={classes.verticalDivider} />
                        <MuiButton
                          buttonRef={assistantButton}
                          onClick={startAssistant}
                          className={cx({
                            [classes.button]: true,
                            [classes.customButtonStyles]: true,
                          })}
                        >
                          <PlayCircleOutlineIcon />
                          {translate('start-configurator')}
                        </MuiButton>
                      </>
                    )}
                  </CardActions>
                </>
              )}
            </Card>
          </AccordionSummary>
        )}
      </Accordion>
    </GridItem>
  ) : (
    <></>
  );
}
