// global imports
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioContactType, BexioSettings } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';

// local imports
import { Person } from '@material-ui/icons';
import Search from '../../../Search/Search';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const CustomerSearch: React.FC<Props> = props => {
  const {
    translate,
    bexioSettings,
    connectionStatus,
    bexioCustomerFetchResult,
    bexioCustomersSearchResult,
    getCustomerRequest,
    putSettingsRequest,
    searchCustomerRequest,
  } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [customersList, setCustomersList] = useState<IOptionType[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<IOptionType | null>(null);

  useEffect(() => {
    if (bexioSettings && connectionStatus === 'ok') {
      searchCustomerRequest({ searchTerm: '' });
    }
  }, [bexioSettings, connectionStatus]);

  // convert customer search result for react-select
  useEffect(() => {
    if (bexioCustomerFetchResult && !selectedCustomer) {
      setSelectedCustomer({
        value: bexioCustomerFetchResult.id,
        label:
          bexioCustomerFetchResult.name +
          (bexioCustomerFetchResult.firstname ? ' ' + bexioCustomerFetchResult.firstname : ''),
      });
    }
    if (bexioCustomersSearchResult) {
      setCustomersList(
        bexioCustomersSearchResult.map((account: BexioContactType) => ({
          value: account.id,
          label: account.name + (account.firstname ? ' ' + account.firstname : ''),
        })),
      );
    }
  }, [bexioCustomersSearchResult, bexioCustomerFetchResult]);

  // set settings locally
  useEffect(() => {
    if (bexioSettings && connectionStatus === 'ok') {
      const newSettings = { ...cloneDeep(bexioSettings) };
      // fetch actual data from id
      if (newSettings.defaultCustomerId) {
        getCustomerRequest({ customerId: newSettings.defaultCustomerId });
      }
      setSettings(newSettings);
    }
  }, [bexioSettings, connectionStatus]);

  // save settings to backend
  useEffect(() => {
    if (bexioSettings && settings && !isEqual(bexioSettings, settings)) {
      putSettingsRequest({ system: 'bexio', data: settings });
    }
  }, [settings]);

  const searchCustomers = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3 || newSearchTerm.length === 0) {
      props.searchCustomerRequest({ searchTerm: newSearchTerm });
    }
  };

  const searchCustomerChange = (value: IOptionType) => {
    setSelectedCustomer(value);
    if (settings) {
      setSettings({
        ...settings,
        defaultCustomerId: parseInt(value.value, 10),
      });
    }
  };

  const clearTerm = () => {
    setSearchTerm('');
  };

  return (
    <>
      {settings && (
        <Search
          data={customersList}
          placeholder={translate('select_prompt') as string}
          resultClicked={searchCustomerChange}
          termChange={searchCustomers}
          currentValue={selectedCustomer}
          searchTerm={searchTerm}
          icon={<Person />}
          clearTerm={clearTerm}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
  connectionStatus: state.bexio.connectionStatus,
  bexioCustomerFetchResult: state.bexio.customerFetchResult,
  bexioCustomersSearchResult: state.bexio.customersSearchResult,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  getCustomerRequest: bexioActions.getCustomerRequest,
  searchCustomerRequest: bexioActions.searchCustomerRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(CustomerSearch));
