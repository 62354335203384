import { useState, useEffect, useCallback } from 'react';
import * as bexioApi from '../../../store/api/bexio';
import { Loading, ReminderSubscriptionState, ReminderStatisticsState, ReminderCheckoutState } from './types.d';

export function useReminderSubscriptionState(): [Loading, ReminderSubscriptionState | null] {
  const [loading, setLoading] = useState<Loading>('initial');
  const [data, setData] = useState<ReminderSubscriptionState | null>(null);
  useEffect(() => {
    setLoading('pending');
    setData(null);
    bexioApi.getBexioReminderSubscription()
      .then(response => {
        setData(response.data as ReminderSubscriptionState);
        setLoading('ready');
      })
      .catch(err => {
        console.log(err);
        setLoading('error');
      });
  }, []);
  return [loading, data];
}

export function useReminderStatisticsState(): [Loading, ReminderStatisticsState | null] {
  const [loading, setLoading] = useState<Loading>('initial');
  const [data, setData] = useState<ReminderStatisticsState | null>(null);
  useEffect(() => {
    setLoading('pending');
    setData(null);
    bexioApi.getBexioReminderStatistics()
      .then(response => {
        setData(response.data as ReminderStatisticsState);
        setLoading('ready');
      })
      .catch(err => {
        console.log(err);
        setLoading('error');
      });
  }, []);
  return [loading, data];
}

export function useReminderCheckout() {
  const [loading, setLoading] = useState<Loading>('initial');
  const [data, setData] = useState<ReminderCheckoutState | null>(null);
  const request = useCallback((plan: string, returnUrl: string) => {
    setLoading('pending');
    setData(null);
    bexioApi.postBexioReminderSubscription(plan, returnUrl)
      .then(response => {
        setData(response.data as ReminderCheckoutState);
        setLoading('ready');
      })
      .catch(err => {
        console.log(err);
        setLoading('error');
      });
  }, []);
  return { request, response: { loading, data } };
}
