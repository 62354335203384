// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
import * as authActions from '../../../../store/actions/auth_actions';
import * as shopifyActions from '../../../../store/actions/shopify_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const ImportOrders: React.FC<Props & typeof defaultProps> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    OAuthURL,
    testConnection,
    testConnectionTimeout,
    connectionStatus,
    fetchOAuthUrlRequest,
    importInvoicesRequest,
    testConnectionForShopRequest,
    shopifyShops,
  } = props;

  const [testDelayActive, setTestDelayActive] = useState<any>(false);

  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, 'days')
      .toDate(),
  );

  // didMount fetch data
  useEffect(() => {
    if (testConnection) {
      if (testConnectionTimeout) {
        setTestDelayActive(true);
      }
      setTimeout(() => {
        const shop = shopifyShops.find(s => s.active);
        if (shop) {
          testConnectionForShopRequest({ shop });
        }
        if (!OAuthURL) {
          fetchOAuthUrlRequest({ system: 'shopify' });
        }
        setTestDelayActive(false);
      }, testConnectionTimeout);
    }
  }, []);

  // test connection status
  useEffect(() => {
    if (connectionStatus) {
      if (connectionStatus === 'nok' || connectionStatus === 'nook') {
        fetchOAuthUrlRequest({ system: 'shopify' });
      }
    }
  }, [connectionStatus]);

  const importInvoices = () => {
    const shop = shopifyShops.find(s => s.active);
    if (shop) {
      importInvoicesRequest({ from: startDate, to: endDate, shop: shop.name });
    }
  };

  const DateSelectBlock = () => (
    <div
      className={cx({
        [classes.selectSmall]: true,
        [classes.contentContainer]: true,
        [classes.flexCenter]: true,
        [classes.widthAuto]: true,
      })}
    >
      <label className={classes.datepickerLabel}>{translate('from')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart={true}
        startDate={startDate}
        endDate={endDate}
      />
      <br />
      <label className={classes.datepickerLabel}>{translate('to')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd={true}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );

  return (
    <>
      {testDelayActive || connectionStatus !== 'loading'
        ? connectionStatus === 'ok' && (
            <>
              <p className={classes.bodyLg}>{translate('import_orders')}</p>
              <div
                className={cx(classes.runLabel, {
                  [classes.flexCenter]: !props.shopifyLastRun || props.shopifyLastRun.exitState !== 'error',
                })}
              >
                <DateSelectBlock />
                <Button className={classes.formButton} type="button" color="primary" onClick={importInvoices}>
                  {translate('run.run')}
                </Button>
              </div>
              {props.shopifyImportOrdersStatus === 'loading' && <CircularProgress />}
              {props.shopifyImportOrdersStatus === 'ok' && (
                <p className={classes.iconGreen}>
                  {translate('successfully_imported', undefined, { renderInnerHtml: true })}
                </p>
              )}
              {props.shopifyImportOrdersStatus === 'failed' && (
                <p className={classes.iconRed}>{translate('unsuccessfully_imported')}</p>
              )}
            </>
          )
        : props.showSpinner && <CircularProgress />}
    </>
  );
};

const defaultProps: OwnProps = {
  testConnection: false,
  testConnectionTimeout: 3000,
  showSpinner: false,
};
ImportOrders.defaultProps = defaultProps;

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: state.shopify.OAuthURL,
  shopifyLastRun: state.shopify.lastRun,
  shopifyLastJob: state.shopify.lastJob,
  connectionStatus: state.shopify.connectionStatus,
  shopifyImportOrdersStatus: state.shopify.importOrdersStatus,
  shopifyShops: state.shopify.shops,
});

const mapDispatchToProps = {
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  importInvoicesRequest: shopifyActions.importInvoicesRequest,
  testConnectionForShopRequest: shopifyActions.testConnectionForShopRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ImportOrders));
