import { ActionPattern, all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  GET_CANONICAL_FIELDS,
  GET_STRIPE_PORTAL,
  getCanonicalFieldsFailure,
  getCanonicalFieldsSuccess,
  getStripePortalFailure,
  getStripePortalSuccess,
  POST_STRIPE_CUSTOMER,
  POST_STRIPE_SUBSCRIPTION,
  postStripeCustomerFailure,
  postStripeCustomerSuccess,
  postStripeSubscriptionFailure,
  postStripeSubscriptionSuccess,
  USER_HAS_SUBSCRIPTION,
  USER_SEARCH_SUBSCRIPTION_PRODUCT,
  userHasSubscriptionFailure,
  userHasSubscriptionSuccess,
  userSearchSubscriptionProductFailure,
  userSearchSubscriptionProductSuccess,
  POST_STRIPE_CHECKOUT,
  postStripeCheckoutSuccess,
  postStripeCheckoutFailure,
  GET_NAMED_KEY_VALUES,
  POST_NAMED_KEY_VALUE,
  DELETE_NAMED_KEY_VALUE,
  getNamedKeyValuesSuccess,
  getNamedKeyValuesFailure,
  postNamedKeyValueSuccess,
  postNamedKeyValueFailure,
  deleteNamedKeyValueSuccess,
  deleteNamedKeyValueFailure,
} from './../actions/commons_actions';

import { BedayaAction } from '../../types/BedayaAction';
import {
  getStripePortal,
  hasSubscription,
  postCustomer,
  postSubscription,
  searchSubscriptionProduct,
  postStripeCheckout,
  getNamedKeyValues,
  postNamedKeyValue,
  deleteNamedKeyValue,
} from '../api/user';
import { getCanonicalFields } from './../api/commons';

function* getCanonicalFieldsSaga(action: BedayaAction) {
  // yield delay(500);
  try {
    const { data } = yield call(getCanonicalFields);
    yield put(getCanonicalFieldsSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getCanonicalFieldsFailure(processedError || error));
  }
}

function* postStripeCustomerSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postCustomer, action.data);
    yield put(postStripeCustomerSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postStripeCustomerFailure(processedError || error));
  }
}

function* postStripeSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postSubscription, action.data);
    yield put(postStripeSubscriptionSuccess(data));
  } catch (error) {
    yield put(postStripeSubscriptionFailure(error));
  }
}

function* getStripePortalSaga() {
  try {
    const { data } = yield call(getStripePortal);
    yield put(getStripePortalSuccess(data));
  } catch (error) {
    yield put(getStripePortalFailure(error));
  }
}

function* userHasSubscriptionSaga(action: BedayaAction) {
  try {
    const { data } = yield call(hasSubscription);
    yield put(userHasSubscriptionSuccess(data));
  } catch (error) {
    yield put(userHasSubscriptionFailure(error));
  }
}

function* userSearchSubscriptionProductSaga(action: BedayaAction) {
  try {
    const { data } = yield call(searchSubscriptionProduct);
    yield put(userSearchSubscriptionProductSuccess(data));
  } catch (error) {
    yield put(userSearchSubscriptionProductFailure(error));
  }
}

function* postStripeCheckoutSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postStripeCheckout, action.data);
    yield put(postStripeCheckoutSuccess(data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postStripeCheckoutFailure(processedError || error));
  }
}

function* getNamedKeyValuesSaga(action: BedayaAction) {
  try {
    const { data } = yield call(getNamedKeyValues, action.data.name);
    yield put(getNamedKeyValuesSuccess(action.data.name, data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(getNamedKeyValuesFailure(action.data.name, processedError || error));
  }
}

function* postNamedKeyValueSaga(action: BedayaAction) {
  try {
    const { data } = yield call(postNamedKeyValue, action.data.name, action.data.data.key, action.data.data.value);
    yield put(postNamedKeyValueSuccess(action.data.name, data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(postNamedKeyValueFailure(action.data.name, processedError || error));
  }
}

function* deleteNamedKeyValueSaga(action: BedayaAction) {
  try {
    yield call(deleteNamedKeyValue, action.data.name, action.data.data.key);
    yield put(deleteNamedKeyValueSuccess(action.data.name, action.data.data));
  } catch (error) {
    let processedError;
    try {
      processedError = JSON.parse(error.message);
    } catch (e) {
      console.error(e);
    }
    yield put(deleteNamedKeyValueFailure(action.data.name, processedError || error));
  }
}

function* getCanonicalFieldsWatcher() {
  yield takeLatest(GET_CANONICAL_FIELDS.REQUEST as ActionPattern, getCanonicalFieldsSaga);
}

function* postStripeCustomerWatcher() {
  yield takeLatest(POST_STRIPE_CUSTOMER.REQUEST as ActionPattern, postStripeCustomerSaga);
}

function* postStripeSubscriptionWatcher() {
  yield takeLatest(POST_STRIPE_SUBSCRIPTION.REQUEST as ActionPattern, postStripeSubscriptionSaga);
}

function* getStripePortalWatcher() {
  yield takeLatest(GET_STRIPE_PORTAL.REQUEST as ActionPattern, getStripePortalSaga);
}

function* userHasSubscriptionWatcher() {
  yield takeLatest(USER_HAS_SUBSCRIPTION.REQUEST as ActionPattern, userHasSubscriptionSaga);
}

function* userSearchSubscriptionProductWatcher() {
  yield takeLatest(USER_SEARCH_SUBSCRIPTION_PRODUCT.REQUEST as ActionPattern, userSearchSubscriptionProductSaga);
}

function* postStripeCheckoutWatcher() {
  yield takeLatest(POST_STRIPE_CHECKOUT.REQUEST as ActionPattern, postStripeCheckoutSaga);
}

function* getNamedKeyValuesWatcher() {
  yield takeLatest(GET_NAMED_KEY_VALUES.REQUEST as ActionPattern, getNamedKeyValuesSaga);
}

function* postNamedKeyValueWatcher() {
  yield takeLatest(POST_NAMED_KEY_VALUE.REQUEST as ActionPattern, postNamedKeyValueSaga);
}

function* deleteNamedKeyValueWatcher() {
  yield takeLatest(DELETE_NAMED_KEY_VALUE.REQUEST as ActionPattern, deleteNamedKeyValueSaga);
}

export default function* commonsSaga() {
  yield all([
    fork(getCanonicalFieldsWatcher),
    fork(postStripeCustomerWatcher),
    fork(postStripeSubscriptionWatcher),
    fork(getStripePortalWatcher),
    fork(userHasSubscriptionWatcher),
    fork(userSearchSubscriptionProductWatcher),
    fork(postStripeCheckoutWatcher),
    fork(getNamedKeyValuesWatcher),
    fork(postNamedKeyValueWatcher),
    fork(deleteNamedKeyValueWatcher),
  ]);
}
