import { StyleRules } from '@material-ui/core/styles';

import { whiteColor } from './material-dashboard-pro-react';

const commonStyles = {
  iconRed: {
    color: 'red',
    '& a': {
      color: 'red',
      textDecoration: 'underline',
    },
  },
  iconGreen: {
    color: 'green',
    '& a': {
      color: 'green',
      textDecoration: 'underline',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  itemIcon: {
    color: 'inherit',
    height: '24px',
    width: '24px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: '0.8',
    objectFit: 'contain',
  },
  switch: {
    width: '58px',
  },
  checked: {
    color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
  },
  a: {
    color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
    '&:hover,&:focus': {
      color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#66bb6a !important' : '#fa6100 !important',
    },
  },
  contentLarge: {
    width: '75%',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 'bold',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  confirmationHeading: {
    fontSize: '16px',
    marginTop: '0',
  },
  addButton: {
    width: '150px',
    marginLeft: '200px',
  },
  addButton1: {
    width: '150px',
    marginLeft: '300px',
  },
  selectSmall: {
    width: '25%',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'left',
  },
  selectSmallFixed: {
    width: '200px',
    maxWidth: '100%',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'left',
    boxSizing: 'border-box',
  },
  selectSmallVmiddle: {
    width: '25%',
    display: 'inline-block',
    padding: '0px 5px',
    textAlign: 'left',
    lineHeight: '38px',
  },
  selectSmaller: {
    width: '12%',
    display: 'inline-block',
    padding: '0 5px',
  },
  selectSmallerVmiddle: {
    width: '12%',
    display: 'inline-block',
    padding: '0 5px',
    lineHeight: '38px',
  },
  selectSmallerCentered: {
    width: '12%',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  selectSmallerCenteredFixed: {
    width: '100px',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  selectBiggerCenteredFixed: {
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  widthAuto: {
    width: 'auto !important',
  },
  spacedIcon: {
    padding: '0 10px',
    width: '20px !important',
    height: '20px !important',
  },
  runLabel: {
    whiteSpace: 'nowrap',
    width: '50%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  linkStyle: {
    padding: '0 15px',
  },
  linkInline: {
    color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
    display: 'inline-block',
    '&:hover,&:focus': {
      color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#66bb6a !important' : '#fa6100 !important',
    },
  },
  runStatusContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 10px',
    fontSize: '16px',
  },
  flexCenter: {
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  textLabel: {
    padding: '0 10px',
    fontSize: '16px',
    width: '50px',
    display: 'inline-block',
    verticalAlign: 'super',
  },
  datepickerLabel: {
    padding: '0 10px 0 0',
    fontSize: '16px',
    width: 'auto',
    display: 'inline-block',
  },
  inputStyle: {
    padding: '2px 5px',
    fontSize: '14px',
    width: '100px',
    float: 'right',
  },
  selectSmallCentered: {
    width: '25%',
    display: 'inline-block',
    padding: '0 5px',
    textAlign: 'center',

    '& *': {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
  },
  formButton: {
    padding: '9.5px 30px',
  },
  contentContainer: {
    display: 'flex !important',
  },
  errorOutline: {
    outline: '1px solid red',
  },
  errorBLeft: {
    borderLeft: '3px #e74c3c solid',
  },
  labelSmall: {
    display: 'inline-block',
    width: '90px',
    padding: '0 5px',
  },
  bodyLg: {
    display: 'block',
    fontSize: '16px',
  },
  boldTitle: {
    fontWeight: 400,
  },
  bodyText: {
    fontSize: '14px',
  },
  connectRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  note: {
    fontFamily: '"Lato", "Roboto", "Helvetica", "Arial", sans-serif',
    bottom: '10px',
    color: '#c0c1c2',
    display: 'block',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '13px',
    left: '0',
    marginLeft: '20px',
    width: '260px',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '500',
  },
  debAccounts: {
    display: 'block',
    width: '75%',
    boxSizing: 'border-box',
    textAlign: 'left',

    '& > div': {
      textAlign: 'left',

      '@media (min-width: 1200px)': {
        width: 'auto !important',
        margin: '0',
        transform: 'none',
        display: 'inline-block',
        position: 'relative',
      },

      '& label': {
        margin: 0,
      },

      '& > div:first-child > div:first-child > label': {
        boxSizing: 'border-box',
        width: '200px',

        '&:last-child': {
          width: '100px',
        },
      },

      '& > div': {
        width: '555px',
      },
      '& > button': {
        width: '150px',
        display: 'block',
        margin: '5px auto',
        height: 'auto',
        paddingTop: '7px',
        paddingBottom: '7px',

        '& > span': {
          display: 'inline-block',
          lineHeight: '15px',

          '& > svg': {
            display: 'inline',
          },
        },
      },
    },
  },
  bankAccounts: {
    display: 'block',
    width: '45%',
    boxSizing: 'border-box',
    textAlign: 'left',

    '& > div': {
      textAlign: 'left',

      '@media (min-width: 1200px)': {
        width: 'auto !important',
        margin: '0',
        transform: 'none',
        position: 'relative',
      },

      '& label': {
        margin: 0,
      },

      '& > div:first-child > div:first-child > label': {
        boxSizing: 'border-box',
        width: '200px',

        '&:last-child': {
          width: '100px',
        },
      },

      '& > div': {
        width: '555px',
      },
      '& > button': {
        width: '150px',
        display: 'block',
        margin: '5px auto',
        height: 'auto',
        paddingTop: '7px',
        paddingBottom: '7px',

        '& > span': {
          display: 'inline-block',
          lineHeight: '15px',

          '& > svg': {
            display: 'inline',
          },
        },
      },
    },
  },
  bexioVat: {
    '& label': {
      margin: 0,
    },
  },
  connected: {
    color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
  },
  connectedContainer: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  connectedStatus: {
    marginRight: '20px',
    display: 'inline-block',
    color: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50 !important' : '#e55100 !important',
    border: 'none',
    margin: '5px 1px',
    padding: '10px 20px',
    minWidth: 'auto',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '1.4',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    letterSpacing: '0.2ox !important',
    textTransform: 'none',
    boxShadow: '0 4px 21px rgba(0, 0, 0, 0.09)',
    '&:before': {
      content: '" "',
      display: 'inline-block',
      position: 'relative',
      marginRight: '10px',
      height: '11px',
      width: '5px',
      transform: 'rotate(45deg)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: process.env.REACT_APP_ACTIVE_DOMAIN === 'bexio' ? '#4caf50' : '#e55100',
      borderTopWidth: '0px',
      borderLeftWidth: '0px',
    },
  },
  disconnect: {
    color: '#d60000',
    cursor: 'pointer',
    position: 'relative',
    paddingLeft: '15px',
    display: 'inline-block',
    fontWeight: '500',
    transition: 'all .2s ease',
    '&:before': {
      content: '" "',
      display: 'inline-block',
      position: 'absolute',
      left: '0',
      top: '50%',
      marginRight: '10px',
      height: '11px',
      width: '2px',
      transform: 'rotate(45deg) translateY(-50%)',
      backgroundColor: '#d60000',
      transition: 'all .2s ease',
      marginTop: '-2px',
    },
    '&:after': {
      content: '" "',
      display: 'inline-block',
      position: 'absolute',
      left: '7.5px',
      top: '50%',
      marginRight: '10px',
      height: '11px',
      width: '2px',
      transform: 'rotate(-45deg) translateY(-50%)',
      backgroundColor: '#d60000',
      transition: 'all .2s ease',
      marginTop: '-2px',
    },
    '&:hover, &:active, &:focus': {
      color: 'red',
      '&:after, &:before': {
        backgroundColor: 'red',
      },
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  blockAlignCenter: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  w100: {
    width: '100%',
  },
  selectW50: {
    width: '49%',
    display: 'inline-block',
    paddingLeft: 5,
  },
  m0: {
    margin: '0',
  },
  mb0: {
    marginBottom: '0 !important',
  },
  mb10: {
    marginBottom: '10px',
  },
  mt10: {
    marginTop: '10px',
  },
  mt20: {
    marginTop: '20px',
  },
  mr20: {
    marginRight: '20px',
  },
  pt2: {
    paddingTop: '2px',
  },
  pt10: {
    paddingTop: '10px',
  },
  pl0: {
    paddingLeft: '0px',
  },
  pl5: {
    paddingLeft: '5px',
  },
  w45: {
    width: '45% !important',
  },
  ul: {
    paddingLeft: '15px',
  },
  radio: {
    flexDirection: 'row',
  },
  whiteColor: {
    color: whiteColor,
  },
  alignItemsCenter: {
    alignItems: 'center',
    display: 'flex',
  },
};

export default commonStyles as StyleRules;
