import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store/reducers';
import { PlanzerSettings } from '../../types/PlanzerSettings';
import * as commonActions from '../../store/actions/common_actions';
import * as planzerActions from '../../store/actions/planzer_actions';

export function usePlanzerSettings(): [boolean, boolean, () => any] {
  const dispatch = useDispatch();

  const planzerSettings = useSelector<IState, PlanzerSettings>(state => state.planzer.settings);
  const hasSubscription = useSelector<IState, boolean>(state => !!state.planzer.hasSubscription);
  const checkoutUrl = useSelector<IState, string>(state => state.planzer.checkoutUrl);

  const [planzerEnabled, setPlanzerEnabled] = React.useState<boolean>(false);
  React.useEffect(() => {
    dispatch(commonActions.getSettingsRequest({ system: 'planzer' }));
    dispatch(planzerActions.hasSubscriptionRequest());
  }, []);
  React.useEffect(() => {
    setPlanzerEnabled(
      !!(planzerSettings && planzerSettings.customerNumber && planzerSettings.account && planzerSettings.department),
    );
  }, [planzerSettings]);

  React.useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  const checkout = () => dispatch(planzerActions.postCheckoutRequest());
  return [planzerEnabled, hasSubscription, checkout];
}
