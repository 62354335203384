// global imports
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

// project imports
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioProduct } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';

// local imports
import { LocalPostOffice } from '@material-ui/icons';
import Search from '../../../Search/Search';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const WrappingProductSearch: React.FC<Props> = props => {
  const {
    translate,
    getProductRequest,
    bexioSettings,
    connectionStatus,
    bexioProductFetchResult,
    bexioProductSearchResult,
    putSettingsRequest,
  } = props;

  const [productList, setProductList] = useState<IOptionType[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<IOptionType | null>(null);

  useEffect(() => {
    if (bexioSettings && connectionStatus === 'ok') {
      if (bexioSettings.wrappingProductId && bexioSettings.wrappingProductId > 0) {
        getProductRequest({ productId: bexioSettings.wrappingProductId });
      }
      props.searchProductRequest({ searchTerm: '' });
    }
  }, [bexioSettings, getProductRequest, connectionStatus]);

  // convert product search result for react-select
  useEffect(() => {
    if (bexioSettings && bexioProductFetchResult) {
      const updatedSettings = {
        ...bexioSettings,
        wrappingProductId: bexioProductFetchResult.id,
      };
      if (bexioSettings.wrappingProductId !== updatedSettings.wrappingProductId) {
        putSettingsRequest({ system: 'bexio', data: updatedSettings });
      }
      setSelectedProduct({
        label: bexioProductFetchResult.name,
        value: bexioProductFetchResult.id,
      });
    }
    if (bexioProductSearchResult) {
      setProductList(
        bexioProductSearchResult.map((product: BexioProduct) => ({
          value: product.id,
          label: product.name,
        })),
      );
    }
  }, [
    setProductList,
    setSelectedProduct,
    putSettingsRequest,
    bexioSettings,
    bexioProductSearchResult,
    bexioProductFetchResult,
  ]);

  const searchProduct = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3 || newSearchTerm.length === 0) {
      props.searchProductRequest({ searchTerm: newSearchTerm });
    }
  };

  const searchProductChange = (value: IOptionType) => {
    setSelectedProduct(value);
    props.getProductRequest({ productId: value.value });
  };

  const clearTerm = () => {
    setSearchTerm('');
  };

  return (
    <Search
      data={productList}
      placeholder={translate('select_prompt') as string}
      resultClicked={searchProductChange}
      termChange={searchProduct}
      currentValue={selectedProduct}
      searchTerm={searchTerm}
      icon={<LocalPostOffice/>}
      clearTerm={clearTerm}
    />
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
  bexioProductFetchResult: state.bexio.wrappingProductFetchResult,
  bexioProductSearchResult: state.bexio.wrappingProductSearchResult,
  connectionStatus: state.bexio.connectionStatus,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  searchProductRequest: bexioActions.searchWrappingProductRequest,
  getProductRequest: bexioActions.getWrappingProductRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(WrappingProductSearch));
