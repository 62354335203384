import { Button } from '@material-ui/core';
import React from 'react';

export default function CustomButton(props: any) {
  return (
    <Button
      {...props}
      style={{
        boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.23913), 0px 1px 1px rgba(0, 0, 0, 0.0869565)',
        borderRadius: 2,
        backgroundColor: 'white',
        padding: '8px 30px',
        marginTop: 5,
      }}
    >
      {props.children}
    </Button>
  );
}
