import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'APPS';
export const GET_APPS = createRequestTypes(`${filePrefix}_GET_APPS`);
export const PUT_APPS = createRequestTypes(`${filePrefix}_PUT_APPS`);

export const getAppsRequest = () => ({
  type: GET_APPS.REQUEST,
});
export const getAppsSuccess = (data: any) => ({
  type: GET_APPS.SUCCESS,
  data,
});
export const getAppsFailure = (error: any) => ({
  type: GET_APPS.FAILURE,
  error,
});

export const putAppsRequest = (data: any) => ({
  type: PUT_APPS.REQUEST,
  data,
});
export const putAppsSuccess = (data: any) => ({
  type: PUT_APPS.SUCCESS,
  data,
});
export const putAppsFailure = (error: any) => ({
  type: PUT_APPS.FAILURE,
  error,
});
