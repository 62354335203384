import commonStyles from '../../../../assets/jss/commonStyles';
import { config } from '../../../../config';

const styles = {
  ...commonStyles,
  iconStyle: {
    verticalAlign: 'bottom',
  },
  contentContainer: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  checked: {
    color: config.activeDomain === 'bexio' ? '#4caf50 !important' : '#e91e63 !important',
  },
  typo: {
    paddingLeft: '25%',
    marginBottom: '40px',
  },
  selectSmall: {
    width: '15%',
  },
  selectSmallVmiddle: {
    width: '20%',
    lineHeight: '38px',
  },
  selectSmallPtop: {
    width: '15%',
    paddingTop: '5px',
  },
};

export default styles;
