import React from 'react';
import { Grid } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { IOptionType } from '../../../types/ReactSelect';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import { StandardPosition } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';

const languageOptions: IOptionType[] = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
];

type StandardPositionSectionProps = {
  enabled: boolean;
  settings: StandardPosition;
  setSettings: (data: StandardPosition) => void;
} & LocalizeContextProps;

function StandardPositionSection(props: StandardPositionSectionProps) {
  const {settings, enabled, translate, setSettings} = props;
  const classes = useDefaultStyles();
  const [language, setLanguage] = React.useState<string | undefined>(undefined);
  return (
    <Grid container={true} style={{marginTop: 10, marginBottom: 10}}>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center',  paddingTop: 5, paddingBottom: 5, }}>
        {translate('reminders.levels.language')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{ paddingTop: 5, paddingBottom: 5 }}>
        <Select
          isSearchable={false}
          options={languageOptions}
          value={languageOptions.find(opt => opt.value === language)}
          isDisabled={!enabled}
          onChange={(value: ValueType<IOptionType, false>) => {
            if (value) {
              setLanguage(value.value);
              if (!settings.text.find(x => x.language === value?.value)) {
                setSettings({...settings, text: [...settings.text, {
                  text: '',
                  language: value.value,
                }]});
              }
            }
          }}
          styles={colourStyles}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5, }}>
        {translate('reminders.levels.reminderCosts.standardPosition.text')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{ paddingTop: 5, paddingBottom: 5 }}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.reminderCosts.standardPosition.text') as string}
          value={settings.text.find(it => it.language === language)?.text}
          disabled={!enabled || language === undefined}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, text: settings.text.map(x => x.language !== language ? x : ({...x, text: value}))})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5, }}>
        {translate('reminders.levels.reminderCosts.standardPosition.price')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{ paddingTop: 5, paddingBottom: 5 }}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.reminderCosts.standardPosition.price') as string}
          value={settings.price}
          disabled={!enabled}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, price: value})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5, }}>
        {translate('reminders.levels.reminderCosts.standardPosition.vat')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{ paddingTop: 5, paddingBottom: 5 }}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.reminderCosts.standardPosition.vat') as string}
          value={settings.vat}
          disabled={!enabled}
          className={classes.w100}
          style={{marginBottom: 10}}
          onChange={(value: string) => setSettings({...settings, vat: value})}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(StandardPositionSection);
