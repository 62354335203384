// global imports
import { makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

// project imports
import * as bexioActions from '../../../../store/actions/bexio_actions';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { BexioContactType, BexioSettings } from '../../../../types/BexioSettings';
import { IOptionType } from '../../../../types/ReactSelect';

// local imports
import colourStyles from '../../../../assets/jss/reactSelectStyle';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const InvoiceOrderOwnerSearch: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { bexioSettings, connectionStatus, bexioUsersFetchResult, getUsersRequest, putSettingsRequest } = props;

  const [settings, setSettings] = useState<BexioSettings | null>(null);
  const [usersList, setUsersList] = useState<IOptionType[]>([]);
  const [selectedUser, setSelectedUser] = useState<IOptionType | null>(null);

  useEffect(() => {
    if (bexioSettings && connectionStatus === 'ok') {
      getUsersRequest({});
    }
  }, [bexioSettings, connectionStatus]);

  useEffect(() => {
    if (bexioUsersFetchResult) {
      const settingsInvoiceUserId = bexioSettings && bexioSettings.invoiceUserId;
      const newSelectUser = bexioUsersFetchResult.find((item: any) => item.id === settingsInvoiceUserId) || {};
      setSelectedUser({
        value: newSelectUser.id,
        label: newSelectUser.name,
      });
      setUsersList(
        bexioUsersFetchResult.map((account: BexioContactType) => ({
          value: account.id,
          label: account.name,
        })),
      );
    }
  }, [bexioUsersFetchResult]);

  useEffect(() => {
    if (bexioSettings && connectionStatus === 'ok') {
      const newSettings = { ...cloneDeep(bexioSettings) };
      setSettings(newSettings);
    }
  }, [bexioSettings, connectionStatus]);

  // save settings to backend
  useEffect(() => {
    if (bexioSettings && settings && !isEqual(bexioSettings, settings)) {
      putSettingsRequest({ system: 'bexio', data: settings });
    }
  }, [settings]);

  const selectedUserChange = (value: IOptionType) => {
    setSelectedUser(value);
    if (settings) {
      setSettings({
        ...settings,
        invoiceUserId: parseInt(value.value, 10),
      });
    }
  };

  return (
    <>
      {settings && (
        <div className={classes.selectSmall}>
          <Select
            isSearchable={false}
            options={usersList}
            value={selectedUser}
            onChange={(value: ValueType<IOptionType, false>) => selectedUserChange(value as IOptionType)}
            styles={colourStyles}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  bexioSettings: state.bexio.settings,
  connectionStatus: state.bexio.connectionStatus,
  bexioUsersFetchResult: state.bexio.usersFetchResult,
});

const mapDispatchToProps = {
  putSettingsRequest: commonActions.putSettingsRequest,
  getUsersRequest: bexioActions.getUsersRequest,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(InvoiceOrderOwnerSearch));
