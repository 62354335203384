import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project types imports
import { IState } from '../../../../store/reducers';
import { WixSettings } from '../../../../types/WixSettings';

// local imports
import { putSettingsRequest } from '../../../../store/actions/common_actions';
import BaseStoreOrderAs from '../../PrestashopWidgets/StoreOrderAs/BaseStoreOrderAs';

const StoreOrderAs = () => {
  const dispatch = useDispatch();

  const wixSettings = useSelector<IState, WixSettings>(({ wix: { settings } }) => settings);

  useEffect(() => {
    setTargetEntity(wixSettings.targetEntity);
  }, [wixSettings.targetEntity]);

  const [targetEntity, setTargetEntity] = useState<string | undefined>();

  const handleValueChange = (value: string) => {
    setTargetEntity(value);
    dispatch(putSettingsRequest({ system: 'wix', data: { ...wixSettings, targetEntity: value } }));
  };

  return (
    <BaseStoreOrderAs
      system="wix"
      targetEntity={targetEntity}
      onChange={handleValueChange}
    />
  );
};

export default StoreOrderAs;
