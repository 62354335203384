import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../store/reducers';
import * as commonsActions from '../../store/actions/commons_actions';
import { TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import { StateProps, AnwrSettingsProps, KeyValueEditProps } from './types';

const KeyValueEdit = (props: KeyValueEditProps) => {
  const {isNew, loading, onSave, onDelete, item} = props;
  const [key, setKey] = React.useState<string>(item.key);
  const [value, setValue] = React.useState<string>(item.value);
  return (
    <GridContainer>
      <GridItem xs={12} sm={5}>
        <TextField
          placeholder="Key"
          style={{ width: '100%' }}
          value={key}
          onChange={(ev: any) => setKey(ev.target.value)}
          onBlur={() => key ? onSave({ key, value }) : null}
          multiline={false}
        />
      </GridItem>
      <GridItem xs={12} sm={5}>
        <TextField
          placeholder="Value"
          style={{ width: '100%' }}
          value={value}
          onChange={(ev: any) => setValue(ev.target.value)}
          onBlur={() => key ? onSave({ key, value }) : null}
          multiline={false}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        {!isNew ? (
          <Button
            justIcon={true}
            round={true}
            color="transparent"
            onClick={() => onDelete({ key, value })}
            disabled={loading}
          >
            <DeleteIcon />
          </Button>
        ) : null}
      </GridItem>
    </GridContainer>
  );
}

const AnwrSettings = (props: AnwrSettingsProps) => {
  const { values, loading, getNamedKeyValues, postNamedKeyValue, deleteNamedKeyValue } = props;
  React.useEffect(() => getNamedKeyValues(), []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4>Anwr settings</h4>
          </CardHeader>
          <CardBody>
            {values.map((item, i) => (
              <KeyValueEdit
                key={i}
                item={item}
                isNew={false}
                loading={loading}
                onSave={postNamedKeyValue}
                onDelete={deleteNamedKeyValue}
              />
            ))}
            <KeyValueEdit
              key={values.length}
              item={{ key: '', value: '' }}
              isNew={true}
              loading={loading}
              onSave={postNamedKeyValue}
              onDelete={deleteNamedKeyValue}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  loading: state.commons.namedKeyValues['anwr'] ? state.commons.namedKeyValues['anwr'].loading : false,
  values: state.commons.namedKeyValues['anwr'] ? state.commons.namedKeyValues['anwr'].values : [],
});

const mapDispatchToProps = {
  getNamedKeyValues: () => commonsActions.getNamedKeyValuesRequest('anwr'),
  postNamedKeyValue: (data: any) => commonsActions.postNamedKeyValueRequest('anwr', data),
  deleteNamedKeyValue: (data: any) => commonsActions.deleteNamedKeyValueRequest('anwr', data),
};

export default connect(mapStateToProps, mapDispatchToProps)(AnwrSettings);
