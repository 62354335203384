import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { IOptionType } from '../../../types/ReactSelect';
import colourStyles from '../../../assets/jss/reactSelectStyle';
import { EmailSettings, Mailers } from '../types';
import { useDefaultStyles } from '../styles';
import TextFieldDelayed from './TextFieldDelayed';


const languageOptions: IOptionType[] = [
  { label: 'English', value: 'en' },
  { label: 'German', value: 'de' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
];

const mailProviders: IOptionType[] = [
  { label: 'Template', value: 'template' },
  { label: 'Bexio', value: 'bexio' },
  //{ label: 'Swisswhite', value: 'swisswhite' },
];

type EmailSettingsSectionProps = {
  enabled: boolean;
  settings: EmailSettings;
  setSettings: (data: EmailSettings) => void;
} & LocalizeContextProps;

function EmailSettingsSection(props: EmailSettingsSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  const [language, setLanguage] = React.useState<string | undefined>(undefined);
  return (
    <Grid container={true} style={{marginBottom: 10, marginTop: 10}}>
      <Grid item={true} xs={12} style={{marginBottom: 10, fontWeight: 'bold'}}>
        {translate('reminders.levels.emailSettings.title')}
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.emailSettings.mailers')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <Select
          isSearchable={false}
          options={mailProviders}
          isMulti={true}
          defaultValue={mailProviders.filter(x => settings.mailers.find(y => y === x.value))}
          styles={colourStyles}
          isDisabled={!enabled}
          onChange={(newValue: ValueType<IOptionType, true>) => setSettings({...settings, mailers: newValue ? newValue.map(x => x.value as Mailers) : [] })}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.language')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <Select
          isSearchable={false}
          options={languageOptions}
          value={languageOptions.find(opt => opt.value === language)}
          onChange={(value: ValueType<IOptionType, false>) => {
            if (value) {
              const newSettings: any = {};
              if (!settings.subjects.find(it => it.language === value.value)) {
                newSettings.subjects = [...settings.subjects, { text: '', language: value.value }];
              }
              if (!settings.emailTemplates.find(it => it.language === value.value)) {
                newSettings.emailTemplates = [...settings.emailTemplates, { text: '', language: value.value, html: false }];
              }
              if (!settings.filename.find(it => it.language === value.value)) {
                newSettings.filename = [...settings.filename, { name: '', language: value.value }];
              }
              setSettings({...settings, ...newSettings})
              setLanguage(value.value);
            }
          }}
          styles={colourStyles}
          isDisabled={!enabled}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.emailSettings.subject')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.emailSettings.subject') as string}
          value={settings.subjects.find(it => it.language === language)?.text}
          disabled={!enabled || language === undefined}
          className={classes.w100}
          onChange={(value: string) => setSettings({...settings, subjects: settings.subjects.map(x => x.language !== language ? x : {...x, text: value })})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.emailSettings.text')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.emailSettings.text') as string}
          value={settings.emailTemplates.find(it => it.language === language)?.text}
          disabled={!enabled || language === undefined}
          className={classes.w100}
          multiline={true}
          rows={3}
          onChange={(value: string) => setSettings({...settings, emailTemplates: settings.emailTemplates.map(x => x.language !== language ? x : {...x, text: value })})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3}>
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="email-settings-html"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.levels.emailSettings.html") as string}
          checked={settings.emailTemplates.find(it => it.language === language)?.html}
          disabled={!enabled || language === undefined}
          onChange={(event: any, newValue: boolean) => setSettings({...settings, emailTemplates: settings.emailTemplates.map(x => x.language !== language ? x : {...x, html: newValue })})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.emailSettings.filename')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.emailSettings.filename') as string}
          value={settings.filename.find(it => it.language === language)?.name}
          disabled={!enabled || language === undefined}
          className={classes.w100}
          onChange={(value: string) => setSettings({...settings, filename: settings.filename.map(x => x.language !== language ? x : {...x, name: value })})}
        />
      </Grid>
      <Grid item={true} xs={12} sm={3} style={{display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
        {translate('reminders.levels.emailSettings.bccEmail')}
      </Grid>
      <Grid item={true} xs={12} sm={9} style={{paddingTop: 5, paddingBottom: 5}}>
        <TextFieldDelayed
          placeholder={translate('reminders.levels.emailSettings.bccEmail') as string}
          value={settings.bccEMail}
          disabled={!enabled}
          className={classes.w100}
          onChange={(value: string) => setSettings({...settings, bccEMail: value })}
        />
      </Grid>
    </Grid>
  );
}

export default withLocalize(EmailSettingsSection);
