import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// project imports
import { StyledSwitch } from '../../../../components/StyledSwitch';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';

// local imports
import { Props, StateProps } from './types';

const SwitchField = (props: Props) => {
  const { emailSettings, putSettingsRequest, fieldName, translate, type } = props;
  const dispatch = useDispatch();

  const [value, setValue] = useState<boolean | null>(null);

  useEffect(() => {
    if (emailSettings && emailSettings[type]) {
      const cfg = emailSettings[type] || {};
      setValue(cfg[fieldName] ?? false);
    }
  }, [emailSettings, fieldName]);

  const handleSwitchChange = (event: any) => {
    if (value !== null) {
      setValue(event.target.checked);
      dispatch(
        putSettingsRequest({
          system: 'email',
          type,
          data: {
            [fieldName]: event.target.checked,
          },
        }),
      );
    }
  };

  return (
    <div>
      <StyledSwitch
        inputProps={{ 'aria-label': translate(`email.${fieldName}`) as string  }}
        checked={value ?? false}
        onChange={handleSwitchChange}
        value={fieldName}
      />
    </div>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  emailSettings: state.email.settings,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SwitchField),
);
