// global imports
import cx from 'classnames';
import { Grid, InputLabel, makeStyles, StyleRules, TextField, Theme, CircularProgress } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import _ from 'lodash';
import React, { useRef, useState, useCallback } from 'react';
import { withLocalize } from 'react-localize-redux';
import CustomButton from '../../../../components/NewUI/CustomButton';
import { testConnection } from '../../../../store/api/prestashop';

// project imports

// project types imports
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import styles from './styles';
import { Props } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(styles as StyleRules);

const ApiKey = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate, webServiceKey, onChange, shop } = props;

  const [currentValue, setCurrentValue] = useState(webServiceKey);
  const delayedInput = useRef(_.debounce(value => onChange('webServiceKey', value), 1000)).current;
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>(undefined);

  const handleValueChange = (ev: React.ChangeEvent<{ value: string }>) => {
    const value = ev.target.value;

    setCurrentValue(value);
    delayedInput(value);
    setStatus(undefined);
  };

  const onTestConnection = useCallback(() => {
    setLoading(true);
    testConnection(shop)
      .then(r => r.data)
      .then(r => {
        setStatus(r.status);
        setLoading(false);
      }).catch(() => {
        setStatus(undefined);
        setLoading(false);
      });
  }, [shop, setLoading, setStatus, setLoading]);

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={4}>
          <InputLabel className={classes.pt10}>{translate('prestashop.api-key')}</InputLabel>
        </Grid>
        <Grid item={true} xs={8}>
          <TextField className={classes.w100} value={currentValue} onChange={handleValueChange} />
        </Grid>
      </Grid>
      <label className={classes.subLabel} style={{ marginTop: 15 }}>
        {translate('prestashop.api-key-notice')}
      </label>
      <Grid container={true}>
        <Grid item={true} xs={8} />
        <Grid item={true} xs={4}>
          <CustomButton className={classes.w100} disabled={shop === '' || loading || webServiceKey === ''} onClick={() => onTestConnection()}>
            {translate('prestashop.check-connection')}
            {status === 'ok' ? (
              <Check className={cx(classes.iconGreen, classes.spacedIcon)} />
            ) : null}
            {status === 'nok' ? (
              <Close className={cx(classes.iconRed, classes.spacedIcon)} />
            ) : null}
            {loading ? (
              <div style={{display: 'flex'}}>
                  <CircularProgress className={classes.spacedIcon} />
              </div>
            ) : null}
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
};

export default withLocalize(ApiKey);
