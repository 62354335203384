import { BedayaAction } from '../../types/BedayaAction';
import { EmailSettings, BexioEmailSettings } from '../../types/EmailSettings';
import { GET_SETTINGS, PUT_SETTINGS } from '../actions/common_actions';

export interface IEmailSettingsState {
  settings: {
    invoice: EmailSettings;
    delivery: EmailSettings;
    invoice_by_bexio: Partial<BexioEmailSettings>;
  };

  isFetching: boolean;
  isFetchingDisconnect: any;
  error: any;
}

const initialState: IEmailSettingsState = {
  settings: {
    invoice: { subject: '', recipient: '', text: '', sender: '', CC: '' },
    delivery: { subject: '', recipient: '', text: '', sender: '', CC: '' },
    invoice_by_bexio: { translations: [], attachPdf: false },
  },

  isFetching: true,
  isFetchingDisconnect: false,
  error: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: IEmailSettingsState = initialState, action: BedayaAction): IEmailSettingsState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['email'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['email'].includes(action.data.system)) {
        return {
          ...state,
          settings: {
            ...state.settings,
            [action.data.type]: action.data.data,
          },
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
      if (['email'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    default:
      return state;
  }
}
