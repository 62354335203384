import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// import { Manager, Target, Popper } from "react-popper";
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { getTranslate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
// core components
import Button from '../../../components/CustomButtons/Button';

import styles from '../../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import { logoutRequest } from '../../../store/actions/auth_actions';
import LanguageToggle from '../../../components/LanguageToggle';

const useStyles = makeStyles(styles);

const HeaderLinks = props => {
  const { userEmail, userCompanies, activeCompanyId, translate } = props;

  const [openProfile, setOpenProfile] = React.useState(null);
  const [currentCompany, setCurrentCompany] = React.useState(null);

  React.useEffect(() => {
    const newCurrentCompany = _.find(userCompanies, company => company.id === activeCompanyId);

    setCurrentCompany(newCurrentCompany);
  }, [activeCompanyId, userCompanies]);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    props.logoutRequest();
    setOpenProfile(null);
  };

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = cx(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = cx({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = cx({
    [classes.managerClasses]: true,
  });

  return (
    <div className={wrapper}>
      <LanguageToggle />
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : '',
          }}
        >
          <Person
            className={
              classes.headerLinksSvg + ' ' + (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Profile'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={cx({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <p className={classes.userInfoText}>{userEmail}</p>
                    {currentCompany && <p className={classes.userInfoSmallText}>{currentCompany.name}</p>}
                    <NavLink to={`/admin/profile`} onClick={() => setOpenProfile(false)}>
                      <MenuItem className={dropdownItem}>{translate('edit-profile')}</MenuItem>
                    </NavLink>
                    <NavLink to={`/admin/email`} onClick={() => setOpenProfile(false)}>
                      <MenuItem className={dropdownItem}>{translate('email.title')}</MenuItem>
                    </NavLink>
                    <NavLink target="_blank" to={{ pathname: 'https://help.bedaya.ch' }}>
                      <MenuItem className={dropdownItem}>{translate('help')}</MenuItem>
                    </NavLink>
                    <Divider light />
                    <MenuItem onClick={handleLogout} className={dropdownItem}>
                      {rtlActive ? 'الخروج' : 'Log out'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

HeaderLinks.defaultProps = {
  activeLanguage: {
    name: '',
    code: '',
    active: true,
  },
};

const mapStateToProps = state => ({
  userEmail: state.auth.userInfo.email,
  userCompanies: state.auth.userInfo.companies,
  activeCompanyId: state.auth.tokenInfo.payload.company,
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutRequest,
    },
    dispatch,
  );

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HeaderLinks),
);
