import { clientApp } from './index';

export const importInvoices = (from: string, to: string, shop: string) =>
  clientApp.get(`/ui/shopify/import/invoices/${shop}?from=${from}&to=${to}`);

export const importOrder = (name: string, shop: string) =>
  clientApp.get(`/ui/shopify/import/order/${shop}?name=${name}`);

export const importProducts = (shop: string) => clientApp.get(`/ui/shopify/import/product/${shop}`);

export const checkShopifyConfirmation = (shop: string) => clientApp.get(`/ui/shopify/charge/check/${shop}`);

export const createCharge = (shop: string, plan: string) => clientApp.get(`/ui/shopify/charge/create/${shop}/${plan}`);

export const postCharge = (id: number) => clientApp.get(`/ui/shopify/charge/confirm/${id}`);
export const getShops = () => clientApp.get(`/ui/shopify/shops`);
export const getSettingsForShop = (shop: string) => clientApp.get(`/ui/shopify/settings/${shop}`);
export const putSettingsForShop = (shop: any, data: any) => clientApp.put(`/ui/shopify/settings/${shop}`, data);
export const testConnectionForShop = (shop = '') => clientApp.get(`/ui/shopify/connection/test/${shop}`);
export const shopLocations = () => clientApp.get(`/ui/shopify/locations`);
export const getShopStatistics = (shop: any) => clientApp.get(`/ui/shopify/statistics/${shop}`);
export const getShopStatisticsAll = () => clientApp.get('/ui/shopify/statistics');
export const getShopStatisticsDetailed = () => clientApp.get('/ui/shopify/statistics/detailed');
