import { BedayaAction } from '../../types/BedayaAction';
import { ShopifySettings } from '../../types/ShopifySettings';
import {
  GET_SETTINGS_FOR_SHOP,
  IMPORT_ORDER,
  CREATE_CHARGE,
  POST_CHARGE,
  PUT_SETTINGS_FOR_SHOP,
  SET_ACTIVE_SHOP,
  SHOPS,
  TEST_CONNECTION_FOR_SHOP,
  SHOP_LOCATIONS,
  GET_SHOP_STATISTICS,
  GET_SHOP_STATISTICS_ALL,
  GET_SHOP_STATISTICS_DETAILED,
} from './../actions/shopify_actions';

import { ShopifyShop } from '../../types/ShopifyShop';
import { FETCH_OAUTH_URL, POST_OAUTH_TOKEN } from '../actions/auth_actions';
import { DISCONNECT, GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';
import { CHECK_CONFIRMATION, IMPORT_INVOICES, IMPORT_PRODUCTS } from '../actions/shopify_actions';

export type ShopStatistics = {
  shop?: string;
  planName: string | null;
  lastPayment: string | null;
  numOrders: number;
  maxOrders: number;
}

export interface IShopifyState {
  settings: ShopifySettings | null;
  settingsLoading: boolean;

  shops: ShopifyShop[];
  lastRun: any;
  lastJob: any;
  importProductsStatus: any;
  importOrdersStatus: any;
  importOrderStatus: any;

  OAuthURL: string;
  authenticated: boolean;

  connectionStatus: any;
  connectionStatusLoading: boolean;

  isFetching: any;
  isFetchingDisconnect: any;
  error: any;

  confirmed: boolean;
  isLoading: boolean;
  confirmationUrl: string | null;
  confirmationLoading: boolean;

  planName: string | null;
  locations: string[];

  statistics: ShopStatistics;
  statisticsAll: ShopStatistics;
  statisticsDetailed: ShopStatistics[];
  statisticsLoading: boolean;
}

const initialState: IShopifyState = {
  settings: null,
  settingsLoading: false,

  shops: [],

  lastRun: null,
  lastJob: null,
  importProductsStatus: '',
  importOrdersStatus: '',
  importOrderStatus: '',

  OAuthURL: '',
  authenticated: false,

  connectionStatus: 'nook',
  connectionStatusLoading: false,

  isFetching: false,
  isFetchingDisconnect: false,
  error: '',

  confirmed: false,
  isLoading: true,
  confirmationUrl: null,
  confirmationLoading: false,

  planName: null,
  locations: [],

  statistics: {
    planName: '',
    lastPayment: null,
    numOrders: 0,
    maxOrders: 0,
  },
  statisticsAll: {
    planName: '',
    lastPayment: null,
    numOrders: 0,
    maxOrders: 0,
  },
  statisticsDetailed: [],
  statisticsLoading: false,
};

try {
  const lastRun = localStorage.getItem('shopifyLastRun');
  const lastJob = localStorage.getItem('shopifyLastJob');
  initialState.lastRun = lastRun ? JSON.parse(lastRun) : null;
  initialState.lastJob = lastJob ? JSON.parse(lastJob) : null;
} catch (e) {
  console.warn(e);
}

export default function reducer(state: IShopifyState = initialState, action: BedayaAction): IShopifyState {
  switch (action.type) {
    // protocol actions
    case TEST_CONNECTION.REQUEST:
    case TEST_CONNECTION_FOR_SHOP.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
          connectionStatusLoading: true,
        };
      }
      return state;
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
          settingsLoading: true,
        };
      }
      return state;
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case DISCONNECT.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: true,
        };
      }
      return state;
    case FETCH_OAUTH_URL.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: '',
          isFetching: true,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.REQUEST:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: false,
          isFetching: true,
        };
      }
      return state;

    case TEST_CONNECTION.SUCCESS:
    case TEST_CONNECTION_FOR_SHOP.SUCCESS:
    case DISCONNECT.SUCCESS:
      if (['shopify'].includes(action.data.system)) {
        const status = action.data.status || 'nook'; // (action.data.system === 'bexio' ? 'nook' : 'nook');
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          connectionStatus: status,
          connectionStatusLoading: false,
          lastRun: null,
          lastJob: null,
          isFetching: false,
          isFetchingDisconnect: false,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          settingsLoading: false,
          isFetching: false,
        };
      }
      return state;
    case RUN_JOB.SUCCESS:
    case GET_JOB.SUCCESS:
      if (['shopify'].includes(action.data.system)) {
        localStorage.setItem(`${action.data.system}LastRun`, JSON.stringify(action.data.data));
        localStorage.setItem(`${action.data.system}LastJob`, JSON.stringify(action.data.jobNumber));
        return {
          ...state,
          lastRun: action.data.data,
          lastJob: action.data.jobNumber,
          isFetching: false,
        };
      }
      return state;
    case FETCH_OAUTH_URL.SUCCESS:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          OAuthURL: action.data.url,
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.SUCCESS:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          authenticated: true,
          isFetching: false,
        };
      }
      return state;

    case RUN_JOB.FAILURE:
    case GET_JOB.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        localStorage.removeItem(`${action.data.system}LastRun`);
        localStorage.removeItem(`${action.data.system}LastJob`);
        return {
          ...state,
          lastRun: null,
          lastJob: null,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
          settingsLoading: false,
        };
      }
      return state;
    case TEST_CONNECTION.FAILURE:
    case TEST_CONNECTION_FOR_SHOP.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          connectionStatusLoading: false,
          error: action.data.error,
        };
      }
      return state;
    case DISCONNECT.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          isFetchingDisconnect: false,
          error: action.data.error,
        };
      }
      return state;
    case FETCH_OAUTH_URL.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;
    case POST_OAUTH_TOKEN.FAILURE:
      if (['shopify'].includes(action.data.system)) {
        return {
          ...state,
          error: action.data.error.toString(),
          isFetching: false,
        };
      }
      return state;

    // own actions
    case IMPORT_PRODUCTS.REQUEST:
      return {
        ...state,
        importProductsStatus: 'loading',
      };
    case IMPORT_PRODUCTS.SUCCESS:
      return {
        ...state,
        importProductsStatus: 'ok',
      };
    case IMPORT_PRODUCTS.FAILURE:
      return {
        ...state,
        importProductsStatus: 'failed',
      };
    case IMPORT_INVOICES.REQUEST:
      return {
        ...state,
        importOrdersStatus: 'loading',
      };
    case IMPORT_ORDER.REQUEST:
      return {
        ...state,
        importOrderStatus: 'loading',
      };
    case IMPORT_INVOICES.SUCCESS:
      return {
        ...state,
        importOrdersStatus: 'ok',
      };
    case IMPORT_ORDER.SUCCESS:
      return {
        ...state,
        importOrderStatus: 'ok',
      };
    case IMPORT_INVOICES.FAILURE:
      return {
        ...state,
        importOrdersStatus: 'failed',
      };
    case IMPORT_ORDER.FAILURE:
      return {
        ...state,
        importOrderStatus: 'failed',
      };
    case CHECK_CONFIRMATION.REQUEST:
    case POST_CHARGE.REQUEST:
    case CREATE_CHARGE.REQUEST:
      return {
        ...state,
        isLoading: true,
        confirmationLoading: true,
      };
    case CHECK_CONFIRMATION.SUCCESS:
    case POST_CHARGE.SUCCESS:
    case CREATE_CHARGE.SUCCESS:
      return {
        ...state,
        confirmed: action.data.confirmed,
        isLoading: false,
        confirmationUrl: action.data.confirmationUrl,
        planName: action.data.planName,
        confirmationLoading: false,
      };
    case CHECK_CONFIRMATION.FAILURE:
    case POST_CHARGE.FAILURE:
    case CREATE_CHARGE.FAILURE:
      return {
        ...state,
        confirmed: false,
        isLoading: false,
        confirmationUrl: null,
        planName: null,
        confirmationLoading: false,
      };

    case SHOPS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SHOPS.SUCCESS:
      const shopsNames: string[] = action.data;
      return {
        ...state,
        shops: shopsNames.map(name => {
          return {
            name,
            active: false,
          };
        }),
        isFetching: false,
      };
    case SHOPS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error,
      };

    case GET_SETTINGS_FOR_SHOP.REQUEST:
    case PUT_SETTINGS_FOR_SHOP.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SETTINGS_FOR_SHOP.SUCCESS:
    case PUT_SETTINGS_FOR_SHOP.SUCCESS:
      return {
        ...state,
        settings: action.data,
        isFetching: false,
      };
    case GET_SETTINGS_FOR_SHOP.FAILURE:
    case PUT_SETTINGS_FOR_SHOP.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data ? action.data.error : 'Error',
      };
    case SET_ACTIVE_SHOP.SUCCESS:
      const newState = {
        ...state,
      };
      for (const shop of newState.shops) {
        if (shop.name === action.data.shop) {
          shop.active = true;
        } else {
          shop.active = false;
        }
      }
      return newState;
    case SHOP_LOCATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SHOP_LOCATIONS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        locations: action.data
      };
    case SHOP_LOCATIONS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.data.error,
      };
    case GET_SHOP_STATISTICS.REQUEST:
      return {
        ...state,
        statistics: { planName: '', lastPayment: null, numOrders: 0, maxOrders: 0 },
        statisticsLoading: true,
        isFetching: true,
      };
    case GET_SHOP_STATISTICS.SUCCESS:
      return {
        ...state,
        statistics: action.data,
        statisticsLoading: false,
        isFetching: false,
      };
    case GET_SHOP_STATISTICS_ALL.REQUEST:
      return {
        ...state,
        statisticsAll: { planName: '', lastPayment: null, numOrders: 0, maxOrders: 0 },
        statisticsLoading: true,
        isFetching: true,
      };
    case GET_SHOP_STATISTICS_ALL.SUCCESS:
      return {
        ...state,
        statisticsAll: action.data,
        statisticsLoading: false,
        isFetching: false,
      };
    case GET_SHOP_STATISTICS_DETAILED.REQUEST:
      return {
        ...state,
        statisticsDetailed: [],
        statisticsLoading: true,
        isFetching: true,
      };
    case GET_SHOP_STATISTICS_DETAILED.SUCCESS:
      return {
        ...state,
        statisticsDetailed: action.data,
        statisticsLoading: false,
        isFetching: false,
      };
    case GET_SHOP_STATISTICS.FAILURE:
    case GET_SHOP_STATISTICS_ALL.FAILURE:
    case GET_SHOP_STATISTICS_DETAILED.FAILURE:
      return {
        ...state,
        isFetching: false,
        statisticsLoading: false,
        error: action.data.error,
      };

    default:
      return state;
  }
}
