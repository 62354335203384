import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { localizeReducer } from 'react-localize-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();

export const { store, runSaga, persistor } = (function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();

  const combinedReducers = combineReducers({
    ...rootReducer,
    localize: localizeReducer,
    router: connectRouter(history),
    form: formReducer,
  });

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? composeWithDevTools({ trace: true, traceLimit: 25 }) // So we can see the trace in dev tools
      : compose;

  const createdStore = createStore(
    combinedReducers,
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );
  const persistedStore = persistStore(createdStore);

  return {
    store: createdStore,
    persistor: persistedStore,
    runSaga: sagaMiddleware.run,
  };
})();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
