import React from 'react';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { StyledSwitch } from '../../../components/StyledSwitch';
import { ReminderCosts, StandardPosition } from '../types';
import ProductSection from './ProductSection';
import StandardPositionSection from './StandardPositionSection';

type ReminderCostSectionProps = {
  enabled: boolean;
  settings: ReminderCosts;
  setSettings: (data: ReminderCosts) => void;
} & LocalizeContextProps;

function ReminderCostSection(props: ReminderCostSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const [tab, setTab] = React.useState<number>(0);
  return (
    <Grid container={true}>
      <Grid item={true} xs={12} sm={3} style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
        {translate('reminders.levels.reminderCosts.title')}
      </Grid>
      <Grid item={true} xs={12} sm={9}>
        <StyledSwitch
          checked={settings.enabled}
          onChange={event => setSettings({...settings, enabled: event.target.checked})}
        />
      </Grid>
      <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)} style={{ width: '100%'}}>
        <Tab
          label={translate('reminders.levels.reminderCosts.product.title') as string}
          disabled={!enabled || !settings.enabled}
        />
        <Tab
          label={translate('reminders.levels.reminderCosts.standardPosition.title') as string}
          disabled={!enabled || !settings.enabled}
        />
      </Tabs>
      <div hidden={tab !== 0} style={{width: '100%'}}>
        <ProductSection
          enabled={enabled && settings.enabled}
          settings={settings}
          setSettings={(data: ReminderCosts) => setSettings({...settings, productPosition: data.productPosition })}
        />
      </div>
      <div hidden={tab !== 1} style={{width: '100%'}}>
        <StandardPositionSection
          enabled={enabled && settings.enabled}
          settings={settings.standardPosition}
          setSettings={(data: StandardPosition) => setSettings({...settings, standardPosition: data})}
        />
      </div>
    </Grid>
  );
}

export default withLocalize(ReminderCostSection);
