import { Card, Grid, InputAdornment, ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { ArrowDropDown, Check, CloseOutlined, Search as IconSearch } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { IOptionType } from '../../types/ReactSelect';

export default function Search(props: {
  currentValue: IOptionType | null;
  data: IOptionType[];
  placeholder: string;
  termChange: any;
  resultClicked: any;
  searchTerm: string;
  clearTerm: any;
  icon: any;
}) {
  const [active, setActive] = useState<boolean>(false);

  const refActive = useRef();
  const refBtn = useRef();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!refActive || !refActive.current || !refBtn || !refBtn.current) {
        return;
      }
      if (!(refActive.current as any).contains(event.target) && !(refBtn.current as any).contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refBtn, refActive]);

  const cardClicked = () => {
    setActive(!active);
  };

  const clearField = () => {
    props.termChange('');
    props.clearTerm();
  };

  const itemClicked = (item: IOptionType) => {
    props.resultClicked(item);
    setActive(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid style={{ position: 'relative' }}>
        <Card
          ref={refBtn}
          onClick={cardClicked}
          style={{
            padding: 10,
            paddingRight: 0,
            cursor: 'pointer',
            color: active ? '#F05A00' : 'black',
            boxShadow: active ? '0px 1px 3px 0px rgba(240, 90, 0,0.6)' : '',
          }}
        >
          <Grid container={true} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid item={true} sm={11}>
              <span style={{ verticalAlign: 'middle' }}>{props.icon}</span>
              <span style={{ color: 'black', marginLeft: 10 }}>
                {props.currentValue ? props.currentValue.label : props.placeholder}
              </span>
            </Grid>
            <Grid item={true} sm={1} style={{ textAlign: 'center' }}>
              {props.currentValue ? <Check /> : <ArrowDropDown />}
            </Grid>
          </Grid>
        </Card>

        {active && (
          <Card
            ref={refActive}
            style={{
              position: 'absolute',
              marginTop: 20,
              padding: 10,
              zIndex: 6,
              width: '95%',
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              label="Enter search term"
              fullWidth={true}
              variant="outlined"
              placeholder={props.placeholder}
              value={props.searchTerm}
              onChange={(event: any) => props.termChange(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {<CloseOutlined style={{ cursor: 'pointer' }} onClick={clearField} />}
                  </InputAdornment>
                ),
              }}
            />
            <List
              style={{ marginTop: 10, maxHeight: 200, overflowY: 'scroll' }}
              component="nav"
              aria-label="secondary mailbox folders"
            >
              {props.data.map((item: IOptionType, index) => (
                <ListItem onClick={() => itemClicked(item)} button={true} key={index}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}

              {props.data.length === 0 && <h6 style={{ margin: 10, textAlign: 'center' }}>No results found</h6>}
            </List>
          </Card>
        )}
      </Grid>
    </div>
  );
}
