import { LocationState } from 'history';
import { LocalizeState } from 'react-localize-redux';
import { FormReducer } from 'redux-form/lib/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appsReducer, { IAppsState } from './apps_reducer';
import authReducer, { IAuthState } from './auth_reducer';
import bexioReducer, { IBexioState } from './bexio_reducer';
import bitrixReducer, { IBitrixState } from './bitrix_reducer';
import commonsReducer, { ICommonsState } from './commons_reducer';
import debitoorReducer, { IDebitoorState } from './debitoor_reducer';
import deliveryReducer, { IDeliveryState } from './delivery_reducer';
import dpdReducer, { IDpdState } from './dpd_reducer';
import emailReducer, { IEmailSettingsState } from './email_reducer';
import enforeReducer, { IEnforeState } from './enfore_reducer';
import ecwidReducer, { IEcwidState } from './ecwid_reducer';
import woocommerceReducer, { IWooCommerceState } from './woocommerce_reducer';
import forceManagerReducer, { IForceManagerState } from './forcemanager_reducer';
import gastrofixReducer, { IGastrofixState } from './gastrofix_reducer';
import glsReducer, { IGlsState } from './gls_reducer';
import lightspeedRReducer, { ILightspeedRState } from './lightspeed-r_reducer';
import lightspeedReducer, { ILightspeedState } from './lightspeed_reducer';
import mailchimpReducer, { IMailchimpState } from './mailchimp_reducer';
import pingenReducer, { IPingenState } from './pingen_reducer';
import planzerReducer, { IPlanzerState } from './planzer_reducer';
import prestashopReducer, { IPrestashopState } from './prestashop_reducer';
import protocolReducer, { IProtocolState } from './protocol_reducer';
import shopifyReducer, { IShopifyState } from './shopify_reducer';
import sumupReducer, { ISumupState } from './sumup_reducer';
import vendReducer, { IVendState } from './vend_reducer';
import weclappReducer, { IWeclappState } from './weclapp_reducer';
import wixReducer, { IWixState } from './wix_reducer';
import xeniaPosReducer, { IXeniaPosState } from './xeniapos_reducer';
import zohoBooksReducer, { IZohoBooksState } from './zoho_books_reducer';
import zohoCrmReducer, { IZohoCrmState } from './zoho_crm_reducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  // whitelist: [],
  whitelist: [
    /*'token', 'tokenInfo', 'userInfo', 'authenticated', 'OAuthURL', 'shopifyLastCheckedShop', */ 'redirectPath',
  ],
};

const appsPersistConfig = {
  key: 'apps',
  storage,
  whitelist: [],
  // whitelist: ['apps'],
};

const protocolPersistConfig = {
  key: 'protocol',
  storage,
  whitelist: [],
};

const deliveryPersistConfig = {
  key: 'protocol',
  storage,
  whitelist: [],
};

const sumupPersistConfig = {
  key: 'sumup',
  storage,
  whitelist: [],
};

const wixPersistConfig = {
  key: 'wix',
  storage,
  whitelist: [],
};

const bitrixPersistConfig = {
  key: 'bitrix',
  storage,
  whitelist: [],
};
const gastrofixPersistConfig = {
  key: 'gastrofix',
  storage,
  whitelist: [],
};

const shopifyPersistConfig = {
  key: 'shopify',
  storage,
  whitelist: [],
};
const bexioPersistConfig = {
  key: 'bexio',
  storage,
  whitelist: [],
};
const prestashopPersistConfig = {
  key: 'prestashop',
  storage,
  whitelist: [],
};
const weclappPersistConfig = {
  key: 'weclapp',
  storage,
  whitelist: [],
};
const mailchimpPersistConfig = {
  key: 'mailchimp',
  storage,
  whitelist: [],
};
const vendPersistConfig = {
  key: 'vend',
  storage,
  whitelist: [],
};
const debitoorPersistConfig = {
  key: 'debitoor',
  storage,
  whitelist: [],
};
const zohoBooksPersistConfig = {
  key: 'zoho-books',
  storage,
  whitelist: [],
};
const zohoCrmPersistConfig = {
  key: 'zoho-crm',
  storage,
  whitelist: [],
};
const forceManagerPersistConfig = {
  key: 'forcemanager',
  storage,
  whitelist: [],
};
const lightspeedPersistConfig = {
  key: 'lightspeed',
  storage,
  whitelist: [],
};
const emailSettingsPersistConfig = {
  key: 'email-settings',
  storage,
  whitelist: [],
};
const commonsPersistConfig = {
  key: 'commons',
  storage,
  whitelist: [],
};
const pingenPersistConfig = {
  key: 'pingen',
  storage,
  whitelist: [],
};
const lightspeedRPersistConfig = {
  key: 'lightspeed-r',
  storage,
  whitelist: [],
};
const enforePersistConfig = {
  key: 'enfore',
  storage,
  whitelist: [],
};
const xeniaPosPersistConfig = {
  key: 'xeniaPos',
  storage,
  whitelist: [],
};
const dpdPersistConfig = {
  key: 'dpd',
  storage,
  whitelist: [],
};
const planzerPersistConfig = {
  key: 'planzer',
  storage,
  whitelist: [],
};
const glsPersistConfig = {
  key: 'gls',
  storage,
  whitelist: [],
};
const ecwidPersistConfig = {
  key: 'ecwid',
  storage,
  whitelist: [],
};
const woocommercePersistConfig = {
  key: 'woocommerce',
  storage,
  whitelist: [],
};

export interface IState {
  auth: IAuthState;
  apps: IAppsState;
  protocol: IProtocolState;
  delivery: IDeliveryState;
  localize: LocalizeState;
  router: LocationState;
  form: FormReducer;
  sumup: ISumupState;
  bitrix: IBitrixState;
  wix: IWixState;
  shopify: IShopifyState;
  bexio: IBexioState;
  prestashop: IPrestashopState;
  weclapp: IWeclappState;
  mailchimp: IMailchimpState;
  vend: IVendState;
  debitoor: IDebitoorState;
  'zoho-books': IZohoBooksState;
  'zoho-crm': IZohoCrmState;
  gastrofix: IGastrofixState;
  forcemanager: IForceManagerState;
  lightspeed: ILightspeedState;
  'lightspeed-r': ILightspeedRState;
  email: IEmailSettingsState;
  commons: ICommonsState;
  pingen: IPingenState;
  enfore: IEnforeState;
  xeniaPos: IXeniaPosState;
  dpd: IDpdState;
  planzer: IPlanzerState;
  gls: IGlsState;
  ecwid: IEcwidState;
  woocommerce: IWooCommerceState;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: persistReducer(authPersistConfig, authReducer),
  apps: persistReducer(appsPersistConfig, appsReducer),
  protocol: persistReducer(protocolPersistConfig, protocolReducer),
  delivery: persistReducer(deliveryPersistConfig, deliveryReducer),
  sumup: persistReducer(sumupPersistConfig, sumupReducer),
  wix: persistReducer(wixPersistConfig, wixReducer),
  bitrix: persistReducer(bitrixPersistConfig, bitrixReducer),
  shopify: persistReducer(shopifyPersistConfig, shopifyReducer),
  bexio: persistReducer(bexioPersistConfig, bexioReducer),
  prestashop: persistReducer(prestashopPersistConfig, prestashopReducer),
  weclapp: persistReducer(weclappPersistConfig, weclappReducer),
  mailchimp: persistReducer(mailchimpPersistConfig, mailchimpReducer),
  vend: persistReducer(vendPersistConfig, vendReducer),
  debitoor: persistReducer(debitoorPersistConfig, debitoorReducer),
  'zoho-books': persistReducer(zohoBooksPersistConfig, zohoBooksReducer),
  'zoho-crm': persistReducer(zohoCrmPersistConfig, zohoCrmReducer),
  gastrofix: persistReducer(gastrofixPersistConfig, gastrofixReducer),
  forcemanager: persistReducer(forceManagerPersistConfig, forceManagerReducer),
  lightspeed: persistReducer(lightspeedPersistConfig, lightspeedReducer),
  'lightspeed-r': persistReducer(lightspeedRPersistConfig, lightspeedRReducer),
  email: persistReducer(emailSettingsPersistConfig, emailReducer),
  commons: persistReducer(commonsPersistConfig, commonsReducer),
  pingen: persistReducer(pingenPersistConfig, pingenReducer),
  enfore: persistReducer(enforePersistConfig, enforeReducer),
  xeniaPos: persistReducer(xeniaPosPersistConfig, xeniaPosReducer),
  dpd: persistReducer(dpdPersistConfig, dpdReducer),
  planzer: persistReducer(planzerPersistConfig, planzerReducer),
  gls: persistReducer(glsPersistConfig, glsReducer),
  ecwid: persistReducer(ecwidPersistConfig, ecwidReducer),
  woocommerce: persistReducer(woocommercePersistConfig, woocommerceReducer),
};
