import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { KsDebtCollection } from '../types';
import { useDefaultStyles } from '../styles';


type DebtCollectionSectionProps = {
  enabled: boolean;
  settings: KsDebtCollection;
  setSettings: (item: KsDebtCollection) => void;
} & LocalizeContextProps;

function DebtCollectionSection(props: DebtCollectionSectionProps) {
  const {enabled, settings, translate, setSettings} = props;
  const classes = useDefaultStyles();
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  return (
    <Grid container={true}>
      <Grid item={true} xs={12} style={{paddingTop: 5, paddingBottom: 5}}>
        <FormControlLabel
          name="gtc-accepted"
          control={<Checkbox color="primary" />}
          classes={{
            label: classes.label,
            root: classes.checkbox,
          }}
          label={translate("reminders.debt-collection.gtc-accepted") as string}
          checked={settings.gtc.accepted !== null}
          onChange={(event: any, newValue: boolean) => setSettings({...settings, gtc: { accepted: newValue ? new Date() : null }})}
          disabled={!enabled}
        />
        <div>{translate("reminders.debt-collection.description", undefined, { renderInnerHtml: true }) as string}</div>
        <button style={{ margin: '1em 0' }} onClick={() => setShowDetails(x => !x)}>{translate("reminders.debt-collection.show-more")}</button>
        {showDetails && (
          <div style={{ whiteSpace: 'normal' }}>{translate("reminders.debt-collection.detailed-description", undefined, { renderInnerHtml: true }) as string}</div>
        )}
      </Grid>
    </Grid>
  );
}

export default withLocalize(DebtCollectionSection);
