import { Grid } from '@material-ui/core';
import React from 'react';

export default function GridRow(props: any) {
  return (
    <Grid container={true} style={{ marginTop: 40 }}>
      {props.children}
    </Grid>
  );
}
