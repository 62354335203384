import { LightspeedSettings } from '../../types/LightspeedSettings';

import { BedayaAction } from '../../types/BedayaAction';
import { GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB, TEST_CONNECTION } from '../actions/common_actions';

export interface ILightspeedState {
  settings: LightspeedSettings;

  isFetching: any;
  error: any;
}

const initialState: ILightspeedState = {
  settings: {
    destinationSystem: null,
    username: null,
    password: null,
    shopId: null,

    productSync: {
      enabled: false,
    },
    invoiceSync: {
      enabled: false,
    },
  },

  isFetching: false,
  error: '',
};

export default function reducer(state: ILightspeedState = initialState, action: BedayaAction): ILightspeedState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case TEST_CONNECTION.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['lightspeed'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['lightspeed'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    case TEST_CONNECTION.FAILURE:
      if (['lightspeed'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: false,
          error: action.data.error,
        };
      }
      return state;
    default:
      return state;
  }
}
