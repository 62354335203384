// global imports
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

// project imports
import Button from '../../../../../../components/CustomButtons/Button';
import GridItem from '../../../../../../components/Grid/GridItem';
import ApiKey from '../../../../PrestashopWidgets/ApiKey/ApiKey';
import PriceIncludeVat from '../../../../PrestashopWidgets/PriceIncludeVat/PriceIncludeVat';
import ShopCurrency from '../../../../PrestashopWidgets/ShopCurrency/ShopCurrency';
import ShopName from '../../../../PrestashopWidgets/ShopName/ShopName';
import ShopUrl from '../../../../PrestashopWidgets/ShopUrl/ShopUrl';

// local imports
import FormCard from '../../../../../../components/FormCard/FormCard';
import WebhookHash from '../../../../PrestashopWidgets/WebhookHash/WebhookHash';
import { Props } from './types';

const ShopItem = ({
  shop: { id, name, shopCurrencyIso, url, vatInc, webServiceKey },
  onDeleteShop,
  onChangeOption,
}: Props) => {
  const onChange = (optionName: string, value: string | boolean) => {
    onChangeOption(id, optionName, value);
  };

  const onDelete = () => {
    onDeleteShop(id);
  };

  return (
    <FormCard marginBottom={true}>
      <Grid container={true} direction="row" justify="space-between">
        <Grid item={true} xs={11}>
          <GridItem xs={12}>
            <ShopName name={name} onChange={onChange} />
          </GridItem>
          <GridItem xs={12}>
            <ShopCurrency shopCurrencyIso={shopCurrencyIso} onChange={onChange} />
          </GridItem>
          <GridItem xs={12}>
            <PriceIncludeVat vatInc={vatInc} onChange={onChange} />
          </GridItem>
          <GridItem xs={12}>
            <ShopUrl url={url} onChange={onChange} />
          </GridItem>
          <GridItem>
            <WebhookHash />
          </GridItem>
          <GridItem xs={12}>
            <ApiKey webServiceKey={webServiceKey} onChange={onChange} shop={name} />
          </GridItem>
        </Grid>
        <Grid item={true} xs={1} style={{ textAlign: 'center' }}>
          <Button justIcon={true} round={true} color="transparent" onClick={onDelete}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ShopItem;
