import { ForceManagerSettings } from '../../types/ForceManagerSettings';

import { BedayaAction } from '../../types/BedayaAction';
import { GET_JOB, GET_SETTINGS, PUT_SETTINGS, RUN_JOB } from '../actions/common_actions';

export interface IForceManagerState {
  settings: ForceManagerSettings;

  isFetching: boolean;
  error: any;
}

const initialState: IForceManagerState = {
  settings: {
    destinationSystem: null,
    apiKey: null,
    privateKey: null,
  },

  isFetching: true,
  error: '',
};

export default function reducer(state: IForceManagerState = initialState, action: BedayaAction): IForceManagerState {
  switch (action.type) {
    // protocol actions
    case GET_SETTINGS.REQUEST:
    case PUT_SETTINGS.REQUEST:
    case RUN_JOB.REQUEST:
    case GET_JOB.REQUEST:
      if (['forcemanager'].includes(action.data.system)) {
        return {
          ...state,
          isFetching: true,
        };
      }
      return state;
    case PUT_SETTINGS.SUCCESS:
    case GET_SETTINGS.SUCCESS:
      if (['forcemanager'].includes(action.data.system)) {
        return {
          ...state,
          settings: action.data.data,
          isFetching: false,
        };
      }
      return state;
    case GET_SETTINGS.FAILURE:
    case PUT_SETTINGS.FAILURE:
    default:
      return state;
  }
}
