import { BedayaAction } from '../../types/BedayaAction';
import {
  GET_CANONICAL_FIELDS,
  GET_STRIPE_PORTAL,
  POST_STRIPE_CUSTOMER,
  POST_STRIPE_SUBSCRIPTION,
  USER_HAS_SUBSCRIPTION,
  USER_SEARCH_SUBSCRIPTION_PRODUCT,
  POST_STRIPE_CHECKOUT,
  GET_NAMED_KEY_VALUES,
  POST_NAMED_KEY_VALUE,
  DELETE_NAMED_KEY_VALUE,
} from './../actions/commons_actions';

interface IKeyValue {
  key: string;
  value: string;
};

interface INamedKeyValues {
  [name: string]: {
    loading: boolean;
    values: IKeyValue[];
  }
};

export interface ICommonsState {
  canonicalFields: {
      product: string[];
      order: string[];
      company: string[];
  },
  isFetching: boolean;
  error: string;
  stripe: {
    customer: any;
    subscription: any;
    sending: boolean;
    error: string | null;
    portalUrl: string;
    subscriptionProduct: any;
    hasSubscription: boolean;
    checkoutUrl: string;
  };
  namedKeyValues: INamedKeyValues;
}

const initialState: ICommonsState = {
  canonicalFields: {
      product: [],
      order: [],
      company: [],
  },
  isFetching: false,
  error: '',
  stripe: {
    customer: null,
    subscription: null,
    sending: false,
    error: null,
    portalUrl: '',
    subscriptionProduct: null,
    hasSubscription: false,
    checkoutUrl: '',
  },
  namedKeyValues: {},
};

export default function reducer(state: ICommonsState = initialState, action: BedayaAction): ICommonsState {
  switch (action.type) {
    case GET_CANONICAL_FIELDS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_CANONICAL_FIELDS.SUCCESS:
      return {
        ...state,
        canonicalFields: action.data,
        isFetching: false,
      };
    case GET_CANONICAL_FIELDS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case POST_STRIPE_CUSTOMER.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          customer: action.data,
          sending: false,
        },
      };
    case POST_STRIPE_SUBSCRIPTION.REQUEST:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: true,
        },
      };
    case POST_STRIPE_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          subscription: action.data,
          sending: false,
        },
      };
    case POST_STRIPE_SUBSCRIPTION.FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: false,
          error: action.error,
        },
      };
    case GET_STRIPE_PORTAL.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          portalUrl: action.data.url,
          sending: false,
        },
      };
    case GET_STRIPE_PORTAL.FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: false,
          error: action.error,
        },
      };
    case USER_HAS_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          hasSubscription: action.data.subscription,
        },
      };
    case USER_HAS_SUBSCRIPTION.FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: false,
          hasSubscription: false,
        },
      };
    case USER_SEARCH_SUBSCRIPTION_PRODUCT.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          subscriptionProduct: action.data.product,
        },
      };
    case USER_SEARCH_SUBSCRIPTION_PRODUCT.FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: false,
          subscriptionProduct: null,
        },
      };
    case POST_STRIPE_CHECKOUT.REQUEST:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: true,
        },
      };
    case POST_STRIPE_CHECKOUT.SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          checkoutUrl: action.data.url,
          sending: false,
        },
      };
    case POST_STRIPE_CHECKOUT.FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          sending: false,
          error: action.error,
        },
      };
    case GET_NAMED_KEY_VALUES.REQUEST:
    case POST_NAMED_KEY_VALUE.REQUEST:
    case DELETE_NAMED_KEY_VALUE.REQUEST:
      return {
        ...state,
        namedKeyValues: {
          ...state.namedKeyValues,
          [action.data.name]: state.namedKeyValues[action.data.name] ?
            ({ ...state.namedKeyValues[action.data.name], loading: true }) :
            ({ loading: true, values: [] }),
        }
      };
    case GET_NAMED_KEY_VALUES.SUCCESS:
      return {
        ...state,
        namedKeyValues: {
          ...state.namedKeyValues,
          [action.data.name]: state.namedKeyValues[action.data.name] ?
            ({ ...state.namedKeyValues[action.data.name], loading: false, values: action.data.data }) :
            ({ loading: false, values: action.data.data }),
        }
      };
    case POST_NAMED_KEY_VALUE.SUCCESS:
      return {
        ...state,
        namedKeyValues: {
          ...state.namedKeyValues,
          [action.data.name]: state.namedKeyValues[action.data.name] ?
            ({
              ...state.namedKeyValues[action.data.name],
              loading: false,
              values: state.namedKeyValues[action.data.name].values.find(x => x.key === action.data.data.key) !== undefined ?
                state.namedKeyValues[action.data.name].values.map(x => x.key === action.data.data.key ? action.data.data : x) :
                [...state.namedKeyValues[action.data.name].values, action.data.data],
            }) :
            ({ loading: false, values: [action.data.data] }),
        }
      };
    case DELETE_NAMED_KEY_VALUE.SUCCESS:
      return {
        ...state,
        namedKeyValues: {
          ...state.namedKeyValues,
          [action.data.name]: state.namedKeyValues[action.data.name] ?
            ({
              ...state.namedKeyValues[action.data.name],
              loading: false,
              values: state.namedKeyValues[action.data.name].values.filter(x => x.key !== action.data.data.key),
            }) :
            ({ loading: false, values: [] }),
        }
      };
    case GET_NAMED_KEY_VALUES.FAILURE:
    case POST_NAMED_KEY_VALUE.FAILURE:
    case DELETE_NAMED_KEY_VALUE.FAILURE:
      return {
        ...state,
        namedKeyValues: {
          ...state.namedKeyValues,
          [action.data.name]: state.namedKeyValues[action.data.name] ?
            ({ ...state.namedKeyValues[action.data.name], loading: false }) :
            ({ loading: false, values: [] }),
        }
      };
    default:
      return state;
  }
}
