// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import Button from '../../../../components/CustomButtons/Button.jsx';
import { importEntriesRequest } from '../../../../store/actions/xeniapos_actions';
import { testConnectionRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { Props } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => commonStyles as StyleRules);

const ImportOrders: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const { translate } = props;

  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(
    moment()
      .add(1, 'days')
      .toDate(),
  );

  const DateSelectBlock = () => (
    <div
      className={cx({
        [classes.selectSmall]: true,
        [classes.contentContainer]: true,
        [classes.flexCenter]: true,
        [classes.widthAuto]: true,
      })}
    >
      <label className={classes.datepickerLabel}>{translate('from')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={startDate}
        onChange={(date: any) => setStartDate(date)}
        selectsStart={true}
        startDate={startDate}
        endDate={endDate}
      />
      <br />
      <label className={classes.datepickerLabel}>{translate('to')}</label>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        className={classes.inputStyle}
        selected={endDate}
        onChange={(date: any) => setEndDate(date)}
        selectsEnd={true}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </div>
  );

  const [importStatus, setImportStatus] = useState('initial');
  const connectionStatus = useSelector<IState, string>(state => state.xeniaPos.connectionStatus);
  const isFetching = useSelector<IState, boolean>(state => state.xeniaPos.isFetching);
  const dispatch = useDispatch();
  const doImport = () => {
    dispatch(importEntriesRequest({ from: startDate, to: endDate }));
    setImportStatus('loading');
  }
  const connectCall = () => {
    dispatch(testConnectionRequest({ system: 'xeniapos' }));
  }
  React.useEffect(() => {
    connectCall();
  }, []);
  React.useEffect(() => {
    if (!isFetching && importStatus === 'loading') {
      setImportStatus('ready');
    }
  }, [isFetching]);

  if (connectionStatus !== 'ok') {
    return null;
  }

  return (
    <div
      className={cx(classes.runLabel)}
      style={{alignItems: 'center'}}
    >
      <DateSelectBlock />
      {isFetching ? (
        <CircularProgress />
      ) : (
        <Button className={classes.formButton} type="button" color="primary" onClick={doImport}>
          {translate('run.run')}
        </Button>
      )}
      {importStatus === 'ready' && (
        <div style={{paddingLeft: '2em'}}>{translate('successfully_imported', undefined, { renderInnerHtml: true })}</div>
      )}
    </div>
  );
};

export default withLocalize(ImportOrders);
