// global imports
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import commonStyles from '../../../../assets/jss/commonStyles';
import { putSettingsRequest } from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(commonStyles as StyleRules);

const ApiToken = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const dispatch = useDispatch();

  const weclappSettings = useSelector<IState, StateProps['weclappSettings']>(({ weclapp: { settings } }) => settings);

  const [currentApiToken, setCurrentApiToken] = useState(weclappSettings.apiToken);
  const delayedInput = useRef(
    _.debounce(
      (currentSettings, value) =>
        dispatch(putSettingsRequest({ system: 'weclapp', data: { ...currentSettings, apiToken: value } })),
      1000,
    ),
  ).current;

  useEffect(() => {
    setCurrentApiToken(weclappSettings.apiToken);
  }, [weclappSettings.apiToken]);

  const handleValueChange = (event: any) => {
    const newValue = event.target.value;

    setCurrentApiToken(newValue);
    delayedInput(weclappSettings, newValue);
  };

  return (
    <TextField value={currentApiToken} onChange={handleValueChange} placeholder="API Token" className={classes.w100} />
  );
};

export default withLocalize(ApiToken);
