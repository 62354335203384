import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router';
import { Link, CircularProgress } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { withLocalize } from 'react-localize-redux';

import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import { IState } from '../../store/reducers';
import * as shopifyActions from '../../store/actions/shopify_actions';
import { PlanType, CardProps, PlansPageProps, PageParams, StateProps } from './types.d';
import { plans, featureNames } from './data';


const featureHeights: Record<string, string> = {
  'import_orders': '2.5rem',
  'import_products': '3rem',
  'import_customers': '3rem',
  'send_invoices': '3.5rem',
  'write_back_invoice_statuses': '4rem',
  'write_back_product_statuses': '4rem',
  'configuration_and_changes': '5rem',
  'support': '7.5rem',
  'installation': '2rem',
};

const PlanCard = ({plan, translate, loading, onSelect, isCurrent, isSimple}: CardProps) => (
  <Card style={{
    height: 'auto',
    margin: '1rem 0.5rem',
    width: '13rem',
  }}>
    <CardHeader color="primary">
      <span style={{fontWeight: 'bold'}}>
        {translate(`shopify_plans.names.${plan.name}`, plan.params, { renderInnerHtml: true })}
      </span>
      <br />
      <span style={{fontWeight: 'bold'}}>
        ({translate('shopify_plans.price_per_month', plan.params, { renderInnerHtml: true })})
      </span>
    </CardHeader>
    <CardBody>
      {isSimple ? (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {translate('shopify_plans.additional_shop', plan.params, { renderInnerHtml: true })}
        </div>
      ) : (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {featureNames.map((name, idx) => (
            <div
              key={idx}
              style={{
                height: featureHeights[name] ? featureHeights[name] : '2rem',
                paddingBottom: '0.5rem',
                marginBottom: '1rem',
                display: 'flex',
              }}
            >
              {plan.features[name] ? <Check style={{ color: 'green' }} /> : null}
              {plan.features[name] ? translate(`shopify_plans.features.${plan.features[name]}`, plan.params, { renderInnerHtml: true }) : ' '}
            </div>
          ))}
        </div>
      )}
    </CardBody>
    <Button
      type="button"
      color={isCurrent ? "secondary" : "primary"}
      disabled={loading}
      onClick={onSelect}
    >
      {isCurrent ? translate('shopify_plans.current_plan') : translate('shopify_plans.button_order')}
    </Button>
  </Card>
);

const PlansPage = (props: PlansPageProps) => {
  const { confirmed, confirmationUrl, loading, translate, connectionStatus, shopifyShops, testConnectionForShopRequest, checkConfirmationRequest, createChargeRequest, planName, getShopsRequest
  } = props;
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const { shop } = useParams<PageParams>();

  React.useEffect(() => {
    if (!shop) {
      setRedirect("/admin/shopify-app");
    } else {
      getShopsRequest({});
    }
  }, [shop]);
  React.useEffect(() => {
    if (shopifyShops.length) {
      testConnectionForShopRequest({ shop });
      checkConfirmationRequest({ shop });
    }
  }, [shopifyShops]);
  React.useEffect(() => {
    if (connectionStatus === 'nok') {
      setRedirect("/admin/shopify-app");
    }
  }, [connectionStatus]);
  React.useEffect(() => {
    if (!confirmed && confirmationUrl) {
      window.location.href = confirmationUrl;
    }
  }, [confirmed, confirmationUrl]);
  const onSelectPlan = (plan: PlanType) => {
    createChargeRequest({ shop, plan: plan.name });
  };

  if (redirect) {
    return <Redirect to={redirect} />
  }
  if (shopifyShops.length === 0 && loading) {
      return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        color: 'black',
        maxWidth: '1170px',
        margin: '0 auto',
        zIndex: 10,
        marginBottom: '5rem',
        position: 'relative',
      }}
    >
      <Link
        href="/admin/shopify-app"
        style={{
          position: 'absolute',
          right: 0,
        }}
      >
        {translate('back')}
      </Link>
      {(shopifyShops[0].name === shop) ? (
        <>
          <div style={{ marginBottom: '1rem', fontSize: '1.25rem' }}>
            {translate('shopify_plans.trial', undefined, { renderInnerHtml: true })}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {plans.map((plan, idx) => (
              <PlanCard
                key={idx}
                plan={plan}
                translate={translate}
                loading={loading}
                onSelect={() => onSelectPlan(plan)}
                isCurrent={confirmed && planName === plan.name}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div style={{ marginBottom: '1rem', fontSize: '1.25rem' }}>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {plans.filter(plan => plan.name === 'basic').map((plan, idx) => (
              <PlanCard
                key={idx}
                plan={plan}
                translate={translate}
                loading={loading}
                onSelect={() => onSelectPlan(plan)}
                isCurrent={confirmed && planName === plan.name}
                isSimple
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: IState): StateProps => ({
  confirmed: state.shopify.confirmed,
  loading: state.shopify.isLoading,
  confirmationUrl: state.shopify.confirmationUrl,
  connectionStatus: state.shopify.connectionStatus,
  planName: state.shopify.planName || 'basic',
  shopifyShops: state.shopify.shops,
});

const mapDispatchToProps = {
  getShopsRequest: shopifyActions.shopsRequest,
  testConnectionForShopRequest: shopifyActions.testConnectionForShopRequest,
  checkConfirmationRequest: shopifyActions.checkConfirmationRequest,
  createChargeRequest: shopifyActions.createChargeRequest,
};

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(PlansPage)
);
