// global imports
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect, useDispatch } from 'react-redux';

// project imports
import assign from '../../../../services/assign';
import * as commonActions from '../../../../store/actions/common_actions';

// project types imports
import { IState } from '../../../../store/reducers';
import { IStyleProps, PropsClasses } from '../../../../types/StyleProps';

// local imports
import _ from 'lodash';
import { EmailSettings } from '../../../../types/EmailSettings';
import style from './styles';
import { Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(style as StyleRules);

const EmailField = (props: Props) => {
  const classes: PropsClasses = useStyles({} as IStyleProps);
  const { emailSettings, putSettingsRequest, field, translate, type } = props;
  const dispatch = useDispatch();

  const [settings, setSettings] = useState<Partial<EmailSettings> | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (emailSettings) {
      setSettings(emailSettings[type]);
    }
  }, [emailSettings]);

  const delayedInput = useRef(
    _.debounce((value: string) => {
      dispatch(
        putSettingsRequest({
          system: 'email',
          type,
          data: {
            ...settings,
            [field]: value,
          },
        }),
      );
    }, 1000),
  ).current;

  const handleValueChange = (event: any) => {
    if (settings) {
      setSettings(assign({ ...settings }, field, event.target.value));
      delayedInput(event.target.value);
      setError(false);
    }
  };

  return (
    <div>
      <TextField
        placeholder={translate(`email.${field}`) as string}
        className={classes.w100}
        value={settings ? settings[field] : ''}
        onChange={handleValueChange}
        multiline={field === 'text'}
        rows={3}
      />
      {error && <span style={{ fontSize: 14 }}>{translate('email.network_link_error')}</span>}
    </div>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  emailSettings: state.email.settings,
});

const mapDispatchToProps = {
  getSettingsRequest: commonActions.getSettingsRequest,
  putSettingsRequest: commonActions.putSettingsRequest,
};

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmailField),
);
