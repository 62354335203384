import { clientApp } from './index';

export const testConnection = ({ system = '' }) => clientApp.get(`/ui/${system}/connection/test`);

export const disconnect = (system = '', data: { [index: string]: any } = {}) => {
  const params = Object.keys(data)
    .filter(key => key !== 'system')
    .map((key: string) => `${key}=${data[key]}`)
    .join('&');
  let path = `/ui/user/oauth/${system}/disconnect`;
  switch (system) {
    case 'shopify':
      path = `/ui/shopify/disconnect`;
      break;
    case 'sumup':
      path = `/ui/sumup/disconnect`;
      break;
  }
  return clientApp.get(`${path}?${params}`);
};

export const getSettings = ({ system = '', type = null }) => {
  if (system === 'email' && type === 'invoice_by_bexio') {
      return clientApp.get(`/ui/${system}/settings/${type}`);
  }
  return clientApp.get(`/ui/${system}/settings${type ? '?type=' + type : ''}`);
}

export const putSettings = ({ system = '', type = null, sourceSystem = '', data = {} }) => {
  if (system === 'email' && type === 'invoice_by_bexio') {
    return clientApp.put(`/ui/${system}/settings/${type}`, data);
  }
  return clientApp.put(`/ui/${system}/settings?sourceSystem=${sourceSystem}${type ? '&type=' + type : ''}`, data);
}

export const runJob = ({ system = '', from = '', to = '' }) => clientApp.get(`/ui/${system}/run?from=${from}&to=${to}`);
export const getJob = (jobId: string) => clientApp.get(`/ui/user/job/protocol/${jobId}`);
