/* eslint-disable react/jsx-no-undef */
import { Add, ArrowBack, Delete } from '@material-ui/icons';
import React from 'react';
import CardHeader from '../Card/CardHeader';
import Button from '../CustomButtons/Button';

export default function SettingsContainer(props: {
  children: any;
  appName: string;
  onBack?: () => void;
  showBack?: boolean;
  onAdd?: () => void;
  showAdd?: boolean;
  addText?: string;
  showDelete?: boolean;
  onDelete?: () => void;
}) {
  return (
    <>
      <CardHeader
        color="primary"
        style={{ margin: 0, color: 'white', alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ alignItems: 'center', display: 'flex' }}>
          {props.showBack && <ArrowBack style={{ cursor: 'pointer' }} color="inherit" onClick={props.onBack} />}
          <span style={{ fontSize: 17, fontWeight: 'bold', marginLeft: 10 }}>{props.appName}</span>
        </div>
        {props.showAdd && (
          <div style={{ color: 'white' }}>
            <Button style={{ margin: '0 10px' }} justIcon={true} round={true} color="transparent" onClick={props.onAdd}>
              <Add style={{ marginRight: 5 }} fontSize="large" color="inherit" />
              {/* {props.addText} */}
            </Button>
          </div>
        )}
        {props.showDelete && (
          <div style={{ color: 'white' }}>
            <Button
              style={{ margin: '0 10px' }}
              justIcon={true}
              round={true}
              color="transparent"
              onClick={props.onDelete}
            >
              <Delete style={{ marginRight: 5 }} fontSize="large" color="inherit" />
            </Button>
          </div>
        )}
      </CardHeader>
      <div style={{ paddingTop: 50 }}>{props.children}</div>
    </>
  );
}
