import React, { useEffect } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import { withLocalize } from 'react-localize-redux';
import FormCard from '../FormCard/FormCard';
import CustomButton from '../NewUI/CustomButton';
import GridRow from '../NewUI/GridRow';
import Label from '../NewUI/Label/Label';

import { Props } from './types.d';

const Subscription = (props: Props) => {
  const { translate, hasSubscription, hasSubscriptionRequest, checkoutUrl, postCheckoutRequest, isFetching, baseName } = props;

  useEffect(() => {
    if(checkoutUrl) {
      window.location.href = checkoutUrl;
    }
  }, [checkoutUrl]);

  useEffect(() => {
    hasSubscriptionRequest();
  }, []);

  return (
    <GridRow container={true}>
      <Grid item={true} md={4}>
        <Label
          title={translate(`${baseName}.subscription`) as string}
          desc={translate(`${baseName}.subscription-desc`) as string}
        />
      </Grid>
      <Grid item={true} md={8}>
        <FormCard>
          {hasSubscription === true && (
            <span style={{margin: '1em 0'}}>{translate(`${baseName}.subscription-status`)}</span>
          )}
          {hasSubscription === false && (
            <CustomButton onClick={() => postCheckoutRequest()} disabled={isFetching}>{translate(`${baseName}.subscription-checkout-button`)}</CustomButton>
          )}
          {isFetching && (
            <CircularProgress />
          )}
        </FormCard>
      </Grid>
    </GridRow>
  );
};

export default withLocalize(Subscription);
