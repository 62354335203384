import createRequestTypes from '../../services/requestsTypes';

const filePrefix = 'GASTROFIX';

export const IMPORT_TRANSACTIONS = createRequestTypes(`${filePrefix}_IMPORT_TRANSACTIONS`);
export const GET_ARTICLE_FAMILIES = createRequestTypes(`${filePrefix}_GET_ARTICLE_FAMILIES`);
export const GET_ACCOUNTS = createRequestTypes(`${filePrefix}_GET_ACCOUNTS`);
export const SET_CURRENT_SETTINGS = `SET_CURRENT_SETTINGS`;
export const DELETE_CONFIG = createRequestTypes(`${filePrefix}_DELETE_CONFIG`);

export const importTransactionsRequest = (data: any) => ({
  type: IMPORT_TRANSACTIONS.REQUEST,
  data,
});
export const importTransactionsSuccess = (data: any) => ({
  type: IMPORT_TRANSACTIONS.SUCCESS,
  data,
});
export const importTransactionsFailure = (error: any) => ({
  type: IMPORT_TRANSACTIONS.FAILURE,
  error,
});

export const getArticleFamiliesRequest = (data: any) => ({
  type: GET_ARTICLE_FAMILIES.REQUEST,
  data,
});
export const getArticleFamiliesSuccess = (data: any) => ({
  type: GET_ARTICLE_FAMILIES.SUCCESS,
  data,
});
export const getArticleFamiliesFailure = (error: any) => ({
  type: GET_ARTICLE_FAMILIES.FAILURE,
  error,
});

export const getAccountsRequest = (data: any) => ({
  type: GET_ACCOUNTS.REQUEST,
  data,
});
export const getAccountsSuccess = (data: any) => ({
  type: GET_ACCOUNTS.SUCCESS,
  data,
});
export const getAccountsFailure = (error: any) => ({
  type: GET_ACCOUNTS.FAILURE,
  error,
});

export const deleteConfigRequest = (data: any) => ({
  type: DELETE_CONFIG.REQUEST,
  data,
});
export const deleteConfigSuccess = (data: any) => ({
  type: DELETE_CONFIG.SUCCESS,
  data,
});
export const deleteConfigFailure = (error: any) => ({
  type: DELETE_CONFIG.FAILURE,
  error,
});

export const setCurrentSettings = (data: any) => ({
  type: SET_CURRENT_SETTINGS,
  data,
});
