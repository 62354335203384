import React from 'react';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import FormCard from '../../../components/FormCard/FormCard';
import Button from '../../../components/CustomButtons/Button';
import { Level, ReminderCosts, EmailSettings, LevelBehavior, SmsSettingsLevel, DebtCollection } from '../types';
import ReminderCostSection from './ReminderCostSection';
import EmailSettingsSection from './EmailSettingsSection';
import BehaviorLevelSection from './BehaviorLevelSection';
import SmsLevelSection from './SmsLevelSection';
import DebtCollectionLevelSection from './DebtCollectionLevel';

type ReminderLevelSectionProps = {
  enabled: boolean;
  settings: Level;
  setSettings: (data: Level) => void;
  onDelete: () => void;
} & LocalizeContextProps;

function ReminderLevelSection(props: ReminderLevelSectionProps) {
  const {enabled, settings, setSettings, translate, onDelete} = props;
  return (
    <FormCard>
      <Grid container={true}>
        <Grid item={true} xs={11} style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
          {translate('reminders.levels.itemTitle') as string} {settings.level}
        </Grid>
        <Grid item={true} xs={1}>
          <Button justIcon={true} round={true} color="transparent" onClick={() => onDelete()}>
            <DeleteIcon />
          </Button>
        </Grid>
        <ReminderCostSection
          enabled={enabled}
          settings={settings.reminderCosts}
          setSettings={(data: ReminderCosts) => setSettings({...settings, reminderCosts: data})}
        />
        <EmailSettingsSection
          enabled={enabled}
          settings={settings.emailSettings}
          setSettings={(data: EmailSettings) => setSettings({...settings, emailSettings: data})}
        />
        <BehaviorLevelSection
          enabled={enabled}
          settings={settings.behavior ?? {}}
          setSettings={(data: LevelBehavior) => setSettings({...settings, behavior: data})}
        />
        <SmsLevelSection
          enabled={enabled}
          settings={settings.smsSettings}
          setSettings={(data: SmsSettingsLevel) => setSettings({...settings, smsSettings: data})}
        />
        <DebtCollectionLevelSection
          enabled={enabled && settings.lastLevel}
          settings={settings.debtCollection}
          setSettings={(data: DebtCollection) => setSettings({...settings, debtCollection: data})}
        />
      </Grid>
    </FormCard>
  );
}

export default withLocalize(ReminderLevelSection);
