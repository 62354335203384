import { clientApp } from './index';

export const getUserInfo = () => clientApp.get(`/ui/user/info`);
export const putUserInfo = (data = []) => clientApp.put(`/ui/user/info`, data);
export const postCustomer = (data: any) => clientApp.post(`/ui/stripe/customer`, data);
export const postSubscription = (data: any) => clientApp.post(`/ui/stripe/subscription`, data);
export const getStripePortal = () => clientApp.get(`/ui/stripe/portal`);
export const hasSubscription = () => clientApp.get(`/ui/user/subscription`);
export const searchSubscriptionProduct = () => clientApp.get(`/ui/stripe/product/user`);
export const postStripeCheckout = (data: any) => clientApp.post(`/ui/stripe/checkout`, data);
export const getNamedKeyValues = (name: string) => clientApp.get(`/ui/user/namedkeyvalues/${name}`);
export const postNamedKeyValue = (name: string, key: string, value: string) => clientApp.post(`/ui/user/namedkeyvalues/${name}/${key}`, { key, value });
export const deleteNamedKeyValue = (name: string, key: string) => clientApp.delete(`/ui/user/namedkeyvalues/${name}/${key}`);

export const getUsers = ({
  sortField = 'id',
  sortOrder = 'desc',
  page = 1,
  resultsPerPage = 10,
  textFilter = '',
}) => {
  const params = new URLSearchParams({
    sortField,
    sortOrder,
    page: page + '',
    resultsPerPage: resultsPerPage + '',
    textFilter,
  });
  return clientApp.get(`/ui/user/admin/userproperties?${params.toString()}`);
}
export const getUserToken = (id: string) => clientApp.get(`/ui/user/admin/auth/token/${id}`);
