// global imports
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie/es6';

// project imports
import Button from '../../components/CustomButtons/Button.jsx';
import * as authActions from '../../store/actions/auth_actions';
import * as bexioActions from '../../store/actions/bexio_actions';
import * as commonActions from '../../store/actions/common_actions';

// project types imports
import { IState } from '../../store/reducers';
import { IStyleProps, PropsClasses } from '../../types/StyleProps';

// local imports
import { config } from '../../config';
import baseStyle from './styles';
import { DispatchProps, OwnProps, Props, StateProps } from './types';

const useStyles = makeStyles<Theme, IStyleProps>(() => baseStyle as StyleRules);

const ConnectWidget: React.FC<Props> = props => {
  const classes: PropsClasses = useStyles({} as IStyleProps);

  const {
    translate,
    appName,
    showTitle,
    error,
    disabled,
    redirect,
    OAuthURL,
    connectionStatus,
    setRedirect,
  } = props;

  // didMount fetch data
  /*useEffect(() => {
    if (!noSync) {
      if (!OAuthURL[appName]) {
        fetchOAuthUrlRequest({ system: appName });
      }
      if (appName === 'bexio') {
        testBexioConnectionRequest({ system: 'bexio' });
        return;
      }
      testConnectionRequest({ system: appName });
    }
  }, []);*/

  const cookies = new Cookies();

  const connectCall = () => {
    if (redirect) {
      setRedirect(redirect);
    }
    const url = OAuthURL[appName];
    switch (appName) {
      case 'shopify':
        window.open('https://apps.shopify.com/bexiosync', '_self');
        break;
      case 'pingen':
        const params = new URL(url).searchParams;
        cookies.set('pingen_state', params.get('state'), { path: '/' });
        cookies.set('pingen_code_verifier', params.get('code_challenge'), { path: '/' });
        window.open(url ? url : '', '_self');
        break;
      // case 'wix':
      // window.open('http://wix.to/TMASC7k', '_self');
      // break;
      default:
        cookies.set('flow', 'connect', { path: '/' });
        window.open(url ? url : '', '_self');
    }
  };

  return (
    <>
      {connectionStatus[appName] !== 'loading' ? (
        connectionStatus[appName] === 'ok' ? (
          <>
            {showTitle ? <p>{translate(`${appName}-connected`)}</p> : null}
            <CheckCircleIcon className={classes.connected} />
          </>
        ) : (
          <div style={{ display: 'flex' }}>
            {showTitle && <p>{translate('press-connect-button')}</p>}
            <Button
              type="button"
              color="primary"
              onClick={connectCall}
              disabled={config.activeDomain === 'bexio' ? false : disabled}
            >
              {translate('connect')}
            </Button>
            <div>{error && <p className={classes.connectError}>{error}</p>}</div>
          </div>
        )
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

const mapStateToProps = (state: IState): StateProps => ({
  OAuthURL: {
    prestashop: state.prestashop.OAuthURL,
    debitoor: state.debitoor.OAuthURL,
    'zoho-books': state['zoho-books'].OAuthURL,
    'zoho-crm': state['zoho-crm'].OAuthURL,
    vend: state.vend.OAuthURL,
    sumup: state.sumup.OAuthURL,
    shopify: state.shopify.OAuthURL,
    bexio: state.bexio.OAuthURL,
    mailchimp: state.mailchimp.OAuthURL,
    wix: state.wix.OAuthURL,
    bitrix: state.bitrix.OAuthURL,
    pingen: state.pingen.OAuthURL,
    'lightspeed-r': state['lightspeed-r'].OAuthURL,
  },
  connectionStatus: {
    prestashop: state.prestashop.connectionStatus,
    debitoor: state.debitoor.connectionStatus,
    'zoho-books': state['zoho-books'].connectionStatus,
    'zoho-crm': state['zoho-crm'].connectionStatus,
    vend: state.vend.connectionStatus,
    sumup: state.sumup.connectionStatus,
    shopify: state.shopify.connectionStatus,
    bexio: state.bexio.connectionStatus,
    mailchimp: state.mailchimp.connectionStatus,
    wix: state.wix.connectionStatus,
    bitrix: state.bitrix.connectionStatus,
    pingen: state.pingen.connectionStatus,
    'lightspeed-r': state['lightspeed-r'].connectionStatus,
  },
});

const mapDispatchToProps = {
  testConnectionRequest: commonActions.testConnectionRequest,
  testBexioConnectionRequest: bexioActions.testConnectionRequest,
  fetchOAuthUrlRequest: authActions.fetchOAuthUrlRequest,
  setRedirect: authActions.setRedirect,
};

export default connect<StateProps, DispatchProps, OwnProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ConnectWidget));
